import {produce} from "immer";
import {loyaltyConstants as constants} from "../constants";

const initialState = {
  modal: {
    open: false,
    modalType: constants.loyaltyTypes.currencyGroup,
    modalSubType: constants.loyaltySubTypes.none,
    title: null,
    subtitle: null,
    newData: null,
    originalData: null,
    saving: false,
    dataToCopy: null,
    copyApplied: null, //null, true, false
    saved: null,
  },
  dialog: {
    open: false,
    loyaltyType: constants.loyaltyTypes.currencyGroup,
    action: null,
    data: null,
    saving: false,
    originalData: null,
    error: null,
    // error: {
    //   title: "",
    //   messages: "",
    // }
  },
  init: false,
  currencyTotalCount: 0,
  currencies: [],
  currencyGroups: [],
  campaignGroups: [],
  campaigns: [],
  rankSystems: [],
  missions: [],
};

const loyalty = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case constants.SHOW_EDIT_MODAL: {
        // console.log("loyalty - SHOW_EDIT_MODAL", {data: action.data});
        draft.modal = {
          ...state.modal,
          ...action.data,
          open: true,
          saving: false,
          saved: null,
          dataToCopy: null,
          copyApplied: null,
        };
        break;
      }


      case constants.HIDE_EDIT_MODAL: {
        // console.log("loyalty - HIDE_EDIT_MODAL", {data: action.data});
        const clearedDataModalState = {
          open: false,
          title: null,
          subtitle: null,
          modalSubType: constants.loyaltySubTypes.none,
          originalData: {...action.data.initialData},
          newData: {...action.data.initialData},
          dataToCopy: null,
          copyApplied: null,
          saving: false,
          saved: null,
        };
        draft.modal = {...clearedDataModalState};
        break;
      }


      case constants.SET_MODAL_STARTING_DATA: {
        // console.log("loyalty - SET_MODAL_STARTING_DATA", {data: action.data});
        const newStartModalState = {
          ...action.data,
          open: false,
          saving: false,
          dataToCopy: null,
          copyApplied: null,
        }
        draft.modal = {...newStartModalState};
        break;
      }


      case constants.SET_MODAL_DATA: {
        // console.log("loyalty - SET_MODAL_DATA", {data: action.data});
        const newStartModalState = {
          ...state.modal,
          ...action.data,
        }
        draft.modal = {...newStartModalState};
        break;
      }


      case constants.INIT_PAGE: {
        // console.log("loyalty - INIT_PAGE", {data: action.data});
        draft.init = action.data.init;
        break;
      }


      case constants.SHOW_DIALOG: {
        // console.log("loyalty - SHOW_DIALOG", {data: action.data});
        draft.dialog = {
          ...state.dialog,
          ...action.data,
          open: true,
        };
        break;
      }


      case constants.HIDE_DIALOG: {
        // console.log("loyalty - HIDE_DIALOG", {data: action.data});
        draft.dialog = {
          open: false,
          dialogType: constants.loyaltyTypes.currencyGroup,
          title: "",
          data: action.data && typeof action.data.revertToOriginal !== "undefined" && action.data.revertToOriginal ? state.dialog.originalData : null,
          saving: false,
        };
        break;
      }

      case constants.SET_DIALOG_DATA: {
        // console.log("loyalty - SET_DIALOG_DATA", {data: action.data});
        draft.dialog = {
          ...state.dialog,
          ...action.data,
        };
        break;
      }


      case constants.LOADED_CURRENCY_TOTAL_COUNT: {
        // console.log("loyalty - LOADED_CURRENCY_TOTAL_COUNT", {data: action.data});
        draft.currencyTotalCount = action.data.total;
        draft.init = true;
        break;
      }


      case constants.LOADED_CURRENCIES: {
        // console.log("loyalty - LOADED_CURRENCIES", {data: action.data});
        draft.currencies = action.data.entities;
        break;
      }


      case constants.LOADED_CURRENCY_GROUPS: {
        // console.log("loyalty - LOADED_CURRENCY_GROUPS", {data: action.data});
        draft.currencyGroups = action.data.entities;
        break;
      }


      case constants.LOADED_CAMPAIGN_GROUPS: {
        // console.log("loyalty - LOADED_CAMPAIGN_GROUPS", {data: action.data});
        draft.campaignGroups = action.data.entities;
        break;
      }

      case constants.LOADED_RANK_CAMPAIGNS:
      case constants.LOADED_CAMPAIGNS: {
        // console.log("loyalty - LOADED_CAMPAIGNS", {data: action.data});
        draft.campaigns = action.data.entities;
        break;
      }


      case constants.LOADED_RANK_SYSTEMS: {
        draft.campaignGroups = action.data.entities || [action.data.data];
        break;
      }


      case constants.LOADED_LOYALTY_MISSIONS: {
        // console.log("loyalty - LOADED_LOYALTY_MISSIONS", {data: action.data});
        draft.missions = action.data.entities;
        break;
      }


      default:
        /* noop */
        break;
    }
  });

export default loyalty;