// ** Dropdowns Imports
import { Fragment } from "react";

// ** Third Party Components
import { Sun, Moon, Menu, ChevronRight } from "react-feather";
import { NavItem, NavLink } from "reactstrap";

import UserDropdown from "./UserDropdown";
import TenantSelector from "./TenantSelector";
import SiteSelector from "./SiteSelector";
import IntlDropdown from "./IntlDropdown";
import themeConfig from "@configs/themeConfig";
import CommandPalette from "../../../../components/CommandPalette";

const NavbarUser = props => {
  // ** Props
  const {
    skin,
    setSkin,
    menuCollapsed,
    menuVisibility,
    setMenuCollapsed,
    setMenuVisibility,
    menuHover,
    setMenuHover,
    setIsHidden,
  } = props;

  // ** Function to toggle Theme (Light/Dark)
  const ThemeToggler = () => {
    if (skin === "dark") {
      return <Sun className="ficon" onClick={() => setSkin("light")} title="Set light mode" />;
    } else {
      return <Moon className="ficon" onClick={() => setSkin("dark")} title="Set dark mode" />;
    }
  };

  return (
    <Fragment>
      <ul className={`navbar-nav  align-items-center`}>
        <NavItem className="mobile-menu mr-auto">
          <NavLink
            className="nav-menu-main menu-toggle hidden-xs is-active"
            onClick={() => {
              if (menuCollapsed) setMenuHover(true);
              setIsHidden(false);
            }}
          >
            <div className="animated-hamburger">
              <div className="a-h-bar a-h-arrow-top"></div>
              <div className="a-h-bar a-h-arrow-middle"></div>
              <div className="a-h-bar a-h-arrow-bottom"></div>
            </div>
          </NavLink>
        </NavItem>
      </ul>
      <h2 className="navbar-app-title">{themeConfig.app.appName}</h2>
      <div id="nav-bar-replaceable" className="d-flex justify-content-between">
        {/* If we remove div from Tenant Selector component the page will break */}
        <div className="d-flex">
          <TenantSelector
            routerProps={props.routerProps}
          />

          <SiteSelector {...props} />
        </div>
        <ul className="nav navbar-nav align-items-center ml-auto">
          <IntlDropdown />
          <CommandPalette />
          <NavItem className="d-none d-lg-block">
            <NavLink className="nav-link-style">
              <ThemeToggler />
            </NavLink>
          </NavItem>
          <UserDropdown />
        </ul>
      </div>
    </Fragment>
  );
};
export default NavbarUser;
