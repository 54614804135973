// ** Third Party Components
import { X } from 'react-feather';

// ** Config
import themeConfig from '@configs/themeConfig'
import { UncontrolledTooltip } from 'reactstrap';
import React, { useState } from 'react';

const VerticalMenuHeader = props => {
  // ** Props
  const { menuCollapsed, setMenuCollapsed, menuHover, setMenuHover, setMenuVisibility, setIsHidden } = props


  // ** Menu toggler component
  const Toggler = () => {
    if (!menuCollapsed) {
      return (
        <svg className='text-primary toggle-icon' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setMenuCollapsed(true)}>
          <path className="line" d="M22.75 9.125C23.0484 9.125 23.3345 9.24353 23.5455 9.4545C23.7565 9.66548 23.875 9.95163 23.875 10.25V21.5C23.875 21.7984 23.7565 22.0845 23.5455 22.2955C23.3345 22.5065 23.0484 22.625 22.75 22.625H9.25C8.95163 22.625 8.66548 22.5065 8.4545 22.2955C8.24353 22.0845 8.125 21.7984 8.125 21.5V10.25C8.125 9.95163 8.24353 9.66548 8.4545 9.4545C8.66548 9.24353 8.95163 9.125 9.25 9.125H22.75ZM9.25 8C8.65326 8 8.08097 8.23705 7.65901 8.65901C7.23705 9.08097 7 9.65326 7 10.25V21.5C7 22.0967 7.23705 22.669 7.65901 23.091C8.08097 23.5129 8.65326 23.75 9.25 23.75H22.75C23.3467 23.75 23.919 23.5129 24.341 23.091C24.7629 22.669 25 22.0967 25 21.5V10.25C25 9.65326 24.7629 9.08097 24.341 8.65901C23.919 8.23705 23.3467 8 22.75 8H9.25Z" fill="#D1D1D1" />
          <path className="rectangle" d="M10 11.8333C10 11.3731 10.4477 11 11 11H13C13.5523 11 14 11.3731 14 11.8333V20.1667C14 20.6269 13.5523 21 13 21H11C10.4477 21 10 20.6269 10 20.1667V11.8333Z" fill="#AAAAAA" />
        </svg>
      );
    } else {
      return (
        <svg className='text-primary toggle-icon' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setMenuCollapsed(false)}>
          <rect className="line" x="7" y="9" width="4" height="14" rx="1" fill="#AAAAAA" />
          <rect className="rectangle2" x="13.5" y="8.5" width="11" height="15" rx="1.5" stroke="#D1D1D1" />
        </svg>
      );
    }
  }

  const hideMenu = () => {
    setIsHidden(true);
  }

  return (
    <div className='navbar-header'>
      <ul className='nav navbar-nav flex-row'>
        <li className='nav-item mr-auto'>
          <div className='navbar-brand'>
            <div className="chevron-holder" onClick={hideMenu}>
              <X size={24} />
            </div>
            <h2 className='brand-text mb-0'>{themeConfig.app.appName}</h2>
          </div>
        </li>
        <li className='nav-item nav-toggle'>
          <div className='nav-link modern-nav-toggle cursor-pointer'>
            <div id="sidebar-menu-layout-toggler">
              <Toggler />
            </div>
            <UncontrolledTooltip
              target={'sidebar-menu-layout-toggler'}
            >
              {!menuCollapsed ? "Switch to fullscreen layout" : "Switch to sidebar layout"}
            </UncontrolledTooltip>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default VerticalMenuHeader
