import { tableFiltersSorting } from "./../constants";

export const tableFilterSortingClear = (data) => ({
  type: tableFiltersSorting.TABLE_FILTER_SORTING_CLEAR,
  data,
});

export const tableFilterSortingSetData = (data) => ({
  type: tableFiltersSorting.TABLE_FILTER_SORTING_SET_DATA,
  data,
});

