import {takeEvery, put} from "redux-saga/effects";
import {delay} from "redux-saga/effects";

import store from "..";
import {tableSearchFilterConstants as Constants} from "../constants";
import {
  loadTableSearchesFromStorage,
  saveTableSearchInStorage,
  tableSearchStorageTypes,
  updateTableSearchInStorage,
} from "src/components/table-search-filters/common/utils/local-storage-manager";
import {
  tableFilterStorePresetDefinition,
  tableFilterStoredPresetDefinition,
  tableFilterLoadAllPresetDefinitions,
  tableFilterSetAllPresetDefinitions,
} from "@store/actions/table-search-filter";
import {cloneDeep} from "lodash/fp";

function* tableSearchSaveLastVisitedResultSaga(action) {
  const {tableSearchFilter} = store.getState();
  const {data} = action;

  // console.log("SAGA tableSearchSaveLastVisitedResultSaga", data);
  if (data == null || data.storageKey == null || !data.storageKey.length || data.result == null || data.displayData == null) {
    return;
  }

  try {
    const storageType = tableSearchStorageTypes.SEARCH;
    saveTableSearchInStorage(storageType, data.storageKey, {
      clientId: data.result.clientId,
      data: data.result,
      displayData: data.displayData,
    });
    yield delay(1000);
  } catch (e) {
    console.error("Failed to save table search result to local storage", e.toString());
  }
}

function* tableSearchGetLastRequestSentSaga(action) {
  const {tableSearchFilter} = store.getState();
  const {data} = action;

  // console.log("SAGA tableSearchSetLastRequestSentSaga", data);
  if (
    !data ||
    !data.storageKey ||
    !data.storageKey.length ||
    !data.searchField ||
    !data.searchValue
  ) {
    return;
  }

  try {
    const storageType = tableSearchStorageTypes.SEARCH;
    const searches = loadTableSearchesFromStorage(storageType, data.storageKey);

    // console.log("SAGA tableSearchSetLastRequestSentSaga stored searches found", {
    //   searches,
    //   searchField: data.searchField,
    //   searchValue: data.searchValue,
    // });

    let storedResults = null;
    if (searches != null) {
      if (typeof searches.clientIds !== "undefined") {
        if (typeof data.clientId === "undefined" || typeof searches.clientIds[data.clientId] === "undefined") {
          yield put({
            type: Constants.SET_SEARCH_LAST_VISITED_RESULT,
            data: {
              lastVisitedResult: null,
            },
          });
          return;
        }

        storedResults = searches.clientIds[data.clientId];
        // console.log("SAGA tableSearchSetLastRequestSentSaga stored results found", storedResults);
        if (storedResults == null || storedResults.data == null || storedResults.displayData == null) {
          yield put({
            type: Constants.SET_SEARCH_LAST_VISITED_RESULT,
            data: {
              lastVisitedResult: null,
            },
          });
          return;
        }

        const storedData = storedResults?.data[data.searchField] != null ? `${storedResults.data[data.searchField]}` : "";
        if (storedData !== "" && storedData.toLocaleLowerCase().includes(data.searchValue.toLocaleLowerCase())) {
          yield put({
            type: Constants.SET_SEARCH_LAST_VISITED_RESULT,
            data: {
              lastVisitedResult: storedResults.displayData,
            },
          });
        } else {
          yield put({
            type: Constants.SET_SEARCH_LAST_VISITED_RESULT,
            data: {
              lastVisitedResult: null,
            },
          });
        }
      }

      yield delay(1000);
    }
  } catch (e) {
    console.error("Failed to save table search result to local storage", e.toString());
  }
}

function* tableFilterStoreLastSearchSaga(action) {
  const {tableSearchFilter,} = store.getState();
  const {data} = action;

  // console.log("SAGA tableFilterStoreLastSearchSaga", data);
  if (
    !data ||
    !data.storageKey ||
    !data.storageKey.length ||
    !data.filters ||
    !(Array.isArray(data.filters) && data.filters.length)
  ) {
    return;
  }

  try {
    const storageType = tableSearchStorageTypes.FILTER_LAST_SEARCH;
    saveTableSearchInStorage(storageType, data.storageKey, {
      clientId: data.clientId,
      filters: data.filters,
    });
    // yield delay(1000);
    yield put({
      type: Constants.SAVE_FILTER_LAST_SEARCH,
      data: {filters: data.filters},
    });
  } catch (e) {
    console.error("Failed to save table filter last search to local storage", e.toString());
  }
}

function* tableFilteredSearchLoadStoredLastSearchSaga(action) {
  const {tableSearchFilter} = store.getState();
  const {data} = action;

  // console.log("SAGA tableFilteredSearchLoadStoredLastSearchSaga", data);
  if (
    !data ||
    !data.storageKey ||
    !data.storageKey.length
  ) {
    return;
  }

  try {
    const storageType = tableSearchStorageTypes.FILTER_LAST_SEARCH;
    const searches = loadTableSearchesFromStorage(storageType, data.storageKey);

    let storedResults = null;
    if (searches != null) {
      if (typeof searches.clientIds !== "undefined") {
        if (typeof data.clientId === "undefined" || typeof searches.clientIds[data.clientId] === "undefined") {
          yield put({
            type: Constants.SAVE_FILTER_LAST_SEARCH,
            data: {filters: []},
          });
          return;
        }

        storedResults = searches.clientIds[data.clientId];
        // console.log("SAGA tableFilteredSearchLoadStoredLastSearchSaga stored results found", storedResults);
        if (storedResults == null || storedResults.filters == null || !Array.isArray(storedResults.filters)) {
          yield put({
            type: Constants.SAVE_FILTER_LAST_SEARCH,
            data: {filters: []},
          });
          return;
        } else {
          yield put({
            type: Constants.SAVE_FILTER_LAST_SEARCH,
            data: {filters: storedResults.filters},
          });
        }
      }

      yield delay(1000);
    }
  } catch (e) {
    console.error("Failed to set table filter last search from local storage", e.toString());
  }
}

function* tableFilterStorePresetDefinitionSaga(action) {
  const {tableSearchFilter,} = store.getState();
  const {data} = action;

  // console.log("SAGA tableFilterStorePresetDefinitionSaga", data);
  if (
    !data ||
    !data.storageKey ||
    !data.storageKey.length ||
    !data.presetDefinition
  ) {
    return;
  }

  try {
    const storageType = tableSearchStorageTypes.FILTER;
    saveTableSearchInStorage(storageType, data.storageKey, {
      clientId: data.presetDefinition.clientId,
      originalViewName: data.presetDefinition.originalViewName,
      viewName: data.presetDefinition.viewName,
      filters: data.presetDefinition.filters,
      default: data.presetDefinition.default,
    });
    yield put(tableFilterStoredPresetDefinition({success: true, error: null}));
  } catch (e) {
    console.error("Failed to save table filter preset definition to local storage.", e.toString());
    yield put(tableFilterStoredPresetDefinition({success: false, error: e}));
  }
}

function* tableFilterLoadAllPresetDefinitionSaga(action) {
  const {tableSearchFilter,} = store.getState();
  const {data} = action;

  // console.log("SAGA tableFilterLoadAllPresetDefinitionSaga", data);
  if (
    !data ||
    !data.storageKey ||
    !data.storageKey.length ||
    !data.clientId
  ) {
    return;
  }

  const presets = data.presets.map(
    (t) => {
      const copyT = cloneDeep(t);
      copyT.custom = false;
      return copyT;
    }) || [];

  try {
    const storageType = tableSearchStorageTypes.FILTER;

    const customPresets = loadTableSearchesFromStorage(storageType, data.storageKey).clientIds[data.clientId] || [];
    // console.log("SAGA tableFilterLoadAllPresetDefinitionSaga customPresets", customPresets);
    customPresets.forEach((preset) => {
      const copyPreset = cloneDeep(preset);
      copyPreset.custom = true;
      presets.push(copyPreset);
    });
  } catch (e) {
    console.error("Failed to load filter preset definitions from local storage.", e.toString());
  } finally {
    yield put(tableFilterSetAllPresetDefinitions({presets: presets}));
  }
}

function* tableFilterUpdateAllPresetDefinitionsSaga(action) {
  const {tableSearchFilter,} = store.getState();
  const {data} = action;

  // console.log("SAGA tableFilterUpdateAllPresetDefinitionsSaga", data);
  if (
    !data ||
    !data.storageKey ||
    !data.storageKey.length ||
    !data.clientId ||
    !data.presets ||
    !Array.isArray(data.presets)
  ) {
    return;
  }

  const copyPresets = data.presets
    .filter((p) => p.custom === true)
    .map(
      (p) => {
        const copyP = cloneDeep(p);
        if (Object.keys(copyP).includes("custom")) {
          delete copyP.custom;
        }
        return copyP;
      }) || [];

  let presets = data.presets;
  try {
    const storageType = tableSearchStorageTypes.FILTER;
    updateTableSearchInStorage(storageType, data.storageKey, {
      clientId: data.clientId,
      presets: copyPresets,
    });

    const presets = data.presets.filter((p) => !p.custom) || [];
    yield put(tableFilterLoadAllPresetDefinitions({
      storageKey: data.storageKey,
      clientId: data.clientId,
      presets: presets,
    }));
  } catch (e) {
    console.error("Failed to load filter preset definitions from local storage.", e.toString());
  }
}

export default function* watchTableSearchFilterSaga() {
  yield takeEvery(Constants.SAVE_SEARCH_LAST_VISITED_RESULT, tableSearchSaveLastVisitedResultSaga);
  yield takeEvery(Constants.GET_SEARCH_LAST_VISITED_RESULT, tableSearchGetLastRequestSentSaga);
  yield takeEvery(Constants.STORE_FILTER_LAST_SEARCH, tableFilterStoreLastSearchSaga);
  yield takeEvery(Constants.LOAD_FILTER_LAST_SEARCH, tableFilteredSearchLoadStoredLastSearchSaga);
  yield takeEvery(Constants.STORE_FILTER_PRESET_DEFINITION, tableFilterStorePresetDefinitionSaga);
  yield takeEvery(Constants.LOAD_ALL_FILTER_PRESET_DEFINITIONS, tableFilterLoadAllPresetDefinitionSaga);
  yield takeEvery(Constants.UPDATE_STORED_ALL_FILTER_PRESET_DEFINITIONS, tableFilterUpdateAllPresetDefinitionsSaga);
}