import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { X, Search, CheckCircle, Circle, RotateCw } from "react-feather";

import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

import { Modal, ModalBody, Input, InputGroup, InputGroupText } from "reactstrap";

import DefaultTenantLogo from "./assets/default-tenant-logo.svg";

import "./TenantSelector.scss";
import { setCurrentTenant } from "../../../../store/actions/tenants";

const toLocalStorage = val => {
  let data = localStorage.getItem("recentTenantsSelected");
  if (!data) {
    data = [];
  } else {
    data = JSON.parse(data);
  }

  let filtered = data.filter(t => t.id.toString() !== val.id.toString());
  filtered.unshift(val);

  filtered = filtered.slice(0, 3);
  localStorage.setItem("recentTenantsSelected", JSON.stringify(filtered));
  return filtered;
};
const fromLocalStorage = () => {
  let data = localStorage.getItem("recentTenantsSelected");
  if (!data) {
    data = [];
  } else {
    data = JSON.parse(data);
  }
  return data;
};

const TenantSelector = props => {
  const { routerProps } = props;

  const routePath = routerProps?.match?.path ?? null;
  const routeClientId = !!routerProps?.match?.params && (routerProps.match.params.clientId || routerProps.match.params.tenantId) ? routerProps.match.params.clientId || routerProps.match.params.tenantId : null;

  const state = useSelector(state => ({
    tenants: state.tenants.tenants,
    pagesWithSystem: state.tenants.pagesWithSystem,
    currentTenant: state.tenants.currentTenant,
    previousTenant: state.tenants.previousTenant,
  }));
  const dispatch = useDispatch();

  const [query, setQuery] = React.useState("");
  const [showSelector, setShowSelector] = React.useState(false);
  const handleShowSelector = () => {
    setShowSelector(true);
  };
  const closeSelector = () => {
    setShowSelector(false);
  };
  const handleQuery = e => {
    setQuery(e.target.value);
  };

  const supportsSystem = routePath && state.pagesWithSystem ? !!state.pagesWithSystem[routePath] : false;

  const filteredTenants = state.tenants.filter(t => {
    if (supportsSystem) return true;
    return t.id !== "0";
  });

  const queryFilteredTenants = filteredTenants.filter(t => {
    if (query) {
      if (t.label.toLowerCase().indexOf(query.toLowerCase()) > -1) return true;
      return false;
    }
    return true;
  });

  const setSelectedTenant = value => {
    dispatch(setCurrentTenant(value.id.toString()));
  };
  const selectTenant = e => {
    if (e.currentTarget && e.currentTarget.dataset && e.currentTarget.dataset.tenant) {
      const data = JSON.parse(e.currentTarget.dataset.tenant);
      toLocalStorage(data);
      setSelectedTenant(data);
      closeSelector();
    }
  };

  React.useEffect(() => {
    if (routeClientId != null) {
      const supportsSystem = routePath && state.pagesWithSystem ? !!state.pagesWithSystem[routePath] : false;

      const paramsTenantId = routeClientId;
      if (paramsTenantId && paramsTenantId !== state.currentTenant) {
        if (supportsSystem) {
          setSelectedTenant({ id: paramsTenantId });
        } else {
          if (paramsTenantId !== "0") {
            setSelectedTenant({ id: paramsTenantId });
          }
        }
      }
    }
  }, [routeClientId, routePath, state.currentTenant, state.pagesWithSystem]);

  React.useEffect(() => {
    const actualValue = filteredTenants.find(
      t => t.id && state.currentTenant && t.id.toString() === state.currentTenant.toString()
    );
    if (!actualValue) {
      if (state.previousTenant !== "") {
        setSelectedTenant({ id: state.previousTenant });
      } else if (filteredTenants && filteredTenants.length) {
        setSelectedTenant({ id: filteredTenants[0].id });
      }
    }
  }, [routePath, state.currentTenant, state.previousTenant, filteredTenants]);

  const actualValue = filteredTenants.find(
    t => t.id && state.currentTenant && t.id.toString() === state.currentTenant.toString()
  );
  if (!actualValue) {
    if (filteredTenants.length === 0) return null;
  }

  const letters = {};
  const getLetter = letter => {
    if (typeof letters[letter] === "undefined") {
      letters[letter] = true;
      return letter;
    }
    return <span>&nbsp;</span>;
  };

  const recentSelection = fromLocalStorage();

  queryFilteredTenants.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div className="nav-bar-tenant-selector">
      <div className="nav-bar-tenant-selector-wrapper" onClick={handleShowSelector}>
        <div className="tenant-title">{actualValue ? actualValue.label : "UNKNOWN"}</div>
        <div className="tenant-selector">Change tenant</div>
      </div>
      <div className="fill">&nbsp;</div>
      <Modal
        isOpen={showSelector}
        toggle={closeSelector}
        modalClassName="tenant-selector-modal-parent"
        className="tenant-selector-modal"
      >
        <ModalBody className="p-0">
          <div className="nav-bar">
            <div>
              <div className="tenant">{actualValue ? actualValue.label : "UNKNOWN"}</div>
              <div className="sep"></div>
              <div className="label">Switch tenant</div>
              <div className="full"></div>
              <div className="close" onClick={closeSelector}>
                <X className="ficon" />
              </div>
            </div>
          </div>
          <div className="content-container">
            <div className="tenants-list">
              {recentSelection.length != 0 && (
                <div className="recent-tenant-selection">
                  <div className="recent-title">
                    <RotateCw size={10} /> Recent tenants
                  </div>
                  <div className="recent-list">
                    {recentSelection.map((t, i) => {
                      return (
                        <div className="tenant" key={i} data-tenant={JSON.stringify(t)} onClick={selectTenant}>
                          <div className="logo">
                            <img src={DefaultTenantLogo} alt="" />
                          </div>
                          <div className="fill">
                            <div className="label">{t.label}</div>
                            <div className="sublabel">www</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              <div className="query">
                <InputGroup className="input-group-merge">
                  <InputGroupText>
                    <Search size={16} />
                  </InputGroupText>
                  <Input type="text" value={query} onChange={handleQuery} placeholder="Search tenant" />
                </InputGroup>
              </div>
              <div className="list">
                {queryFilteredTenants.map((t, i) => {
                  return (
                    <div
                      key={i}
                      className={`d-flex ${actualValue && t.id.toString() === actualValue.id.toString() ? "selected" : ""
                        }`}
                    >
                      <div className="letter">{getLetter(t.label[0].toUpperCase())}</div>
                      <div className="tenant" data-tenant={JSON.stringify(t)} onClick={selectTenant}>
                        <div className="logo">
                          <img src={DefaultTenantLogo} alt="" />
                        </div>
                        <div className="fill">
                          <div className="label">{t.label}</div>
                          <div className="sublabel">www</div>
                        </div>
                        <div className="selected">
                          {(!actualValue || t.id.toString() !== actualValue.id.toString()) && (
                            // <Circle className="ficon" size={16} />
                            <RadioButtonUncheckedIcon className="ficon" size={16} />
                          )}
                          {actualValue && t.id.toString() === actualValue.id.toString() && (
                            // <CheckCircle className="ficon checked" size={16} />
                            <RadioButtonCheckedIcon className="ficon checked" size={16} />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default TenantSelector;
