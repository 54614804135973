import React from "react";
import { connect } from "react-redux";
import MaterialTable from "src/components/table-customization/MaterialTable";
import axios from "src/store/_shared/authAxios";
import { cloneDeep } from "lodash/fp";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useQueryParams, StringParam, withDefault } from "use-query-params";
import { CsvBuilder } from "filefy";
import TablePagination from "@mui/material/TablePagination";
import TableFilters from "src/components/table-filters/table-filters";
import { tableFilterSortingClear, tableFilterSortingSetData } from "src/store/actions/table-filters-sorting";

import "./Airdrop.scss";

import { operationsAccess, accessPages, utils } from "src/utils/users";
import { buildApiUrl } from "src/utils/api";

function AirdropPlayersOperationsDataList(props) {
  const { currentTenant, apiURL } = props;
  const rootPath = window.ini.REACT_APP_ROOT_PATH;
  const history = useHistory();
  const opAccess = operationsAccess(accessPages.AIRDROP_PLAYERS_OPERATIONS);

  const tableRef = React.useRef();
  const [pageSize, setPageSize] = React.useState(20);

  const [values, setValues] = useQueryParams(
    {
      player_id: withDefault(StringParam, ""),
      operation_type: withDefault(StringParam, ""),
      campaign_id: withDefault(StringParam, ""),
      tenant_id: withDefault(StringParam, currentTenant),
    },
    {
      updateType: "replaceIn",
    }
  );

  const oldTenantId = React.useRef(-1);

  const filters = [
    {
      type: "input",
      key: "campaign_id",
      label: "Campaign ID",
      value: values.campaign_id,
    },
    {
      type: "input",
      key: "player_id",
      label: "Player ID",
      value: values.player_id,
    },
    {
      type: "input",
      key: "operation_type",
      label: "Operation Type",
      value: values.operation_type,
    },
  ];

  React.useEffect(() => {
    const copyFilters = [];
    filters.forEach((filter, i) => {
      if (
        filter &&
        filter.value !== null &&
        filter.value !== undefined &&
        filter.value !== "" &&
        !(Array.isArray(filter.value) && filter.value.length === 0)
      ) {
        let value = cloneDeep(filter.value);

        copyFilters.push({
          filterIndex: i.toString(),
          value: value,
          applied: true,
        });
      }
    });

    let sortBy = null;
    let sortDirection = null;

    state.columns.find(c => {
      if (c.defaultSort) {
        sortBy = { label: c.title, value: c.field };
        const directions = {
          asc: "Ascendent",
          desc: "Descendent",
        };
        sortDirection = { label: directions[c.defaultSort], value: c.defaultSort };
      }
      return false;
    });

    props.tableFilterSortingSetData({
      filtersSelected: copyFilters,
      filtersDeleted: [],
      changed: true,
      sortBy,
      sortDirection,
    });

    return () => {
      // clear the sorting/filter component upon page exit
      props.tableFilterSortingClear();
    };
  }, []);

  React.useEffect(() => {
    if (currentTenant) {
      setValues(v => ({ ...v, tenant_id: currentTenant }));
      if (oldTenantId.current !== -1) reloadData();
      oldTenantId.current = currentTenant;
    }
  }, [currentTenant]);

  const [state] = React.useState({
    columns: [
      {
        title: "Player ID",
        field: "player_id",
        filtering: false,
        defaultSort: "desc",
      },
      {
        title: "Campaign ID",
        field: "campaign_id",
        filtering: false,
      },
      {
        title: "Operation Type",
        field: "operation_type",
        filtering: false,
      },
      {
        title: "Operation Key",
        field: "operation_key",
        filtering: false,
      },
      {
        title: "Operation Timestamp",
        field: "operation_value",
        filtering: false,
        render: rowData => {
          if (!rowData.operation_value?.timestamp) return <div>{""}</div>;

          return <div>{moment(rowData.operation_value.timestamp).format("YYYY-MM-DD HH:mm:ss")}</div>;
        },
      },
      {
        title: "Prize ID",
        field: "operation_value",
        filtering: false,
        render: rowData => {
          if (!rowData.operation_value?.prize_id) return <div>{""}</div>;

          return <div>{rowData.operation_value.prize_id}</div>;
        },
      },
    ],
  });

  const handleRowClick = (ev, data) => {
    if (!opAccess.canGet) return;
    if (!data.id) return;
    history.push(`${rootPath}/airdrop/prizes/${data.id}`);
  };

  const onRowsPerPageChange = (event, props) => {
    if (event.target.value) {
      let pageSize = event.target.value;
      if (event.target.value === -1 && tableRef.current) {
        pageSize = tableRef.current.dataManager.data.length;
      }
      setPageSize(pageSize);
    }
    props.onRowsPerPageChange(event);
  };

  const reloadData = () => {
    if (tableRef && tableRef.current && typeof tableRef.current.onQueryChange === "function")
      tableRef.current.onQueryChange();
  };

  const filtersComponent = props => {
    return (
      <TableFilters
        storageKey="airdrop-players-ops-list-component"
        tableRef={tableRef}
        portalId="table-filters"
        filters={filters}
        onFilterChange={updates => {
          const newValues = cloneDeep(values);
          updates.forEach(update => {
            newValues[update.key] = update.value;
          });
          setValues(newValues);
          reloadData();
        }}
        {...props}
      />
    );
  };

  return (
    <div className="airdrop-campaigns-component fixed-actions">
      <div id="table-filters" />
      <MaterialTable
        tableRef={tableRef}
        title=" "
        columns={state.columns}
        options={{
          pageSize,
          search: false,
          debounceInterval: 1000,
          filtering: false,
          pageSizeOptions: [5, 10, 20, 35, 50],
          emptyRowsWhenPaging: false,
          columnsButton: true,
          actionsColumnIndex: 20,
          /*exportMenu: opAccess.canExport
            ? [
                {
                  label: "Export CSV",
                  exportFunc: (columns, data) => {
                    new Promise((resolve, reject) => {
                      if (!Array.isArray(columns) || !columns.length) return;

                      const builder = new CsvBuilder("PlayersOperationsData.csv");
                      const rows = [];

                      const exportData = {
                        limit: 0, // Todo: intreaba daca las 50 sau pun -1 sau 0 sau ceva ...
                        offset: 0,
                        filter: [],
                        sort: [],
                        // columns: [],
                        tenant_id: currentTenant,
                      };

                      if (
                        columns.orderByCollection &&
                        columns.orderByCollection.length &&
                        columns.orderByCollection[0].orderDirection
                      ) {
                        exportData.sort.push({
                          name: state.columns[columns.orderByCollection[0].orderBy].field,
                          dir: columns.orderByCollection[0].orderDirection,
                        });
                      }

                      if (values.operation_type) {
                        exportData.filter = exportData.filter || [];
                        exportData.filter.push({ name: "operation_type", value: values.operation_type });
                      }

                      if (values.player_id) {
                        exportData.filter = exportData.filter || [];
                        exportData.filter.push({ name: "player_id", value: values.player_id });
                      }

                      if (values.campaign_id) {
                        exportData.filter = exportData.filter || [];
                        exportData.filter.push({ name: "campaign_id", value: parseInt(values.campaign_id, 10) });
                      }

                      // TODO: replace with correct API endpoint
                      resolve();
                      return;

                      axios
                        .post(
                          `${buildApiUrl(`/admin/airdrop-campaigns/players/operations/list?export=true`)}`,
                          exportData
                        )
                        .then(function (response) {
                          const resp = response.data;
                          console.log(`debug Players Operations EXPORT response: `, resp);

                          resp.data &&
                            Array.isArray(resp.data) &&
                            resp.data.forEach(item => {
                              const row = [];
                              columns.forEach(col => {
                                if (col.field === "operation_value") {
                                  if (col.title === "Operation Timestamp") {
                                    row.push(moment(item[col.field].timestamp).format("YYYY-MM-DD HH:mm:ss"));
                                  }
                                  if (col.title === "Prize ID") {
                                    row.push(item[col.field].prize_id);
                                  }
                                } else {
                                  row.push(item[col.field]);
                                }
                              });
                              rows.push(row);
                            });

                          builder
                            .setDelimeter(",")
                            .setColumns(columns.map(c => c.title))
                            .addRows(rows)
                            .exportFile();

                          resolve();
                        })
                        .catch(function (error) {
                          console.log(error);
                        });
                    });
                  },
                },
              ]
            : [],*/
        }}
        onOrderChange={(orderedColumnId, orderDirection) => {
          const directions = {
            "": "None",
            asc: "Ascendent",
            desc: "Descendent",
          };

          let sortBy = { label: state.columns[orderedColumnId].title, value: state.columns[orderedColumnId].field };
          let sortDirection = { label: directions[orderDirection], value: orderDirection };

          props.tableFilterSortingSetData({ sortBy, sortDirection });
        }}
        components={{
          Toolbar: filtersComponent,
          Pagination: props => {
            return (
              <TablePagination
                {...props}
                onRowsPerPageChange={event => {
                  onRowsPerPageChange(event, props);
                }}
              />
            );
          },
        }}
        data={
          !opAccess.canList
            ? utils.noop
            : query =>
                new Promise((resolve, reject) => {
                  const postData = {
                    limit: query.pageSize,
                    offset: query.page * query.pageSize,
                    filter: [],
                    sort: [],
                    tenant_id: currentTenant,
                  };

                  if (
                    query.orderByCollection &&
                    query.orderByCollection.length &&
                    query.orderByCollection[0].orderDirection
                  ) {
                    postData.sort.push({
                      name: state.columns[query.orderByCollection[0].orderBy].field,
                      dir: query.orderByCollection[0].orderDirection,
                    });
                  }

                  if (values.operation_type) {
                    postData.filter = postData.filter || [];
                    postData.filter.push({ name: "operation_type", value: values.operation_type });
                  }

                  if (values.player_id) {
                    postData.filter = postData.filter || [];
                    postData.filter.push({ name: "player_id", value: values.player_id });
                  }

                  if (values.campaign_id) {
                    postData.filter = postData.filter || [];
                    postData.filter.push({ name: "campaign_id", value: parseInt(values.campaign_id, 10) });
                  }

                  axios
                    .post(`${buildApiUrl(`/admin/airdrop-campaigns/players/operations/list`)}`, postData)
                    .then(function (response) {
                      const resp = response.data;
                      // console.log(`debug Players Operations response: `, resp);

                      const rows = [];

                      resp.data &&
                        Array.isArray(resp.data) &&
                        resp.data.forEach(item => {
                          const object = Object.assign({}, item);

                          rows.push(object);
                        });

                      const res = {
                        data: rows,
                        page: resp.total < pageSize ? 0 : query.page,
                        totalCount: resp.total,
                      };

                      resolve(res);
                    })
                    .catch(function (error) {
                      console.log(error);
                    });
                })
        }
        // onRowClick={!opAccess.canGet ? null : handleRowClick}
      />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    tenants: state.tenants.tenants,
    currentTenant: state.tenants.currentTenant,
  };
};

const mapActions = {
  tableFilterSortingClear,
  tableFilterSortingSetData,
};

export default connect(mapStateToProps, mapActions)(AirdropPlayersOperationsDataList);
