import React from 'react';


import { isDateTime, toUtc } from './data';
import { TimeRangePicker } from './TimeRangePicker';
import { LocalStorageValueProvider } from './store/LocalStorageValueProvider';

const LOCAL_STORAGE_KEY = 'core.dashboard.timepicker.history';

export const TimePickerWithHistory = props => {
  return (<LocalStorageValueProvider storageKey={LOCAL_STORAGE_KEY} defaultValue={[]}>
    {(values, onSaveToStore) => {
      return (<TimeRangePicker {...props} history={convertIfJson(values)} onChange={value => {
        if (value && value.from && value.to && value.from.isValid() && value.to.isValid()) {
          onAppendToHistory(value, values, onSaveToStore);
        }
        props.onChange(value);
      }} />);
    }}
  </LocalStorageValueProvider>);
};

function convertIfJson(history) {
  return history.map(time => {
    if (isDateTime(time.from)) {
      return time;
    }
    return {
      from: toUtc(time.from),
      to: toUtc(time.to),
      raw: time.raw,
    };
  });
}

function onAppendToHistory(toAppend, values, onSaveToStore) {
  if (!isAbsolute(toAppend)) {
    return;
  }
  const toStore = limit([toAppend, ...values]);
  onSaveToStore(toStore);
}

function isAbsolute(value) {
  return isDateTime(value.raw.from) || isDateTime(value.raw.to);
}

function limit(value) {
  return value.slice(0, 4);
}