import React from "react";
import FormControl from "@mui/material/FormControl";
import { connect } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';

import "../../../../components/ui/ui-style.scss";
import "./MultiSelect.scss";
import { loadTags } from "../../../../store/actions/tags";
import {getContrastFromRatioValue, getContrastRatioValue} from "../../../../utils/colors";
import VirtualizedSelectOption from "../../../../components/ui/ui-components/virtualized-select-option";

const colourStyles = {
  singleValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: data.background + "!important",
      color: data.color + "!important",
      border: data.border ? data.border + "!important" : "",
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: data.background + "!important",
      color: data.color + "!important",
      border: data.border ? data.border + "!important" : "",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    backgroundColor: data.background + "!important",
    color: data.color + "!important",
  }),

};

const MultiSelect = ({ value, setValue, readonly, placeholder, customProps, dataSource, loadTags, menuPlacement }) => {
  const [classesList, setClassesList] = React.useState([]);
  const [selectedClass, setSelectedClass] = React.useState("");
  const [tagsList, setTagsList] = React.useState([]);

  React.useEffect(() => {
    setClassesList(dataSource.classes)
  }, [dataSource.classes]);

  React.useEffect(() => {
    if (selectedClass) {
      if (typeof dataSource.tags[selectedClass] === "undefined") {
        loadTags({ tag_class_id: selectedClass });
      } else {
        setTagsList(dataSource.tags[selectedClass]);
      }
    }
  }, [dataSource.tags, dataSource.tags_loading, selectedClass, loadTags]);

  React.useEffect(() => {
    if (value && value.length && !selectedClass) {
      const tag_class_id = value[0].split("-", 2)[0];
      loadTags({ tag_class_id: tag_class_id });
      setSelectedClass(tag_class_id);
    }
  }, [value, selectedClass, loadTags]);

  const onClassChange = e => {

    if (e.target.value === undefined)
      return;
    setSelectedClass(e.target.value);
    setValue([]);
  };

  const hasValue = value != null && value.length > 0;

  const onChange = e => {
    if (e.target.value === undefined)
      return;
    setValue(e.target.value);
  };

  const showLoader = !(selectedClass !== "" && typeof dataSource.tags[selectedClass] !== 'undefined') && selectedClass !== "";

  if (hasValue && !selectedClass && tagsList.length === 0 && classesList.length === 0) {
    return <div className="rule-select">
      <div className="loader">
        <CircularProgress size={20} />
      </div>
    </div>
  }

  return (
    <div className="rule-multi-select">
      <div className="style-editor options-panel">
        <div className="option-group">
          <FormControl className="d-inline-block">
            <VirtualizedSelectOption
              name={"select_tag_class"}
              label={""}
              value={selectedClass && classesList.length ? selectedClass : ""}
              onChange={onClassChange}
              options={classesList}
              searchable={true}
              valueKey={"id"}
              labelKey={"name"}
              className="select-wrapper"
              menuPlacement={menuPlacement}
              disabled={readonly}
            />
          </FormControl>
          {showLoader && dataSource.tags_loading && <div className="loader">
            <CircularProgress size={20} />
          </div>}
          {!showLoader && selectedClass !== "" && <FormControl className="d-inline-block">
            <VirtualizedSelectOption
              value={hasValue && tagsList && tagsList.length ? value : []}
              onChange={onChange}
              options={tagsList && tagsList.length ? tagsList.map(({ id, name }) => {
                let color = "#ffffff";
                if (selectedClass && dataSource.classes) {
                  const tagClass = dataSource.classes.find(cls => cls.id === selectedClass);
                  if (tagClass && tagClass.color) {
                    color = tagClass.color;
                  }
                }
                const contrastValue = getContrastRatioValue(color);
                const fontColor = getContrastFromRatioValue(contrastValue);

                const style = {
                  background: color,
                  color: fontColor,
                  WebkitTextFillColor: fontColor,
                };
                if (contrastValue >= 230) {
                  style.border = `1px solid #d8d6de80`;
                }

                return {
                  name,
                  id: `${selectedClass}-${id}`,
                  ...style
                };
              }) : []}
              clearable={true}
              searchable={true}
              multi={true}
              disabled={readonly}
              valueKey={"id"}
              labelKey={"name"}
              className="select-wrapper"
              valueType={"array"}
              styles={colourStyles}
              menuPlacement={menuPlacement}
            />
          </FormControl>}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  dataSource: state.classes
});

const mapActions = {
  loadTags,
};

export default connect(mapStateToProps, mapActions)(MultiSelect);