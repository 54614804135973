import { casinoConstants } from "../constants";

export const doCasinoAuthentication = () => ({
  type: casinoConstants.INIT
});

export const doneCasinoAuthentication = data => ({
  type: casinoConstants.AUTH,
  data
});
