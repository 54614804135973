import { produce } from "immer";
import { jackpotConstants } from "../constants";

const initialState = {
  selected_sources: [],
};

const jackpotReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case jackpotConstants.SET_SELECTED_JACKPOT_SOURCES: {
        if (typeof action.data !== "undefined") draft.selected_sources = action.data;

        break;
      }

      case jackpotConstants.SET_JACKPOT_DATA: {
        if (typeof action.data !== "undefined") draft.jackpot = action.data;

        break;
      }

      default:
        /* noop */
        break;
    }
  });

export default jackpotReducer;
