import { tenantsConstants } from "../constants";

export const tenantsFetch = data => ({
  type: tenantsConstants.FETCH,
  data
});

export const tenantsFetchAll = data => ({
  type: tenantsConstants.FETCH_ALL,
  data
});

export const tenantsLoad = data => ({
  type: tenantsConstants.LOADED,
  data
});

export const tenantsLoadAll = data => ({
  type: tenantsConstants.LOADED_ALL,
  data
});

export const tenantsIsLoading = data => ({
  type: tenantsConstants.IS_LOADING,
  data
});

export const tenantsAddTenant = data => ({
  type: tenantsConstants.ADD_TENANT,
  data
});

export const tenantsCopyTenantConfig = data => ({
  type: tenantsConstants.COPY_TENANT_CONFIG,
  data
});

export const tenantRemoveRemote = data => ({
  type: tenantsConstants.DELETE_TENANT_REMOTE,
  data
});
export const tenantRemoveLocal = data => ({
  type: tenantsConstants.DELETE_TENANT_LOCAL,
  data
});

export const setCurrentTenant = tenantId => ({
  type: tenantsConstants.SET_CURRENT_TENANT,
  tenantId
});