import { bulkDeductionGrant } from "../constants";

export const bulkDeductionGrantStart = data => ({
  type: bulkDeductionGrant.START,
  data
});

export const bulkDeductionGrantDone = (data) => ({
  type: bulkDeductionGrant.DONE,
  data
});

export const bulkDeductionGrantClear = () => ({
  type: bulkDeductionGrant.CLEAR,
});

export const bulkDeductionGrantProgress = () => ({
  type: bulkDeductionGrant.PROGRESS
});
