export default {
  "Home": "Acasa",
  "English": "Engleza",
  "Romanian": "Romana",
  "Change Language": "Schimba Limba",
  "Switch to light mode": "Schimba in light-mode",
  "Switch to dark mode": "Schimba in dark-mode",
  "Logout": "Iesire",
  "Press 'shift+alt+/' to search pages": "Apasa 'shift+alt+/' pentru a cauta pagini",
  "to navigate": "pentru navigare",
  "to select": "pentru selectare",
  "to dismiss": "pentru a inchide",
  "Search": "Cauta",
  "Search for command": "Cauta comanda"
};
