const Calendar = ({size, style}) => {

  const iconSize = size ?? 33;

  return (
    <i className="calendar-icon" style={style}>
      <svg width={`${iconSize}`} height={`${iconSize}`} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M25.4442 4.0172H22.7658V2.67803C22.7658 1.87453 22.2302 1.33887 21.4267 1.33887C20.6232 1.33887 20.0875 1.87453 20.0875 2.67803V4.0172H12.0525V2.67803C12.0525 1.87453 11.5168 1.33887 10.7133 1.33887C9.90983 1.33887 9.37416 1.87453 9.37416 2.67803V4.0172H6.69583C4.41925 4.0172 2.67833 5.75812 2.67833 8.0347V26.783C2.67833 29.0596 4.41925 30.8005 6.69583 30.8005H25.4442C27.7207 30.8005 29.4617 29.0596 29.4617 26.783V8.0347C29.4617 5.75812 27.7207 4.0172 25.4442 4.0172ZM6.69583 6.69553H9.37416V8.0347C9.37416 8.8382 9.90983 9.37387 10.7133 9.37387C11.5168 9.37387 12.0525 8.8382 12.0525 8.0347V6.69553H20.0875V8.0347C20.0875 8.8382 20.6232 9.37387 21.4267 9.37387C22.2302 9.37387 22.7658 8.8382 22.7658 8.0347V6.69553H25.4442C26.2477 6.69553 26.7833 7.2312 26.7833 8.0347V12.0522H5.35666V8.0347C5.35666 7.2312 5.89233 6.69553 6.69583 6.69553ZM6.69583 28.1222H25.4442C26.2477 28.1222 26.7833 27.5865 26.7833 26.783V14.7305H5.35666V26.783C5.35666 27.5865 5.89233 28.1222 6.69583 28.1222Z"/>
      </svg>


    </i>
  );
};

export default Calendar;