import { produce } from "immer";
import { sideMenuConstants } from "../constants";

const initialState = {
  switches: {
    "PLAYERS": true, // true means the group is callapsed by default
    "RISK AND PERFORMANCE OPS": true,
    "PAGES & CONTENT": true,
    "LOYALITY & GAMIFICATION": true,
    "REPORTS": true,
    "PLATFORM CONTROL": true,
    "COMPLIANCE": true,
    "USERS": true,
  },
};

const sideMenuReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case sideMenuConstants.SET: {
        if (typeof action.data.key !== "undefined") draft.switches[action.data.key] = action.data.value;
        break;
      }
      default:
        /* noop */
        break;
    }
  });

export default sideMenuReducer;