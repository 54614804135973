import { selectsConstants } from "../constants";

export const loadSelects = (data) => ({
  type: selectsConstants.LOAD,
  data
});

export const loadedSelects = (data) => ({
  type: selectsConstants.LOADED,
  data: data
});