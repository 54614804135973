import { dataConstants } from "../constants";

export const dataTypesDefRequest = () => ({
    type: dataConstants.TYPESDEF_REQUEST
});

export const dataTypesDefReceived = typesDef => ({
    type: dataConstants.TYPESDEF_RECEIVED,
    typesDef
});
