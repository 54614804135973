import { takeEvery, put } from "redux-saga/effects";
import axios from "src/store/_shared/authAxios";

import { liveCasinoConstants } from "../constants";
import { loadedTables } from "../actions/live-casino";

let liveCasinoApiUrl = window.ini.REACT_APP_EVOLUTION_API_URL + "/evolution";
liveCasinoApiUrl = 'https://micros1-ro.play-online.com/evolution'

function* loadTablesSaga(action) {
  try {
    const { data } = yield axios.get(`${liveCasinoApiUrl}/tables`);

    yield put(loadedTables({ tables: data.response }));
  } catch (e) {
    console.log("[SAGA] loadTablesSaga error", e.toString());
  }
}

export default function* watchLiveCasinoSaga() {
  //console.log("[SAGA] watching live-casino");
  yield takeEvery(liveCasinoConstants.LOAD, loadTablesSaga);
}
