import React from "react";
import moment from "moment";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";

import CalendarIcon from "./assets/calendar-icon";

const DateTimePickerCustom = ({forwardedRef, ...props}) => {
  const {
    id,
    name,
    label,
    format,
    onChange,
    useUtcAdapter,
    disablePast,
    disableFuture,
    KeyboardButtonProps,
    showToolbar,
    hideShortcuts,
    toolbarLabel,
    readOnly,
    disabled,
    className,
    inputProps,
    closeOnSelect,
    hideActionBar,
  } = props;

  const ampm = typeof props.ampm !== "undefined" ? props.ampm : false;
  let inputFormat = format ? format : "YYYY-MM-DD HH:mm";
  if (ampm && inputFormat.includes("HH")) {
    inputFormat = inputFormat.replace("HH", "hh") + " A";
  } else if (!ampm) {
    if (inputFormat.includes("hh")) inputFormat = inputFormat.replace("hh", "HH");
    if (inputFormat.includes(" A")) inputFormat = inputFormat.replace(" A", "");
    if (inputFormat.includes(" a")) inputFormat = inputFormat.replace(" a", "");
  }

  const shortcutsItems = typeof hideShortcuts !== "undefined" && hideShortcuts ? null : [
    {
      label: "Last 1 year",
      getValue: () => moment().subtract(1, "years"),
    },
    {
      label: "Last 30 days",
      getValue: () => moment().subtract(30, "days"),
    },
    {
      label: "Last 7 days",
      getValue: () => moment().subtract(7, "days"),
    },
    {
      label: "Yesterday",
      getValue: () => moment().subtract(1, "days"),
    },
    {
      label: "Today",
      getValue: () => moment(),
    },
    {
      label: "Next 7 days",
      getValue: () => moment().add(7, "days"),
    },
    {
      label: "Next 30 days",
      getValue: () => moment().add(30, "days"),
    },
    {
      label: "Next 1 year",
      getValue: () => moment().add(1, "years"),
    }
  ];

  let value = typeof props.value !== "undefined" && props.value !== null ? props.value : null;
  if (typeof value !== "object") {
    if (value > 4102437599000) value = 4102437599000;
    value = moment(value);
    if (useUtcAdapter) value = value.utc(false);
  }

  const inputPropsTemp = typeof inputProps !== "undefined"
    ? inputProps
    : (typeof KeyboardButtonProps !== "undefined"
      ? KeyboardButtonProps
      : {
        "aria-label": "change time",
      });

  if (readOnly) {
    inputPropsTemp.readonly = "";
  }

  if (disabled) {
    inputPropsTemp.disabled = true;
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      dateLibInstance={useUtcAdapter ? moment.utc : null}
    >
      <DateTimePicker
        className={className ? className : "date-time-control"}
        ampm={typeof ampm !== "undefined" ? ampm : false}
        ampmInClock={typeof ampm !== "undefined" ? ampm : false}
        label={label ? label : ""}
        format={inputFormat}
        disablePast={typeof disablePast !== "undefined" ? disablePast : false}
        disableFuture={typeof disableFuture !== "undefined" ? disableFuture : false}
        closeOnSelect={typeof closeOnSelect !== "undefined" ? closeOnSelect : false}
        value={value}
        onChange={onChange}
        views={['minutes', 'hours', 'year', 'month', 'day']}
        inputRef={forwardedRef}
        readOnly={readOnly}
        disabled={disabled}
        components={{
          OpenPickerIcon: (openPickerProps) => <CalendarIcon size={16} {...openPickerProps} />,
        }}
        slotProps={{
          textField: {
            id: id ? id : "datetime-picker",
            name: name ? name : (id ? id : "datetime-picker"),
            inputProps: inputPropsTemp,
          },
          toolbar: {
            toolbarFormat: "MMM D",
            hidden: typeof showToolbar !== "undefined" ? !showToolbar : false,
          },
          actionBar: {
            actions: !hideActionBar ? ["cancel", "accept"] : [],
          },
          shortcuts: {
            items: shortcutsItems,
          },
        }}
        localeText={{
          toolbarTitle: label ? label : (toolbarLabel ? toolbarLabel : ""),
        }}
      />
    </LocalizationProvider>
  );
};

const DateTimePickerCustomRef = React.forwardRef((props, ref) => {
  return <DateTimePickerCustom {...props} forwardedRef={ref}/>;
});

export default DateTimePickerCustomRef;