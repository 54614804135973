import { produce } from "immer";

import { tenantsConstants } from "../constants";
import { buildApiUrl, getPlayerApiBaseUrl } from "../../utils/api";

const apiUrl = buildApiUrl("/admin");
const playerApiUrl = getPlayerApiBaseUrl("/admin");
const templatizationApiUrl = buildApiUrl("/templatization");
const rootPath = window.ini.REACT_APP_ROOT_PATH;

const checkForTenantInUrl = () => {
  const url = new URL(window.location.href);

  let tenantId = "";

  if (url.searchParams.has("clientId") && url.searchParams.get("clientId")) {
    tenantId = url.searchParams.get("clientId");
  } else if (url.searchParams.has("tenant") && url.searchParams.get("tenant")) {
    tenantId = url.searchParams.get("tenant");
  }
  if (tenantId === "-1") return window.ini.DEFAULT_TENANT ?? "2"; // defaults to Casino
  if (tenantId) return tenantId;

  return window.ini.DEFAULT_TENANT ?? "2";
};

const initialState = {
  tenants: [],
  pages: {},
  pagesWithSystem: {},
  isLoading: false,
  currentTenant: checkForTenantInUrl(),
  previousTenant: "",
};

initialState.pages[`${rootPath}/sports/:tenantId`] = {
  apiUrl: `${apiUrl}/sports/tenants`,
  deleteUrl: `${apiUrl}/sports/delete`,
  copyUrl: `${apiUrl}/sports/copy`,
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/crests/:tenantId`] = {
  apiUrl: "",
  copyUrl: "",
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/highlights/:tenantId`] = {
  apiUrl: `${apiUrl}/highlights/tenants`,
  deleteUrl: `${apiUrl}/highlights/delete`,
  copyUrl: `${apiUrl}/highlights/copy`,
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/entities/:tenantId`] = {
  apiUrl: "",
  copyUrl: "",
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/calendar-hot/:tenantId`] = {
  apiUrl: `${apiUrl}/hot-days/tenants`,
  deleteUrl: `${apiUrl}/hot-days/delete`,
  copyUrl: `${apiUrl}/hot-days/copy`,
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/bets/:tenantId`] = {
  apiUrl: "",
  copyUrl: "",
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/outcomes/:tenantId`] = {
  apiUrl: "",
  copyUrl: "",
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/sr/:tenantId`] = {
  apiUrl: "",
  copyUrl: "",
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/settings/:tenantId`] = {
  apiUrl: `${apiUrl}/settings/tenants`,
  deleteUrl: `${apiUrl}/settings/delete`,
  copyUrl: `${apiUrl}/settings/copy`,
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/leagues/:tenantId`] = {
  apiUrl: `${apiUrl}/homepage_settings/tenants`,
  deleteUrl: `${apiUrl}/homepage_settings/delete`,
  copyUrl: `${apiUrl}/homepage_settings/copy`,
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/news/:tenantId`] = {
  apiUrl: `${apiUrl}/homepage_news/tenants`,
  deleteUrl: `${apiUrl}/homepage_news/delete`,
  copyUrl: `${apiUrl}/homepage_news/copy`,
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/homepage-groups/:tenantId`] = {
  apiUrl: `${apiUrl}/homepage_groups/tenants`,
  deleteUrl: `${apiUrl}/homepage_groups/delete`,
  copyUrl: `${apiUrl}/homepage_groups/copy`,
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/homepage-groups-images/:tenantId`] = {
  apiUrl: `${apiUrl}/registry/tenants`,
  deleteUrl: `${apiUrl}/registry/delete`,
  copyUrl: `${apiUrl}/registry/copy`,
  reg_key: "homepage_groups_images",
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/homepage-sports-navbar/:tenantId`] = {
  apiUrl: `${apiUrl}/registry/tenants`,
  deleteUrl: `${apiUrl}/registry/delete`,
  copyUrl: `${apiUrl}/registry/copy`,
  reg_key: "homepage_sports",
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/live-top-league/:tenantId`] = {
  apiUrl: `${apiUrl}/registry/tenants`,
  deleteUrl: `${apiUrl}/registry/delete`,
  copyUrl: `${apiUrl}/registry/copy`,
  reg_key: "live_top_leagues",
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/lotto-promoted/:tenantId`] = {
  apiUrl: `${apiUrl}/registry/tenants`,
  deleteUrl: `${apiUrl}/registry/delete`,
  copyUrl: `${apiUrl}/registry/copy`,
  reg_key: "homepage_lotto_promoted",
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/happy-hours-definitions/:tenantId`] = {
  apiUrl: "", //`${apiUrl}/happy-hour/definition/tenants`,
  deleteUrl: "", //`${apiUrl}/hour/definition/delete`,
  copyUrl: "", //`${apiUrl}/happy-hour/definition/copy`,
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/happy-hours-events/:tenantId`] = {
  apiUrl: "", //`${apiUrl}/happy-hour/event/tenants`,
  deleteUrl: "", //`${apiUrl}/hour/event/delete`,
  copyUrl: "", //`${apiUrl}/happy-hour/event/copy`,
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/happy-hours-definition/:tenantId/:id`] = {
  apiUrl: "",
  copyUrl: "",
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/slots-bg-color/:tenantId`] = {
  apiUrl: `${apiUrl}/registry/tenants`,
  deleteUrl: `${apiUrl}/registry/delete`,
  copyUrl: `${apiUrl}/registry/copy`,
  reg_key: "casino_games_colors",
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/users/:tenantId`] = {
  apiUrl: ``,
  deleteUrl: ``,
  copyUrl: ``,
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/casino-super-banner/:tenantId`] = {
  apiUrl: `${apiUrl}/registry/tenants`,
  deleteUrl: `${apiUrl}/registry/delete`,
  copyUrl: `${apiUrl}/registry/copy`,
  reg_key: "super_banner_casino",
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/lotto-super-banner/:tenantId`] = {
  apiUrl: `${apiUrl}/registry/tenants`,
  deleteUrl: `${apiUrl}/registry/delete`,
  copyUrl: `${apiUrl}/registry/copy`,
  reg_key: "super_banner_lotto",
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/virtual-super-banner/:tenantId`] = {
  apiUrl: `${apiUrl}/registry/tenants`,
  deleteUrl: `${apiUrl}/registry/delete`,
  copyUrl: `${apiUrl}/registry/copy`,
  reg_key: "super_banner_virtual",
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/homepage-components/:tenantId`] = {
  apiUrl: `${apiUrl}/components/tenants`,
  deleteUrl: `${apiUrl}/components/delete`,
  copyUrl: `${apiUrl}/components/copy`,
  loaded: false,
  tenants: [],
};

initialState.pages[`${rootPath}/maintenance/:tenantId`] = {
  apiUrl: `${apiUrl}/registry/tenants`,
  deleteUrl: `${apiUrl}/registry/delete`,
  copyUrl: `${apiUrl}/registry/copy`,
  reg_key: "maintenance",
  loaded: false,
  tenants: [],
};

initialState.pages[`${rootPath}/winner-fun/calendar/:tenantId`] = {
  apiUrl: `${apiUrl}/winner-fun/calendar/tenants`,
  deleteUrl: `${apiUrl}/winner-fun/calendar/deleteTenant`,
  copyUrl: `${apiUrl}/winner-fun/calendar/copyTenant`,
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/winner-fun/win-coins-systems/:tenantId`] = {
  apiUrl: `${apiUrl}/winner-fun/win-coins-systems/tenants`,
  deleteUrl: `${apiUrl}/winner-fun/win-coins-systems/deleteTenant`,
  copyUrl: `${apiUrl}/winner-fun/win-coins-systems/copyTenant`,
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/winner-fun/ranking/:type/:id`] = {
  apiUrl: "",
  copyUrl: "",
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/winner-fun/referral-settings/:tenantId`] = {
  apiUrl: `${apiUrl}/registry/tenants`,
  deleteUrl: `${apiUrl}/registry/delete`,
  copyUrl: `${apiUrl}/registry/copy`,
  reg_key: "winner_fun_referral_settings",
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/winner-fun/super-banner/:tenantId`] = {
  apiUrl: `${apiUrl}/registry/tenants`,
  deleteUrl: `${apiUrl}/registry/delete`,
  copyUrl: `${apiUrl}/registry/copy`,
  reg_key: "super_banner_winner_fun",
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/winner-fun/ranking-settings/:tenantId`] = {
  apiUrl: `${apiUrl}/registry/tenants`,
  deleteUrl: `${apiUrl}/registry/delete`,
  copyUrl: `${apiUrl}/registry/copy`,
  reg_key: "winner_fun_ranking_settings",
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/winner-fun/teams-list/:tenantId`] = {
  apiUrl: `${apiUrl}/winner-fun/teams/tenants`,
  deleteUrl: `${apiUrl}/winner-fun/teams/deleteTenant`,
  copyUrl: `${apiUrl}/winner-fun/teams/copyTenant`,
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/winner-fun/teams-ui/:tenantId`] = {
  apiUrl: `${apiUrl}/registry/tenants`,
  deleteUrl: `${apiUrl}/registry/delete`,
  copyUrl: `${apiUrl}/registry/copy`,
  reg_key: "winner_fun_teams_ui",
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/lobby/data-sources/:tenantId`] = {
  apiUrl: `${apiUrl}/lobby/data-sources/tenants`,
  deleteUrl: `${apiUrl}/lobby/data-sources/deleteTenant`,
  copyUrl: `${apiUrl}/lobby/data-sources/copyTenant`,
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/lobby/pages/:tenantId`] = {
  apiUrl: `${apiUrl}/lobby/pages/tenants`,
  deleteUrl: `${apiUrl}/lobby/pages/deleteTenant`,
  copyUrl: `${apiUrl}/lobby/pages/copyTenant`,
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/wheel/products/:tenantId`] = {
  apiUrl: `${apiUrl}/wheel/products/tenants`,
  deleteUrl: `${apiUrl}/wheel/products/deleteTenant`,
  copyUrl: `${apiUrl}/wheel/products/copy`,
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/wheel/values/:tenantId`] = {
  apiUrl: `${apiUrl}/wheel/values/tenants`,
  deleteUrl: `${apiUrl}/wheel/values/deleteTenant`,
  copyUrl: `${apiUrl}/wheel/values/copy`,
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/wheel/multipliers/:tenantId`] = {
  apiUrl: `${apiUrl}/wheel/multipliers/tenants`,
  deleteUrl: `${apiUrl}/wheel/multipliers/deleteTenant`,
  copyUrl: `${apiUrl}/wheel/multipliers/copy`,
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/wheel/setups/:tenantId`] = {
  apiUrl: `${apiUrl}/wheel/setups/tenants`,
  deleteUrl: `${apiUrl}/wheel/setups/deleteTenant`,
  copyUrl: `${apiUrl}/wheel/setups/copy`,
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/wheel/prizes/:tenantId`] = {
  apiUrl: `${apiUrl}/wheel/prizes/tenants`,
  deleteUrl: `${apiUrl}/wheel/prizes/deleteTenant`,
  copyUrl: `${apiUrl}/wheel/prizes/copy`,
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/wheel/puzzles/:tenantId`] = {
  apiUrl: `${apiUrl}/wheel/puzzles/tenants`,
  deleteUrl: `${apiUrl}/wheel/puzzles/deleteTenant`,
  copyUrl: `${apiUrl}/wheel/puzzles/copy`,
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/wheel/player-level-setups/:tenantId`] = {
  apiUrl: `${apiUrl}/wheel/player-level-setups/tenants`,
  deleteUrl: `${apiUrl}/wheel/player-level-setups/deleteTenant`,
  copyUrl: `${apiUrl}/wheel/player-level-setups/copy`,
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/wheel/settings/:tenantId`] = {
  apiUrl: `${apiUrl}/wheel/settings/tenants`,
  deleteUrl: `${apiUrl}/wheel/settings/deleteTenant`,
  copyUrl: `${apiUrl}/wheel/settings/copy`,
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/promo/campaign-settings/:tenantId`] = {
  apiUrl: `${apiUrl}/promo/settings/tenants`,
  deleteUrl: `${apiUrl}/promo/settings/deleteTenant`,
  copyUrl: `${apiUrl}/promo/settings/copy`,
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/landing/pages/:tenantId`] = {
  apiUrl: `${apiUrl}/landing-pages/pages/tenants`,
  deleteUrl: `${apiUrl}/landing-pages/pages/deleteTenant`,
  copyUrl: `${apiUrl}/landing-pages/pages/copyTenant`,
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/customize-navbar/:tenantId`] = {
  apiUrl: `${apiUrl}/registry/tenants`,
  deleteUrl: `${apiUrl}/registry/delete`,
  copyUrl: `${apiUrl}/registry/copy`,
  reg_key: "navElements",
  loaded: false,
  tenants: [],
};

initialState.pages[`${rootPath}/markets-groups-sorter/:tenantId`] = {
  apiUrl: `${apiUrl}/registry/tenants`,
  deleteUrl: `${apiUrl}/registry/delete`,
  copyUrl: `${apiUrl}/registry/copy`,
  reg_key: "marketsSorter",
  loaded: false,
  tenants: [],
};

initialState.pages[`${rootPath}/missions-tournaments/groups/:tenantId`] = {
  apiUrl: `${apiUrl}/missions-tournaments/groups/tenants`,
  deleteUrl: `${apiUrl}/missions-tournaments/groups/deleteTenant`,
  copyUrl: `${apiUrl}/missions-tournaments/groups/copyTenant`,
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/casino/attributes-games/:tenantId`] = {
  apiUrl: `${apiUrl}/casino/games-attributes/tenants`,
  deleteUrl: `${apiUrl}/casino/games-attributes/deleteTenant`,
  copyUrl: `${apiUrl}/casino/games-attributes/copyTenant`,
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/players/avatars/:tenantId`] = {
  apiUrl: `${apiUrl}/players/avatars/tenants`,
  deleteUrl: `${apiUrl}/players/avatars/deleteTenant`,
  copyUrl: `${apiUrl}/players/avatars/copyTenant`,
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/social/definitions-avatars/:tenantId`] = {
  apiUrl: `${playerApiUrl}/avatars_definitions/tenants`,
  deleteUrl: `${playerApiUrl}/avatars_definitions/deleteTenant`,
  copyUrl: `${playerApiUrl}/avatars_definitions/copyTenant`,
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/retail/settings/:tenantId`] = {
  apiUrl: `${apiUrl}/retail/settings/tenants`,
  deleteUrl: `${apiUrl}/retail/settings/delete`,
  copyUrl: `${apiUrl}/retail/settings/copy`,
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/templatization/layout-pages/:tenantId`] = {
  apiUrl: `${templatizationApiUrl}/page-layouts/tenants`,
  deleteUrl: `${templatizationApiUrl}/page-layouts/deleteTenant`,
  copyUrl: `${templatizationApiUrl}/page-layouts/copyTenant`,
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/templatization/pages/:tenantId`] = {
  apiUrl: `${templatizationApiUrl}/pages/tenants`,
  deleteUrl: `${templatizationApiUrl}/pages/deleteTenant`,
  copyUrl: `${templatizationApiUrl}/pages/copyTenant`,
  loaded: false,
  tenants: [],
};
initialState.pages[`${rootPath}/uk-settings-markets/:tenantId`] = {
  apiUrl: `${apiUrl}/registry/tenants`,
  deleteUrl: `${apiUrl}/registry/delete`,
  copyUrl: `${apiUrl}/registry/copy`,
  reg_key: "ukSettingsMarkets",
  loaded: false,
  tenants: [],
};

initialState.pagesWithSystem[`${rootPath}/templatization/brands-sites`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/edit-brand-site/:tenantId/:id`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/sites-templates`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/edit-site-template/:tenantId/:id`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/sets-pages`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/edit-set-pages/:tenantId/:id`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/sets-components-data-defaults`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/edit-set-components-data-defaults/:tenantId/:id`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/sets-components-defaults`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/edit-set-components-defaults/:tenantId/:id`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/pages`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/edit-page/:tenantId/:pageId`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/layout-pages`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/edit-layout-page/:tenantId/:pageLayoutId`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/data-components`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/edit-data-component/:tenantId/:id`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/components`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/edit-component/:tenantId/:id`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/css-variables`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/edit-css-variable/:tenantId/:id`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/sets-fonts`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/edit-set-fonts/:tenantId/:id`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/sets-css-fonts/:category`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/edit-set-css-fonts/:category/:tenantId/:id`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/sets-css-style-shadow/:category`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/edit-set-css-style-shadow/:category/:tenantId/:id`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/sets-css-style-border-radius/:category`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/edit-set-css-style-border-radius/:category/:tenantId/:id`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/sets-css-style-border/:category`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/edit-set-css-style-border/:category/:tenantId/:id`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/sets-css-component-variables/:category`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/edit-set-css-component-variables/:category/:tenantId/:id`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/sets-css-colors/:category`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/edit-set-css-colors/:category/:tenantId/:id`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/sets-css-spacing/:category`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/edit-set-css-spacing/:category/:tenantId/:id`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/css-break-points`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/editor-styles-presets`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/edit-editor-styles-preset/:tenantId/:id`] = true;
initialState.pagesWithSystem[`${rootPath}/templatization/elements`] = true;

const tenantsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case tenantsConstants.LOADED: {
        const tenants = [];
        let hasGeneral = false;
        action.data.tenants.forEach(tID => {
          const pID = parseInt(tID, 10);
          if (pID === 0) {
            hasGeneral = true;
            tenants.push({ label: "General", id: pID });
          } else {
            const found = draft.tenants.find(t => parseInt(t.id, 10) === pID);
            if (found) {
              tenants.push(found);
            }
          }
        });
        if (!hasGeneral) tenants.unshift({ label: "General", id: 0 });
        draft.pages[action.data.pageUrl].tenants = tenants;
        draft.pages[action.data.pageUrl].loaded = true;
        draft.isLoading = false;
        break;
      }
      case tenantsConstants.LOADED_ALL: {
        // const keys = Object.keys(action.data ? action.data : {});
        // const tenants = [];
        // keys.sort().forEach(k => {
        //   tenants.push({ label: action.data[k], id: parseInt(k, 10) });
        // });

        const tenants = action.data.map(t => ({
          label: t.name,
          id: t.id,
        }));

        draft.tenants = tenants;
        draft.isLoading = false;
        break;
      }
      case tenantsConstants.ADD_TENANT: {
        if (action.data.pageUrl && action.data.tenant)
          draft.pages[action.data.pageUrl].tenants.push(action.data.tenant);
        break;
      }
      case tenantsConstants.DELETE_TENANT_LOCAL: {
        if (action.data.pageUrl && action.data.tenant) {
          const tenants = draft.pages[action.data.pageUrl].tenants;
          const newTenants = [];
          tenants.forEach(t => {
            if (t.id !== action.data.tenant.id) {
              newTenants.push(t);
            }
          });
          draft.pages[action.data.pageUrl].tenants = newTenants;
        }
        break;
      }
      case tenantsConstants.IS_LOADING: {
        draft.isLoading = action.data;
        break;
      }
      case tenantsConstants.SET_CURRENT_TENANT: {
        if (action.tenantId === "0") {
          draft.previousTenant = draft.currentTenant;
        }
        console.log("SET_CURRENT_TENANT", action.tenantId);
        draft.currentTenant = action.tenantId;
        break;
      }
      default:
        /* noop */
        break;
    }
  });

export default tenantsReducer;

