import React from "react";

import TextBoxOption from "../../ui/ui-components/text-box-option";

const MTextField = ({ forwardedRef, ...props }) => {
  return (
    <TextBoxOption
      {...props}
      ref={forwardedRef}
      fullWidth
      name={props.columnDef.field}
      // placeholder={props.columnDef.editPlaceholder || props.columnDef.title}
      type={typeof props.columnDef.type !== "undefined"
        ? props.columnDef.type
        : "text"}
      inputType={typeof props.columnDef.inputType !== "undefined"
        ? props.columnDef.inputType
        : "text"}
      onChange={(event) => {
        //console.log(event);
        props.onChange(event.target.value);
      }
      }
    />
  )
};

const MTextFieldRef = React.forwardRef((props, ref) => {
  return <MTextField {...props} forwardedRef={ref} />;
});

export default MTextFieldRef