import {bulkAffiliateInfoUpdate as Constants} from "../constants";

export const bulkAffiliateInfoUpdateStart = data => ({
  type: Constants.START,
  data
});

export const bulkAffiliateInfoUpdateDone = (data) => ({
  type: Constants.DONE,
  data
});

export const bulkAffiliateInfoUpdateClear = () => ({
  type: Constants.CLEAR,
});

export const bulkAffiliateInfoUpdateProgress = () => ({
  type: Constants.PROGRESS
});

export const bulkAffiliateInfoUpdateRequestMaxLength = data => ({
  type: Constants.UPDATE_REQUEST_MAX_LENGTH,
  data
});