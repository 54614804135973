import { produce } from "immer";
import { sportsConstants } from "../constants";

// eslint-disable-next-line
const getItems = (count, startIndex) =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k + startIndex}`,
    content: `Sport ${k + startIndex}`
  }));

const initialState = {
  live: {
    pool: [],
    selected: []
  },
  prematch: {
    pool: [],
    selected: []
  }
};

const sportsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case sportsConstants.LOADED_SPORTS: {
        draft.live = action.data.live;
        draft.prematch = action.data.prematch;
        break;
      }
      case sportsConstants.SELECTED_SPORTS_CHANGED: {
        draft[action.sType].selected = action.data.selected;
        break;
      }
      case sportsConstants.POOL_SPORTS_CHANGED: {
        draft[action.sType].pool = action.data.pool;
        break;
      }
      default:
        /* noop */
        break;
    }
  });

export default sportsReducer;
