import {cloneDeep} from "lodash/fp";

export const tableSearchStorageTypes = Object.freeze({
  FILTER: "tfilter-views",
  FILTER_LAST_SEARCH: "tfilter-last-search",
  SEARCH: "tsearch-results",
});

export const loadTableSearchesFromStorage = (storageType, key) => {
  if (!Object.values(tableSearchStorageTypes).includes(storageType)) {
    throw new Error(`Storage type ${storageType} is not supported`);
  }

  const views = localStorage.getItem(`${storageType}-${key}`);
  if (views) {
    return JSON.parse(views);
  }
  return [];
};
export const saveTableSearchInStorage = (storageType, key, data) => {
  if (!Object.values(tableSearchStorageTypes).includes(storageType)) {
    throw new Error(`Storage type ${storageType} is not supported`);
  }

  // console.log("Saving search visited result in storage", data);

  let views = localStorage.getItem(`${storageType}-${key}`);
  if (views) {
    views = JSON.parse(views);
    if (typeof data === "object" && typeof data.clientId !== "undefined") {
      if (typeof views.clientIds[data.clientId] === "undefined") {
        views.clientIds[data.clientId] = [];
      }
    }
  } else {
    if (typeof data === "object" && typeof data.clientId !== "undefined") {
      views = {clientIds: {}};
      views.clientIds[data.clientId] = [];
    } else {
      views = [];
    }
  }

  switch (storageType) {
    case tableSearchStorageTypes.SEARCH:
    case tableSearchStorageTypes.FILTER_LAST_SEARCH:
      if (typeof data === "object" && typeof data.clientId !== "undefined") {
        views.clientIds[data.clientId] = data;
        localStorage.setItem(`${storageType}-${key}`, JSON.stringify(views));
      }
      break;
    case tableSearchStorageTypes.FILTER:
      if (typeof data === "object"
        && typeof data.clientId !== "undefined"
        && typeof data.viewName !== "undefined"
      ) {
        const existingViewDefIdx = data.originalViewName == null
          ? -1
          : views.clientIds[data.clientId].findIndex(v => v.viewName === data.originalViewName);

        const newDef = {
          viewName: data.viewName,
          filters: data.filters,
          default: data.default,
        };
        if (existingViewDefIdx > -1) {
          views.clientIds[data.clientId][existingViewDefIdx] = newDef;
        } else {
          const duplicatedPresetIdx = views.clientIds[data.clientId].findIndex(v => v.viewName === data.viewName);
          if (duplicatedPresetIdx > -1) {
            throw new Error(`View name "${data.viewName}" already exists`);
          }
          views.clientIds[data.clientId].push(newDef);
        }
        localStorage.setItem(`${storageType}-${key}`, JSON.stringify(views));
      }
      // else if (Array.isArray(views)) {
      //   views.push(data);
      //   localStorage.setItem(`${storageType}-${key}`, JSON.stringify(views));
      // }
      break;
    default:
      break;
  }

  // console.log("Saved search last visit", views);

  return views;
};
export const removeTableSearchInStorage = (storageType, key, id) => {
  if (!Object.values(tableSearchStorageTypes).includes(storageType)) {
    throw new Error(`Storage type ${storageType} is not supported`);
  }

  let views = localStorage.getItem(`${storageType}-${key}`);
  if (views) {
    views = JSON.parse(views);
  } else {
    views = [];
  }

  if (Array.isArray(views)) {
    const index = views.findIndex(v => v.id === id);
    if (index > -1) {
      views.splice(index, 1);
      localStorage.setItem(`${storageType}-${key}`, JSON.stringify(views));
    }
  }

  return views;
};
export const getTableSearchFromStorage = (storageType, key, id) => {
  if (!Object.values(tableSearchStorageTypes).includes(storageType)) {
    throw new Error(`Storage type ${storageType} is not supported`);
  }

  let views = localStorage.getItem(`${storageType}-${key}`);
  if (views) {
    views = JSON.parse(views);
  } else {
    views = [];
  }

  if (Array.isArray(views)) {
    const index = views.findIndex(v => v.id === id);
    if (index > -1) {
      return views[index];
    }
  }

  return null;
};
export const updateTableSearchInStorage = (storageType, key, data) => {
  if (!Object.values(tableSearchStorageTypes).includes(storageType)) {
    throw new Error(`Storage type ${storageType} is not supported`);
  }

  let views = localStorage.getItem(`${storageType}-${key}`);
  if (views) {
    views = JSON.parse(views);
    if (typeof data === "object" && typeof data.clientId !== "undefined") {
      if (typeof views.clientIds[data.clientId] === "undefined") {
        views.clientIds[data.clientId] = [];
      }
    }
  } else {
    if (typeof data === "object" && typeof data.clientId !== "undefined") {
      views = {clientIds: {}};
      views.clientIds[data.clientId] = [];
    } else {
      views = [];
    }
  }

  let update = false;
  switch (storageType) {
    case tableSearchStorageTypes.SEARCH:
    case tableSearchStorageTypes.FILTER_LAST_SEARCH:
      break;
    case tableSearchStorageTypes.FILTER:
      if (typeof data === "object" &&
        typeof data.clientId !== "undefined" &&
        typeof views.clientIds[data.clientId] !== "undefined"
      ) {
        if (Object.keys(data).includes("presets")) {
          views.clientIds[data.clientId] = cloneDeep(data.presets);
          update = true;
        }
      } else {
        throw new Error(`Update local storage failed: "clientId" is required`);
      }
      break;
    default:
      break;
  }

  if (update) {
    localStorage.setItem(`${storageType}-${key}`, JSON.stringify(views));
    // console.log("Updated local storage", {storageType, key, views});
  }
  return views;
};