import React from 'react';

const ButtonGroup = (props) => {
  return (
    <div className={`option buttongroup  ${props.className ? props.className : ''}`} id={props.id}>
      <div className="option-content">
        {props.label && <span>{props.label}</span>}
        <div className="control">
          <div className={"btn-container" + (props.inline ? " inline" : "")}>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ButtonGroup;