import { produce } from "immer";
import { tableFiltersSorting } from "../constants";

// eslint-disable-next-line
const getItems = (count, startIndex) =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k + startIndex}`,
    content: `Sport ${k + startIndex}`
  }));

const initialState = {
  filtersSelected: [],
  filtersDeleted: [],
  sortBy: null,
  sortDirection: null,
  changed: false,
};

const tableFiltersSortingReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case tableFiltersSorting.TABLE_FILTER_SORTING_SET_DATA: {
        if (typeof action.data.filtersSelected !== "undefined") draft.filtersSelected = action.data.filtersSelected;
        if (typeof action.data.filtersDeleted !== "undefined") draft.filtersDeleted = action.data.filtersDeleted;
        if (typeof action.data.sortBy !== "undefined") draft.sortBy = action.data.sortBy;
        if (typeof action.data.sortDirection !== "undefined") draft.sortDirection = action.data.sortDirection;
        if (typeof action.data.changed !== "undefined") draft.changed = action.data.changed;
        break;
      }
      case tableFiltersSorting.TABLE_FILTER_SORTING_CLEAR: {
        draft.filtersSelected = [];
        draft.filtersDeleted = [];
        draft.changed = false;
        break;
      }
      default:
        /* noop */
        break;
    }
  });

export default tableFiltersSortingReducer;
