import { Mail, Home } from 'react-feather'

const rootPath = window.ini.REACT_APP_ROOT_PATH;

export default [
  {
    id: 'home',
    title: 'Home',
    icon: <Home size={20} />,
    navLink: `${rootPath}/home`,
  },
  {
    id: 'secondPage',
    title: 'Customer Accounts',
    icon: <Mail size={20} />,
    navLink: `${rootPath}/players/search`,
  },
  {
    id: '/player-bonus/search',
    title: 'Bonus Search',
    icon: <Mail size={20} />,
    navLink: `${rootPath}/player-bonus/search`,
  }
]
