import React from 'react';
import { TimePickerTitle } from './TimePickerTitle';
import { TimeRangeOption } from './TimeRangeOption';
import { mapOptionToTimeRange } from './mapper';

export const TimeRangeList = props => {
  const { title, options, placeholderEmpty } = props;
  if (typeof placeholderEmpty !== 'undefined' && options.length <= 0) {
    return <React.Fragment>{placeholderEmpty}</React.Fragment>;
  }
  if (!title) {
    return <Options {...props} />;
  }
  return (<React.Fragment>
    <div className={"gf-trl-title"}>
      <TimePickerTitle>{title}</TimePickerTitle>
    </div>
    <Options {...props} />
  </React.Fragment>);
};

const Options = ({ options, value, onSelect, timeZone }) => {
  return (<React.Fragment>
    <div>
      {options.map((option, index) => (<TimeRangeOption key={keyForOption(option, index)} value={option} selected={isEqual(option, value)} onSelect={option => onSelect(mapOptionToTimeRange(option, timeZone))} />))}
    </div>
    <div className={"gf-trl-grow"}></div>
  </React.Fragment>);
};

function keyForOption(option, index) {
  return `${option.from}-${option.to}-${index}`;
}

function isEqual(x, y) {
  if (!y || !x) {
    return false;
  }
  return y.raw.from === x.from && y.raw.to === x.to;
}