import {produce} from "immer";
import {notificationTemplatesConstants as constants} from "../constants";

const initialState = {
  languages: [],
  types: [],
  notifications: [],
  loaded: false,
  loading: false,
};

const notificationTemplatesReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case constants.LOADING_VALUES: {
        draft.loading = true;
        draft.loaded = false;
        break;
      }
      case constants.LOADED_VALUES: {
        // console.log("LOADED_VALUES", action);
        draft.loading = false;
        draft.loaded = true;
        if (action.data) {
          draft.languages = action.data.languages.map(l => ({label: l.name, value: l.id}));
          draft.types = action.data.types.map(t => ({label: t.name, value: t.id}));
          draft.notifications = action.data.notifications.map(n => ({label: n.title, value: n.id}));
        }
        break;
      }
      case constants.CLEAR: {
        draft.loading = false;
        draft.loaded = false;
        draft.languages = [];
        draft.types = [];
        draft.notifications = [];
        break;
      }
      default:
        /* noop */
        break;
    }
  });

export default notificationTemplatesReducer;