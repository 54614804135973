// ** Third Party Components
import ReactCountryFlag from 'react-country-flag'
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'

import { useTranslation } from "react-i18next";

const IntlDropdown = () => {
  const { t, i18n } = useTranslation();

  let currentLang = i18n.language;
  //console.log("currentLang", currentLang);
  currentLang = currentLang === "en-US" ? "en" : currentLang;

  // ** Vars
  const langObj = {
    en: t('English'),
    ro: t('Romanian'),
  }

  // ** Function to switch Language
  const handleLangUpdate = (e, lang) => {
    e.preventDefault()
    i18n.changeLanguage(lang);
  }

  return (
    <UncontrolledDropdown href='/' tag='li' className='dropdown-language nav-item'>
      <DropdownToggle href='/' tag='a' className='nav-link' onClick={e => e.preventDefault()}>
        <ReactCountryFlag
          className='country-flag flag-icon'
          countryCode={currentLang === 'en' ? 'us' : currentLang}
          svg
        />
        <span className='selected-language'>{langObj[currentLang]}</span>
      </DropdownToggle>
      <DropdownMenu className='mt-0' right>
        <DropdownItem href='/' tag='a' onClick={e => handleLangUpdate(e, 'en')}>
          <ReactCountryFlag className='country-flag' countryCode='us' svg />
          <span className='ml-1'>{t("English")}</span>
        </DropdownItem>
        <DropdownItem href='/' tag='a' onClick={e => handleLangUpdate(e, 'ro')}>
          <ReactCountryFlag className='country-flag' countryCode='ro' svg />
          <span className='ml-1'>{t("Romanian")}</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default IntlDropdown
