import * as rangeUtil from '../data/rangeutil';
import { dateTimeFormat } from "../data/formatters";

export const mapOptionToTimeRange = (option, timeZone) => {
  return rangeUtil.convertRawToRange({ from: option.from, to: option.to }, timeZone);
};

export const mapRangeToTimeOption = (range, timeZone) => {
  const from = dateTimeFormat(range.from, { timeZone });
  const to = dateTimeFormat(range.to, { timeZone });

  return {
    from,
    to,
    section: 3,
    display: `${from} to ${to}`,
  };
};
