import { takeEvery, put } from "redux-saga/effects";
import axios from "src/store/_shared/authAxios";
import store from "../../store";
import { sportsConstants } from "../constants";
import { loadedSports } from "../actions/sports";

import { buildApiUrl } from "../../utils/api";

const sportApiUrl = buildApiUrl("/admin/sports");

function* fetchSports(action) {
  try {
    const extra = action && action.data ? action.data : {};
    const { data } = yield axios.get(sportApiUrl, { params: extra });

    console.log("fetchSports result", data);

    yield put(loadedSports(data));
  } catch (e) {
    console.log("[SAGA] fetchSports error", e.toString());
  }
}

function* saveSelectedSports(action) {
  const { sports } = store.getState();

  const extra = action && action.data ? action.data : {};

  try {
    yield axios.post(sportApiUrl + `?tenant_id=${extra.tenant_id}`, {
      ...extra,
      live: sports.live.selected,
      prematch: sports.prematch.selected
    });
  } catch (e) {
    console.log("[SAGA] saveSelectedSports error", e.toString());
  }
}

export default function* watchSportsSaga() {
  //console.log("[SAGA] watching sports");
  yield takeEvery(sportsConstants.FETCH_SPORTS, fetchSports);
  yield takeEvery(sportsConstants.SAVE_SPORTS, saveSelectedSports);
}
