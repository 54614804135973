import axios from "axios";
import { decodeJwt } from "jose";
import { endpoints } from "src/api/endpoints";
import store from "../index";
import { userActions } from "../_shared/_redux/authActions";
import moment from "moment";

const DEBUG = false;
const EXPIRATION_THRESHOLD = 30;
const ALOW_QUERY_TOKENS = true;
const QUERY_ACCESS = "atoken";
const QUERY_REFRESH = "rtoken";

const cancelRequest = (config) => {
  DEBUG && console.log("cancel request", config);

  return {
    ...config,
    cancelToken: new axios.CancelToken((cancel) => cancel("internal cancel")),
  };
};

// class to keep an authed axios instance
class AuthAxios {
  constructor() {
    DEBUG && console.log("authAxios constructor");

    const options = {};

    // auth axios instance
    this.instance = axios.create(options);

    // refresh request already in progress
    this.refreshRequest = false;

    // this is the list of waiting requests that will retry after the JWT refresh complete
    this.subscribers = [];

    if (ALOW_QUERY_TOKENS) {
      this.loadTokensFromQuery();
    }

    // auth axios request interceptor to automate adding Authorization header
    this.instance.interceptors.request.use(async (config) => {
      try {
        DEBUG && console.log("intercept config", config);

        // load and setup access token
        this.loadAccessToken();

        // load and setup refresh token
        this.loadRefreshToken();

        // if there is no access token, we need to refresh
        if (!this.access_token) {
          DEBUG && console.log("no access token");

          if (!this.refresh_token) {
            // if there is no refresh token, do logout
            DEBUG && console.log("no refresh token");

            throw "no refresh token";
          }

          // get new access token
          await this.refreshToken();
        } else {
          const now = Math.round(moment().utc().valueOf() / 1000);

          DEBUG &&
            console.log(
              "access token exp",
              this.decoded_access_token.exp,
              "now",
              now,
              "valid for",
              this.decoded_access_token.exp - now
            );

          // check if access token is expire
          if (this.decoded_access_token.exp < now + EXPIRATION_THRESHOLD) {
            DEBUG && console.log("access token expired");

            // get new access token
            await this.refreshToken();
          }
        }
      } catch (e) {
        console.error("failed to refresh access token", e);

        userActions.logout(null)(store.dispatch);
        return cancelRequest(config);
      }

      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: "Bearer " + this.access_token,
        },
      };
    });

    // auth axios response interceptor to automate retry for failed requests
    this.instance.interceptors.response.use(
      (response) => {
        // good response, we don't have to do anything and just return the response
        //DEBUG && console.log("good response", response);

        return response;
      },
      (error) => {
        // some kind of error
        DEBUG && console.error("error response", error);

        // determine if we need to refresh the access token
        const res = this.isTokenExpiredError(error);
        if (res) {
          DEBUG && console.log("access is token expired");
          return this.refreshTokenAndReattemptRequest(error);
        } else if (res === null) {
          DEBUG && console.log("request canceled", res);
          //return;
        } else {
          DEBUG && console.log("not an access token error", res);
        }

        // if the error is due to other reasons, we just throw it back to axios
        return Promise.reject(error);
      }
    );
  }

  refreshToken = async () => {
    if (this.refreshRequest) {
      return this.refreshRequest;
    }

    this.clearAccessToken();

    if (!this.refresh_token) {
      throw "no refresh token";
    }

    DEBUG && console.log("refresh using token", this.refresh_token);

    this.refreshRequest = axios({
      method: "post",
      url: endpoints.user.refresh,
      data: {
        refresh_token: this.refresh_token,
        decoded_permissions: true,
      },
    })
      .then((response) => {
        // handle new access token
        const newAccessToken = response.data.data.access_token;
        this.saveAccessToken(newAccessToken);
        this.onAccessTokenFetched(newAccessToken);

        // handle new refresh token if received and different from the old one
        const newRefreshToken = response.data.data.refresh_token;
        if (newRefreshToken && newRefreshToken !== this.refresh_token) {
          this.saveRefreshToken(newRefreshToken);
        }
      })
      .catch((e) => {
        this.clearRefreshToken();
        throw e;
      })
      .finally(() => {
        this.refreshRequest = null;
      });

    return this.refreshRequest;
  };

  // use the refresh token to get new access token and buffer requests
  refreshTokenAndReattemptRequest = async (error, ocfg) => {
    try {
      let cfg;

      if (error) {
        cfg = error.response.config;
      } else {
        error = new Error("failed to refresh token");
        cfg = ocfg;
      }

      // check if refresh token is present
      if (!this.refresh_token) {
        // we can't refresh, throw the original error
        return Promise.reject(error);
      }

      // add the original request to retry queue
      const retryOriginalRequest = new Promise((resolve) => {
        DEBUG && console.log("adding subscriber", cfg);

        this.addSubscriber((access_token) => {
          cfg.headers.Authorization = "Bearer " + access_token;
          resolve(axios(cfg));
        });
      });

      /*
      if (!this.isAlreadyFetchingAccessToken) {
        // only one refresh request
        this.isAlreadyFetchingAccessToken = true;

        // use refresh token
        const response = await axios({
          method: 'post',
          url: endpoints.user.refresh,
          data: {
            refresh_token: this.refresh_token
          }
        });

        // new access token is required
        if (!response.data) {
          return Promise.reject(error);
        }

        // handle new access token
        const newAccessToken = response.data.data.access_token;
        this.saveAccessToken(newAccessToken);
        this.onAccessTokenFetched(newAccessToken);

        // hanle new refresh token if received and different from the old one
        const newRefreshToken = response.data.data.refresh_token;
        if (newRefreshToken !== this.refresh_token) {
          this.saveRefreshToken(newRefreshToken);
        }

        // done
        this.isAlreadyFetchingAccessToken = false;
      }
      */
      if (!this.refreshRequest) {
        await this.refreshToken();
      }

      // wait for the refresh token request to complete
      return retryOriginalRequest;
    } catch (err) {
      return Promise.reject(err);
    }
  };

  // called when a new access token has been received
  onAccessTokenFetched(access_token) {
    // after a successful refresh, we start retrying the requests one by one and empty the queue
    this.subscribers.forEach((callback) => callback(access_token));
    this.subscribers = [];
  }

  // buffer UI requests
  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  // decide if an error is because an expired refresh token
  isTokenExpiredError(error) {
    DEBUG && console.log("errorResponse", error, typeof error);

    if (error instanceof axios.Cancel) {
      DEBUG && console.log("internal cancel");
      // request was canceled internally
      return null;
    } else {
      DEBUG && console.log("not internal cancel");
    }

    if (error && error.status === 401) {
      return true;
    }

    return false;
  }

  // access token support

  // save and set new access token
  saveAccessToken(access_token) {
    DEBUG && console.log("save access token", access_token);

    this.access_token = access_token;
    window._access_token = access_token;
    localStorage.setItem("access_token", access_token);
    this.decodeAccessToken();
  }

  loadTokensFromQuery() {
    const queryParams = new URLSearchParams(window.location.search);
    const access = queryParams.get(QUERY_ACCESS);
    const refresh = queryParams.get(QUERY_REFRESH);
    if (access) {
      this.saveAccessToken(access);
    }
    if (refresh) {
      this.saveRefreshToken(refresh);
    }
  }

  // load access token from storage
  loadAccessToken() {
    this.access_token = localStorage.getItem("access_token");
    window._access_token = this.access_token;
    this.decodeAccessToken();

    DEBUG && console.log("loaded access token", this.access_token);
  }

  // decode access token
  decodeAccessToken() {
    if (this.access_token) {
      try {
        // this.decoded_access_token = decode(this.access_token);
        this.decoded_access_token = decodeJwt(this.access_token);
        DEBUG && console.log("decoded access token", this.decoded_access_token);
      } catch (e) {
        console.error("failed to decode acces token", e);
        this.access_token = null;
      }
    }
  }

  // clear access token
  clearAccessToken() {
    localStorage.removeItem("access_token");
    this.access_token = null;
    this.decoded_access_token = null;

    DEBUG && console.log("cleared access token");
  }

  // refresh token support

  // save refresh token
  saveRefreshToken(refresh_token) {
    DEBUG && console.log("save refresh token", refresh_token);

    this.refresh_token = refresh_token;
    localStorage.setItem("refresh_token", refresh_token);
  }

  // load refresh token from storage
  loadRefreshToken() {
    this.refresh_token = localStorage.getItem("refresh_token");

    DEBUG && console.log("loaded refresh token", this.refresh_token);
  }

  // clear refresh token
  clearRefreshToken() {
    localStorage.removeItem("refresh_token");
    this.refresh_token = null;

    DEBUG && console.log("cleared refresh token");
  }

  // retrieve refresh token
  getRefreshToken() {
    return this.refresh_token;
  }

  // retrieve access token
  getAccessToken() {
    return this.access_token;
  }

  // retrieve decoded access token
  getDecodedAccessToken() {
    return this.decoded_access_token;
  }

  // get axios instance
  getInstance() {
    return this.instance;
  }
}

// object
const authAxios = new AuthAxios();

// export helpers
export const saveAccessToken = (token) => authAxios.saveAccessToken(token);
export const clearAccessToken = () => authAxios.clearAccessToken();
export const saveRefreshToken = (token) => authAxios.saveRefreshToken(token);
export const clearRefreshToken = () => authAxios.clearRefreshToken();
export const getRefreshToken = () => authAxios.getRefreshToken();
export const getAccessToken = () => authAxios.getAccessToken();
export const getDecodedAccessToken = () => authAxios.getDecodedAccessToken();
export const refreshToken = () => authAxios.refreshToken();

export default authAxios.getInstance();
