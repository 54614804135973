import { produce } from "immer";
import { contentManagementConstants } from "../constants";

const cache = {
  definitionTypes: null,
  definitionsByType: {},
  templateByDefinitionType: {},
};

const cacheClearDefinitionTypes = () => {
  cache.definitionTypes = null;
  cache.attributesList = null;
};

const cacheClearDefinitionsByDefinitionType = type => {
  delete cache.definitionsByType[type];
  // delete cache.attributesList - by type ?
};

const cacheClearTemplatesByDefinitionType = definition_type => {
  delete cache.templateByDefinitionType[definition_type];
};

const makeInitialState = () => ({
  definitionTypes: null,
  attributesList: null,
});

const contentManagementReducer = (state = makeInitialState(), action) =>
  produce(state, draft => {
    switch (action.type) {
      case contentManagementConstants.DEFINITION_TYPES_RECEIVED:
        // console.log(`REDUCER: DEFINITION_TYPES_RECEIVED: `, action.definitionTypes);

        draft.definitionTypes = action.definitionTypes;
        break;

      case contentManagementConstants.ATTRIBUTES_LIST_RECEIVED:
        // console.log(`REDUCER: ATTRIBUTES_LIST_RECEIVED: `, action.attributesList);

        draft.attributesList = action.attributesList;
        break;

      default:
        break;
    }
  });

export default contentManagementReducer;

export { cacheClearDefinitionTypes, cacheClearDefinitionsByDefinitionType, cacheClearTemplatesByDefinitionType };
