import { produce } from "immer";
import { classesConstants, tagsConstants } from "../constants";

const initialState = {
  tenant_id: 0,
  classes: [],
  classes_loading: false,
  tags: {},
  tags_loading: false,
};

const classesReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case classesConstants.LOAD: {
        draft.classes_loading = true;
        draft.tenant_id = action.data.tenant_id;
        break;
      }
      case tagsConstants.LOAD: {
        draft.tags_loading = true;
        break;
      }
      case classesConstants.LOADED: {
        draft.classes_loading = false;
        draft.classes = action.data && action.data.rows ? action.data.rows : [];
        break;
      }
      case tagsConstants.LOADED: {
        draft.tags_loading = false;
        if (action.data && action.data.tag_class_id && action.data.rows) {
          const newTags = { ...draft.tags };
          newTags[action.data.tag_class_id] = action.data.rows;
          draft.tags = newTags;
        }

        break;
      }
      case classesConstants.CLEAR_LOADING: {
        draft.classes_loading = false;
        draft.tags_loading = false;
        break;
      }
      case tagsConstants.CLEAR: {
        draft.classes_loading = false;
        draft.classes = [];
        draft.tags_loading = false;
        draft.tags = {};
        break;
      }
      default:
        /* noop */
        break;
    }
  });

export default classesReducer;