import { druidConstants } from "../constants";

export const loadDruid = (data) => ({
  type: druidConstants.LOAD,
  data
});

export const loadedDruid = (data) => ({
  type: druidConstants.LOADED,
  data: data
});