import React, { useMemo, useCallback } from 'react';
import { toLower, isEmpty } from 'lodash';
import VirtualizedSelect from "react-select";

import { getTimeZoneInfo, getTimeZoneGroups, InternalTimeZones, } from './data';
import { formatUtcOffset } from './TimeZonePicker/TimeZoneOffset';

export const TimeZonePicker = props => {
  const { onChange, value, includeInternal = false, className = "gf-select-control" } = props;
  const groupedTimeZones = useTimeZones(includeInternal);
  const selected = useSelectedTimeZone(groupedTimeZones, value);
  const onChangeTz = useCallback((event) => {
    if (event.value) {
      return onChange(event.value);
    }
  }, [onChange]);

  const now = Date.now();

  const classNames = {
    clearIndicator: state => "react-select-clearIndicator",
    container: state => {
      //console.log("container-state", state);
      return `react-select-container ${state.isFocused ? "is--focused" : ""} ${state.hasValue ? "has--value" : ""} ${state.isMulti ? "is--multi" : ""
      } ${state.isDisabled ? "is--disabled" : ""} `;
    },
    control: state => {
      //console.log("control-state", state, state.getClassNames("menu", state));
      let bottomPlacement = true;
      if (state.selectProps.menuPlacement === "top") bottomPlacement = false;
      return `react-select-control ${state.isFocused ? "is--focused" : ""} ${state.menuIsOpen ? "is--menu-opened" : ""} ${state.menuIsOpen ? (!bottomPlacement ? "menu-placement-top" : "menu-placement-bottom") : ""
      }`;
    },
    dropdownIndicator: state => "react-select-dropdownIndicator",
    group: state => "react-select-group",
    groupHeading: state => "react-select-groupHeading",
    indicatorsContainer: state => "react-select-indicatorsContainer",
    indicatorSeparator: state => "react-select-indicatorSeparator",
    input: state => "react-select-input",
    loadingIndicator: state => "react-select-loadingIndicator",
    loadingMessage: state => "react-select-loadingMessage",
    menu: state => {
      //console.log("menu-state", state);
      return `react-select-menu ${state.menuPlacement === "top" ? "menu-placement-top" : "menu-placement-bottom"}`;
    },
    menuList: state => "react-select-menuList",
    menuPortal: state => "react-select-menuPortal",
    multiValue: state => "react-select-multiValue",
    multiValueLabel: state => "react-select-multiValueLabel",
    multiValueRemove: state => "react-select-multiValueRemove",
    noOptionsMessage: state => "react-select-noOptionsMessage",
    option: state =>
      `react-select-option ${state.isFocused ? "is--focused" : ""} ${state.isSelected ? "is--selected" : ""}`,
    placeholder: state => "react-select-placeholder",
    singleValue: state => "react-select-singleValue",
    valueContainer: state => "react-select-valueContainer",
  };

  return <VirtualizedSelect
    className={className}
    placeholder="Type to search (country, city, abbreviation)"
    value={selected || ""}
    onChange={onChangeTz}
    maxMenuHeight={200}
    closeMenuOnSelect={false}
    classNames={classNames}
    getOptionLabel={o => {
      const info = getTimeZoneInfo(o.value, now);
      const indexes = o.searchIndex.split('|');
      const utcDiff = typeof indexes[2] !== "undefined" ? indexes[2] : null;
      const label = info ? info.ianaName : o.label;
      o.label = `${utcDiff !== null ? "("+utcDiff.toLocaleUpperCase()+") " : "" }${label}`;
      return o.label;
    }}
    options={groupedTimeZones.map((g, i) => {
      const options = g.options.map((o, j) => {
        const info = getTimeZoneInfo(o.value, now);
        const indexes = o.searchIndex.split('|');
        const utcDiff = typeof indexes[2] !== "undefined" ? indexes[2] : null;
        const label = info ? info.ianaName : o.label;
        return {
          type: "option",
          label: `${utcDiff !== null ? "("+utcDiff.toLocaleUpperCase()+") " : "" }${label}`,
          value: o.value,
          searchIndex: o.searchIndex,
        };
      });
      return {
        type: "group",
        label: g.label,
        options,
      };
    })}
  />


  //return (<Select value={selected} placeholder="Type to search (country, city, abbreviation)" autoFocus={autoFocus} openMenuOnFocus={true} width={width} filterOption={filterBySearchIndex} options={groupedTimeZones} onChange={onChangeTz} onBlur={onBlur} components={{ Option: TimeZoneOption, Group: TimeZoneGroup }} />);
};

const useTimeZones = (includeInternal) => {
  const now = Date.now();
  const timeZoneGroups = getTimeZoneGroups(includeInternal).map((group) => {
    const options = group.zones.reduce((options, zone) => {
      const info = getTimeZoneInfo(zone, now);
      if (!info) {
        return options;
      }
      options.push({
        label: info.name,
        value: info.zone,
        searchIndex: getSearchIndex(info, now),
      });
      return options;
    }, []);
    return {
      label: group.name,
      options,
    };
  });
  return timeZoneGroups;
};

const useSelectedTimeZone = (groups, timeZone) => {
  return useMemo(() => {
    if (timeZone === undefined) {
      return undefined;
    }
    const tz = toLower(timeZone);
    const group = groups.find(group => {
      if (!group.label) {
        return isInternal(tz);
      }
      return tz.startsWith(toLower(group.label));
    });
    return group === null || group === void 0 ? void 0 : group.options.find(option => {
      if (isEmpty(tz)) {
        return option.value === InternalTimeZones.default;
      }
      return toLower(option.value) === tz;
    });
  }, [groups, timeZone]);
};

const isInternal = (timeZone) => {
  switch (timeZone) {
    case InternalTimeZones.default:
    case InternalTimeZones.localBrowserTime:
    case InternalTimeZones.utc:
      return true;
    default:
      return false;
  }
};

/*
const useFilterBySearchIndex = () => {
  return useCallback((option, searchQuery) => {
    if (!searchQuery || !option.data || !option.data.searchIndex) {
      return true;
    }
    return option.data.searchIndex.indexOf(toLower(searchQuery)) > -1;
  }, []);
};
*/

const getSearchIndex = (info, timestamp) => {
  const parts = [
    toLower(info.name),
    toLower(info.abbreviation),
    toLower(formatUtcOffset(timestamp, info.zone)),
  ];
  for (const country of info.countries) {
    parts.push(toLower(country.name));
    parts.push(toLower(country.code));
  }
  return parts.join('|');
};