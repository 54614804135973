import { classesConstants } from "../constants";

export const loadClasses = (data) => ({
  type: classesConstants.LOAD,
  data
});

export const loadingClasses = () => ({
  type: classesConstants.LOADING
});

export const loadedClasses = (data) => ({
  type: classesConstants.LOADED,
  data: data
});

export const clearClasses = () => ({
  type: classesConstants.CLEAR
});

export const clearLoading = () => ({
  type: classesConstants.CLEAR_LOADING
});