import { bulkBonusGrant } from "../constants";

export const bulkBonusGrantStart = data => ({
  type: bulkBonusGrant.START,
  data
});

export const bulkBonusGrantDone = (data) => ({
  type: bulkBonusGrant.DONE,
  data
});

export const bulkBonusGrantClear = () => ({
  type: bulkBonusGrant.CLEAR,
});

export const bulkBonusGrantProgress = () => ({
  type: bulkBonusGrant.PROGRESS
});
