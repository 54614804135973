import { all, call } from "redux-saga/effects";

import watchSportsSaga from "./sports";
import watchAuthSaga from "./auth";
import watchCasinoSaga from "./casino";
import watchTenantsSaga from "./tenants";
import watchSitesSaga from "./sites";
import watchClassesSaga from "./classes";
import watchSelectsSaga from "./selects";
import watchGamesSaga from "./games";
import watchLotteriesSaga from "./lotteries";
import watchLiveCasinoSaga from "./live-casino";
import watchDataSourcesCacheSaga from "./data-sources-cache";
import watchPlayerHistorySaga from "./player-history";
import watchBulkBonusGrantSaga from "./bulk-bonus-grant";
import watchBulkDeductionGrantSaga from "./bulk-deduction-grant";
import watchBulkAffiliateInfoUpdateSaga from "./bulk-update-affiliate-info";
import watchLoyaltySaga from "./loyalty";
import watchDataSaga from "./data";
import watchContentManagementSaga from "./content-management";
import watchTableSearchFilterSaga from "./table-search-filter";
import watchSelectsMarketingEventsSaga from "./selects-marketing-events";
import watchDruidSaga from "./druid";
import watchNotificationTemplatesSaga from "./notification-templates";

export default function* rootSaga() {
  yield all([
    call(watchSportsSaga),
    call(watchAuthSaga),
    call(watchCasinoSaga),
    call(watchTenantsSaga),
    call(watchSitesSaga),
    call(watchClassesSaga),
    call(watchSelectsSaga),
    call(watchGamesSaga),
    call(watchLotteriesSaga),
    call(watchLiveCasinoSaga),
    call(watchDataSourcesCacheSaga),
    call(watchPlayerHistorySaga),
    call(watchBulkBonusGrantSaga),
    call(watchBulkDeductionGrantSaga),
    call(watchBulkAffiliateInfoUpdateSaga),
    call(watchLoyaltySaga),
    call(watchDataSaga),
    call(watchContentManagementSaga),
    call(watchTableSearchFilterSaga),
    call(watchSelectsMarketingEventsSaga),
    call(watchDruidSaga),
    call(watchNotificationTemplatesSaga),
  ]);
}
