// ** React Imports
import { useEffect, useState } from 'react'

// ** Third Party Components
import axios from 'axios'
import { Users } from 'react-feather'

// ** Custom Components
import StatsWithAreaChart from '@components/widgets/stats/StatsWithAreaChart'
import { getRandomBetwenMinAndMax, getRandomArray } from '@utils';

const SubscribersGained = ({ kFormatter }) => {
  // ** State
  const values = getRandomArray(7, 25, 60)
  const [data, setData] = useState({
    "series": [
      {
        "name": "Subscribers",
        "data": values
      }
    ],
    "analyticsData": {
      "data": values.reduce((partialSum, a) => partialSum + a, 0)
    }
  });

  /*
    useEffect(() => {
      axios.get('/card/card-statistics/subscribers').then(res => setData(res.data))
      return () => setData(null)
    }, [])
    */

  return data !== null ? (
    <StatsWithAreaChart
      icon={<Users size={21} />}
      color='primary'
      stats={kFormatter(data.analyticsData.data)}
      statTitle='Subscribers Gained'
      series={data.series}
      type='area'
    />
  ) : null
}

export default SubscribersGained
