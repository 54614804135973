import { liveCasinoConstants } from "../constants";

export const loadTables = (data) => ({
  type: liveCasinoConstants.LOAD,
  data
});

export const loadedTables = (data) => ({
  type: liveCasinoConstants.LOADED,
  data: data
});