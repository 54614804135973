import { selectsMarketingEventsConstants } from "../constants";

export const loadSelects = (data) => ({
  type: selectsMarketingEventsConstants.LOAD,
  data
});

export const loadedSelects = (data) => ({
  type: selectsMarketingEventsConstants.LOADED,
  data: data
});