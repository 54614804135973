import React, { useState, useCallback, useEffect } from 'react';
import { isDateTime, dateMath, dateTimeFormat, dateTimeParse, rangeUtil, } from '../data';
import { TimePickerCalendar } from './TimePickerCalendar';

// import Button from '@mui/material/Button';
import { ChevronDown } from 'react-feather';

const errorMessage = 'Please enter a past date or "now"';

export const TimeRangeForm = props => {
  const {
    value,
    isFullscreen = false,
    timeZone,
    onApply: onApplyFromProps,
    onValueChange,
    handleTempValueChange,
  } = props;
  const [from, setFrom] = useState(valueToState(value.raw.from, false, timeZone));
  const [to, setTo] = useState(valueToState(value.raw.to, true, timeZone));
  const [isOpen, setOpen] = useState(false);

  // Synchronize internal state with external value
  useEffect(() => {
    setFrom(valueToState(value.raw.from, false, timeZone));
    setTo(valueToState(value.raw.to, true, timeZone));
  }, [value.raw.from, value.raw.to, timeZone]);

  const onOpen = useCallback((event) => {
    event.preventDefault();
    setOpen(true);
  }, [setOpen]);

  const onFocus = useCallback((event) => {
    if (!isFullscreen) {
      return;
    }
    onOpen(event);
  }, [isFullscreen, onOpen]);

  const onApply = useCallback((e) => {
    e.preventDefault();
    if (to.invalid || from.invalid) {
      return;
    }
    const raw = { from: from && from.value ? from.value : "", to: to && to.value ? to.value : "" };
    const timeRange = rangeUtil.convertRawToRange(raw, timeZone);
    onApplyFromProps(timeRange);
  }, [from.invalid, from.value, onApplyFromProps, timeZone, to.invalid, to.value]);

  const onChange = useCallback((from, to) => {
    setFrom(valueToState(from, false, timeZone));
    setTo(valueToState(to, true, timeZone));
  }, [timeZone]);

  React.useEffect(() => {
    handleTempValueChange({ from, to });
    if (to.invalid || from.invalid) {
      return;
    }
    const raw = { from: from && from.value ? from.value : "", to: to && to.value ? to.value : "" };
    const timeRange = rangeUtil.convertRawToRange(raw, timeZone);
    onValueChange(timeRange);
  }, [from.invalid, from.value, timeZone, to.invalid, to.value]);

  return (<div>
    <div className="gf-input-field-row">
      <div>
        <span className="gf-input-field-label">From</span>
        <div className="gf-input-field-input-wrapper">
          <input className="gf-input-field" onClick={event => event.stopPropagation()} onFocus={onFocus} onChange={event => setFrom(eventToState(event, false, timeZone))} aria-label="TimePicker from field" value={from.value || ""} />
          {from.invalid && <div className="invalid-feedback">{errorMessage}</div>}
          <ChevronDown size={19} className="gf-input-field-chevron"/>
        </div>
      </div>
      <div>
        <span className="gf-input-field-label">To</span>
        <div className="gf-input-field-input-wrapper">
          <input className="gf-input-field" onClick={event => event.stopPropagation()} onFocus={onFocus} onChange={event => setTo(eventToState(event, true, timeZone))} aria-label="TimePicker to field" value={to.value || ""} />
          <ChevronDown size={19} className="gf-input-field-chevron"/>
        </div>
        {to.invalid && <div className="invalid-feedback">{errorMessage}</div>}
      </div>
    </div>
    {/*<Button onClick={onApply} color="primary" variant="contained">*/}
    {/*  Apply time range*/}
    {/*</Button>*/}

    <TimePickerCalendar isFullscreen={isFullscreen} isOpen={isOpen} from={dateTimeParse(from.value, { timeZone })} to={dateTimeParse(to.value, { timeZone })} onApply={onApply} onClose={() => setOpen(false)} onChange={onChange} timeZone={timeZone} isReversed={props.isReversed} />
  </div>);
};

function eventToState(event, roundup, timeZone) {
  return valueToState(event.currentTarget.value, roundup, timeZone);
}

function valueToState(raw, roundup, timeZone) {
  const value = valueAsString(raw, timeZone);
  const invalid = !isValid(value, roundup, timeZone);
  return { value, invalid };
}

function valueAsString(value, timeZone) {
  if (isDateTime(value)) {
    return dateTimeFormat(value, { timeZone });
  }
  return value;
}

function isValid(value, roundUp, timeZone) {
  if (isDateTime(value)) {
    return value.isValid();
  }
  if (dateMath.isMathString(value)) {
    return dateMath.isValid(value);
  }
  const parsed = dateTimeParse(value, { roundUp, timeZone });
  return parsed.isValid();
}