import { sportsConstants } from "../constants";

export const fetchSports = data => ({
	type: sportsConstants.FETCH_SPORTS,
	data
});

export const loadedSports = data => ({
	type: sportsConstants.LOADED_SPORTS,
	data
});

export const selectedSportsChanged = (sType, data) => ({
	type: sportsConstants.SELECTED_SPORTS_CHANGED,
	sType,
	data
});

export const poolSportsChanged = (sType, data) => ({
	type: sportsConstants.POOL_SPORTS_CHANGED,
	sType,
	data
});

export const saveSelectedSports = data => ({
	type: sportsConstants.SAVE_SPORTS,
	data
});
