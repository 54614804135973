import moment from 'moment-timezone';
import { systemDateFormats } from './formats';
import { getTimeZone } from './common';

export const dateTimeFormat = (dateInUtc, options) => toTz(dateInUtc, getTimeZone(options)).format(getFormat(options));

export const dateTimeFormatISO = (dateInUtc, options) => toTz(dateInUtc, getTimeZone(options)).format();

export const dateTimeFormatTimeAgo = (dateInUtc, options) => toTz(dateInUtc, getTimeZone(options)).fromNow();

export const dateTimeFormatWithAbbrevation = (dateInUtc, options) => toTz(dateInUtc, getTimeZone(options)).format(`${systemDateFormats.fullDate} z`);

export const timeZoneAbbrevation = (dateInUtc, options) => toTz(dateInUtc, getTimeZone(options)).format('z');

const getFormat = (options) => {
  if (options && options.defaultWithMS) {
    return options.format ? options.format : systemDateFormats.fullDateMS;
  }
  return options.format ? options.format : systemDateFormats.fullDate;
};

const toTz = (dateInUtc, timeZone) => {
  const date = dateInUtc;
  const zone = moment.tz.zone(timeZone);
  if (zone && zone.name) {
    return moment.utc(date).tz(zone.name);
  }
  switch (timeZone) {
    case 'utc':
      return moment.utc(date);
    default:
      return moment.utc(date).local();
  }
};