// PlayerHistory selectors
export const playerHistoryData = (state) => state.playerHistory.playerHistory;
export const playerHistoryFullData = (state) => state.playerHistory.playerHistoryFull;
export const playerHistoryError = (state) => state.playerHistory.playerHistoryError;
export const playerHistoryLoading = (state) => state.playerHistory.loading;
export const playerHistoryStartDate = (state) => state.playerHistory.startDate;
export const playerHistoryEndDate = (state) => state.playerHistory.endDate;
export const selectedTransactionTypes = (state) => state.playerHistory.selectedTransactionTypes;
export const transactionTypes = (state) => state.playerHistory.transactionTypes;
export const playerHistoryPendingData = (state) => state.playerHistory.pendingData;
export const playerHistoryLoadingGameSessionRounds = (state) => state.playerHistory.loadingGameSessionRounds;
export const providerWithdrawStatuses = (state) => state.playerHistory.providerWithdrawStatuses;
export const loadedProviderWithdrawStatuses = (state) => state.playerHistory.loadedProviderWithdrawStatuses;
export const oktoTransactionStatuses = (state) => state.playerHistory.oktoTransactionStatuses;
export const loadedOktoTransactionStatuses = (state) => state.playerHistory.loadedOktoTransactionStatuses;
export const skrillTransactionStatuses = (state) => state.playerHistory.skrillTransactionStatuses;
export const loadedSkrillTransactionStatuses = (state) => state.playerHistory.loadedSkrillTransactionStatuses;
export const aircashTransactionStatuses = (state) => state.playerHistory.aircashTransactionStatuses;
export const loadedAircashTransactionStatuses = (state) => state.playerHistory.loadedAircashTransactionStatuses;
