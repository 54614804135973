import 'core-js/actual/array/group';
// ** React Imports
import { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

// ** Redux Imports
import { Provider } from 'react-redux'
import store from './store'

// ** Toast & ThemeColors Context
import { Toaster } from 'react-hot-toast'
import { ThemeContext } from './vuexy/utility/context/ThemeColors'
import GenericAlert from './components/GenericAlert';
import AxiosAlert from "./components/AxiosAlert";

// ** Spinner (Splash Screen)
import Spinner from './vuexy/@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './vuexy/@core/components/ripple-button'

import "react-virtualized-select/styles.css";
import "./style/react-select.css";

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

import themeConfig from './vuexy/configs/themeConfig'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import './vuexy/@core/assets/fonts/feather/iconfont.css'
import './vuexy/@core/scss/core.scss'
import './vuexy/assets/scss/style.scss'

// ** Service Worker
import { registerSW } from 'virtual:pwa-register';

import { authCheck } from "./store/actions/auth";
import { tenantsFetchAll } from "./store/actions/tenants";
import "./i18n/index.js";

import App from "./vuexy/App.js";


ReactDOM.render(
	<Provider store={store}>
		<Suspense fallback={<Spinner />}>
			<ThemeContext>
				<App />
				<Toaster position={themeConfig.layout.toastPosition} toastOptions={{ className: 'react-hot-toast' }} />
				<GenericAlert />
				<AxiosAlert />
			</ThemeContext>
		</Suspense>
	</Provider>,
	document.getElementById('root')
)

store.dispatch(authCheck());
window._store = store;

/*
registerSW({
	onNeedRefresh() { },
	onOfflineReady() { }
})
*/
