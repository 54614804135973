import {put, takeEvery, select} from 'redux-saga/effects';
import axios from "src/store/_shared/authAxios";
import {
  loadedPlayerHistory,
  toggleLoadPlayerHistory,
  updateEndDate,
  updateStartDate,
  loadedPlayerHistoryGameSessionRounds,
  toggleLoadGameSessionRoundsPlayerHistory,
  loadedProviderWithdrawStatuses,
  loadedOktoTransactionStatuses,
  loadedSkrillTransactionStatuses,
  loadedAircashTransactionStatuses,
} from '../actions/player-history';
import {buildApiUrl} from '../../utils/api';
import {playerHistoryConstants} from '../constants';
import {
  playerHistoryStartDate,
  playerHistoryEndDate
} from '../selectors/player-history';
import moment from 'moment';
import {accessPages, operationsAccess} from 'src/utils/users';

import { timelineExample} from "src/sample_data/timeline";

const apiUrl = buildApiUrl("/admin/player-history/details");
const withdrawStatusesUrl = buildApiUrl("/admin");

const USE_DUMMY_DATA = false;

function* loadPlayerHistorySaga(action) {
  try {
    yield put(toggleLoadPlayerHistory(true));

      const actionData = action && action.data ? action.data : {};

      let params = {};

      if (actionData.clientId && actionData.clientId !== "undefined") {
        params.clientId = actionData.clientId;
      }

      if (actionData.playerId && actionData.playerId !== "undefined") {
        params.playerId = actionData.playerId;
      }

      if (actionData.startDate && actionData.startDate !== "undefined") {
        params.startDate = actionData.startDate;
      }

      if (actionData.endDate && actionData.endDate !== "undefined") {
        params.endDate = actionData.endDate;
      }

      const startDate = yield select(playerHistoryStartDate);

      if (params.startDate === undefined && startDate !== undefined) {
        params.startDate = moment(startDate, playerHistoryConstants.DATE_PICKER_FORMAT).unix();
      }

      const endDate = yield select(playerHistoryEndDate);

      if (params.endDate === undefined && endDate !== undefined) {
        params.endDate = moment(endDate, playerHistoryConstants.DATE_PICKER_FORMAT).unix();
      }

    if (!USE_DUMMY_DATA) {
      const {data} = yield axios.get(apiUrl, {params: params});

      yield put(loadedPlayerHistory(data.playerHistory.data, data.playerHistory.error ?? null));


    } else {
      const data = {playerHistory: { success: true, data: timelineExample.sort((a, b) => parseInt(b.date) - parseInt(a.date)) }};

      yield put(loadedPlayerHistory(data.playerHistory.data, null));


    }

    yield put(toggleLoadPlayerHistory(false));
  } catch (e) {
    console.log("[SAGA] playerHistory error", e.toString());
  }
}

function* changedStartDate(action) {
  const endDate = moment(yield select(playerHistoryEndDate), playerHistoryConstants.DATE_PICKER_FORMAT);
  const startDate = moment(action.data, playerHistoryConstants.DATE_PICKER_FORMAT);
  const dateDiff = endDate.diff(startDate, 'days');

  if (dateDiff <= playerHistoryConstants.MAX_DAYS_DIFF) {
    yield put(updateStartDate(action.data));
  }
}

function* changedEndDate(action) {
  const endDate = moment(action.data, playerHistoryConstants.DATE_PICKER_FORMAT);
  const startDate = moment(yield select(playerHistoryStartDate), playerHistoryConstants.DATE_PICKER_FORMAT);
  const dateDiff = endDate.diff(startDate, 'days');

  if (dateDiff <= playerHistoryConstants.MAX_DAYS_DIFF) {
    yield put(updateEndDate(action.data));
  }
}

function* loadGameSessionRounds(action) {
  try {
    yield put(toggleLoadGameSessionRoundsPlayerHistory(true));

    const actionData = action && action.data ? action.data : {};

    let params = {};
    let query = "";

    if (actionData.clientId && actionData.clientId !== "undefined") {
      query = `?clientId=${actionData.clientId}`;
    }

    if (actionData.sessions && actionData.sessions !== "undefined") {
      params.sessions = actionData.sessions;
    }

    const {data} = yield axios.post(`${apiUrl}/extra/gameSessions${query}`, params);

    yield put(loadedPlayerHistoryGameSessionRounds(data.sessions, data.error ?? null));

    yield put(toggleLoadGameSessionRoundsPlayerHistory(false));
  } catch (e) {
    console.log("[SAGA] playerHistory loadGameSessionRounds error", e.toString());
  }
}

function* loadProviderWithdrawStatuses(action) {
  const opAccessDeposits = operationsAccess(accessPages.VIVA_DEPOSITS);
  // const opAccessDepositsOkto = operationsAccess(accessPages.OKTO_TRANSACTIONS);  // todo: update acl okto transactions
  const opAccessDepositsOkto = {canList: true};
  // const opAccessDepositsSkrill = operationsAccess(accessPages.SKRILL_TRANSACTIONS);  // todo: update acl skrill transactions
  const opAccessDepositsSkrill = {canList: true};
  // const opAccessDepositsAircash = operationsAccess(accessPages.AIRCASH_TRANSACTIONS);  // todo: update acl aircash transactions
  const opAccessDepositsAircash = {canList: true};


  if (!opAccessDeposits.canList || !opAccessDepositsOkto.canList || !opAccessDepositsSkrill.canList || !opAccessDepositsAircash.canList) {
    yield put(loadedProviderWithdrawStatuses([], "Insufficient access rights"));
    return
  }
    yield put(toggleLoadPlayerHistory(true));
  try {

    const urls = [
      `${withdrawStatusesUrl}/withdraw/transaction-status`,
    ];
    let [
      responseWithdrawStatuses,
    ] = yield Promise.all(urls.map(url => axios.get(url)));

    if (!!responseWithdrawStatuses.data) {
      yield put(loadedProviderWithdrawStatuses(responseWithdrawStatuses.data.data, responseWithdrawStatuses.data.error ?? null));
    }
  } catch (e) {
    console.log("[SAGA] playerHistory loadProviderWithdrawStatuses error", e.toString());
    yield put(loadedProviderWithdrawStatuses([], e.toString()));
  } finally {
    try {
      yield put(toggleLoadPlayerHistory(true));

      const urls = [
        `${withdrawStatusesUrl}/okto/contants/transaction-status`,
      ];
      let [
        responseOktoTransactionStatuses,
      ] = yield Promise.all(urls.map(url => axios.get(url)));

      if (!!responseOktoTransactionStatuses.data) {
        yield put(loadedOktoTransactionStatuses(responseOktoTransactionStatuses.data.data, responseOktoTransactionStatuses.data.error ?? null));
      }
    } catch (e) {
      console.log("[SAGA] playerHistory loadProviderWithdrawStatuses error", e.toString());
      yield put(loadedOktoTransactionStatuses([], e.toString()));
    } finally {
      try {
        yield put(toggleLoadPlayerHistory(true));

        const urls = [
          `${withdrawStatusesUrl}/skrill/contants/transaction-status`,
        ];
        let [
          responseSkrillTransactionStatuses
        ] = yield Promise.all(urls.map(url => axios.get(url)));

        if (!!responseSkrillTransactionStatuses.data) {
          yield put(loadedSkrillTransactionStatuses(responseSkrillTransactionStatuses.data.data, responseSkrillTransactionStatuses.data.error ?? null));
        }
      } catch (e) {
        console.log("[SAGA] playerHistory loadProviderWithdrawStatuses error", e.toString());
        yield put(loadedSkrillTransactionStatuses([], e.toString()));
      } finally {
        try {
          yield put(toggleLoadPlayerHistory(true));

          const urls = [
            `${withdrawStatusesUrl}/aircash/contants/transaction-status`,
          ];
          let [
            responseAircashTransactionStatuses
          ] = yield Promise.all(urls.map(url => axios.get(url)));

          if (!!responseAircashTransactionStatuses.data) {
            yield put(loadedAircashTransactionStatuses(responseAircashTransactionStatuses.data.data, responseAircashTransactionStatuses.data.error ?? null));
          }
        } catch (e) {
          console.log("[SAGA] playerHistory loadProviderWithdrawStatuses error", e.toString());
          yield put(loadedAircashTransactionStatuses([], e.toString()));
        } finally {
          yield put(toggleLoadPlayerHistory(false));
        }
      }
    }
  }
}

function* loadOktoTransactionStatuses(action) {
  // const opAccessDeposits = operationsAccess(accessPages.OKTO_TRANSACTIONS);  // todo: update acl okto transactions
  const opAccessDeposits = {canList: true};
  if (!opAccessDeposits.canList) {
    yield put(loadedOktoTransactionStatuses([], "Insufficient access rights"));
    return
  }
  try {
    yield put(toggleLoadPlayerHistory(true));

    const {data} = yield axios.get(`${withdrawStatusesUrl}/okto/contants/transaction-status`);

    yield put(loadedOktoTransactionStatuses(data.data, data.error ?? null));
  } catch (e) {
    console.log("[SAGA] playerHistory loadOktoTransactionStatuses error", e.toString());
    yield put(loadedOktoTransactionStatuses([], e.toString()));
  }
}

function* loadSkrillTransactionStatuses(action) {
  // const opAccessDeposits = operationsAccess(accessPages.SKRILL_TRANSACTIONS);  // todo: update acl skrill transactions
  const opAccessDeposits = {canList: true};
  if (!opAccessDeposits.canList) {
    yield put(loadedSkrillTransactionStatuses([], "Insufficient access rights"));
    return
  }
  try {
    yield put(toggleLoadPlayerHistory(true));

    const {data} = yield axios.get(`${withdrawStatusesUrl}/skrill/contants/transaction-status`);

    yield put(loadedSkrillTransactionStatuses(data.data, data.error ?? null));
  } catch (e) {
    console.log("[SAGA] playerHistory loadSkrillTransactionStatuses error", e.toString());
    yield put(loadedSkrillTransactionStatuses([], e.toString()));
  }
}

function* loadAircashTransactionStatuses(action) {
  // const opAccessDeposits = operationsAccess(accessPages.AIRCASH_TRANSACTIONS);  // todo: update acl aircash transactions
  const opAccessDeposits = {canList: true};
  if (!opAccessDeposits.canList) {
    yield put(loadedAircashTransactionStatuses([], "Insufficient access rights"));
    return
  }
  try {
    yield put(toggleLoadPlayerHistory(true));

    const {data} = yield axios.get(`${withdrawStatusesUrl}/aircash/contants/transaction-status`);

    yield put(loadedAircashTransactionStatuses(data.data, data.error ?? null));
  } catch (e) {
    console.log("[SAGA] playerHistory loadAircashTransactionStatuses error", e.toString());
    yield put(loadedAircashTransactionStatuses([], e.toString()));
  }
}

export default function* watchPlayerHistorySaga() {
  yield takeEvery(playerHistoryConstants.LOAD, loadPlayerHistorySaga);
  yield takeEvery(playerHistoryConstants.CHANGED_START_DATE, changedStartDate);
  yield takeEvery(playerHistoryConstants.CHANGED_END_DATE, changedEndDate);
  yield takeEvery(playerHistoryConstants.LOAD_GAME_SESSION_ROUNDS, loadGameSessionRounds);
  yield takeEvery(playerHistoryConstants.LOAD_PROVIDER_WITHDRAW_STATUSES, loadProviderWithdrawStatuses);
  yield takeEvery(playerHistoryConstants.LOAD_OKTO_TRANSACTION_STATUSES, loadOktoTransactionStatuses);
  yield takeEvery(playerHistoryConstants.LOAD_SKRILL_TRANSACTION_STATUSES, loadSkrillTransactionStatuses);
  yield takeEvery(playerHistoryConstants.LOAD_AIRCASH_TRANSACTION_STATUSES, loadAircashTransactionStatuses);
}