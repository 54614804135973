import React from "react";
import {
  FormControl,
  FormHelperText
} from "@mui/material";
import {CustomInput as ReactstrapInput} from "reactstrap";

import ButtonGroup from "../../ui/ui-components/button-group";
import CheckBox from "../../ui/ui-components/check-box";

const BooleanField = ({forwardedRef, ...props}) => {
  // console.log("BooleanField props", props);

  const useRadioButton = typeof props.columnDef.useRadioButton !== "undefined"
    ? props.columnDef.useRadioButton
    : true;

  return (
    <FormControl
      error={Boolean(props.error)}
      ref={forwardedRef}
      component="fieldset"
    >
      <ButtonGroup inline={true}>
        {useRadioButton && (<React.Fragment>
          {props.columnDef.displayLabel && (
            <div className="title">{props.columnDef.label || props.columnDef.title}</div>
          )}
          <div className="form-check form-switch">
            <ReactstrapInput
              id={`table-cell-edit-${props.columnDef.field}`}
              type="switch"
              name={props.columnDef.field}
              checked={!!props.value}
              onChange={(event) => props.onChange(event.target.checked)}
            />
          </div>
        </React.Fragment>)}
        {!useRadioButton && (
          <CheckBox
            {...props}
            name={props.columnDef.field}
            label={props.columnDef.displayLabel ? props.columnDef.label || props.columnDef.title : ""}
            defaultChecked={!!props.value}
            onChange={(event) => props.onChange(event.target.checked)}
          />
        )}
      </ButtonGroup>
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
};

const BooleanFieldRef = React.forwardRef((props, ref) => {
  return <BooleanField {...props} forwardedRef={ref}/>;
});

export default BooleanFieldRef;