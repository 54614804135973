import {notificationTemplatesConstants as constants} from "../constants";

export const loadValues = () => ({
  type: constants.LOAD_VALUES,
});

export const loadedValues = (data) => ({
  type: constants.LOADED_VALUES,
  data,
});

export const loadingValues = () => ({
  type: constants.LOADING_VALUES,
});

export const clear = () => ({
  type: constants.CLEAR,
});