import React from 'react';
import { dateTimeFormat } from "../data/formatters";

import isString from 'lodash/isString';

export const TimeZoneOffset = props => {
  const { timestamp, timeZone, className } = props;


  if (!isString(timeZone)) {
    return null;
  }

  return (
    <React.Fragment>
      <span className={"gf-tzof-localTime"}>{formatLocalTime(timestamp, timeZone)}</span>
      <span className={`gf-tzof-offset ${className ? className : ''}`}>{formatUtcOffset(timestamp, timeZone)}</span>
    </React.Fragment>
  );
};

export const formatUtcOffset = (timestamp, timeZone) => {
  const offset = dateTimeFormat(timestamp, {
    timeZone,
    format: 'Z',
  });

  if (offset === '+00:00') {
    return 'UTC';
  }
  return `UTC${offset}`;
};

const formatLocalTime = (timestamp, timeZone) => {
  return dateTimeFormat(timestamp, {
    timeZone,
    format: 'HH:mm',
  });
};
