import { produce } from "immer";
import { dataConstants } from "../constants";
import { decodeWithFunctions } from "../../utils/encode";
import { getDataApiBaseUrl } from "../../utils/api";
import authAxios from "../../store/_shared/authAxios";

const apiUrl = getDataApiBaseUrl();

const cache = {
  elementTypes: null,
  elementsByElementType: {},
  sourcesByElementType: {},
  sourceReducersByElementType: {},
  sourcePartialsByElementType: {},
}

const cacheClearElementTypes = () => {
  cache.elementTypes = null;
}

const cacheClearElementsByElementType = (element_type_id) => {
  delete cache.elementsByElementType[element_type_id];
}

const cacheClearSourcesByElementType = (element_type_id) => {
  delete cache.sourcesByElementType[element_type_id];
}

const cacheClearSourceReducersByElementType = (element_type_id) => {
  delete cache.sourceReducersByElementType[element_type_id];
}

const makeInitialState = () => ({
  typesDef: null,
});

const dataReducer = (state = makeInitialState(), action) =>
  produce(state, draft => {
    switch (action.type) {
      case dataConstants.TYPESDEF_RECEIVED:
        // console.log(`dataConstants.TYPESDEF_RECEIVED: `, action.typesDef);
        draft.typesDef = new (new Function("return " + action.typesDef)())();

        draft.typesDef.base.getImageInfo = async function (url) {
          const { data } = await authAxios.get(`${getDataApiBaseUrl(`/data/tools/image-info?url=${url}`)}`);
          console.log(data);
          delete data.exif;
          return data;
        };

        draft.typesDef.base.getElementTypes = async function () {
          if (cache.elementTypes) {
            return cache.elementTypes;
          }

          const { data } = await authAxios.get(`${getDataApiBaseUrl(`/data/element-template/list-as-options`)}`);
          
          cache.elementTypes = data;
          
          return data;
        };

        draft.typesDef.base.getElements = async function (element_type_id) {
          if (!element_type_id) {
            throw new Error("element_type_id is not set");
          }

          if (cache.elementsByElementType[element_type_id]) {
            return cache.elementsByElementType[element_type_id];
          }

          const { data } = await authAxios.get(
            `${getDataApiBaseUrl(`/data/element/list-by-element-type-id?element_type_id=${element_type_id}`)}`
          );

          cache.elementsByElementType[element_type_id] = data;

          return data;
        };

        draft.typesDef.base.getSources = async function (element_type_id) {
          if (!element_type_id) {
            throw new Error("element_type_id is not set");
          }

          if (cache.sourcesByElementType[element_type_id]) {
            return cache.sourcesByElementType[element_type_id];
          }

          const { data } = await authAxios.get(
            `${getDataApiBaseUrl(`/data/source/list-by-element-type-id?element_type_id=${element_type_id}`)}`
          );

          cache.sourcesByElementType[element_type_id] = data;

          return data;
        };

        draft.typesDef.base.getSourceReducers = async function (element_type_id) {
          if (!element_type_id) {
            throw new Error("element_type_id is not set");
          }

          if (cache.sourceReducersByElementType[element_type_id]) {
            return cache.sourceReducersByElementType[element_type_id];
          }

          const { data } = await authAxios.get(
            `${getDataApiBaseUrl(`/data/source-reducer/list-by-element-type-id?element_type_id=${element_type_id}`)}`
          );

          cache.sourceReducersByElementType[element_type_id] = data;

          return data;
        };

        draft.typesDef.base.getSourcePartials = async function (element_type_id) {
          if (!element_type_id) {
            throw new Error("element_type_id is not set");
          }

          if (cache.sourcePartialsByElementType[element_type_id]) {
            return cache.sourcePartialsByElementType[element_type_id];
          }

          const { data } = await authAxios.get(
            `${getDataApiBaseUrl(`/data/source-partial/list-by-element-type-id?element_type_id=${element_type_id}`)}`
          );

          cache.sourcePartialsByElementType[element_type_id] = data;

          return data;
        };

        break;
      default:
        break;
    }
  });

export default dataReducer;

export {
  cacheClearElementTypes,
  cacheClearElementsByElementType,
  cacheClearSourcesByElementType,
  cacheClearSourceReducersByElementType,
}