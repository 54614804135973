import { tagsConstants } from "../constants";

export const loadTags = (data) => ({
  type: tagsConstants.LOAD,
  data
});

export const loadingTags = () => ({
  type: tagsConstants.LOADING
});

export const loadedTags = (data) => ({
  type: tagsConstants.LOADED,
  data: data
});