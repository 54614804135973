import React from "react";
import { FormControl, FormHelperText } from "@mui/material";

import VirtualizedSelectOption from "../../ui/ui-components/virtualized-select-option";

const LookupField = ({ forwardedRef, ...props }) => {
  const { columnDef, value, onChange } = props;

  const valueKey = columnDef.lookupDef && typeof columnDef.lookupDef.valueKey !== "undefined"
    ? columnDef.lookupDef.valueKey
    : "id";

  const labelKey = columnDef.lookupDef && typeof columnDef.lookupDef.labelKey !== "undefined"
    ? columnDef.lookupDef.labelKey
    : "name";

  const valueType = columnDef.lookupDef && typeof columnDef.lookupDef.valueType !== "undefined"
    ? columnDef.lookupDef.valueType
    : "string";

  return (
    <FormControl ref={forwardedRef} error={Boolean(props.error)}>
      <VirtualizedSelectOption
        {...props}
        name={columnDef.field}
        searchable={true}
        clearable={false}
        valueKey={valueKey}
        labelKey={labelKey}
        valueType={valueType}
        value={value === undefined ? '' : value}
        onChange={(event) => {
          // console.log(event);
          onChange(event.target.value);
        }}
        options={Object.keys(columnDef.lookup).map(
          (key) => ({
            [valueKey]: key,
            [labelKey]: columnDef.lookup[key],
          }))
        }
        menuPortalTarget={document.body}
      />
      {Boolean(props.helperText) && (
        <FormHelperText>{props.helperText}</FormHelperText>
      )}
    </FormControl>
  )
};

const LookupFieldRef = React.forwardRef((props, ref) => {
  return <LookupField {...props} forwardedRef={ref} />;
});

export default LookupFieldRef;
