import { takeEvery, put } from "redux-saga/effects";
import axios from "src/store/_shared/authAxios";

import { dataConstants } from "../constants";
import { dataTypesDefReceived } from "../actions/data";

import { getDataApiBaseUrl } from "../../utils/api";

import store from "../../store";

const apiUrl = getDataApiBaseUrl();
const typesDef = {
  base: {
    elementPath:
      '_func:(e, p) => {\r\n    if (e === undefined) return undefined;\r\n    if (e === null) return null;\r\n    const path = p.split(".");\r\n    console.log(path);\r\n    let current = e;\r\n    for (let i = 0; i < path.length; i++) {\r\n      if (current === undefined) return undefined;\r\n      if (current === null) return null;\r\n      if (!(path[i] in current)) return undefined;\r\n      current = current[path[i]];\r\n    }\r\n    return current;\r\n  }',
  },
  filterCategory: {
    numeric: {
      equals: { name: "equals", filter: "_func:(l, p, v) => l.filter(e => base.elementPath(e, p) === v)" },
      greater_than: { name: "greater_than", filter: "_func:(l, p, v) => l.filter(e => base.elementPath(e, p) > v)" },
      less_than: { name: "less_than", filter: "_func:(l, p, v) => l.filter(e => base.elementPath(e, p) < v)" },
      between: {
        name: "between",
        filter: "_func:(l, p, v) => l.filter(e => base.elementPath(e, p) >= v.min && base.elementPath(e, p) <= v.max)",
      },
    },
    string: {
      equals: { name: "equals", filter: "_func:(l, p, v) => l.filter(e => base.elementPath(e, p) === v)" },
      contains: { name: "contains", filter: "_func:(l, p, v) => l.filter(e => base.elementPath(e, p).includes(v))" },
      starts_with: {
        name: "starts_with",
        filter: "_func:(l, p, v) => l.filter(e => base.elementPath(e, p).startsWith(v))",
      },
      ends_with: { name: "ends_with", filter: "_func:(l, p, v) => l.filter(e => base.elementPath(e, p).endsWith(v))" },
    },
    boolean: { equals: { name: "equals", filter: "_func:(l, p, v) => l.filter(e => base.elementPath(e, p) === v)" } },
  },
  types: [
    {
      id: "integer",
      kind: "scalar",
      type: "integer",
      filter_category: "numeric",
      as_attribute: "true",
      parse: "_func:v => parseInt(v)",
      validate: "_func:v => !isNaN(v)",
    },
    {
      id: "float",
      kind: "scalar",
      type: "float",
      filter_category: "numeric",
      as_attribute: "true",
      parse: "_func:v => parseFloat(v)",
      validate: "_func:v => !isNaN(v)",
    },
    { id: "string", kind: "scalar", type: "string", filter_category: "string", as_attribute: "true" },
    {
      id: "boolean",
      kind: "scalar",
      type: "boolean",
      filter_category: "boolean",
      as_attribute: "true",
      parse: '_func:v => v === "true"',
      validate: "_func:v => v === true || v === false",
    },
    {
      id: "ranged_integer",
      kind: "scalar",
      type: "integer",
      properties: {
        min: { name: "Min", type: "integer", nullable: "true" },
        max: { name: "Max", type: "integer", nullable: "true" },
      },
      filter_category: "numeric",
      as_attribute: "true",
      parse: "_func:v => parseInt(v)",
      validate: "_func:(v, props) => !isNaN(v) && v >= props.min && v <= props.max",
    },
    {
      id: "ranged_float",
      kind: "scalar",
      type: "float",
      properties: {
        min: { name: "Min", type: "float", nullable: "true" },
        max: { name: "Max", type: "float", nullable: "true" },
      },
      filter_category: "numeric",
      as_attribute: "true",
      parse: "_func:v => parseFloat(v)",
      validate: "_func:(v, props) => !isNaN(v) && v >= props.min && v <= props.max",
    },
    {
      id: "image",
      kind: "scalar",
      type: "string",
      description: "Image URL",
      properties: {
        minWidth: { type: "integer", nullable: "true" },
        maxWidth: { type: "integer", nullable: "false" },
        minHeight: { type: "integer", nullable: "true" },
        maxHeight: { type: "integer", nullable: "false" },
        ratio: { type: "float", nullable: "true" },
        format: { type: "string", nullable: "true" },
        maxSize: { type: "integer", nullable: "true" },
      },
      parse: "_func:v => v",
      validate: "_func:(v, props) => {\r\n      return true;\r\n    }",
    },
    { id: "ref_source", kind: "one_of", type: "string", description: "Reference source", values: [] },
    { id: "ref_element", kind: "one_of", type: "string", description: "Reference element", values: [] },
  ],
};

function* dataTypesDefRequestSaga(action) {
  try {
    const state = store.getState();

    if (state.data.typesDef === null) {
      const { data } = yield axios.get(`${getDataApiBaseUrl(`/data/types/list`)}`);
      //console.log("[SAGA] dataTypesDefRequestSaga received typesDef");
      yield put(dataTypesDefReceived(data));

      // const td = JSON.parse(typesDef);

      // console.log("[SAGA] dataTypesDefRequestSaga", typesDef);
      // yield put(dataTypesDefReceived(typesDef));
    }
  } catch (e) {
    console.log("[SAGA] dataTypesDefRequestSaga error", e.toString());
  }
}

export default function* watchDataSaga() {
  //console.log("[SAGA] watching data");

  yield takeEvery(dataConstants.TYPESDEF_REQUEST, dataTypesDefRequestSaga);
}
