import React, { memo, useCallback } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import Calendar from 'react-calendar';

import { dateTime, dateTimeParse } from '../data';

import { TimePickerTitle } from './TimePickerTitle';
import Button from '@mui/material/Button';

import { Portal } from './Portal';
import { ClickOutsideWrapper } from './ClickOutsideWrapper';

const stopPropagation = (event) => event.stopPropagation();

export const TimePickerCalendar = memo(props => {
  //const styles = getStyles(theme, props.isReversed);
  const { isOpen, isFullscreen } = props;

  if (!isOpen) {
    return null;
  }

  if (isFullscreen) {
    return (<ClickOutsideWrapper onClick={props.onClose}>
      <div className={`gf-tpc-container ${props.isReversed ? 'is-reversed' : ''}`} onClick={stopPropagation}>
        <Body {...props} />
      </div>
    </ClickOutsideWrapper>);
  }

  return (<Portal>
    <div className={`gf-tpc-modal`} onClick={stopPropagation}>
      <div className={`gf-tpc-content`}>
        <Header {...props} />
        <Body {...props} />
        <Footer {...props} />
      </div>
    </div>
    <div className={`gf-tpc-backdrop`} onClick={stopPropagation} />
  </Portal>);
});

TimePickerCalendar.displayName = 'TimePickerCalendar';

const Header = memo(({ onClose }) => {
  //const styles = getHeaderStyles(theme);
  return (<div className={`gf-h-container`}>
    <TimePickerTitle>Select a time range</TimePickerTitle>
    <Button onClick={onClose}>close icon</Button>
  </div>);
});

Header.displayName = 'Header';

const Body = memo(({ onChange, from, to, timeZone }) => {
  const value = inputToValue(from, to);
  const onCalendarChange = useOnCalendarChange(onChange, timeZone);

  //const styles = getBodyStyles(theme);

  return (<Calendar selectRange={true} next2Label={null} prev2Label={null} className={`gf-b-body`} tileClassName={`gf-b-title`} value={value} nextLabel={<ChevronRightIcon />} prevLabel={<ChevronLeftIcon />} onChange={onCalendarChange} locale="en" />);
});

Body.displayName = 'Body';

const Footer = memo(({ onClose, onApply }) => {
  //const styles = getFooterStyles(theme);
  return (<div className={`gf-f-container`}>
    <Button className={`gf-f-apply`} onClick={onApply} variant="contained">
      Apply time range
    </Button>
    <Button color="secondary" onClick={onClose} variant="contained">
      Cancel
    </Button>
  </div>);
});

Footer.displayName = 'Footer';

export function inputToValue(from, to, invalidDateDefault = new Date()) {
  const fromAsDate = from.toDate();
  const toAsDate = to.toDate();
  const fromAsValidDate = dateTime(fromAsDate).isValid() ? fromAsDate : invalidDateDefault;
  const toAsValidDate = dateTime(toAsDate).isValid() ? toAsDate : invalidDateDefault;
  if (fromAsValidDate > toAsValidDate) {
    return [toAsValidDate, fromAsValidDate];
  }
  return [fromAsValidDate, toAsValidDate];
}

function useOnCalendarChange(onChange, timeZone) {
  return useCallback((value) => {
    if (!Array.isArray(value)) {
      return console.error('onCalendarChange: should be run in selectRange={true}');
    }
    const from = dateTimeParse(dateInfo(value[0]), { timeZone });
    const to = dateTimeParse(dateInfo(value[1]), { timeZone });
    onChange(from, to);
  }, [onChange, timeZone]);
}

function dateInfo(date) {
  return [date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()];
}