import { rtNotificationsConstants } from "../constants";

export const rtNotificationReceived = data => ({
  type: rtNotificationsConstants.RT_NOTIFICATION_RECEIVED,
  data,
});

export const rtNotificationDismissed = id => ({
  type: rtNotificationsConstants.RT_NOTIFICATION_DISMISSED,
  id,
});

export const rtNotifiationConfirmed = id => ({
  type: rtNotificationsConstants.RT_NOTIFICATION_CONFIRMED,
  id,
});

export const rtNotificationClear = () => ({
  type: rtNotificationsConstants.RT_NOTIFICATION_CLEAR,
});
