import { dataSourcesConstants } from "../constants";

export const loadDataSources = (data) => ({
  type: dataSourcesConstants.LOAD,
  data
});

export const loadedDataSources = (data) => ({
  type: dataSourcesConstants.LOADED,
  data: data
});

export const loadDataSourcesContent = (data) => ({
  type: dataSourcesConstants.LOAD_CONTENT,
  data
});

export const updateDataSourceContent = (data) => ({
  type: dataSourcesConstants.UPDATE_CONTENT,
  data
});

export const loadedDataSourcesContent = (data) => ({
  type: dataSourcesConstants.LOADED_CONTENT,
  data: data
});

export const clearDataSources = (data) => ({
  type: dataSourcesConstants.CLEAR,
  data: data
});


export const loadDataSourcesContents = (data) => ({
  type: dataSourcesConstants.LOAD_CONTENTS,
  data
});

export const loadedDataSourcesContents = (data) => ({
  type: dataSourcesConstants.LOADED_CONTENTS,
  data: data
});
