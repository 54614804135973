import { takeEvery, put } from "redux-saga/effects";
import axios from "axios";

import { lotteriesConstants } from "../constants";
import { loadedLotteries } from "../actions/lotteries";

const lottoApiUrl = window.ini.REACT_APP_LOTTO_API;

function* loadLotteriesSaga(action) {
  try {
    const { data } = yield axios.get(`${lottoApiUrl}`);

    yield put(loadedLotteries(data.data));
  } catch (e) {
    console.log("[SAGA] loadLotteriesSaga error", e.toString());
  }
}

export default function* watchLotteriesSaga() {
  //console.log("[SAGA] watching lotteries");
  yield takeEvery(lotteriesConstants.LOAD, loadLotteriesSaga);
}
