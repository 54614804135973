import { takeEvery, put } from "redux-saga/effects";
import axios from "src/store/_shared/authAxios";

import store from "../../store";
import { bulkBonusGrant } from "../constants";
import { bulkBonusGrantDone } from "../actions/bulk-bonus-grant";
import { buildApiUrl } from "../../utils/api";

const apiCoreUrl = buildApiUrl("/adminCore");

function* bulkBonusGrantProgressSaga(action) {
  const { bulkBonusGrant } = store.getState();

  const currentRequest = bulkBonusGrant.currentRequest;

  if (currentRequest >= bulkBonusGrant.requests.total) {
    return;
  }

  const requestData = bulkBonusGrant.requests[currentRequest];
  const startTime = + new Date();

  try {
    yield axios.post(`${apiCoreUrl}/bonus/award`, requestData);
    const endTime = + new Date();
    yield put(bulkBonusGrantDone({ currentRequest: currentRequest, success: true, ellapsedTimePerRequest: endTime - startTime }));
  } catch (e) {
    console.log(e.toString());

    const endTime = + new Date();

    let message = e.toString();
    if (e && e.response && e.response.data && e.response.data.error && e.response.data.error.response) {
      const errorCode = e.response.data.error.response.ResponseCode;
      const errorMessage = e.response.data.error.response.ResponseMessage;
      if (errorCode || errorMessage) {
        message = `${errorMessage}${errorCode ? ` (ErrorCode: ${errorCode})` : ''}`;
      }
    }

    yield put(bulkBonusGrantDone({ currentRequest: currentRequest, success: false, message: message, ellapsedTimePerRequest: endTime - startTime }));
  }
}

export default function* watchBulkBonusGrantSaga() {
  yield takeEvery(bulkBonusGrant.PROGRESS, bulkBonusGrantProgressSaga);
}
