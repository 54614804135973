import { saveAccessToken, clearAccessToken, saveRefreshToken, clearRefreshToken } from "../_shared/authAxios";
import { userConstants } from "../constants";
import { websocketAuth, websocketDeauth } from "../_shared/ws";

const initialState = {
  requestActive: false,
  loggedIn: false,
  user: {},
  error: "",
  authed: null,
  inProgress: false,
  details: {},
  tenant: [],
  lastError: "",
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.AUTH_IN_PROGRESS:
      return {
        ...state,
        requestActive: action.active,
      }
    case userConstants.AUTH_CHECK_REQUEST:
    // fall through
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        requestActive: true,
        user: action.user,
        error: "",
      };
    case userConstants.LOGIN_SUCCESS:
      saveAccessToken(action.data.access_token);
      if (action.data.refresh_token) {
        saveRefreshToken(action.data.refresh_token);
      }

      const ns = {
        ...state,
        authed: true,
        inProgress: false,
        details: action.data,
        error: "",
      };

      websocketAuth(ns);
      return ns;

    case userConstants.AUTH_CHECK_SUCCESS: {
      const ns = {
        ...state,
        authed: true,
        inProgress: false,
        details: action.data,
        error: "",
      };

      websocketAuth(ns);
      return ns;
    }
    case userConstants.AUTH_CHECK_FAILURE:
      return {
        ...state,
        authed: false,
        user: {},
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        requestActive: false,
        error: action.error,
      };
    case userConstants.LOGOUT:
      clearAccessToken();
      clearRefreshToken();
      websocketDeauth();

      return {
        ...state,
        authed: false,
        inProgress: false,
        error: "",
        lastError: "",
      };
    default:
      return state;
  }
};

export default auth;
