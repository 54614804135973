import {takeEvery, put} from "redux-saga/effects";
import {delay} from "redux-saga/effects";
import axios from "src/store/_shared/authAxios";

import store from "..";
import {bulkAffiliateInfoUpdate as Constants} from "../constants";
import {bulkAffiliateInfoUpdateDone} from "../actions/bulk-update-affiliate-info";
import {buildApiUrl} from "../../utils/api";

const apiCoreUrl = buildApiUrl("/adminCore");

function* bulkAffiliateInfoUpdateProgressSaga(action) {
  const {bulkUpdateAffiliateInfo} = store.getState();
  // console.log("SAGA bulkUpdateAffiliateInfoProgressSaga");

  const currentRequest = bulkUpdateAffiliateInfo.currentRequest;

  if (currentRequest >= bulkUpdateAffiliateInfo.total || bulkUpdateAffiliateInfo.failed) {
    yield delay(1000);
    yield put(bulkAffiliateInfoUpdateDone({
      currentRequest: currentRequest,
      success: true,
      response: [],
      elapsedTimePerRequest: 0,
    }));
    return;
  }

  const requestData = bulkUpdateAffiliateInfo.requests[currentRequest];
  const startTime = +new Date();

  try {
    const {data} = yield axios.post(`${apiCoreUrl}/players/affiliateInfo/update`, requestData);

    const endTime = +new Date();
    yield put(bulkAffiliateInfoUpdateDone({
      currentRequest: currentRequest,
      success: true,
      elapsedTimePerRequest: endTime - startTime,
      response: data,
    }));
  } catch (e) {
    console.log(e.toString());

    const endTime = +new Date();

    let message = e.toString();
    if (e && e.response && e.response.data && e.response.data.error && e.response.data.error.response) {
      const errorCode = e.response.data.error.response.ResponseCode;
      const errorMessage = e.response.data.error.response.ResponseMessage;
      if (errorCode || errorMessage) {
        message = `${errorMessage}${errorCode ? ` (ErrorCode: ${errorCode})` : ''}`;
      }
    }

    yield put(bulkAffiliateInfoUpdateDone({
      currentRequest: currentRequest,
      success: false,
      message: message,
      elapsedTimePerRequest: endTime - startTime,
    }));
  }
}

export default function* watchBulkAffiliateInfoUpdateSaga() {
  yield takeEvery(Constants.PROGRESS, bulkAffiliateInfoUpdateProgressSaga);
}