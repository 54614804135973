import React, { memo, useCallback, useState } from 'react';
import { useMedia } from 'react-use';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

import { isDateTime } from '../data';
import { mapRangeToTimeOption } from './mapper';
import { TimePickerTitle } from './TimePickerTitle';
import { TimeRangeForm } from './TimeRangeForm';
import { TimeRangeList } from './TimeRangeList';
// import { TimePickerFooter } from './TimePickerFooter';
import { TimeZonePicker } from '../TimeZonePicker';

export const TimePickerContentWithScreenSize = props => {
  const isReversed = props.isReversed;

  const historyOptions = mapToHistoryOptions(props.history, props.timeZone);

  const { quickOptions = [], otherOptions = [], isFullscreen } = props;

  return (<div className={`gf-tpcwsz-container ${props.className ? props.className : ''} is-reversed`}>
    <div className={`gf-tpcwsz-body`}>
      <div className={`gf-tpcwsz-leftSide ${isReversed ? 'is-reversed' : ''}`}>
        <FullScreenForm {...props} visible={isFullscreen} historyOptions={historyOptions} isReversed={isReversed} timeZone={props.timeZone} onChangeTimeZone={props.onChangeTimeZone}/>
      </div>
      <div className={`gf-tpcwsz-rightSide ${isReversed ? 'is-reversed' : ''}`}>
        <NarrowScreenForm {...props} visible={!isFullscreen} historyOptions={historyOptions} />
        <TimeRangeList title="" options={quickOptions} onSelect={props.onChange} value={props.value} timeZone={props.timeZone} />
        {/*<div className={`gf-tpcwsz-spacing`} />*/}
        {/*<TimeRangeList title="Other quick ranges" options={otherOptions} onSelect={props.onChange} value={props.value} timeZone={props.timeZone} />*/}
      </div>
    </div>
    {/*{!props.hideTimeZone && isFullscreen && (<TimePickerFooter timeZone={props.timeZone} onChangeTimeZone={props.onChangeTimeZone} />)}*/}
  </div>);
};

export const TimePickerContent = props => {
  const isFullscreen = useMedia(`(min-width: 992px)`);
  return <TimePickerContentWithScreenSize {...props} isFullscreen={isFullscreen} />;
};

const NarrowScreenForm = props => {
  //const styles = getNarrowScreenStyles(theme);

  const isAbsolute = isDateTime(props.value.raw.from) || isDateTime(props.value.raw.to);

  const [collapsed, setCollapsed] = useState(isAbsolute);
  if (!props.visible) {
    return null;
  }

  return (<React.Fragment>
    <div aria-label="TimePicker absolute time range" className={`gf-nsf-header`} onClick={() => setCollapsed(!collapsed)}>
      <TimePickerTitle>Absolute time range</TimePickerTitle>
      {collapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    </div>
    {collapsed && (<div className={`gf-nsf-body`}>
      <div className={`gf-nsf-form`}>
        <TimeRangeForm value={props.value} onApply={props.onChange} timeZone={props.timeZone} isFullscreen={false} />
      </div>
      {props.showHistory && (<TimeRangeList title="Recently used absolute ranges" options={props.historyOptions || []} onSelect={props.onChange} value={props.value} placeholderEmpty={null} timeZone={props.timeZone} />)}
    </div>)}
  </React.Fragment>);
};

const FullScreenForm = props => {
  const { value, timeZone, onChange: onApplyFromProps } = props;
  if (!props.visible) {
    return null;
  }

  const [valueFinal, setValueFinal] = useState(value);
  const initialValueRef = React.useRef(typeof props.initialValue !== 'undefined' ? props.initialValue : value);
  const initialTimeZoneRef = React.useRef(timeZone);
  const [isDisabled, setIsDisabled] = useState(
    (valueFinal.from == null || valueFinal.to == null || !valueFinal.from.isValid || !valueFinal.to.isValid)
  );

  const handleValueUpdate = (newValue) => {
    setValueFinal(newValue);
  };

  const onApply = useCallback((e) => {
    e.preventDefault();
    onApplyFromProps(valueFinal);
  }, [value, onApplyFromProps, timeZone, valueFinal]);

  const handleDiscard = useCallback((e) => {
    e.preventDefault();
    props.onChangeTimeZone(initialTimeZoneRef.current);
    setValueFinal(initialValueRef.current);
    onApplyFromProps(initialValueRef.current);
  }, [value, onApplyFromProps, timeZone, valueFinal]);

  const handleTempValueChange = (newValue) => {
    if ((!newValue.from.invalid && !newValue.to.invalid) && (newValue.from.value && newValue.to.value)) {
      if (isDisabled) {
        setIsDisabled(false);
      }
    } else {
      if (!isDisabled) {
        setIsDisabled(true);
      }
    }
  }

  return (<React.Fragment>
    <div className={`gf-fsf-container`}>
      <div aria-label="TimePicker absolute time range" className={`gf-fsf-title`}>
        <TimePickerTitle>Date range</TimePickerTitle>
      </div>
      <TimeRangeForm
        value={props.value}
        timeZone={props.timeZone}
        onApply={onApply}
        onValueChange={handleValueUpdate}
        isFullscreen={true}
        isReversed={props.isReversed}
        handleTempValueChange={handleTempValueChange}
      />
      {!props.hideTimeZone && (
        <div className={`gf-tzp-select-wrapper`}>
          <div className="gf-input-field-label">Time zone</div>
          <TimeZonePicker
            className="gf-select-control-select-only"
            includeInternal={true}
            onChange={timeZone => {
              props.onChangeTimeZone(timeZone);
            }}
            autoFocus={true}
            value={props.timeZone}
          />
        </div>
      )}
    </div>
    {props.showHistory && (<div className={`gf-fsf-recent`}>
      <TimeRangeList title="Recently used date ranges" options={props.historyOptions || []} onSelect={props.onChange} value={props.value} placeholderEmpty={<EmptyRecentList />} timeZone={props.timeZone} />
    </div>)}
    <div className={`gf-fsf-footer`}>
      <Button className="gf-fsf-button" onClick={handleDiscard} color="primary" variant="text">
        <span>Discard</span>
      </Button>
      <Button
        className={`gf-fsf-button btn-primary ${isDisabled ? "disabled" : ""}`}
        onClick={onApply} color="primary"
        variant="contained"
        disabled={isDisabled}
      >
        <span>Apply</span>
      </Button>
    </div>
  </React.Fragment>);
};

const EmptyRecentList = memo(() => {
  //const styles = getEmptyListStyles(theme);
  return (<div className={`gf-erc-container`}>
    <div>
      <span>
        It looks like you haven&apos;t used this timer picker before. As soon as you enter some time intervals,
        recently used intervals will appear here.
      </span>
    </div>
  </div>);
});

function mapToHistoryOptions(ranges, timeZone) {
  if (!Array.isArray(ranges) || ranges.length === 0) {
    return [];
  }
  return ranges.slice(ranges.length - 4).map(range => mapRangeToTimeOption(range, timeZone));
}

EmptyRecentList.displayName = 'EmptyRecentList';