import React, { useReducer } from 'react';
import { withRouter, Link as RouterLink } from "react-router-dom";
import { motion } from 'framer-motion';
import { connect } from "react-redux";
import { ArrowLeft } from 'react-feather';
import { useTranslation } from "react-i18next";

import "./sliding-menu.scss";
import { authLogout } from "../../../../../store/actions/auth";
import { setSideMenuSwitch } from "../../../../../store/actions/side-menu";
import { handleMenuHidden } from '@store/actions/layout';
import ScrollManager from "./scroll-manager";
import { ReactComponent as EmptyIcon } from "../assets/empty.svg";

const ListItemLink = connect(state => ({ currentTenant: state.tenants.currentTenant, layout: state.layout }), { authLogout, handleMenuHidden })(withRouter(React.memo((props) => {
  const { icon, primary, to, selected, currentTenant } = props;

  const { t } = useTranslation();

  let redirectTo = to;
  if (redirectTo.indexOf("[CURRENT-TENANT]") > -1) {
    redirectTo = redirectTo.replace("[CURRENT-TENANT]", currentTenant);
  }

  const [state, setState] = React.useState({
    path: "",
    selected: false,
    to: redirectTo,
    originalTo: to,
  });

  React.useEffect(() => {
    let isSelected = false;
    if (Array.isArray(selected)) {
      for (let i = 0; i < selected.length; i++) {
        if (props.location.pathname.indexOf(selected[i]) === 0) {
          isSelected = true;
          break;
        }
      }
    }
    if (props.location.pathname.indexOf(selected) === 0) {
      isSelected = true;
    }
    setState(v => ({
      ...v,
      path: props.location.pathname,
      selected: isSelected
    }));
  }, [props.location.pathname, selected]);
  React.useEffect(() => {
    if (state.originalTo.indexOf("[CURRENT-TENANT]") > -1) {
      setState(v => {
        let redirectTo = v.originalTo;
        if (redirectTo.indexOf("[CURRENT-TENANT]") > -1) {
          redirectTo = redirectTo.replace("[CURRENT-TENANT]", currentTenant);
        }
        return {
          ...v,
          to: redirectTo,
        }
      });
    }
  }, [currentTenant, state.originalTo]);

  return (
    <li className={`nav-item `} title={t(primary)}>
      <RouterLink
        className={`d-flex align-items-center ${state.selected ? 'active' : ''}`}
        to={state.to}
        onClick={() => {
          if (props.layout.menuCollapsed && !props.layout.menuHidden) {
            props.handleMenuHidden(true);
          }
        }}
      >
        {icon ? icon : <EmptyIcon />}
        <span className='menu-item text-truncate' title={t(primary)}>{t(primary)}</span>
      </RouterLink>
    </li>
  );
}, (prevProps, nextProps) => {
  let isEqual = true;

  const props = ['currentTarget', 'icon', 'primary', 'to', 'selected'];

  for (let i = 0; i < props.length; i++) {
    if (prevProps[props[i]] !== nextProps[props[i]]) {
      isEqual = false;
      break;
    }
  }

  if (prevProps.location.pathname !== nextProps.location.pathname) {
    isEqual = false;
  }

  return isEqual;
})));

const ListItemLogout = connect(state => ({ auth: state.auth }), { authLogout })(props => {
  const { icon, primary, authLogout } = props;

  const { t } = useTranslation();

  const handleClick = (e) => {
    e.preventDefault();
    authLogout();
  };

  return (
    <li className='nav-item' >
      <a
        className={`d-flex align-items-center`}
        onClick={handleClick}
      >
        {icon ? icon : <EmptyIcon />}
        <span className='menu-item text-truncate'>{t(primary)}</span>
      </a>
    </li>
  );
});

const ListItem = props => {
  const { icon, primary } = props;

  const { t } = useTranslation();

  return (
    <li className='nav-item category' title={t(primary)}>
      <a className={`d-flex align-items-center`}>
        {icon ? icon : <EmptyIcon />}
        <span className='menu-item text-truncate'>{t(primary)}</span>
      </a>
    </li>
  );
};

const ListItemGroup = connect(state => ({ switches: state.sideMenu.switches }), { setSideMenuSwitch })(props => {
  const { item, dispatch, switches, setSideMenuSwitch } = props;

  const collapsed = switches && item.switchId && switches[item.switchId] ? true : false;

  const toggle = (e) => {
    e.stopPropagation();
    e.preventDefault();

    document.querySelector(".sliding-menu-container")?.classList.add("no-animations");
    setTimeout(() => {
      document.querySelector(".sliding-menu-container")?.classList.remove("no-animations");
    }, 1000);

    setSideMenuSwitch({
      key: item.switchId,
      value: !collapsed
    });
  };

  const { t } = useTranslation();

  const renderItem = (entry, i) => {
    const { selected, to, primary, icon, logout, category, menu: subMenu } = entry;

    if (!subMenu) {
      if (category) {
        return <ListItem
          primary={primary}
          icon={icon}
          key={i}
        />;
      }

      if (logout) {
        return <ListItemLogout key={i} to={to} primary={primary} icon={icon} />;
      }

      return <ListItemLink
        selected={selected}
        to={to}
        primary={primary}
        icon={icon}
        key={i}
      />;
    }

    return <li className="nav-item has-sub" key={i} title={t(primary)}>
      <a className='d-flex align-items-center' onClick={e => {
        e.preventDefault();
        e.stopPropagation();

        dispatch({
          type: 'forward',
          payload: { title: subMenu.title, id: subMenu.id }
        })
      }}>
        {icon ? icon : <EmptyIcon />}
        <span className='menu-item text-truncate'>{t(primary)}</span>
      </a>
    </li>;
  }

  let items = [];
  let allItems = item.menu.items.filter(i => i !== false);

  if (collapsed) {
    items = allItems.slice(0, 5).map((o, i) => {
      if (!o) return null;

      return renderItem(o, i);
    });

    if (allItems.length > 5) {
      items.push(<li className="nav-item-toggler" key={items.length}>
        <a onClick={toggle}>
          {t("See all")}
        </a>
      </li>);
    }
  } else {
    items = allItems.map((o, i) => {
      if (!o) return null;

      return renderItem(o, i);
    });

    if (allItems.length > 5) {
      items.push(<li className="nav-item-toggler" key={items.length}>
        <a onClick={toggle}>
          {t("See less")}
        </a>
      </li>);
    }
  }

  return <React.Fragment>
    {items}
  </React.Fragment>
});

const nestedNavReducer = (state, action) => {
  switch (action.type) {
    case 'forward': {
      const previous = [...state.previous, state.active];
      const previousState = { ...state };
      delete previousState.previousState;
      return {
        active: action.payload,
        previous,
        previousState: previousState
      };
    }
    case 'back': {
      const active = [...state.previous][state.previous.length - 1];
      const previous = [...state.previous];
      previous.pop();
      const previousState = { ...state };
      delete previousState.previousState;

      return {
        active,
        previous,
        previousState: previousState,
      };
    }
    default:
      throw new Error()
  }
};

const rootPath = window.ini.REACT_APP_ROOT_PATH;

const findStateByUrl = (items, parent, url) => {
  if (items && Array.isArray(items)) {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item) {
        if (typeof item.selected !== "undefined") {

          let isSelected = false;
          if (Array.isArray(item.selected)) {
            for (let i = 0; i < item.selected.length; i++) {
              if (url.indexOf(item.selected[i]) === 0) {
                isSelected = true;
                break;
              }
            }
          } else if (url.indexOf(item.selected) === 0) {
            isSelected = true;
          }

          if (isSelected) {
            if (parent.id) {
              return { title: parent.title, id: parent.id };
            } else if (parent.menu && parent.menu.id) {
              return { title: parent.menu.title, id: parent.menu.id };
            }
          }
        }
        if (item && typeof item.menu !== "undefined" && typeof item.menu.items !== "undefined") {
          const res = findStateByUrl(item.menu.items, item, url);
          if (res) return res;
        }
      }
    }
  }
};

const NestedNav = ({ menus }) => {
  const active = { title: menus.title, id: menus.id }
  const initialState = {
    active: active,
    previous: [],
    // Need to store previous state to re-establish `initial` value for `motion`
    // after it re-renders due to prop changes
    previousState: {
      active: active,
      previous: []
    }
  }

  const [state, dispatch] = useReducer(nestedNavReducer, initialState);

  React.useEffect(() => {
    if (menus.currentPath && menus.currentPath !== `${rootPath}/home`) {
      const jumpToState = findStateByUrl(menus.items, menus, menus.currentPath, 0);
      if (jumpToState) {
        dispatch({
          type: 'forward',
          payload: jumpToState
        })
      }
    }
  }, []);

  const Menu = ({ menu }) => {
    if (!menu) return null;

    let goBackFragment = null;
    let hasBack = false;

    if (!menu.isRoot) {
      hasBack = true;

      let title = null;
      if (menu.title) {
        title = <ListItem
          primary={menu.title}
          icon={null}
        />
      }

      goBackFragment = <React.Fragment>
        <li className="nav-item has-back">
          <a className='d-flex align-items-center' onClick={e => {
            e.preventDefault()
            dispatch({ type: 'back' })
          }}>
            <ArrowLeft size={20} />
            <span className='menu-item text-truncate'>Main Menu</span>
          </a>
        </li>
        {title}
      </React.Fragment>;
    }

    return (
      <React.Fragment>
        <ScrollManager scrollKey={`scroll-${menu.id}`}>
          {({ connectScrollTarget }) =>
            <motion.ul
              ref={connectScrollTarget}
              className={`sliding-menu navigation navigation-main ${hasBack ? 'sub-menu' : ''}`}
              transition={{ type: 'tween' }}
              id={menu.id}
              key={menu.id}
              animate={
                menu.id === state.active.id
                  ? 'active'
                  : state.previous.find((m) => m.id === menu.id)
                    ? 'previous'
                    : 'inactive'
              }
              initial={
                menu.id === state.previousState.active.id
                  ? 'active'
                  : state.previousState.previous.find((m) => m.id === menu.id)
                    ? 'previous'
                    : 'inactive'
              }
              variants={{
                active: { x: 0, opacity: 1 },
                inactive: { x: '100%' },
                previous: { x: '-100%', opacity: 0 }
              }}
            >
              {goBackFragment}
              {menu.items.map((item, i) => {
                if (!item) return null;

                const { selected, to, primary, icon, logout, category, group, menu: subMenu } = item;

                if (!subMenu) {
                  if (category) {
                    return <ListItem
                      primary={primary}
                      icon={icon}
                      key={i}
                    />;
                  }

                  if (logout) {
                    return <ListItemLogout key={i} to={to} primary={primary} icon={icon} />;
                  }

                  return <ListItemLink
                    selected={selected}
                    to={to}
                    primary={primary}
                    icon={icon}
                    key={i}
                  />;
                }


                if (group) {
                  return <ListItemGroup key={i} item={item} dispatch={dispatch} />;
                }

                return <li className="nav-item has-sub" key={i}>
                  <a className='d-flex align-items-center' onClick={e => {
                    e.stopPropagation();
                    e.preventDefault()

                    dispatch({
                      type: 'forward',
                      payload: { title: subMenu.title, id: subMenu.id }
                    })
                  }}>
                    {icon ? icon : <EmptyIcon />}
                    <span className='menu-item text-truncate'>{primary}</span>
                  </a>
                </li>;
              })}
            </motion.ul>
          }</ScrollManager>
        {menu.items.map((item, i) => {
          if (!item) return null;
          const { menu } = item;
          return <Menu key={i} menu={menu} />;
        })}
      </React.Fragment>
    )
  };

  return <div className='sliding-menu-container'>
    <Menu menu={menus} />
  </div>;
}

function areEqual(prevProps, nextProps) {
  if (prevProps.menus === nextProps.menus) return true;
  return false;
}

export default React.memo(NestedNav, areEqual);
