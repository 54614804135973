import { takeEvery, put } from "redux-saga/effects";
import axios from "src/store/_shared/authAxios";
import store from "../../store";
import { classesConstants, tagsConstants } from "../constants";
import { loadedClasses, clearLoading } from "../actions/classes";
import { loadedTags } from "../actions/tags";

import { buildApiUrl } from "../../utils/api";

const classesApiUrl = buildApiUrl("/admin/bonuses/classes/all");
const tagsApiUrl = buildApiUrl("/admin/bonuses/tags/all");

const inProgress = {
  classes: {},
  tags: {},
};

function* loadClasses(action) {
  const extra = action && action.data ? action.data : {};

  if (inProgress.classes[extra.tenant_id]) return;
  inProgress.classes[extra.tenant_id] = true;

  try {
    const { data } = yield axios.post(`${classesApiUrl}?tenant_id=${extra.tenant_id}`);

    if (action.data && action.data.cb && typeof action.data.cb === "function") {
      action.data.cb(data);
    } else {
      yield put(loadedClasses(data));
    }
  } catch (e) {
    console.log("[SAGA] loadClasses error", e.toString());
  }

  inProgress.classes[extra.tenant_id] = false;
}

function* loadTags(action) {
  const { classes } = store.getState();

  let tenant_id = classes.tenant_id;

  const extra = action && action.data ? action.data : {};

  if (extra.tenant_id) {
    tenant_id = extra.tenant_id;
  }

  const cacheKey = `${tenant_id}-${extra.tag_class_id}`;

  if (inProgress.tags[cacheKey]) return;
  inProgress.tags[cacheKey] = true;

  if (classes.tags && typeof classes.tags[extra.tag_class_id] !== "undefined") {
    yield put(clearLoading());
    inProgress.tags[extra.tag_class_id] = false;
    return;
  }

  try {
    const { data } = yield axios.post(
      `${tagsApiUrl}?tenant_id=${tenant_id}`,
      {
        tag_class_id: extra.tag_class_id
      }
    );

    if (action.data && action.data.cb && typeof action.data.cb === "function") {
      action.data.cb(data);
    } else {
      yield put(loadedTags(data));
    }
  } catch (e) {
    console.log("[SAGA] loadTags error", e.toString());
  }

  inProgress.tags[cacheKey] = false;
}

export default function* watchSportsSaga() {
  //console.log("[SAGA] watching sports");
  yield takeEvery(classesConstants.LOAD, loadClasses);
  yield takeEvery(tagsConstants.LOAD, loadTags);
}
