import React from "react";
import Tooltip from "@mui/material/Tooltip";
import withStyles from "@mui/styles/withStyles";
import { Link } from "react-feather";

import UploadImage from "../../UploadImage";
import UploadTC from "../../UploadTC";
import Transparent from "../../../assets/images/transparent.png";

let baseUrl = window.ini.REACT_APP_BETS_BANNERS_IMAGES_URL;

const ImageTooltip = withStyles(theme => ({
  tooltip: {
    background: `url(${Transparent})`,
    border: "1px solid #dadde9",
    boxSizing: "border-box",
    "& img": {
      width: 280,
      borderRadius: 4,
      display: "block",
    },
  },
}))(Tooltip);

const imageStyle = {
  height: 25,
  width: 18,
  objectFit: "contain",
  display: "inline-block",
  verticalAlign: "middle",
};

const UploadOption = props => {
  const [opened, setOpened] = React.useState(false);

  const handleClick = () => {
    setOpened(v => !v);
  };

  const style = {
    display: "inline-block",
  };

  if (!props.children) {
    style.display = "none";
  }

  const [uploadState, setUploadState] = React.useState({
    open: false,
    name: "",
  });

  const handleClose = () => {
    setUploadState({
      open: false,
      name: "",
    });
  };

  const openUpload = () => {
    setUploadState({
      open: true,
      name: props.label ? props.label : props.pdf ? "Document" : "Image",
    });
  };

  const handleUpload = ({ imageLink }) => {
    if (imageLink.indexOf("http") === 0) {
      props.onChange({
        target: {
          name: props.name,
          value: imageLink,
        },
      });
    } else {
      props.onChange({
        target: {
          name: props.name,
          value: `${baseUrl}/${imageLink}`,
        },
      });
    }
  };

  return (
    <div
      className={`option textboxoption has-addon has-addon-left ${!opened ? "collapsed" : ""} ${props.className ?? ""}`}
    >
      <label className={"option-content" + (props.inline === true ? " inline" : "")}>
        <span onClick={handleClick} className={props.value && props.def ? "has-changes" : ""}>
          {props.label}
          <i className="caret" style={style}></i>
        </span>
        <div className="control">
          {!props.pdf && (
            <span className="addon align-left button-addon" onClick={openUpload}>
              {!!props.value && (
                <ImageTooltip
                  title={<img src={props.value} alt="" />}
                  placement="left"
                  className="upload-option-image-tooltip"
                >
                  <img src={props.value} style={imageStyle} alt="" />
                </ImageTooltip>
              )}
            </span>
          )}
          {props.pdf && (
            <span className="addon align-left button-addon has-link">
              {!!props.value && (
                <a href={props.value} target="_blank" rel="noopener noreferrer">
                  <Link size={16} />
                </a>
              )}
            </span>
          )}
          <input
            type="text"
            name={props.name}
            value={props.value ? props.value : ""}
            onChange={props.onChange}
            spellCheck="false"
            placeholder={typeof props.def !== "undefined" ? props.def : ""}
          />
          <span
            className="addon button-addon"
            onClick={openUpload}
            title={`Upload ${props.pdf ? "Document" : "Image"}`}
          >
            <i className="material-icons">add_circle</i>
          </span>
        </div>
      </label>
      <div className="children">{props.children}</div>
      {
        <UploadImage
          open={uploadState.open}
          type={uploadState.name}
          onUpload={handleUpload}
          onClose={handleClose}
          tenantId={props.tenantId ?? 0}
          pdf={props.pdf}
        />
      }
    </div>
  );
};

export default UploadOption;
