import { produce } from "immer";
import { editorConstants, rtNotificationsConstants } from "../constants";
import { cloneDeep } from "lodash/fp";

const getEditorSiteTemplateId = () => {
  const ret = localStorage.getItem("editorSiteTemplateId");
  return ret ? ret : "";
}
const setEditorSiteTemplateId = (id) => {
  localStorage.setItem("editorSiteTemplateId", id);
};

const initialState = {
  widthMedia: "320px",
  panels: {},
  groups: {},
  properties: {},
  selectorState: "",
  mediaQueryState: "",
  elements: [],
  elementTemplates: [],
  sources: [],
  cssVariables: [],
  cssSets: [],
  siteTemplates: [],
  editorStylePresets: [],
  cssBreakPoints: [],
  tenantId: '',
  cssVariablesIds: {
    siteTemplateId: getEditorSiteTemplateId(),
    colorsId: '',
    componentVariablesId: '',
    styleBorderId: '',
    styleBorderRadiusId: '',
    styleShadowId: '',
    ts: +new Date(),
  },
  style: {
    tabIndex: 0,
    styleSheet: "",
    value: "",
    loading: false,
    loaded: false,
    styleSheets: [],
  },
  designStyle: {
    tabIndex: 0,
    styleSheet: "",
    value: "",
    loading: false,
    loaded: false,
    styleSheets: [],
  },
  editorStates: {},
  socketId: null,
  ct: +new Date(),
};

const editorReducer = (state = cloneDeep(initialState), action) =>
  produce(state, draft => {
    switch (action.type) {
      case rtNotificationsConstants.RT_NOTIFICATION_RECEIVED: {
        if (action.data && (action.data.type === "common_css" || action.data.type === "css_variables" || action.data.type === "design_css")) {
          draft.cssVariablesIds.ts = +new Date();
        }
        break;
      }
      case editorConstants.SET_EDITOR_STATES:
        draft.editorStates = action.data;
        draft.socketId = action.socketId;
        break;
      case editorConstants.SET_DATA:
        if (action.data !== undefined && action.data.elementTemplates !== undefined)
          draft.elementTemplates = action.data.elementTemplates;
        if (action.data !== undefined && action.data.elements !== undefined)
          draft.elements = action.data.elements;
        if (action.data !== undefined && action.data.sources !== undefined)
          draft.sources = action.data.sources;
        if (action.data !== undefined && action.data.cssVariables !== undefined)
          draft.cssVariables = action.data.cssVariables;

        if (action.data !== undefined && action.data.cssSets !== undefined)
          draft.cssSets = action.data.cssSets;
        if (action.data !== undefined && action.data.siteTemplates !== undefined)
          draft.siteTemplates = action.data.siteTemplates;
        if (action.data !== undefined && action.data.cssVariablesIds !== undefined) {
          Object.keys(action.data.cssVariablesIds).forEach(k => {
            if (action.data.cssVariablesIds[k] != null) {
              if (k === 'siteTemplateId') {
                setEditorSiteTemplateId(action.data.cssVariablesIds[k]);
              }
              draft.cssVariablesIds[k] = action.data.cssVariablesIds[k];
            }
          });
        }
        if (action.data !== undefined && action.data.editorStylePresets !== undefined)
          draft.editorStylePresets = action.data.editorStylePresets;

        if (action.data !== undefined && action.data.tenantId != null) {
          draft.tenantId = action.data.tenantId;
        }

        if (action.data !== undefined && action.data.cssBreakPoints != null) {
          draft.cssBreakPoints = action.data.cssBreakPoints;
        }

        if (action.data !== undefined && action.data.designStyle !== undefined) {
          Object.keys(action.data.designStyle).forEach(k => {
            if (action.data.designStyle[k] != null) {
              draft.designStyle[k] = action.data.designStyle[k];
            }
          });
        }

        if (action.data !== undefined && action.data.style !== undefined) {
          Object.keys(action.data.style).forEach(k => {
            if (action.data.style[k] != null) {
              draft.style[k] = action.data.style[k];
            }
          });
        }

        break;
      case editorConstants.SET_WIDTH_MEDIA:
        draft.widthMedia = action.widthMedia;
        break;
      case editorConstants.SET_PANEL_OPEN:
        draft.panels[action.panel] = action.state;
        break;
      case editorConstants.SET_GROUP_STATE:
        draft.groups[action.group] = action.state;
        break;
      case editorConstants.SET_PROPERTY_STATE:
        draft.properties[action.property] = action.state;
        break;
      case editorConstants.SET_SELECTOR_STATE:
        draft.selectorState = action.state;
        break;
      case editorConstants.SET_MEDIA_QUERY_STATE:
        draft.mediaQueryState = action.state;
        break;
      case editorConstants.CLEAR_PANELS:
        draft.panels = {};
        break;
      case editorConstants.CLEAR:
        draft[action.what] = {};
        break;
      case editorConstants.CLEAR_ALL:
        const newState = cloneDeep(initialState);
        Object.keys(newState).forEach(key => {
          if (key === 'cssVariablesIds') return;
          draft[key] = newState[key];
        })
        break;
      default:
        break;
    }
  });

export default editorReducer;
