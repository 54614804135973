import { produce } from "immer";

import { statScoreConstants } from "../constants";

const initialState = {
  exists: {},
};

const statScoreReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case statScoreConstants.SAVE: {
        const exists = { ...draft.exists };
        exists[action.idMatch] = {
          status: action.status,
          ssId: action.ssId
        };
        draft.exists = exists;
        break;
      }
      case statScoreConstants.SAVE_MULTI: {
        const exists = { ...draft.exists };
        action.matches.forEach(m => {
          exists[m.idMatch] = {
            status: m.status,
            ssId: m.ssId
          };
        });
        draft.exists = exists;
        break;
      }
      default:
        break;
    }
  });

export default statScoreReducer;