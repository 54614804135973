import { takeEvery, put } from "redux-saga/effects";
import axios from "src/store/_shared/authAxios";

import { selectsMarketingEventsConstants } from "../constants";
import { loadedSelects } from "../actions/selects-marketing-events";

import { marketingEventProcessorUrl as buildMarketingEventsApiUrl } from "../../utils/api";
import { accessPages, operationsAccess } from "../../utils/users";

const marketingEventsApiUrl = buildMarketingEventsApiUrl("/postback-tracking/selects");
const marketingEventsNetworksApiUrl = buildMarketingEventsApiUrl("/traffic-networks/all");

function* loadMarketingEventsSelects(action) {
  const extra = action && action.data ? action.data : {};
  try {
    if (operationsAccess(accessPages.AFFILIATES_POSTBACK_TRACKING).canList) {
      const {data} = yield axios.get(`${marketingEventsApiUrl}?tenant_id=${extra.tenant_id}`);

      if (operationsAccess(accessPages.AFFILIATES_POSTBACK_TRACKING_NETWORK).canList) {
        const {data: networkData} = yield axios.get(`${marketingEventsNetworksApiUrl}?tenant_id=0`);
        data.networks = networkData.data || [];
      }
      // console.log("[SAGA] loadMarketingEventsSelects", {data, networkData});

      yield put(loadedSelects(data));
    }
  } catch (e) {
    console.log("[SAGA] loadMarketingEventsSelects error", e.toString());
  }
}

export default function* watchSelectsSaga() {
  //console.log("[SAGA] watching select");
  yield takeEvery(selectsMarketingEventsConstants.LOAD, loadMarketingEventsSelects);
}
