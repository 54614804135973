import {produce} from 'immer';
import {playerHistoryConstants} from '../constants';
import {monthList} from 'src/utils/player-timeline';
import moment from 'moment';
import {cloneDeep} from "lodash/fp";

const initialState = {
  playerHistory: [],
  playerHistoryFull: [],
  playerHistoryError: null,
  pendingData: false,
  loading: true,
  loadingGameSessionRounds: false,
  startDate: moment().subtract(playerHistoryConstants.DEFAULT_DAYS_DIFF, 'd').format(playerHistoryConstants.DATE_PICKER_FORMAT),
  endDate: moment().format(playerHistoryConstants.DATE_PICKER_FORMAT),
  providerWithdrawStatuses: [],
  oktoTransactionStatuses: [],
  skrillTransactionStatuses: [],
  aircashTransactionStatuses: [],
  loadedProviderWithdrawStatuses: false,
  loadedOktoTransactionStatuses: false,
  loadedSkrillTransactionStatuses: false,
  loadedAircashTransactionStatuses: false,
  transactionTypes: [
    {value: 'bet', label: 'Bet'},
    {value: 'bonus', label: 'Bonus'},
    {value: 'deposit', label: 'Deposit'},
    {value: 'withdrawal', label: 'Withdrawal'},
    {value: 'free-bet', label: 'Free Bet'},
    {value: 'free-spin', label: 'Free Spin'},
    {value: 'game-session', label: 'Game Session'},
    {value: 'game-session-live', label: 'Game Session Live'},
    {value: 'happy-hour', label: 'Happy Hour'},
    {value: 'lotto', label: 'Lotto'},
    {value: 'wallet-adjustment', label: 'Adjustment'},
  ],
  selectedTransactionTypes: [
    'bet',
    'bonus',
    'deposit',
    'withdrawal',
    'free-bet',
    'free-spin',
    'game-session',
    'game-session-live',
    'happy-hour',
    'lotto',
    'wallet-adjustment',
  ],
};

const playerHistoryReducer = (state = initialState, action, error) =>
  produce(state, draft => {
    switch (action.type) {
      case playerHistoryConstants.LOADED: {
        if ((typeof action.data !== "undefined") && (Object.keys(action.data).length !== 0)) {
          if (action.data.length > 0) {
            const playerHistoryFull = [];
            action.data.forEach((session) => {
              const sessionFound = playerHistoryFull.find((item, index) => {
                //aggregating game session data sharing the same sessionId, providerId and gameId
                if ((item.type === "game-session" || item.type === "game-session-live")
                  && (session.type === "game-session" || session.type === "game-session-live")
                  && item.type === session.type
                  && item.transaction.sessionId && item.transaction.providerId && item.transaction.gameId
                ) {
                  if (item.transaction
                    && item.transaction.sessionId === session.transaction.sessionId
                    && item.transaction.providerId === session.transaction.providerId
                    && item.transaction.gameId === session.transaction.gameId
                  ) {
                    const transaction = {...item.transaction};
                    transaction.betAmount = `${parseInt(transaction.betAmount) + parseInt(session.transaction.betAmount)}`;
                    transaction.winAmount = `${parseInt(transaction.winAmount) + parseInt(session.transaction.winAmount)}`;
                    transaction.freeSpinAmount = `${parseInt(transaction.freeSpinAmount) + parseInt(session.transaction.freeSpinAmount)}`;
                    transaction.startDate = `${Math.min(parseInt(transaction.startDate), parseInt(session.transaction.startDate))}`;
                    transaction.endDate = `${Math.min(parseInt(transaction.endDate), parseInt(session.transaction.endDate))}`;
                    playerHistoryFull[index].transaction = transaction;
                    playerHistoryFull[index].date = `${Math.min(parseInt(playerHistoryFull[index].date), parseInt(session.date))}`;
                    return true;
                  }
                }
                return false;
              });
              if (typeof sessionFound === "undefined" || !sessionFound) {
                playerHistoryFull.push(session);
              }
            });
            draft.playerHistoryFull = playerHistoryFull;
            const lastTimestamp = playerHistoryFull[0].date;
            const firstTimestamp = playerHistoryFull[playerHistoryFull.length - 1].date;
            const filteredMonths = monthList(firstTimestamp, lastTimestamp, true)
              .reduce((acc, month) => {
                acc[month] = [];
                return acc;
              }, {});
            for (const item of draft.playerHistoryFull) {
              if ((item.type === "game-session" || item.type === "game-session-live")
                && (item.transaction.roundsPlayed === null || typeof item.transaction.roundsPlayed === "undefined")) {
                draft.pendingData = true;
                break;
              }
              const month = moment.unix(item.date).format("MMMM YYYY");
              filteredMonths[month].push(item);
              draft.pendingData = false;
            }

            draft.playerHistory = filteredMonths;
            draft.playerHistoryError = null;
          } else {

            draft.playerHistory = [];
            draft.playerHistoryFull = [];
            draft.playerHistoryError = null;
          }
        } else if ((typeof action.data !== "undefined") && (Object.keys(action.data).length === 0)) {
          draft.playerHistory = [];
          draft.playerHistoryFull = [];
          draft.playerHistoryError = null;
        } else if (action.error !== null) {
          draft.playerHistoryError = action.error;
          draft.playerHistory = [];
          draft.playerHistoryFull = [];
        } else {
          draft.playerHistory = [];
          draft.playerHistoryFull = [];
          draft.playerHistoryError = null;
        }
        break;
      }

      case playerHistoryConstants.TOGGLE_LOAD: {
        if (typeof action.data !== "undefined") draft.loading = action.data;
        break;
      }

      case playerHistoryConstants.UPDATE_START_DATE: {
        if (typeof action.data !== "undefined") draft.startDate = moment(action.data).format(playerHistoryConstants.DATE_PICKER_FORMAT);
        break;
      }

      case playerHistoryConstants.UPDATE_END_DATE: {
        if (typeof action.data !== "undefined") draft.endDate = moment(action.data).format(playerHistoryConstants.DATE_PICKER_FORMAT);
        break;
      }

      case playerHistoryConstants.SELECT_TRANSACTION_TYPES: {
        if (typeof action.data !== "undefined") draft.selectedTransactionTypes = action.data;
        break;
      }

      case playerHistoryConstants.TOGGLE_LOAD_GAME_SESSION_ROUNDS: {
        if (typeof action.data !== "undefined") draft.loadingGameSessionRounds = action.data;
        break;
      }

      case playerHistoryConstants.LOADED_GAME_SESSION_ROUNDS: {
        if (state.playerHistoryFull.length === 0) {
          break;


        }
        if ((typeof action.data !== "undefined") && (Object.keys(action.data).length !== 0)) {
          let startIndex = null;
          if (action.data.length > 0) {
            const playerHistoryFull = cloneDeep(state.playerHistoryFull);
            action.data.forEach(session => {
              playerHistoryFull.find((item, index) => {
                if ((item.type === "game-session" || item.type === "game-session-live") && item.date === session.date) {
                  if (item.transaction && item.transaction.sessionId === session.sessionId) {
                    if (startIndex === null) startIndex = index;
                    playerHistoryFull[index].transaction.roundsPlayed = session.roundsPlayed;
                    return true;
                  }
                }
                return false;
              });
            });
            draft.playerHistoryFull = playerHistoryFull;
            const lastTimestamp = playerHistoryFull[0].date;
            const firstTimestamp = playerHistoryFull[playerHistoryFull.length - 1].date;
            const filteredMonths = monthList(firstTimestamp, lastTimestamp, true)
              .reduce((acc, month) => {
                acc[month] = [];
                return acc;
              }, {});
            for (const item of draft.playerHistoryFull) {
              if ((item.type === "game-session" || item.type === "game-session-live")
                && (item.transaction.roundsPlayed === null || typeof item.transaction.roundsPlayed === "undefined")) {
                draft.pendingData = true;
                break;
              }
              const month = moment.unix(item.date).format("MMMM YYYY");
              filteredMonths[month].push(item);
              draft.pendingData = false;
            }


            draft.playerHistory = filteredMonths;
            draft.playerHistoryError = null;
          } else {
            draft.playerHistoryError = null;
          }
        } else if ((typeof action.data !== "undefined") && (Object.keys(action.data).length === 0)) {
          draft.playerHistoryError = null;
        } else if (action.error !== null) {
          draft.playerHistoryError = action.error;
        } else {
          draft.playerHistoryError = null;
        }
        break;
      }

      case playerHistoryConstants.LOADED_PROVIDER_WITHDRAW_STATUSES: {
        if ((typeof action.data !== "undefined") && action.data.length > 0) {
          const statuses = {};
          statuses["-1"] = {name: "Init", description: "Initial state for deposit transaction"};
          action.data.forEach(d => {
            statuses[d.id.toString()] = {name: d.name, description: d.description};
          });
          draft.providerWithdrawStatuses = statuses;
        } else {
          draft.providerWithdrawStatuses = action.data;
        }
        draft.loadedProviderWithdrawStatuses = true;
        break;
      }

      case playerHistoryConstants.LOADED_OKTO_TRANSACTION_STATUSES: {
        if ((typeof action.data !== "undefined") && action.data.length > 0) {
          const statuses = {};
          action.data.forEach(d => {
            statuses[d.id.toString()] = {name: d.name};
          });
          draft.oktoTransactionStatuses = statuses;
        } else {
          draft.oktoTransactionStatuses = action.data;
        }
        draft.loadedOktoTransactionStatuses = true;
        break;
      }

      case playerHistoryConstants.LOADED_SKRILL_TRANSACTION_STATUSES: {
        if ((typeof action.data !== "undefined") && action.data.length > 0) {
          const statuses = {};
          action.data.forEach(d => {
            statuses[d.id.toString()] = {name: d.name};
          });
          draft.skrillTransactionStatuses = statuses;
        } else {
          draft.skrillTransactionStatuses = action.data;
        }
        draft.loadedSkrillTransactionStatuses = true;
        break;
      }

      case playerHistoryConstants.LOADED_AIRCASH_TRANSACTION_STATUSES: {
        if ((typeof action.data !== "undefined") && action.data.length > 0) {
          const statuses = {};
          action.data.forEach(d => {
            statuses[d.id.toString()] = {name: d.name};
          });
          draft.aircashTransactionStatuses = statuses;
        } else {
          draft.aircashTransactionStatuses = action.data;
        }
        draft.loadedAircashTransactionStatuses = true;
        break;
      }

      default:
        /* noop */
        break;
    }
  });

export default playerHistoryReducer;
