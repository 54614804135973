import { takeEvery, put } from "redux-saga/effects";
import axios from "src/store/_shared/authAxios";

import { dataSourcesConstants } from "../constants";
import { loadedDataSources, loadedDataSourcesContent, loadedDataSourcesContents } from "../actions/data-sources-cache";

import { buildApiUrl } from "../../utils/api";

const dataSourcesApiUrl = buildApiUrl("/admin/lobby/data-sources/allNoFilter");
const dataSourcesContentApiUrl = buildApiUrl("/admin/lobby/data-sources/fetch/");
const dataSourcesContentsApiUrl = buildApiUrl("/admin/lobby/data-sources/fetchIds");
const dataSourcesUpdateApiUrl = buildApiUrl("/admin/lobby/data-sources/update");

function* loadDataSourcesSaga(action) {
  try {
    const { data } = yield axios.get(`${dataSourcesApiUrl}?tenant_id=${action.data.tenantId}`);

    yield put(loadedDataSources({ dataSources: data.rows ? data.rows : [] }));
  } catch (e) {
    console.log("[SAGA] loadDataSourcesSaga error", e.toString());
  }
}

function* loadDataSourcesContentSaga(action) {
  try {
    const { data } = yield axios.get(`${dataSourcesContentApiUrl}${action.data.id}?tenant_id=${action.data.tenantId}`);

    yield put(loadedDataSourcesContent({ id: data.data.id, content: data.data.meta }));
  } catch (e) {
    console.log("[SAGA] loadDataSourcesContentSaga error", e.toString());
  }
}

function* loadDataSourcesContentsSaga(action) {
  try {
    const { data } = yield axios.get(`${dataSourcesContentsApiUrl}?tenant_id=${action.data.tenantId}&ids=${action.data.ids.join(",")}`);
    yield put(loadedDataSourcesContents({ result: data.data }));
  } catch (e) {
    console.log("[SAGA] loadDataSourcesContentsSaga error", e.toString());
  }
}

function* updateDataSourceContentsSaga(action) {
  try {
    const types = {};
    action.data.items.forEach(s => {
      types[s.type] = true;
    });

    const newData = {
      meta: action.data.items,
      type: Object.keys(types).sort().join(","),
    };

    const { data } = yield axios.post(`${dataSourcesUpdateApiUrl}/${action.data.id}?tenant_id=${action.data.tenantId}`, newData);
    yield put(loadedDataSourcesContents({ result: data.data }));
  } catch (e) {
    console.log("[SAGA] updateDataSourceContentsSaga error", e.toString());
  }
}

export default function* watchGamesSaga() {
  //console.log("[SAGA] watching data sources");
  yield takeEvery(dataSourcesConstants.LOAD, loadDataSourcesSaga);
  yield takeEvery(dataSourcesConstants.LOAD_CONTENT, loadDataSourcesContentSaga);
  yield takeEvery(dataSourcesConstants.LOAD_CONTENTS, loadDataSourcesContentsSaga);
  yield takeEvery(dataSourcesConstants.UPDATE_CONTENT, updateDataSourceContentsSaga);
}
