import { takeEvery, put } from "redux-saga/effects";
import axios from "src/store/_shared/authAxios";
import { endpoints } from "src/api/endpoints";

import store from "../../store";
import { tenantsConstants } from "../constants";
import { tenantsLoadAll, tenantsLoad, tenantsIsLoading, tenantsAddTenant, tenantRemoveLocal } from "../actions/tenants";

function* fetchPageTenantsSaga(action) {
  const { tenants } = store.getState();

  yield put(tenantsIsLoading(true));
  try {
    if (action.data.pageUrl) {
      const tenantData = tenants.pages[action.data.pageUrl];

      const params = {};
      if (tenantData.reg_key) {
        params.key = tenantData.reg_key;
      }

      if (!tenantData.loaded && tenantData.apiUrl) {
        const { data } = yield axios.get(tenantData.apiUrl, { params });
        yield put(tenantsLoad({
          pageUrl: action.data.pageUrl,
          tenants: data
        }));
      }
    }
  } catch (e) {
    console.log(e.toString());
  }
  yield put(tenantsIsLoading(false));
}

function* copyTenantConfigSaga(action) {
  const { tenants } = store.getState();

  yield put(tenantsIsLoading(true));
  try {
    if (action.data.pageUrl) {
      const tenantData = tenants.pages[action.data.pageUrl];

      if (tenantData.copyUrl) {
        const params = { ...action.data.params };
        if (tenantData.reg_key) {
          params.key = tenantData.reg_key;
        }
        yield axios.get(tenantData.copyUrl, { params });
        yield put(tenantsAddTenant({
          pageUrl: action.data.pageUrl,
          tenant: action.data.tenant
        }));
      }
    }
  } catch (e) {
    console.log(e.toString());
  }
  yield put(tenantsIsLoading(false));
}

function* deleteTenantRemoteSaga(action) {
  const { tenants } = store.getState();

  yield put(tenantsIsLoading(true));
  try {
    if (action.data.pageUrl) {
      const tenantData = tenants.pages[action.data.pageUrl];

      if (tenantData.deleteUrl) {
        const params = { ...action.data.params };
        if (tenantData.reg_key) {
          params.key = tenantData.reg_key;
        }
        yield axios.get(tenantData.deleteUrl, { params });
        yield put(tenantRemoveLocal({
          pageUrl: action.data.pageUrl,
          tenant: action.data.tenant
        }));
      }
    }
  } catch (e) {
    console.log(e.toString());
  }
  yield put(tenantsIsLoading(false));
}

function* fetchAllTenantsSaga() {
  try {
    const { data } = yield axios.post(
      endpoints.tenant.list,
      {
        page: 1,
        rowsPerPage: 100
      },
    );

    yield put(tenantsLoadAll(data.data.rows));
  } catch (e) {
    console.error("failed to load tenants");
  }
}

export default function* watchTenantsSaga() {
  //console.log("[SAGA] watching tenats");
  yield takeEvery(tenantsConstants.FETCH_ALL, fetchAllTenantsSaga);
  yield takeEvery(tenantsConstants.FETCH, fetchPageTenantsSaga);
  yield takeEvery(tenantsConstants.COPY_TENANT_CONFIG, copyTenantConfigSaga);
  yield takeEvery(tenantsConstants.DELETE_TENANT_REMOTE, deleteTenantRemoteSaga);
}
