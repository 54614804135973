const AlertCircleFull = ({size, style}) => {

  const iconSize = size ?? 16;

  return (
    <i className="alert-circle-icon" style={style}>
      <svg width={`${iconSize}`} height={`${iconSize}`} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M8.00008 0.666992C3.95008 0.666992 0.666748 3.95033 0.666748 8.00033C0.666748 12.0503 3.95008 15.3337 8.00008 15.3337C12.0501 15.3337 15.3334 12.0503 15.3334 8.00033C15.3334 3.95033 12.0501 0.666992 8.00008 0.666992ZM8.66675 4.66699C8.66675 4.49018 8.59651 4.32061 8.47149 4.19559C8.34646 4.07056 8.17689 4.00033 8.00008 4.00033C7.82327 4.00033 7.6537 4.07056 7.52868 4.19559C7.40365 4.32061 7.33341 4.49018 7.33341 4.66699V8.66699C7.33341 8.8438 7.40365 9.01337 7.52868 9.1384C7.6537 9.26342 7.82327 9.33366 8.00008 9.33366C8.17689 9.33366 8.34646 9.26342 8.47149 9.1384C8.59651 9.01337 8.66675 8.8438 8.66675 8.66699V4.66699ZM8.66675 11.0003C8.66675 10.8235 8.59651 10.6539 8.47149 10.5289C8.34646 10.4039 8.17689 10.3337 8.00008 10.3337C7.82327 10.3337 7.6537 10.4039 7.52868 10.5289C7.40365 10.6539 7.33341 10.8235 7.33341 11.0003V11.3337C7.33341 11.5105 7.40365 11.68 7.52868 11.8051C7.6537 11.9301 7.82327 12.0003 8.00008 12.0003C8.17689 12.0003 8.34646 11.9301 8.47149 11.8051C8.59651 11.68 8.66675 11.5105 8.66675 11.3337V11.0003Z"/>
      </svg>
    </i>
  );
};

export default AlertCircleFull;