import { takeEvery, put } from "redux-saga/effects";
import axios from "src/store/_shared/authAxios";

import { contentManagementConstants } from "../constants";
import {
  contentManagementTypesDefReceived,
  contentManagementAttributesListReceived,
} from "../actions/content-management";
import { getContentManagementApiBaseUrl, getGeneralPlatformAttributesApiBaseUrl } from "../../utils/api";

import store from "..";

function* contentManagementTypesDefRequestSaga(action) {
  try {
    const state = store.getState();

    if (state.contentManagement.typesDef == null) {
      const { data } = yield axios.get(`${getContentManagementApiBaseUrl(`/definition/list-types`)}`);

      // console.log("[SAGA] contentManagementTypesDefRequestSaga received typesDef");
      yield put(contentManagementTypesDefReceived(data));
    }
  } catch (e) {
    console.log("[SAGA] contentManagement request Saga error", e.toString());
  }
}

function* contentManagementAttributesListRequestSaga(action) {
  try {
    const state = store.getState();

    if (state.contentManagement.attributesList == null) {
      const { data } = yield axios.post(`${getGeneralPlatformAttributesApiBaseUrl("/attribute/list")}`, {});

      // console.log("[SAGA] contentManagementAttributesListRequestSaga received attributesList", data);
      yield put(contentManagementAttributesListReceived(data.data));
    }
  } catch (e) {
    console.log("[SAGA] contentManagement request Saga error", e.toString());
  }
}

export default function* watchContentManagementSaga() {
  //console.log("[SAGA] watching data");

  yield takeEvery(contentManagementConstants.DEFINITION_TYPES_REQUEST, contentManagementTypesDefRequestSaga);
  yield takeEvery(contentManagementConstants.ATTRIBUTES_LIST_REQUEST, contentManagementAttributesListRequestSaga);
}

// contentManagementAttributesListRequest
// TODO contentManegementAttributesListReceived
