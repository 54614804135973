import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DeleteIcon from "@mui/icons-material/HighlightOff";

import "../style/TenantTabs.scss";
import { tenantsFetch, tenantsAddTenant, tenantsCopyTenantConfig, tenantRemoveRemote } from "../store/actions/tenants";

const TenantTabs = props => {
  const {
    match,
    history,
    tenantId,
    tenants,
    tenantsFetch,
    tenantsAddTenant,
    tenantsCopyTenantConfig,
    tenantRemoveRemote,
    currentTenant,
  } = props;

  let defTabs = [];

  let path = match.path;
  if (path.indexOf(":tenantId") > -1) {
    if (tenants.pages[path] && tenants.pages[path].tenants) {
      defTabs = [...tenants.pages[path].tenants];
    }
  }

  const [tabs, setTabs] = React.useState(defTabs);
  const [allTabs, setAllTabs] = React.useState(tenants.tenants);

  React.useEffect(() => {
    setAllTabs(tenants.tenants);
  }, [tenants.tenants]);

  const filteredTabs = allTabs.filter(t => {
    let exists = false;
    tabs.forEach(tt => {
      if (tt.id === t.id) exists = true;
    });
    if (exists) return false;
    if (t.id === currentTenant) return true;
    return false;
  });

  const findIndex = (id, t, ret) => {
    const ts = t ? t : tabs;
    const index = ts.findIndex(e => parseInt(e.id, 10) === parseInt(id, 10));
    if (index > -1) {
      return index;
    }
    return ret ? -1 : 0;
  };

  const [openModal, setOpenModal] = React.useState({
    open: false,
    from: "-1",
    to: "",
  });
  const [index, setIndex] = React.useState(findIndex(tenantId));

  React.useEffect(() => {
    if (tenantId !== null) {
      setIndex(findIndex(tenantId));
      let path = match.path;
      if (path.indexOf(":tenantId") > -1) {
        if (tenants.pages[path] && tenants.pages[path].apiUrl) {
          tenantsFetch({ pageUrl: path });
        }
      }
    }
  }, [tenantId, match]); //eslint-disable-line

  React.useEffect(() => {
    setAllTabs(tenants.tenants);
    let path = match.path;
    if (path.indexOf(":tenantId") > -1) {
      if (tenants.pages[path] && tenants.pages[path].tenants) {
        const pageTenants = [...tenants.pages[path].tenants];
        setTabs(pageTenants);
        const exists = findIndex(tenantId, pageTenants, true);
        if (exists > -1) {
          setIndex(exists);
          return;
        }
        if (tenantId !== "0") history.push(path.replace(":tenantId", "0"));
      }
    }
  }, [tenantId, tenants, match]); //eslint-disable-line

  const navigateTo = id => () => {
    let path = match.path;
    if (path.indexOf(":tenantId") > -1) {
      path = path.replace(":tenantId", id);
      history.push(path);
    }
  };

  const handleChange = (event, newValue) => {
    setIndex(newValue);
  };

  // TODO: fix tenant selection
  React.useEffect(() => {
    if (
      tabs &&
      index !== 0 &&
      tenantId !== "0" &&
      tenantId !== currentTenant.toString()
    ) {
      navigateTo(findIndex(tenantId))();
    }
  }, [index, tenantId, currentTenant]); // eslint-disable-line

  const handleAddTenant = () => {
    setOpenModal({
      open: true,
      from: "-1",
      to: currentTenant,
    });
  };
  const handleClose = () => {
    setOpenModal({
      open: false,
      from: "-1",
      to: currentTenant,
    });
  };
  const handleAdd = () => {
    if (openModal.to !== "") {
      let path = match.path;

      if (path.indexOf(":tenantId") > -1) {
        const found = filteredTabs.find(t => parseInt(t.id, 10) === parseInt(openModal.to, 10));

        if (found && openModal.from === "-1") {
          tenantsAddTenant({
            pageUrl: path,
            tenant: found,
          });
          setOpenModal({
            open: false,
            from: "-1",
            to: currentTenant,
          });
        } else if (found) {
          tenantsCopyTenantConfig({
            pageUrl: path,
            tenant: found,
            params: {
              tenant_id: parseInt(openModal.from, 10),
              tenant_to: parseInt(openModal.to, 10),
            },
          });
          setOpenModal({
            open: false,
            from: "-1",
            to: currentTenant,
          });
        }
      }
    }
  };

  const handleFromChange = event => {
    setOpenModal(v => ({
      ...v,
      from: event.target.value,
    }));
  };
  const handleToChange = event => {
    setOpenModal(v => ({
      ...v,
      to: event.target.value,
    }));
  };

  const deleteTenant = index => e => {
    if (tabs[index]) {
      if (window.confirm("Are you sure you want to delete this tenant configuration?")) {
        navigateTo(0);
        setIndex(0);
        tenantRemoveRemote({
          pageUrl: match.path,
          tenant: tabs[index],
          params: {
            tenant_id: tabs[index].id,
          },
        });
      }
    }
    e.stopPropagation();
  };

  if (tabs.length === 0) return null;

  let hasTenantConfig = false;
  tabs.forEach(t => {
    if (t.id === 0 || t.id === currentTenant) {
      if (t.id === currentTenant) hasTenantConfig = true;
    }
  });

  return (
    <React.Fragment>
      <div className="tenant-tabs">
        <Tabs variant="scrollable" className="generic-tabs tenants-tab" value={index} onChange={handleChange}>
          {tabs.map((t, i) => {
            let label = null;
            if (t.id === 0) {
              label = <div className="tab-content">{t.label}</div>;
            } else {
              label = (
                <div className="tab-content">
                  {t.label}
                  <div className="tab-delete" onClick={deleteTenant(i)}>
                    <DeleteIcon />
                  </div>
                </div>
              );
            }

            let extraClass = "";
            if (!(t.id === 0 || t.id === currentTenant)) {
              extraClass = "tab-hidden";
            }

            return <Tab className={extraClass} key={`tab_${i}`} label={label} onClick={navigateTo(t.id)} />;
          })}
        </Tabs>
        {!hasTenantConfig && filteredTabs.length !== 0 && (
          <IconButton
            aria-label="add"
            onClick={handleAddTenant}
            title="Add for tenant"
            size="large">
            <AddIcon fontSize="small" />
          </IconButton>
        )}
      </div>
      {openModal.open && (
        <Dialog className="tenant-tabs-modal" fullWidth={true} open={openModal.open} onClose={handleClose}>
          <DialogTitle id="form-dialog-title">Add new configuration for tenant</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Initialize configuration for a new tenant using an existent configuration from another tenant (or General
              config).
            </DialogContentText>
            <FormControl fullWidth={true}>
              <InputLabel id="from-tenant-label">From Tenant (or General config)</InputLabel>
              <Select
                labelId="from-tenant-label"
                id="from-tenant"
                value={openModal.from}
                onChange={handleFromChange}
                label="From Tenant (or General config)"
              >
                <MenuItem key={"none"} value="-1">
                  No Config
                </MenuItem>
                {tabs.map((t, i) => (
                  <MenuItem key={t.id} value={t.id}>
                    {t.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth={true} className="mt10">
              <InputLabel id="to-tenant-label">To Tenant</InputLabel>
              <Select
                labelId="to-tenant-label"
                id="to-tenant"
                value={openModal.to}
                onChange={handleToChange}
                readOnly={true}
                label="To Tenant"
              >
                {filteredTabs.map((t, i) => (
                  <MenuItem key={t.id} value={t.id}>
                    {t.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAdd} color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
};
const mapStateToProps = state => ({
  tenants: state.tenants,
  currentTenant: state.tenants.currentTenant ? state.tenants.currentTenant : "0",
});

const actions = {
  tenantsFetch,
  tenantsAddTenant,
  tenantsCopyTenantConfig,
  tenantRemoveRemote,
};

export default connect(mapStateToProps, actions)(withRouter(TenantTabs));
