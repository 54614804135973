import { produce } from "immer";
import { templatizationEditPageConstants } from "../constants";

const initialState = {
  dataSources: [],
  components: [],
  loaded: false,
};

const templatizationEditPage = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case templatizationEditPageConstants.TEMPLATIZATION_SET_DATA: {
        if (action.data && action.data.dataSources) draft.dataSources = action.data.dataSources;
        if (action.data && action.data.components) draft.components = action.data.components;
        if (action.data && action.data.loaded) draft.loaded = action.data.loaded;
        break;
      }
      case templatizationEditPageConstants.TEMPLATIZATION_CLEAR_DATA: {
        draft.dataSources = [];
        draft.components = [];
        draft.loaded = false;
        break;
      }
      default:
        /* noop */
        break;
    }
  });

export default templatizationEditPage;
