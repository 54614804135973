import {produce} from "immer";
import {cloneDeep} from "lodash/fp";
import {bulkAffiliateInfoUpdate as Constants} from "../constants";

const initialState = {
  status: Constants.IDLE_STATUS,
  maxLengthPlayerIdListPerRequest: Constants.MAX_LENGTH_PLAYER_ID_LIST_PER_REQUEST,
  failed: 0,
  succeeded: 0,
  results: {},
  total: 0,
  elapsedTime: 0,
  requests: [],
  currentRequest: 0,
};

const bulkUpdateAffiliateInfoReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case Constants.START: {
        // console.log("REDUCER bulkUpdateAffiliateInfoReducer START");
        draft.status = Constants.IN_PROGRESS_STATUS;
        draft.requests = action.data.requests;
        draft.total = action.data.requests.length;
        draft.elapsedTime = action.data.requests.length * 500; // estimated milliseconds until we're done
        break;
      }
      case Constants.CLEAR: {
        // console.log("REDUCER bulkUpdateAffiliateInfoReducer CLEAR");
        if (draft.status !== Constants.IDLE_STATUS) {
          draft.status = Constants.IDLE_STATUS;
          draft.failed = 0;
          draft.succeeded = 0;
          draft.results = {};
          draft.total = 0;
          draft.elapsedTime = 0;
          draft.requests = [];
          draft.currentRequest = 0;
        }
        break;
      }
      case Constants.DONE: {
        // console.log("REDUCER bulkUpdateAffiliateInfoReducer DONE", action.data);
        if (action.data) {

          const request = {...draft.requests[draft.currentRequest]};

          draft.elapsedTime = (draft.requests.length - (action.data.currentRequest + 1)) *
            action.data.elapsedTimePerRequest;
          draft.currentRequest = action.data.currentRequest + 1;

          if (draft.currentRequest > draft.total || draft.failed) {
            draft.status = Constants.DONE_STATUS;
            break;
          }

          const updatedIds = cloneDeep(draft.results);
          if (action.data.success) {
            request.playerIds.forEach(playerId => {
              updatedIds[playerId] = Constants.UPDATED;
            });
            draft.results = updatedIds;
            draft.succeeded = draft.succeeded + 1;
          } else {
            request.playerIds.forEach(playerId => {
              updatedIds[playerId] = Constants.UNKNOWN;
            });
            draft.results = updatedIds;
            draft.failed = draft.failed + 1;
          }

          request.response = action.data.response;
          draft.requests[draft.currentRequest - 1] = request;
        }
        break;
      }

      case Constants.UPDATE_REQUEST_MAX_LENGTH: {
        // console.log("REDUCER bulkUpdateAffiliateInfoReducer UPDATE_REQUEST_MAX_LENGTH", action.data);
        if (action.data) {
          draft.maxLengthPlayerIdListPerRequest = action.data.limit;
          draft.status = Constants.IDLE_STATUS;
          draft.failed = 0;
          draft.succeeded = 0;
          draft.results = {};
          draft.total = 0;
          draft.elapsedTime = 0;
          draft.requests = [];
          draft.currentRequest = 0;
        }
      }
      default:
        /* noop */
        break;
    }
  });

export default bulkUpdateAffiliateInfoReducer;