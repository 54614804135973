import React from "react";

const CheckBox = props => {

  const extraProps = {};
  if (typeof props.onChange === "function") {
    extraProps.onChange = props.readOnly || props.disabled ? () => { } : props.onChange;
  }
  if (typeof props.defaultChecked !== "undefined") {
    extraProps.defaultChecked = props.defaultChecked;
  }
  if (typeof props.checked !== "undefined") {
    extraProps.checked = props.checked;
  }
  if (typeof props.value !== "undefined") {
    extraProps.value = props.value;
  }
  if (typeof props.name !== "undefined") {
    extraProps.name = props.name;
  }

  Object.keys(props).forEach(k => {
    if (k.indexOf("data-") === 0) {
      extraProps[k] = props[k];
    }
  });

  return (
    <label className={"checkbox-container" + (props.readOnly || props.disabled ? " read-only" : "") + (props.className ? ` ${props.className}` : "")}>
      <div>
        <input type="checkbox"
          {...extraProps}
        />
        <span
          className={
            "checkbox-icon " +
            ((typeof props.value !== "undefined" && props.value === "on") || props.defaultChecked ? "checked" : "")
          }
        >
          <i className="material-icons">check</i>
        </span>
      </div>
      {props.label && (
        <span className="checkbox-label" title={props.noTitle ? '' : props.label}>
          {props.label}
        </span>
      )}
    </label>
  );
};

export default CheckBox;
