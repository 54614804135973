import { produce } from "immer";

import { sitesConstants } from "../constants";

const checkForSiteInUrl = () => {
  const url = new URL(window.location.href);

  let siteId = "";

  if (url.searchParams.has("site") && url.searchParams.get("site")) {
    siteId = url.searchParams.get("site");
  }
  if (siteId === "-1") return "0"; // defaults to none
  if (siteId) return siteId;

  return "0";
};

const initialState = {
  sites: [],
  // pages: {},
  isLoading: false,
  currentSite: checkForSiteInUrl(),
};

const sitesReducer = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case sitesConstants.LOADED: {
        const sites = [];
        let hasGeneral = false;
        action.data.sites.forEach(sID => {
          const pID = parseInt(sID, 10);
          if (pID === 0) {
            hasGeneral = true;
            sites.push({ id: "0", name: "All sites", label: "All sites", tenant_id: "0" });
          } else {
            const found = draft.sites.find(t => parseInt(t.id, 10) === pID);
            if (found) {
              sites.push(found);
            }
          }
        });
        if (!hasGeneral) sites.unshift({ id: "0", name: "All sites", label: "All sites", tenant_id: "0" });

        draft.isLoading = false;
        break;
      }

      case sitesConstants.LOADED_ALL: {
        const sites = action.data.map(s => ({
          label: `${s.tenant_id} - ${s.name}`,
          name: s.name,
          id: s.id,
          tenant_id: s.tenant_id,
          url: `https://${s.sub_domain}`,
        }));

        sites.unshift({ id: "0", name: "All sites", label: "All sites", tenant_id: "0" });

        draft.sites = sites;
        draft.isLoading = false;
        break;
      }
      case sitesConstants.ADD_SITE: {
        if (action.data.pageUrl && action.data.site) draft.pages[action.data.pageUrl].sites.push(action.data.site);
        break;
      }
      case sitesConstants.DELETE_SITE_LOCAL: {
        if (action.data.pageUrl && action.data.site) {
          const sites = draft.pages[action.data.pageUrl].sites;
          const newTenants = [];
          sites.forEach(t => {
            if (t.id !== action.data.site.id) {
              newTenants.push(t);
            }
          });
          draft.pages[action.data.pageUrl].sites = newTenants;
        }
        break;
      }
      case sitesConstants.IS_LOADING: {
        draft.isLoading = action.data;
        break;
      }

      case sitesConstants.SET_CURRENT_SITE: {
        draft.currentSite = action.siteId;
        break;
      }
      default:
        /* noop */
        break;
    }
  });
};
export default sitesReducer;
