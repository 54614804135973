// ** Router Import
import React from "react";
import Router from "./router/Router";
import { createTheme, ThemeProvider, StyledEngineProvider, adaptV4Theme } from "@mui/material/styles";
import { useSkin } from "@hooks/useSkin";
import { useSelector } from "react-redux";

const App = props => {
  const skin = useSelector(state => state.theme.darkMode);

  const theme = createTheme({
    palette: {
      type: "dark",
      primary: {
        main: "#4580F3",
      },
      secondary: {
        main: "#FF8C00",
      },
    },
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
