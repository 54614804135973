import {loyaltyConstants as Constants} from "../constants";

export const showEditModal = (data) => ({
  type: Constants.SHOW_EDIT_MODAL,
  data
});

export const hideEditModal = (data) => ({
  type: Constants.HIDE_EDIT_MODAL,
  data
});

export const setModalStartingData = (data) => ({
  type: Constants.SET_MODAL_STARTING_DATA,
  data
});

export const setModalData = (data) => ({
  type: Constants.SET_MODAL_DATA,
  data
});

export const initPage = (data) => ({
  type: Constants.INIT_PAGE,
  data
});

export const showDialog = (data) => ({
  type: Constants.SHOW_DIALOG,
  data
});

export const hideDialog = (data) => ({
  type: Constants.HIDE_DIALOG,
  data
});

export const setDialogData = (data) => ({
  type: Constants.SET_DIALOG_DATA,
  data
});

export const loadCurrencyTotalCount = (data) => ({
  type: Constants.LOAD_CURRENCY_TOTAL_COUNT,
  data
});

export const loadedCurrencyTotalCount = (data) => ({
  type: Constants.LOADED_CURRENCY_TOTAL_COUNT,
  data
});

export const loadCurrencies = (data) => ({
  type: Constants.LOAD_CURRENCIES,
  data
});

export const loadedCurrencies = (data) => ({
  type: Constants.LOADED_CURRENCIES,
  data
});

export const loadCurrencyGroups = (data) => ({
  type: Constants.LOAD_CURRENCY_GROUPS,
  data
});

export const loadedCurrencyGroups = (data) => ({
  type: Constants.LOADED_CURRENCY_GROUPS,
  data
});

export const saveCurrencyGroup = (data) => ({
  type: Constants.SAVE_CURRENCY_GROUP,
  data
});

export const saveCurrency = (data) => ({
  type: Constants.SAVE_CURRENCY,
  data
});

export const loadCampaignGroups = (data) => ({
  type: Constants.LOAD_CAMPAIGN_GROUPS,
  data
});

export const loadedCampaignGroups = (data) => ({
  type: Constants.LOADED_CAMPAIGN_GROUPS,
  data
});

export const saveCampaignGroup = (data) => ({
  type: Constants.SAVE_CAMPAIGN_GROUP,
  data
});

export const loadCampaigns = (data) => ({
  type: Constants.LOAD_CAMPAIGNS,
  data,
});

export const loadedCampaigns = (data) => ({
  type: Constants.LOADED_CAMPAIGNS,
  data,
});

export const createCampaign = (data) => ({
  type: Constants.CREATE_CAMPAIGN,
  data
});

export const loadRankSystems = (data) => ({
  type: Constants.LOAD_RANK_SYSTEMS,
  data
});

export const loadedRankSystems = (data) => ({
  type: Constants.LOADED_RANK_SYSTEMS,
  data
});

export const cloneLoyaltyEntity = (data) => ({
  type: Constants.CLONE_ENTITY,
  data
});

export const saveRankCampaignGroup = (data) => ({
  type: Constants.SAVE_RANK_CAMPAIGN_GROUP,
  data
});

export const loadRankCampaigns = (data) => ({
  type: Constants.LOAD_RANK_CAMPAIGNS,
  data,
});

export const loadedRankCampaigns = (data) => ({
  type: Constants.LOADED_RANK_CAMPAIGNS,
  data,
});

export const createRankCampaign = (data) => ({
  type: Constants.CREATE_RANK_CAMPAIGN,
  data
});

export const createLoyaltyMission = (data) => ({
  type: Constants.CREATE_LOYALTY_MISSION,
  data,
});

export const loadLoyaltyMissions = (data) => ({
  type: Constants.LOAD_LOYALTY_MISSIONS,
  data,
});

export const loadedLoyaltyMissions = (data) => ({
  type: Constants.LOADED_LOYALTY_MISSIONS,
  data,
});

export const createLoyaltyObjective = (data) => ({
  type: Constants.CREATE_LOYALTY_OBJECTIVE,
  data,
});