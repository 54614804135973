export const userConstants = {
  AUTH_CHECK_REQUEST: 'AUTH_CHECK_REQUEST',
  AUTH_CHECK_SUCCESS: 'AUTH_CHECK_SUCCESS',
  AUTH_CHECK_FAILURE: 'AUTH_CHECK_FAILURE',

  LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USER_LOGIN_FAILURE',

  LOGOUT: 'USER_LOGOUT',
};
