import { produce } from "immer";
import { liveCasinoConstants } from "../constants";

const initialState = {
  tables: []
};

const liveCasinoReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case liveCasinoConstants.LOADED: {

        if (typeof action.data.tables !== "undefined") draft.tables = action.data.tables;

        break;
      }
      default:
        /* noop */
        break;
    }
  });

export default liveCasinoReducer;