import React from "react";
import MaterialTable from "@material-table/core";
import { Paper } from '@mui/material';
import TablePagination from "@mui/material/TablePagination";
import { connect } from "react-redux";

import TableEditField from "../MTableEditField";
import MTableAction from "../table-actions";

import "./index.scss";

import { ReactComponent as EditIcon } from "./_icons/edit.svg";
import { ReactComponent as DeleteIcon } from "./_icons/delete.svg";
import { ReactComponent as SaveIcon } from "./_icons/save.svg";
import { ReactComponent as ClearIcon } from "./_icons/clear.svg";
import { ReactComponent as ExportIcon } from "./_icons/download.svg";
import { ReactComponent as DetailPanelIcon } from "./_icons/detail-panel.svg";
import { ReactComponent as SearchIcon } from "./_icons/search.svg";
import { ReactComponent as SortArrowIcon } from "./_icons/sort-arrow.svg";
import { ReactComponent as MoveToIcon } from "./_icons/move-to.svg";

function Container(props) {
  const ref = React.useRef(null);
  const refWrapper = React.useRef(null);
  const refBody = React.useRef(null);

  const updateScroll = () => {
    if (ref.current) {
      refWrapper.current.scrollLeft = refBody.current.scrollLeft;
    }
  };

  const handleOnScrollBodyWrapper = (_e) => {
    if (ref.current) {
      refBody.current.scrollLeft = refWrapper.current.scrollLeft;
    }
  };

  const handleOnScrollWrapperBody = (_e) => {
    updateScroll();
  };

  React.useEffect(() => {
    if (ref.current) {
      refWrapper.current = ref.current.querySelector(".scroll-wrapper");
      refBody.current = ref.current.querySelector(".MuiBox-root>div>div");

      refWrapper.current.onscroll = handleOnScrollBodyWrapper;
      refBody.current.onscroll = handleOnScrollWrapperBody;

      const scrollDiv = ref.current.querySelector(".scroll-div");
      scrollDiv.style.width = `${refBody.current.scrollWidth}px`;

      const observer = new ResizeObserver(entries => {
        entries.forEach(_entry => {
          scrollDiv.style.width = `${refBody.current.scrollWidth}px`;
        });
      });

      // start listening to changes
      observer.observe(refBody.current);
    }
  }, []);

  return <Paper elevation={2} {...props} ref={ref} />;
}

const MaterialTableCustom = ({ forwardedRef, ...props }) => {
  // console.log(props);
  const addActionLabel = props?.addActionLabel || "Add";

  //Override the localization
  if (props.localization == null) {
    props.localization = {};
  }
  if (props.localization.body == null) {
    props.localization.body = {};
  }

  if (!props.localization.body.emptyDataSourceMessage) {
    props.localization.body.emptyDataSourceMessage = <div className="pt-2">No records to display</div>;
  }

  if (!props.localization.body.addTooltip) {
    props.localization.body.addTooltip = "Add";
  }

  if (typeof props.options.hideAtStartup !== "undefined" && props.options.hideAtStartup) {
    if (typeof props.style == null) {
      props.style = {};
    }
    props.style = {
      ...props.style,
      opacity: props.sentFirstRequest ? "1" : "0",
      display: props.sentFirstRequest ? "block" : "none",
      pointerEvents: props.sentFirstRequest ? "unset" : "none",
      transition: "opacity 1s ease 0.8s, display 0.5s linear 0s, pointer-events 3s linear 0s",
    };
    props.data = props.sentFirstRequest ? props.data : [];
  }

  //Override the table components
  if (typeof props.components === "undefined") {
    props.components = {};
  }
  props.components = {
    ...props.components,
    // Container: (props) => <Container {...props}>
    //   <div className="scroll-wrapper">
    //     <div className="scroll-div"></div>
    //   </div>
    //   {props.children}
    // </Container>,
    EditField: TableEditField,
    Pagination: props => {
      if (props.count <= props.rowsPerPage) return null;
      return <TablePagination {...props} />;
    },
    Action: props => {
      if (typeof addActionLabel !== "undefined"
        && addActionLabel !== "" &&
        typeof props.action !== "undefined" &&
        typeof props.action.position !== "undefined" &&
        props.action.position === "toolbar"
      ) {
        if (
          typeof props.action.tooltip !== "undefined" &&
          props.action.tooltip === "Add"
        ) {
          //Passing the `addActionLabel` prop to the `MTableAction` component
          // when the action is the default Add action button from `MaterialTable`
          props = {
            ...props,
            action: { ...props.action, tooltip: addActionLabel },
            addActionLabel
          };
        }
        else if (
          typeof props.action.tooltip !== "undefined" &&
          addActionLabel === props.action.tooltip &&
          typeof props.action.icon === "undefined"
        ) {
          props = {
            ...props,
            action: { ...props.action, icon: () => <div className="">{addActionLabel}</div> },
            addActionLabel,
          };
        }
      }
      return <MTableAction {...props} />;
    },
  };

  //console.log("MaterialTableCustom props", props);

  return (
    <MaterialTable
      {...props}
      ref={forwardedRef}
      icons={{
        Edit: EditIcon,
        Delete: DeleteIcon,
        Check: SaveIcon,
        Clear: ClearIcon,
        Export: ExportIcon,
        DetailPanel: DetailPanelIcon,
        Search: SearchIcon,
        SortArrow: SortArrowIcon,
        MoveTo: MoveToIcon,
      }}
      style={{
        ...props.style,
      }}
    />
  );
};

const MaterialTableCustomRef = React.forwardRef((props, ref) => {
  return <MaterialTableCustom {...props} forwardedRef={ref} />;
});

const mapStateToProps = state => {
  return {
    sentFirstRequest: state.tableSearchFilter.sentFirstRequest,
  };
};

export default connect(mapStateToProps)(MaterialTableCustomRef);
