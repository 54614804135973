import React from "react";
import { ArrowLeft } from "react-feather";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import IconButton from '@mui/material/IconButton';

import TenantConfigSelector from "./TenantConfigSelector";

const Arrow = ({ size }) => {
  return (
    <svg
      width={size ? size : 16}
      height={size ? size : 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.37354 1.95703L1.17017 8.1604M1.17017 8.1604L7.37354 14.3638M1.17017 8.1604H14.9584"
        stroke="var(--primary)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ExtraHeader = props => {
  const { route } = props;

  const { t } = useTranslation();

  const [fullscreen, setFullscreen] = React.useState(false);

  React.useEffect(() => {
    return () => {
      document.body.classList.remove("expanded-screen");
    }
  }, []);

  const switchFullScreen = () => {
    setFullscreen(v => {
      if (v === true) {
        document.body.classList.remove("expanded-screen");
      } else {
        document.body.classList.add("expanded-screen");
      }
      return !v;
    })
  };

  if (route && route.layout === "BlankLayout") return null;

  const name = t(route.name);
  const history = useHistory();

  const goBack = () => {
    if (document.body.classList.contains("expanded-screen")) {
      document.body.classList.remove("expanded-screen");
    }
    history.goBack();
  };

  let showExpandButton = true;
  if (route?.hideExpandButton)
    showExpandButton = false;

  return (
    <React.Fragment>
      {!!name && !route.noHeader && (
        <div className="page-header d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            {route.canGoBack && (
              <span className="arrow" onClick={goBack}>
                <Arrow size={14} />
              </span>
            )}
            <span id="page-header-title">{name}</span> <span id="page-header-extra"></span>
          </div>
          {showExpandButton && <div className="expand-button">
            <IconButton className="expand-button-full-page-toggle" onClick={switchFullScreen} title={fullscreen ? 'Exit full page' : 'Enter full page'}>
              {fullscreen && <FullscreenExitIcon />}
              {!fullscreen && <FullscreenIcon />}
            </IconButton>
          </div>}
        </div>
      )}
      <TenantConfigSelector route={route} />
    </React.Fragment>
  );
};

export default ExtraHeader;
