import axios from "axios";
import authAxios from "../authAxios";
import { getRefreshToken } from "../authAxios";
import { endpoints } from "src/api/endpoints";

export const authService = {
  login,
  logout,
  check,
};

function login(username, password) {
  return axios({
    url: endpoints.user.login,
    method: "POST",
    data: JSON.stringify({ username, password, generate_refresh_token: true, decoded_permissions: true }),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => {
    // console.log("Login response", response)
    const r = response.data.data;
    return r;
  });
}

function logout() {
  const refresh_token = getRefreshToken();

  // console.log("logout refresh token", refresh_token);

  if (refresh_token) {
    return authAxios({
      url: endpoints.user.logout,
      method: "POST",
      data: {
        refresh_token,
      },
    });
  }

  return Promise.resolve(true);
}

function check() {
  return authAxios({
    url: endpoints.user.check,
    method: "GET",
    params: {
      decoded_permissions: true,
    }
  }).then((response) => {
    // console.log("Auth check response", response)
    const r = response.data.data;
    return r;
  });
}
