import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { DropzoneArea } from "material-ui-dropzone";
import TextField from "@mui/material/TextField";
import axios from "src/store/_shared/authAxios";

import evBus from "../utils/evBus";
import { buildApiUrl } from "../utils/api";
import { accessPages, operationsAccess } from "../utils/users";
const apiUrl = buildApiUrl("/admin");

const useStyles = makeStyles({
  oh: {
    overflow: "hidden",
    outline: "none",
  },
  input: {
    width: "100%",
  },
});

const GroupImage = props => {
  const classes = useStyles();

  const { open, group, type, onUpload, value, tenantId, onClose } = props;

  const [dialogState, setDialogState] = React.useState({
    files: null,
  });

  const [imageLink, setImageLink] = React.useState("");
  const imageRef = React.useRef("");

  const handleFileChange = files => {
    setDialogState(v => ({
      ...v,
      files,
    }));
  };

  const opAccess = operationsAccess("upload_image");

  React.useEffect(() => {
    if (imageLink && imageRef.current !== imageLink) {
      onUpload({ type: type, imageLink: imageLink });
      imageRef.current = imageLink;
    }
  }, [imageLink, type, onUpload]);

  const doUpload = () => {
    if (!opAccess.canSelf) return;

    if (dialogState.files && dialogState.files.length) {
      const formData = new FormData();
      formData.append("myImage", dialogState.files[0]);

      axios
        .post(`${apiUrl}/upload-image${tenantId ? `?tenant_id=${tenantId}` : ""}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          if (response && response.data) {
            setImageLink(response.data.imageLink);
            onClose();

            evBus.emit("GENERIC_ALERT", {
              msg: `${props.pdf ? "Document" : "Image"} uploaded`,
            });
          }
        })
        .finally(() => {
          setDialogState({
            files: null,
          });
        });
    }
  };

  const handleChange = event => {
    setImageLink(event.target.value);
  };

  if (!open) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="md"
      scroll="body"
      aria-labelledby="upload-dialog"
      aria-describedby="upload-dialog-body"
    >
      <DialogTitle id="scroll-dialog-title">
        Upload {type ? `"${type}"` : ""} {props.pdf ? "document" : "image"} {group ? `for "${group}" group` : ""}
      </DialogTitle>
      <DialogContent dividers={true} id="upload-dialog-body" className={classes.oh}>
        <DropzoneArea
          acceptedFiles={[
            "image/*",
            "audio/mpeg",
            ".csv, text/csv, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values",
            "application/pdf",
            "application/vnd.ms-fontobject",
            "application/font-ttf",
            "application/font-woff",
            "application/font-woff2",
            "application/font-otf",
            "font/ttf",
            "font/woff",
            "font/woff2",
            "font/otf",
            "",
          ]}
          dropzoneText={`Drag and drop ${props.pdf ? "a document" : "an image"} here or click`}
          onChange={handleFileChange}
          maxFileSize={100000000}
        />
        <TextField
          label={props.pdf ? "Document Name" : "Image Name"}
          defaultValue={value}
          type="text"
          onChange={handleChange}
          className={classes.input}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
        {opAccess.canSelf && (
          <Button onClick={doUpload} color="secondary" disabled={!dialogState.files || !dialogState.files.length}>
            Upload
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default GroupImage;
