import {tableSearchFilterConstants  as Constants} from "../constants";

export const tableSearchSet = data => ({
  type: Constants.SET,
  data
});

export const tableSearchClear = () => ({
  type: Constants.CLEAR,
});

export const tableSearchSetSearchValue = data => ({
  type: Constants.SET_SEARCH_VALUE,
  data
});

export const tableSearchDoSearch = (data) => ({
  type: Constants.DO_SEARCH,
  data
});

export const tableSearchSetLastRequestSent = (data) => ({
  type: Constants.LAST_REQUEST_SENT,
  data
});

export const tableSearchSetNewSearchPending = (data) => ({
  type: Constants.SET_NEW_SEARCH_PENDING,
  data
});

export const tableSearchDoDebouncedQuickSearch = (data) => ({
  type: Constants.DO_QUICK_SEARCH,
  data
});

export const tableSearchSetQuickSearchResults = (data) => ({
  type: Constants.SET_QUICK_SEARCH_RESULTS,
  data
});

export const tableSearchSaveSearchLastVisitedResult = (data) => ({
  type: Constants.SET_SEARCH_LAST_VISITED_RESULT,
  data
});

export const tableSearchSaveLastVisitedResult = (data) => ({
  type: Constants.SAVE_SEARCH_LAST_VISITED_RESULT,
  data
});

export const tableSearchGetSearchLastVisitedResult = (data) => ({
  type: Constants.GET_SEARCH_LAST_VISITED_RESULT,
  data
});

export const tableFilterDoNewSearch = (data) => ({
  type: Constants.FILTER_DO_NEW_SEARCH,
  data
});

export const tableFilterStoreLastSearch = (data) => ({
  type: Constants.STORE_FILTER_LAST_SEARCH,
  data
});

export const tableFilterLoadStoredLastSearch = (data) => ({
  type: Constants.LOAD_FILTER_LAST_SEARCH,
  data
});

export const tableFilterStorePresetDefinition = (data) => ({
  type: Constants.STORE_FILTER_PRESET_DEFINITION,
  data,
});

export const tableFilterStoredPresetDefinition = (data) => ({
  type: Constants.STORED_FILTER_PRESET_DEFINITION,
  data,
});

export const tableFilterClearPresetDefinitionModal = () => ({
  type: Constants.CLEAR_FILTER_PRESET_DEFINITION_MODAL,
});

export const tableFilterLoadAllPresetDefinitions = (data) => ({
  type: Constants.LOAD_ALL_FILTER_PRESET_DEFINITIONS,
  data,
});

export const tableFilterSetAllPresetDefinitions = (data) => ({
  type: Constants.SET_ALL_FILTER_PRESET_DEFINITIONS,
  data,
});

export const tableFilterUpdateAllStoredPresetDefinitions = (data) => ({
  type: Constants.UPDATE_STORED_ALL_FILTER_PRESET_DEFINITIONS,
  data,
});

export const tableFilterEditPresetDefinition = (data) => ({
  type: Constants.EDIT_FILTER_PRESET_DEFINITION,
  data,
});

export const tableFilterSetFilterSearchActivePreset = (data) => ({
  type: Constants.SET_FILTER_SEARCH_ACTIVE_PRESET,
  data,
});

export const tableFilterSetFilterIncomingActivePreset = (data) => ({
  type: Constants.SET_FILTER_INCOMING_ACTIVE_PRESET,
  data,
});
