import { produce } from "immer";
import { lotteriesConstants } from "../constants";

const initialState = {
  lotteries: [],
};

const lotteriesReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case lotteriesConstants.LOADED: {

        if (typeof action.data.systems !== "undefined") {
          const systems = [];
          action.data.systems.forEach(s => {
            systems.push({ id: s.system_id, name: s.system_name })
          })
          draft.lotteries = systems;
        }

        break;
      }
      default:
        /* noop */
        break;
    }
  });

export default lotteriesReducer;