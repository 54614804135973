import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import thunkMiddleware from "redux-thunk";
//import logger from "redux-logger";
import rootReducer from "./reducers";
import rootSaga from "./sagas";

const sagaMiddleware = createSagaMiddleware();

//let middlewares = applyMiddleware(logger, thunkMiddleware, sagaMiddleware);
let middlewares = applyMiddleware(thunkMiddleware, sagaMiddleware);

const store = createStore(rootReducer, middlewares);
sagaMiddleware.run(rootSaga);

export default store;
