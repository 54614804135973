import { editorConstants } from "../constants";

export const editorSetWidthMedia = widthMedia => ({
  type: editorConstants.SET_WIDTH_MEDIA,
  widthMedia
});

export const editorSetPanelOpen = (panel, state) => ({
  type: editorConstants.SET_PANEL_OPEN,
  panel,
  state
});

export const editorSetGroupState = (group, state) => ({
  type: editorConstants.SET_GROUP_STATE,
  group,
  state
});
export const editorSetPropertyState = (property, state) => ({
  type: editorConstants.SET_PROPERTY_STATE,
  property,
  state
});
export const editorSetSelectorState = (state) => ({
  type: editorConstants.SET_SELECTOR_STATE,
  state
});
export const editorSetMediaQueryState = (state) => ({
  type: editorConstants.SET_MEDIA_QUERY_STATE,
  state
});

export const editorClearPanels = () => ({
  type: editorConstants.CLEAR_PANELS,
});

export const editorClear = (what) => ({
  type: editorConstants.CLEAR,
  what
});

export const editorClearAll = () => ({
  type: editorConstants.CLEAR_ALL
});

export const editorSetData = (data) => ({
  type: editorConstants.SET_DATA,
  data
});

export const editorSetEditorStates = (data, socketId) => ({
  type: editorConstants.SET_EDITOR_STATES,
  data,
  socketId,
});

