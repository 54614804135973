import {takeEvery, put} from "redux-saga/effects";

import store from "..";
import {loyaltyConstants} from "../constants";
import {
  loadedCampaignGroups,
  loadedCampaigns,
  loadedCurrencies,
  loadedCurrencyGroups,
  loadedCurrencyTotalCount,
  loadedLoyaltyMissions,
  loadedRankCampaigns,
  loadedRankSystems,
  setModalData,
  setModalStartingData,
  setDialogData,
  hideDialog,
} from "../actions/loyalty";
import ValuesSrc from "src/views/constants/loyalty";
import {buildApiUrl} from "src/utils/api";
import evBus from "src/utils/evBus";

const lottoApiUrl = window.ini.REACT_APP_LOTTO_API;
const apiUrl = buildApiUrl("/admin");

function* loadCurrencyTotalCountSaga(action) {
  try {

    const {tenants} = store.getState();

    const postData = {
      client_id: tenants.currentTenant,
      limit: 1,
      page_id: 0,
      filters: [
        {
          id: 0,
          column: "client_id",
          predicate: "=",
          values: tenants.currentTenant,
        }
      ]
    };
    const axiosMethod = ValuesSrc.currencyManager.apiRoutes.currencies.listAll.method;
    const url = ValuesSrc.currencyManager.apiRoutes.currencies.listAll.url(apiUrl, tenants.currentTenant);
    // console.log("[SAGA] loadCurrencyTotalCountSaga Table request ", {axiosMethod, url, postData});
    const {data} = yield axiosMethod(url, postData);

    // console.log("[SAGA] loadCurrencyTotalCountSaga response", data);
    yield put(loadedCurrencyTotalCount(data));
  } catch (e) {
    console.log("[SAGA] loadCurrencyTotalCountSaga error", e.toString());
  }
}

function* loadCurrenciesSaga(action) {
  try {

    const {tenants} = store.getState();

    const postData = {
      client_id: tenants.currentTenant,
      sort_by: "id",
      sort_desc: 0,
      // limit: 1,
      // page_id: 0,
      filters: [
        {
          id: 0,
          column: "client_id",
          predicate: "=",
          values: tenants.currentTenant,
        }
      ]
    };
    const axiosMethod = ValuesSrc.currencyManager.apiRoutes.currencies.listAll.method;
    const url = ValuesSrc.currencyManager.apiRoutes.currencies.listAll.url(apiUrl, tenants.currentTenant);
    // console.log("[SAGA] loadCurrencyTotalCountSaga Table request ", {axiosMethod, url, postData});
    const {data} = yield axiosMethod(url, postData);

    // console.log("[SAGA] loadCurrenciesSaga response", data);
    yield put(loadedCurrencies(data));
  } catch (e) {
    console.log("[SAGA] loadCurrenciesSaga error", e.toString());
  }
}

function* loadCurrencyGroupsSaga(action) {
  try {

    const {tenants} = store.getState();

    const postData = {
      client_id: tenants.currentTenant,
      sort_by: "id",
      sort_desc: 0,
      // limit: 1,
      // page_id: 0,
      filters: [
        {
          id: 0,
          column: "client_id",
          predicate: "=",
          values: tenants.currentTenant,
        }
      ]
    };

    if (action.data) {
      if (action.data.id) {
        postData.filters.push({
          id: postData.filters.length,
          column: "id",
          predicate: "=",
          values: parseInt(action.data.id),
        });
      }
    }

    const axiosMethod = ValuesSrc.currencyManager.apiRoutes.groups.listAll.method;
    const url = ValuesSrc.currencyManager.apiRoutes.groups.listAll.url(apiUrl, tenants.currentTenant);
    // console.log("[SAGA] loadCurrencyGroupsSaga Table request ", {axiosMethod, url, postData});
    const {data} = yield axiosMethod(url, postData);

    // console.log("[SAGA] loadCurrencyGroupsSaga response", data);
    yield put(loadedCurrencyGroups(data));
  } catch (e) {
    console.log("[SAGA] loadCurrencyGroupsSaga error", e.toString());
  }
}

function* saveCurrencyGroupSaga(action) {
  try {
    //console.log("[SAGA] saveCurrencyGroupSaga ", {data: action.data});
    yield put(setModalData({saving: true}));
    const {tenants} = store.getState();

    const postData = {
      client_id: tenants.currentTenant,
      ...action.data,
    };

    const routeName = postData.id == null ? "create" : "edit";

    const axiosMethod = ValuesSrc.currencyManager.apiRoutes.groups[routeName].method;
    const url = `${ValuesSrc.currencyManager.apiRoutes.groups[routeName].url(apiUrl)}?tenant_id=${tenants.currentTenant}`;
    const {data} = yield axiosMethod(url, postData);

    yield put(setModalStartingData({saved: true}));

    if (data.success) {
      const clearCacheMethod = ValuesSrc.currencyManager.apiRoutes.groups.clearCache.method;
      const clearCacheUrl = ValuesSrc.currencyManager.apiRoutes.groups.clearCache.url(apiUrl, tenants.currentTenant);
      yield clearCacheMethod(clearCacheUrl);

      evBus.emit("GENERIC_ALERT", {
        msg: `Loyalty currency group ${postData.id == null ? "created successfully." : "updated."}`,
      });
    } else {
      evBus.emit("GENERIC_ALERT", {
        msg: 'Loyalty currency group failed to save',
        severity: "error",
      });
      console.error("[SAGA] saveCurrencyGroupSaga - Loyalty currency group failed to save", {data});
    }
  } catch (e) {
    console.log("[SAGA] saveCurrencyGroupSaga error", e.toString());
    yield put(setModalData({saving: false}));
  }
}

function* saveCurrencySaga(action) {
  try {
    //console.log("[SAGA] saveCurrencySaga ", {data: action.data});
    yield put(setModalData({saving: true}));
    const {tenants} = store.getState();

    const postData = {
      client_id: tenants.currentTenant,
      ...action.data,
    };

    const routeName = postData.id == null ? "create" : "edit";

    const axiosMethod = ValuesSrc.currencyManager.apiRoutes.currencies[routeName].method;
    const url = `${ValuesSrc.currencyManager.apiRoutes.currencies[routeName].url(apiUrl)}?tenant_id=${tenants.currentTenant}`;
    const {data} = yield axiosMethod(url, postData);

    yield put(setModalStartingData({saved: true}));

    if (data.success) {
      const clearCacheMethod = ValuesSrc.currencyManager.apiRoutes.groups.clearCache.method;
      const clearCacheUrl = ValuesSrc.currencyManager.apiRoutes.groups.clearCache.url(apiUrl, tenants.currentTenant);
      yield clearCacheMethod(clearCacheUrl);

      evBus.emit("GENERIC_ALERT", {
        msg: `Loyalty currency ${postData.id == null ? "created successfully." : "updated."}`,
      });
    } else {
      evBus.emit("GENERIC_ALERT", {
        msg: 'Loyalty currency failed to save',
        severity: "error",
      });
      console.error("[SAGA] saveCurrencySaga - Loyalty currency failed to save", {data});
    }
  } catch (e) {
    console.log("[SAGA] saveCurrencySaga error", e.toString());
    yield put(setModalData({saving: false}));
  }
}

function* loadCampaignGroupsSaga(action) {
  try {

    const {tenants} = store.getState();

    const postData = {
      client_id: tenants.currentTenant,
      // limit: 1,
      // page_id: 0,
    };

    // console.log("[SAGA] loadCampaignGroupsSaga ", {data: action.data});

    if (action.data) {
      if (action.data.id) {
        postData.filters = [{
          id: 0,
          column: "id",
          predicate: "=",
          values: parseInt(action.data.id),
        }];
      }
    }

    const axiosMethod = ValuesSrc.campaignManager.apiRoutes.groups.listAll.method;
    const url = ValuesSrc.campaignManager.apiRoutes.groups.listAll.url(apiUrl, tenants.currentTenant);
    // console.log("[SAGA] loadCampaignGroupsSaga Table request ", {axiosMethod, url, postData});
    const {data} = yield axiosMethod(url, postData);

    // console.log("[SAGA] loadCampaignGroupsSaga response", data);
    yield put(loadedCampaignGroups(data));
  } catch (e) {
    console.log("[SAGA] loadCampaignGroupsSaga error", e.toString());
  }
}

function* saveCampaignGroupSaga(action) {
  try {
    // console.log("[SAGA] saveCampaignGroupSaga ", {data: action.data});
    yield put(setModalData({saving: true}));
    const {tenants} = store.getState();

    const postData = {
      clientId: tenants.currentTenant,
      client_id: tenants.currentTenant,
      ...action.data,
    };
    // console.log("[SAGA] saveCampaignGroupSaga ", {postData})

    const routeName = postData.id == null ? "create" : "edit";

    const axiosMethod = ValuesSrc.campaignManager.apiRoutes.groups[routeName].method;
    const url = ValuesSrc.campaignManager.apiRoutes.groups[routeName].url(apiUrl);
    const {data} = yield axiosMethod(url, postData);

    // console.log("[SAGA] loadCampaignGroupsSaga response", data);
    yield put(setModalStartingData({saved: true}));

    if (data.success) {
      const clearCacheMethod = ValuesSrc.campaignManager.apiRoutes.groups.clearCache.method;
      const clearCacheUrl = ValuesSrc.campaignManager.apiRoutes.groups.clearCache.url(apiUrl, tenants.currentTenant);
      yield clearCacheMethod(clearCacheUrl);

      evBus.emit("GENERIC_ALERT", {
        msg: `Loyalty shop campaign group ${postData.id == null ? "created successfully." : "updated."}`,
      });
    } else {
      evBus.emit("GENERIC_ALERT", {
        msg: 'Loyalty shop campaign group failed to save',
        severity: "error",
      });
      console.error("[SAGA] saveCampaignGroupSaga - Loyalty shop campaign group failed to save", {data});
    }
  } catch (e) {
    console.log("[SAGA] saveCampaignGroupSaga error", e.toString());
    yield put(setModalData({saving: false}));
  }
}

function* loadCampaignsSaga(action) {
  try {

    const {tenants} = store.getState();

    const postData = {
      client_id: tenants.currentTenant,
      // limit: 1,
      // page_id: 0,
    };

    // console.log("[SAGA] loadCampaignsSaga ", {data: action.data});

    if (action.data) {
      if (action.data.id) {
        postData.filters = [{
          id: 0,
          column: "id",
          predicate: "=",
          values: parseInt(action.data.id),
        }];
      }
    }

    const axiosMethod = ValuesSrc.campaignManager.apiRoutes.campaigns.listAll.method;
    const url = ValuesSrc.campaignManager.apiRoutes.campaigns.listAll.url(apiUrl, tenants.currentTenant);
    // console.log("[SAGA] loadCampaignsSaga Table request ", {axiosMethod, url, postData});
    const {data} = yield axiosMethod(url, postData);

    // console.log("[SAGA] loadCampaignsSaga response", data);
    yield put(loadedCampaigns(data));
  } catch (e) {
    console.log("[SAGA] loadCampaignsSaga error", e.toString());
  }
}

function* createCampaignSaga(action) {
  try {
    //console.log("[SAGA] createCampaignSaga ", {data: action.data});
    yield put(setModalData({saving: true}));
    const {tenants} = store.getState();

    const postData = {
      clientId: tenants.currentTenant,
      client_id: tenants.currentTenant,
      ...action.data,
    };

    const axiosMethod = ValuesSrc.campaignManager.apiRoutes.campaigns.create.method;
    const url = ValuesSrc.campaignManager.apiRoutes.campaigns.create.url(apiUrl);
    const {data} = yield axiosMethod(url, postData);

    // console.log("[SAGA] createCampaignSaga response", data);
    yield put(setModalStartingData({saved: true}));

    if (data.success) {
      const clearCacheMethod = ValuesSrc.campaignManager.apiRoutes.groups.clearCache.method;
      const clearCacheUrl = ValuesSrc.campaignManager.apiRoutes.groups.clearCache.url(apiUrl, tenants.currentTenant);
      yield clearCacheMethod(clearCacheUrl);

      evBus.emit("GENERIC_ALERT", {
        msg: "Loyalty shop campaign created successfully",
      });
    } else {
      evBus.emit("GENERIC_ALERT", {
        msg: 'Loyalty shop campaign failed to save',
        severity: "error",
      });
      console.error("[SAGA] createCampaignSaga - Loyalty shop campaign failed to save", {data});
    }
  } catch (e) {
    console.log("[SAGA] createCampaignSaga error", e.toString());
    yield put(setModalData({saving: false}));
  }
}

function* loadRankSystemsSaga(action) {
  try {

    const {tenants} = store.getState();
    let groupId;
    let routeName = "listAll";

    const postData = {
      client_id: tenants.currentTenant,
      // limit: 1,
      // page_id: 0,
    };

    // console.log("[SAGA] loadRankSystemsSaga ", {data: action.data});

    if (action.data) {
      if (action.data.id) {
        postData.filters = [{
          id: 0,
          column: "id",
          predicate: "=",
          values: parseInt(action.data.id),
        }];
        groupId = parseInt(action.data.id);
        routeName = "fetch";
      }
    }

    const axiosMethod = ValuesSrc.rankSystems.apiRoutes.groups[routeName].method;
    const url = ValuesSrc.rankSystems.apiRoutes.groups[routeName].url(apiUrl, tenants.currentTenant, groupId);
    // console.log("[SAGA] loadRankSystemsSaga Table request ", {axiosMethod, url, postData});
    const {data} = yield axiosMethod(url, postData);

    // console.log("[SAGA] loadRankSystemsSaga response", data);
    yield put(loadedRankSystems(data));
  } catch (e) {
    console.log("[SAGA] loadRankSystemsSaga error", e.toString());
  }
}

function* saveRankCampaignGroupSaga(action) {
  try {
    console.log("[SAGA] saveRankCampaignGroupSaga ", {data: action.data});
    yield put(setModalData({saving: true}));
    const {tenants} = store.getState();

    const postData = {
      clientId: tenants.currentTenant,
      client_id: tenants.currentTenant,
      ...action.data,
    };
    // console.log("[SAGA] saveRankCampaignGroupSaga ", {postData})

    const routeName = postData.id == null ? "create" : "edit";

    const axiosMethod = ValuesSrc.rankSystems.apiRoutes.groups[routeName].method;
    const url = ValuesSrc.rankSystems.apiRoutes.groups[routeName].url(apiUrl);
    const {data} = yield axiosMethod(url, postData);

    // console.log("[SAGA] saveRankCampaignGroupSaga response", data);
    yield put(setModalStartingData({saved: true}));
    if (data.success) {

      const clearCacheMethod = ValuesSrc.rankSystems.apiRoutes.groups.clearCache.method;
      const clearCacheUrl = ValuesSrc.rankSystems.apiRoutes.groups.clearCache.url(apiUrl, tenants.currentTenant);
      yield clearCacheMethod(clearCacheUrl);

      evBus.emit("GENERIC_ALERT", {
        msg: `Loyalty Rank campaign group ${postData.id == null ? "created successfully." : "updated."}`,
      });
    } else {
      evBus.emit("GENERIC_ALERT", {
        msg: 'Loyalty Rank campaign group failed to save',
        severity: "error",
      });
      console.error("[SAGA] saveRankCampaignGroupSaga - Loyalty Rank campaign group failed to save", {data});
    }
  } catch (e) {
    console.error("[SAGA] saveRankCampaignGroupSaga error", e.toString());
    yield put(setModalData({saving: false}));
  }
}

function* loadRankCampaignsSaga(action) {
  try {

    const {tenants} = store.getState();

    const postData = {
      client_id: tenants.currentTenant,
      // limit: 1,
      // page_id: 0,
    };

    // console.log("[SAGA] loadRanCampaignsSaga ", {data: action.data});

    if (action.data) {
      if (action.data.id) {
        postData.filters = [{
          id: 0,
          column: "id",
          predicate: "=",
          values: parseInt(action.data.id),
        }];
      }
    }

    const axiosMethod = ValuesSrc.rankSystems.apiRoutes.campaigns.listAll.method;
    const url = ValuesSrc.rankSystems.apiRoutes.campaigns.listAll.url(apiUrl, tenants.currentTenant);
    // console.log("[SAGA] loadRanCampaignsSaga Table request ", {axiosMethod, url, postData});
    const {data} = yield axiosMethod(url, postData);

    // console.log("[SAGA] loadRanCampaignsSaga response", data);
    yield put(loadedRankCampaigns(data));
  } catch (e) {
    console.log("[SAGA] loadRanCampaignsSaga error", e.toString());
  }
}

function* createRankCampaignSaga(action) {
  try {
    //console.log("[SAGA] createRankCampaignSaga ", {data: action.data});
    yield put(setModalData({saving: true}));
    const {tenants} = store.getState();

    const postData = {
      clientId: tenants.currentTenant,
      client_id: tenants.currentTenant,
      ...action.data,
    };

    const axiosMethod = ValuesSrc.rankSystems.apiRoutes.campaigns.create.method;
    const url = ValuesSrc.rankSystems.apiRoutes.campaigns.create.url(apiUrl);
    const {data} = yield axiosMethod(url, postData);

    // console.log("[SAGA] createRankCampaignSaga response", data);
    yield put(setModalStartingData({saved: true}));
    if (data.success) {

      const clearCacheMethod = ValuesSrc.rankSystems.apiRoutes.groups.clearCache.method;
      const clearCacheUrl = ValuesSrc.rankSystems.apiRoutes.groups.clearCache.url(apiUrl, tenants.currentTenant);
      yield clearCacheMethod(clearCacheUrl);

      evBus.emit("GENERIC_ALERT", {
        msg: "Loyalty rank campaign created successfully",
      });
    } else {
      evBus.emit("GENERIC_ALERT", {
        msg: 'Loyalty Rank campaign failed to save',
        severity: "error",
      });
      console.error("[SAGA] createRankCampaignSaga - Loyalty Rank campaign failed to save", {data});
    }
  } catch (e) {
    console.log("[SAGA] createRankCampaignSaga error", e.toString());
    yield put(setModalData({saving: false}));
  }
}

function* createLoyaltyMissionSaga(action) {
  try {
    //console.log("[SAGA] createLoyaltyMissionSaga ", {data: action.data});
    yield put(setModalData({saving: true}));
    const {tenants} = store.getState();

    const postData = {
      clientId: tenants.currentTenant,
      client_id: tenants.currentTenant,
      ...action.data,
    };

    const axiosMethod = ValuesSrc.missions.apiRoutes.missions.create.method;
    const url = ValuesSrc.missions.apiRoutes.missions.create.url(apiUrl);
    const {data} = yield axiosMethod(url, postData);

    // console.log("[SAGA] createLoyaltyMissionSaga response", data);
    yield put(setModalStartingData({saved: true}));
    if (data.success) {
      evBus.emit("GENERIC_ALERT", {
        msg: "Loyalty Mission created successfully",
      });
    } else {
      evBus.emit("GENERIC_ALERT", {
        msg: 'Loyalty Mission failed to save',
        severity: "error",
      });
      console.error("[SAGA] createLoyaltyMissionSaga - Loyalty Mission failed to save", {data});
    }
  } catch (e) {
    console.log("[SAGA] createLoyaltyMissionSaga error", e.toString());
    yield put(setModalData({saving: false}));
  }
}

function* loadLoyaltyMissionsSaga(action) {
  try {

    const {tenants} = store.getState();

    const postData = {
      client_id: tenants.currentTenant,
      // limit: 1,
      // page_id: 0,
      filters: [
        {
          id: 0,
          column: "client_id",
          predicate: "=",
          values: tenants.currentTenant,
        },
        {
          id: 1,
          column: "type",
          predicate: "in",
          values: `${ValuesSrc.missions.typeIds.loyaltyMission},${ValuesSrc.missions.typeIds.rankMission}`,
        },
      ],
    };

    // console.log("[SAGA] loadLoyaltyMissionsSaga ", {data: action.data});

    if (action.data) {
      if (action.data.id) {
        postData.filters.push({
          id: 2,
          column: "id",
          predicate: "=",
          values: parseInt(action.data.id),
        });
      }
    }

    const axiosMethod = ValuesSrc.missions.apiRoutes.missions.listAll.method;
    const url = ValuesSrc.missions.apiRoutes.missions.listAll.url(apiUrl, tenants.currentTenant);
    // console.log("[SAGA] loadLoyaltyMissionsSaga Table request ", {axiosMethod, url, postData});
    const {data} = yield axiosMethod(url, postData);

    // console.log("[SAGA] loadLoyaltyMissionsSaga response", data);
    yield put(loadedLoyaltyMissions(data));
  } catch (e) {
    console.log("[SAGA] loadLoyaltyMissionsSaga error", e.toString());
  }
}

function* createLoyaltyObjectiveSaga(action) {
  try {
    //console.log("[SAGA] createLoyaltyObjectiveSaga ", {data: action.data});
    yield put(setModalData({saving: true}));
    const {tenants} = store.getState();

    const postData = {
      client_id: tenants.currentTenant,
      ...action.data,
    };

    const axiosMethod = ValuesSrc.missions.apiRoutes.objectives.create.method;
    const url = ValuesSrc.missions.apiRoutes.objectives.create.url(apiUrl, tenants.currentTenant);
    const {data} = yield axiosMethod(url, postData);

    // console.log("[SAGA] createLoyaltyObjectiveSaga response", data);
    yield put(setModalStartingData({saved: true}));
    if (data.success) {
      evBus.emit("GENERIC_ALERT", {
        msg: "Loyalty Objective created successfully",
      });
    } else {
      evBus.emit("GENERIC_ALERT", {
        msg: 'Loyalty Objective failed to save',
        severity: "error",
      });
      console.error("[SAGA] createLoyaltyObjectiveSaga - Loyalty Objective failed to save", {data});
    }
  } catch (e) {
    console.log("[SAGA] createLoyaltyObjectiveSaga error", e.toString());
    yield put(setModalData({saving: false}));
  }
}

function* cloneEntitySaga(action) {
  try {

    yield put(setDialogData({saving: true}));
    const {tenants} = store.getState();

    const postData = {
      clientId: tenants.currentTenant,
      client_id: tenants.currentTenant,
      // limit: 1,
      // page_id: 0,
    };

    console.log("[SAGA] cloneEntitySaga ", {data: action.data});

    const entityType = action.data.loyaltyType;
    let url = null;
    let axiosMethod = null;
    switch (entityType) {
      case loyaltyConstants.loyaltyTypes.currencyGroup:
        axiosMethod = ValuesSrc.currencyManager.apiRoutes.groups.clone.method;
        url = ValuesSrc.currencyManager.apiRoutes.groups.clone.url(apiUrl);
        break;
      case loyaltyConstants.loyaltyTypes.currency:
        axiosMethod = ValuesSrc.currencyManager.apiRoutes.currencies.clone.method;
        url = ValuesSrc.currencyManager.apiRoutes.currencies.clone.url(apiUrl);
        break;
      case loyaltyConstants.loyaltyTypes.campaignGroup:
        axiosMethod = ValuesSrc.campaignManager.apiRoutes.groups.clone.method;
        url = ValuesSrc.campaignManager.apiRoutes.groups.clone.url(apiUrl);
        break;
      case loyaltyConstants.loyaltyTypes.campaign:
        axiosMethod = ValuesSrc.campaignManager.apiRoutes.campaigns.clone.method;
        url = ValuesSrc.campaignManager.apiRoutes.campaigns.clone.url(apiUrl);
        break;
      case loyaltyConstants.loyaltyTypes.rankCampaignGroup:
        axiosMethod = ValuesSrc.rankSystems.apiRoutes.groups.clone.method;
        url = ValuesSrc.rankSystems.apiRoutes.groups.clone.url(apiUrl);
        break;
      case loyaltyConstants.loyaltyTypes.rankCampaign:
        axiosMethod = ValuesSrc.rankSystems.apiRoutes.campaigns.clone.method;
        url = ValuesSrc.rankSystems.apiRoutes.campaigns.clone.url(apiUrl);
        break;
      case loyaltyConstants.loyaltyTypes.mission:
        axiosMethod = ValuesSrc.missions.apiRoutes.missions.clone.method;
        url = ValuesSrc.missions.apiRoutes.missions.clone.url(apiUrl);
        break;
      case loyaltyConstants.loyaltyTypes.objective:
        axiosMethod = ValuesSrc.missions.apiRoutes.objectives.clone.method;
        url = ValuesSrc.missions.apiRoutes.objectives.clone.url(apiUrl);
        break;
      default:
        break;
    }

    if (url == null || !url || axiosMethod == null || action.data.entity == null || action.data.entity.id == null) {
      return;
    }
    postData.id = action.data.entity.id;

    const {data} = yield axiosMethod(url, postData);
    console.log("[SAGA] cloneEntitySaga response", data);

    yield put(setModalStartingData({saved: true}));

    evBus.emit("GENERIC_ALERT", {
      msg: "Loyalty entity cloned successfully",
    });

    yield put(setDialogData({saving: true}));
    yield put(hideDialog());
  } catch (e) {
    console.log("[SAGA] cloneEntitySaga error", e.toString());
  }
}

export default function* watchLoyaltySaga() {
  //console.log("[SAGA] watching loyalty");
  yield takeEvery(loyaltyConstants.LOAD_CURRENCY_TOTAL_COUNT, loadCurrencyTotalCountSaga);
  yield takeEvery(loyaltyConstants.LOAD_CURRENCIES, loadCurrenciesSaga);
  yield takeEvery(loyaltyConstants.LOAD_CURRENCY_GROUPS, loadCurrencyGroupsSaga);
  yield takeEvery(loyaltyConstants.SAVE_CURRENCY_GROUP, saveCurrencyGroupSaga);
  yield takeEvery(loyaltyConstants.SAVE_CURRENCY, saveCurrencySaga);
  yield takeEvery(loyaltyConstants.LOAD_CAMPAIGN_GROUPS, loadCampaignGroupsSaga);
  yield takeEvery(loyaltyConstants.LOAD_CAMPAIGNS, loadCampaignsSaga);
  yield takeEvery(loyaltyConstants.CREATE_CAMPAIGN, createCampaignSaga);
  yield takeEvery(loyaltyConstants.SAVE_CAMPAIGN_GROUP, saveCampaignGroupSaga);
  yield takeEvery(loyaltyConstants.LOAD_RANK_SYSTEMS, loadRankSystemsSaga);
  yield takeEvery(loyaltyConstants.SAVE_RANK_CAMPAIGN_GROUP, saveRankCampaignGroupSaga);
  yield takeEvery(loyaltyConstants.LOAD_RANK_CAMPAIGNS, loadRankCampaignsSaga);
  yield takeEvery(loyaltyConstants.CREATE_RANK_CAMPAIGN, createRankCampaignSaga);
  yield takeEvery(loyaltyConstants.CREATE_LOYALTY_MISSION, createLoyaltyMissionSaga);
  yield takeEvery(loyaltyConstants.LOAD_LOYALTY_MISSIONS, loadLoyaltyMissionsSaga);
  yield takeEvery(loyaltyConstants.CREATE_LOYALTY_OBJECTIVE, createLoyaltyObjectiveSaga);
  yield takeEvery(loyaltyConstants.CLONE_ENTITY, cloneEntitySaga);
}
