import React, { PureComponent, createRef } from 'react';

export class ClickOutsideWrapper extends PureComponent {
  constructor() {
    super(...arguments);
    this.myRef = createRef();
    this.state = {
      hasEventListener: false,
    };
    this.onOutsideClick = (event) => {
      const domNode = this.myRef.current;
      if (!domNode || !domNode.contains(event.target)) {
        this.props.onClick();
      }
    };
  }
  componentDidMount() {
    this.props.parent.addEventListener('click', this.onOutsideClick, this.props.useCapture);
    if (this.props.includeButtonPress) {
      // Use keyup since keydown already has an event listener on window
      this.props.parent.addEventListener('keyup', this.onOutsideClick, this.props.useCapture);
    }
  }
  componentWillUnmount() {
    this.props.parent.removeEventListener('click', this.onOutsideClick, this.props.useCapture);
    if (this.props.includeButtonPress) {
      this.props.parent.removeEventListener('keyup', this.onOutsideClick, this.props.useCapture);
    }
  }
  render() {
    return <div ref={this.myRef}>{this.props.children}</div>;
  }
}

ClickOutsideWrapper.defaultProps = {
  includeButtonPress: true,
  parent: window,
  useCapture: false,
};