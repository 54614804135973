import { produce } from "immer";
import { pagesLinksConstants } from "../constants";

const initialState = {
  links: [],
};

const pagesLinksCache = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case pagesLinksConstants.LOADED: {

        if (typeof action.data.links !== "undefined") draft.links = action.data.links;

        break;
      }
      case pagesLinksConstants.CLEAR: {
        draft.links = [];
        break;
      }
      default:
        /* noop */
        break;
    }
  });

export default pagesLinksCache;