import {produce} from "immer";
import {tableSearchFilterConstants as Constants} from "../constants";

const initialState = {
  searchField: null,
  searchFields: [],
  newSearchPending: true,
  searchValue: "",
  originalSearchValue: "",
  useSearch: false,
  sentFirstRequest: false,
  lastRequestSent: false,
  makeQuickSearch: false,
  quickSearchResults: [],
  quickSearchResultsTotalCount: 0,
  quickSearchValue: "",
  lastVisitedResult: null,
  newFilterSearchPending: false,
  filtersLastSearch: [],
  editViewsModal: {
    saving: false,
    viewName: null,
    filters: null,
    default: null,
    success: null,
    error: null,
  },
  presets: [],
  selectedViewName: null,
  incomingSelectedView: {
    viewName: null,
    doSearch: false,
  },
};

const tableSearchFilterReducer = (state = initialState, action) => {
  return produce(state, draft => {
    if (draft == null) return;
    switch (action.type) {
      case Constants.SET: {
        // console.log("REDUCER tableSearchFilterReducer SET", action.data, draft.originalSearchValue);
        draft.searchField = action.data.searchField;
        draft.searchFields = action.data.searchFields;
        draft.originalSearchValue = action.data.originalSearchValue;
        draft.newSearchPending = true;
        draft.searchValue = draft.originalSearchValue;
        draft.useSearch = false;
        break;
      }
      case Constants.CLEAR: {
        // console.log("REDUCER tableSearchFilterReducer CLEAR");
        draft.searchField = null;
        draft.searchFields = [];
        draft.newSearchPending = true;
        draft.searchValue = "";
        draft.originalSearchValue = "";
        draft.useSearch = false;
        draft.sentFirstRequest = false;
        draft.lastRequestSent = false;
        draft.makeQuickSearch = false;
        draft.quickSearchResults = [];
        draft.quickSearchResultsTotalCount = 0;
        draft.quickSearchValue = "";
        draft.lastVisitedResult = null;
        draft.filtersLastSearch = [];
        draft.editViewsModal = {
          saving: false,
          viewName: null,
          filters: null,
          default: null,
          success: null,
          error: null,
        };
        draft.presets = [];
        draft.selectedViewName = null;
        draft.incomingSelectedView = {
          viewName: null,
          doSearch: false,
        };
        break;
      }
      case Constants.SET_SEARCH_VALUE: {
        // console.log("REDUCER tableSearchFilterReducer SET_SEARCH_VALUE", action.data);
        draft.searchValue = action.data.searchValue;
        draft.newSearchPending = true;
        break;
      }
      case Constants.DO_SEARCH: {
        // console.log("REDUCER tableSearchFilterReducer DO_SEARCH", action.data);
        let value = draft.searchValue;
        if (typeof action.data.searchValue !== "undefined") {
          value = action.data.searchValue;
        }
        draft.originalSearchValue = value;
        draft.newSearchPending = false;
        draft.searchValue = draft.originalSearchValue;
        draft.useSearch = true;
        draft.sentFirstRequest = true;
        draft.lastRequestSent = false;
        draft.makeQuickSearch = false;
        draft.quickSearchResults = [];
        draft.quickSearchResultsTotalCount = 0;
        draft.quickSearchValue = "";
        break;
      }
      case Constants.LAST_REQUEST_SENT: {
        // console.log("REDUCER LAST_REQUEST_SENT");
        const data = typeof action.data !== "undefined" ? action.data : null;
        if (data == null) {
          draft.sentFirstRequest = true;
          draft.lastRequestSent = true;
        } else {
          draft.sentFirstRequest = data.sentFirstRequest == null ? true : data.sentFirstRequest;
          draft.lastRequestSent = data.lastRequestSent == null ? true : data.lastRequestSent;
        }
        break;
      }
      case Constants.SET_NEW_SEARCH_PENDING: {
        // console.log("REDUCER tableSearchFilterReducer SET_NEW_SEARCH_PENDING", action.data);
        draft.newSearchPending = action.data.newSearchPending;
        break;
      }
      case Constants.DO_QUICK_SEARCH: {
        // console.log("REDUCER tableSearchFilterReducer DO_QUICK_SEARCH", action.data);
        draft.makeQuickSearch = true;
        draft.quickSearchValue = action.data.searchValue;
        break;
      }
      case Constants.SET_QUICK_SEARCH_RESULTS: {
        // console.log("REDUCER tableSearchFilterReducer SET_QUICK_SEARCH_RESULTS", action.data);
        draft.quickSearchResults = action.data.results;
        draft.quickSearchResultsTotalCount = action.data.totalCount;
        break;
      }
      case Constants.SET_SEARCH_LAST_VISITED_RESULT: {
        // console.log("REDUCER tableSearchFilterReducer SET_QUICK_SEARCH_RESULTS", action.data);
        draft.lastVisitedResult = action.data.lastVisitedResult;
        break;
      }
      case Constants.FILTER_DO_NEW_SEARCH: {
        // console.log("REDUCER tableSearchFilterReducer FILTER_DO_NEW_SEARCH", action.data);
        draft.newFilterSearchPending = action.data.newFilterSearchPending;
        break;
      }
      case Constants.SAVE_FILTER_LAST_SEARCH: {
        // console.log("REDUCER tableSearchFilterReducer SAVE_FILTER_LAST_SEARCH", action.data);
        draft.filtersLastSearch = action.data.filters;
        break;
      }
      case Constants.STORED_FILTER_PRESET_DEFINITION: {
        // console.log("REDUCER tableSearchFilterReducer STORED_FILTER_PRESET_DEFINITION", action.data);
        draft.editViewsModal = {
          ...draft.editViewsModal,
          success: action.data.success,
          error: action.data.error,
        };
        break;
      }
      case Constants.CLEAR_FILTER_PRESET_DEFINITION_MODAL: {
        // console.log("REDUCER tableSearchFilterReducer CLEAR_FILTER_PRESET_DEFINITION_MODAL", action.data);
        draft.editViewsModal = {
          saving: false,
          viewName: null,
          filters: null,
          default: null,
          success: null,
          error: null,
        };
        break;
      }
      case Constants.SET_ALL_FILTER_PRESET_DEFINITIONS: {
        // console.log("REDUCER tableSearchFilterReducer SET_ALL_FILTER_PRESET_DEFINITIONS", action.data);
        draft.presets = action.data.presets;
        break;
      }
      case Constants.EDIT_FILTER_PRESET_DEFINITION: {
        // console.log("REDUCER tableSearchFilterReducer EDIT_FILTER_PRESET_DEFINITION", action.data);
        draft.editViewsModal = {
          saving: false,
          success: null,
          error: null,
          ...action.data,
        };
        break;
      }
      case Constants.SET_FILTER_SEARCH_ACTIVE_PRESET: {
        // console.log("REDUCER tableSearchFilterReducer SET_FILTER_SEARCH_ACTIVE_PRESET", action.data);
        draft.selectedViewName = action.data.preset && action.data.preset.viewName ? action.data.preset.viewName : null;
        break;
      }
      case Constants.SET_FILTER_INCOMING_ACTIVE_PRESET: {
        // console.log("REDUCER tableSearchFilterReducer SET_FILTER_INCOMING_ACTIVE_PRESET", action.data);
        const name = action.data.preset && action.data.preset.viewName ? action.data.preset.viewName : null;
        draft.incomingSelectedView = {
          viewName: name,
          doSearch: !name ? false : (action.data.doSearch ? action.data.doSearch : false),
        };
        break;
      }
      default:
        break;
    }
  });
};

export default tableSearchFilterReducer;