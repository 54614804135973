import store from "../store";
import { hasAclAccess } from "./acl";

const DEBUG = false;

export const flags = {
  USER_MANAGEMENT: 1 << 0,
  CASINO_MANAGEMENT: 1 << 1,
  BETS_MANAGEMENT: 1 << 2,
  NSOFT_MANAGEMENT: 1 << 3,
  BONUS_MANAGEMENT: 1 << 4,
  HAPPY_HOUR_MANAGEMENT: 1 << 5,
  LOTTO_MANAGEMENT: 1 << 6,
  VIRTUALS_MANAGEMENT: 1 << 7,
  LOGS_MANAGEMENT: 1 << 8,
  MESSAGES_MANAGEMENT: 1 << 9,
  MAINTENANCE_MANAGEMENT: 1 << 10,
  WINNER_FUN: 1 << 11,
  PLAYERS_MANAGEMENT: 1 << 12,
  ALL_BONUSES_MANAGEMENT: 1 << 13,
  WINNER_FUN_TEAMS: 1 << 14,
  LOBBY_MANAGEMENT: 1 << 15,
  AUDIT_MANAGEMENT: 1 << 16,
  CASINO_GAME_MANAGEMENT: 1 << 17,
  OPTIMOVE_MANAGEMENT: 1 << 18,
  WHEEL_MANAGEMENT: 1 << 19,
  WHEEL_STATS: 1 << 20,
  PROMO_MANAGEMENT: 1 << 21,
  PROMO_STATS: 1 << 22,
  LANDING_PAGES_MANAGEMENT: 1 << 23,
  MISSIONS_MANAGEMENT: 1 << 24,
  EVOLUTION_MANAGEMENT: 1 << 25,
  RETAIL_TICKETS_MANAGEMENT: 1 << 26,
  PLAYER_PROFILE: 1 << 27,
  PAYMENTS_MANAGEMENT: 1 << 28,
};

export const flagsStr = {
  [flags.USER_MANAGEMENT]: "Users",
  [flags.CASINO_MANAGEMENT]: "Casino",
  [flags.BETS_MANAGEMENT]: "Bets",
  [flags.NSOFT_MANAGEMENT]: "NSoft",
  [flags.BONUS_MANAGEMENT]: "Bets Bonus",
  [flags.HAPPY_HOUR_MANAGEMENT]: "Happy Hour",
  [flags.LOTTO_MANAGEMENT]: "Lotto",
  [flags.VIRTUALS_MANAGEMENT]: "Virtuals",
  [flags.LOGS_MANAGEMENT]: "Logs",
  [flags.MESSAGES_MANAGEMENT]: "Messages",
  [flags.MAINTENANCE_MANAGEMENT]: "Maintenance",
  [flags.WINNER_FUN]: "Winner Fun",
  [flags.PLAYERS_MANAGEMENT]: "Players",
  [flags.ALL_BONUSES_MANAGEMENT]: "All Bonuses",
  [flags.WINNER_FUN_TEAMS]: "Winner fun teams",
  [flags.LOBBY_MANAGEMENT]: "Lobby",
  [flags.AUDIT_MANAGEMENT]: "Audit",
  [flags.CASINO_GAME_MANAGEMENT]: "Casino Game management",
  [flags.OPTIMOVE_MANAGEMENT]: "Optimove",
  [flags.WHEEL_MANAGEMENT]: "Wheel management",
  [flags.WHEEL_STATS]: "Wheel stats",
  [flags.PROMO_MANAGEMENT]: "Promo management",
  [flags.PROMO_STATS]: "Promo stats",
  [flags.LANDING_PAGES_MANAGEMENT]: "Landing Pages",
  [flags.MISSIONS_MANAGEMENT]: "Missions & Tournaments",
  [flags.EVOLUTION_MANAGEMENT]: "Evolution",
  [flags.RETAIL_TICKETS_MANAGEMENT]: "Retail Tickets",
  [flags.PLAYER_PROFILE]: "Player Profile",
  [flags.PAYMENTS_MANAGEMENT]: "Payments",
};

export const hasAccess = flag => {
  const state = store.getState();

  const uf = state.auth.details.flags || 0;

  if (uf === 0) {
    return false;
  }

  if (!(uf & flag)) {
    return false;
  }

  return true;
};

export const permissionsList = dbFlags => {
  let permissions = typeof dbFlags === "string" ? parseInt(dbFlags, 10) : dbFlags;
  permissions = permissions || 0;

  const list = [
    flags.USER_MANAGEMENT,
    flags.CASINO_MANAGEMENT,
    flags.BETS_MANAGEMENT,
    flags.NSOFT_MANAGEMENT,
    flags.BONUS_MANAGEMENT,
    flags.HAPPY_HOUR_MANAGEMENT,
    flags.LOTTO_MANAGEMENT,
    flags.VIRTUALS_MANAGEMENT,
    flags.LOGS_MANAGEMENT,
    flags.MESSAGES_MANAGEMENT,
    flags.MAINTENANCE_MANAGEMENT,
    flags.WINNER_FUN,
    flags.PLAYERS_MANAGEMENT,
    flags.ALL_BONUSES_MANAGEMENT,
    flags.WINNER_FUN_TEAMS,
    flags.LOBBY_MANAGEMENT,
    flags.AUDIT_MANAGEMENT,
    flags.CASINO_GAME_MANAGEMENT,
    flags.OPTIMOVE_MANAGEMENT,
    flags.WHEEL_MANAGEMENT,
    flags.WHEEL_STATS,
    flags.PROMO_MANAGEMENT,
    flags.PROMO_STATS,
    flags.LANDING_PAGES_MANAGEMENT,
    flags.MISSIONS_MANAGEMENT,
    flags.EVOLUTION_MANAGEMENT,
    flags.RETAIL_TICKETS_MANAGEMENT,
    flags.PLAYER_PROFILE,
    flags.PAYMENTS_MANAGEMENT,
  ];

  const res = [];
  list.forEach(p => {
    if (p & permissions) {
      res.push({
        label: flagsStr[p],
        id: p,
      });
    }
  });
  return res;
};

export const permissionsListFull = () => {
  const state = store.getState();
  const uf = state.auth.details.flags || 0;
  return permissionsList(uf);
};

export const permissionsListToValue = strList => {
  let res = 0;
  let list = strList.split(",").map(p => parseInt(p, 10));

  list.forEach(p => {
    res = res | p;
  });
  return res;
};

/**
 * NEW ACL IMPLEMENTATION
 */

export const accessPages = {
  HOME: "Home",

  RISK_PERFORMANCE_ALERTS: "risk_performance_alerts",
  RISK_PERFORMANCE_AUDIT_LOGS: "risk_performance_audit_logs",
  RISK_PERFORMANCE_DUE_DILIGENCE: "risk_performance_due_diligence",
  RISK_PERFORMANCE_DOCUMENTS: "risk_performance_documents",
  RISK_PERFORMANCE_CLEAR_CLOSED_ACCOUNTS: "risk_performance_clear_closed_accounts",
  // RISK_PERFORMANCE_WALLETS: "risk_performance_wallets",  // replaced with customer account wallets

  BULK_TOOLS_DEDUCTION: "bulk_tools_deduction",
  BULK_TOOLS_GRANT_BONUS: "bulk_tools_grant_bonus",
  BULK_TOOLS_GRANT_PLAYER_TAGS: "bulk_tools_grant_player_tags",
  PLAYER_AFFILIATE_INFO: "player_affiliate_info",
  // ACL_TENANTS: "acl_tenant",
  // ACL_API_TOKEN: "",
  // ACL_ENTITY_GROUP: "",
  // ACL_USER: "",
  // ACL_USER_GROUP: "",
  // ACL_PERMISSION: "",
  // ACL_ROLE: "",
  // ACL_RULE :"",

  CUSTOMER_ACCOUNTS_PLAYER_SEARCH: "customer_accounts_player_search",
  // TODO: player search submeniu:
  CUSTOMER_ACCOUNTS_BONUS_SEARCH: "customer_accounts_bonus_search",
  CUSTOMER_ACCOUNTS_OVERVIEW: "customer_accounts_overview",
  CUSTOMER_ACCOUNTS_TIMELINE: "customer_accounts_timeline",
  CUSTOMER_ACCOUNTS_PLAYER_ACCOUNT_MANAGEMENT: "customer_accounts_player_account_management",
  CUSTOMER_ACCOUNTS_PLAYER_TAGS: "customer_accounts_player_tags",
  CUSTOMER_ACCOUNTS_PLAYER_TAG_HISTORY: "customer_accounts_player_tag_history",
  CUSTOMER_ACCOUNTS_RESTRICTIONS_LIMITS: "customer_accounts_restrictions_limits",
  CUSTOMER_ACCOUNTS_FRAUD: "customer_accounts_fraud",
  CUSTOMER_ACCOUNTS_GAMES_ACTIVITY: "customer_accounts_games_activity",
  CUSTOMER_ACCOUNTS_GAMES_ACTIVITY_SPORTBETS: "customer_accounts_games_activity_sportbets",
  CUSTOMER_ACCOUNTS_GAMES_ACTIVITY_FREEBETS: "customer_accounts_games_activity_freebets",
  CUSTOMER_ACCOUNTS_GAMES_ACTIVITY_CASINO: "customer_accounts_games_activity_casino",
  CUSTOMER_ACCOUNTS_GAMES_ACTIVITY_LIVECASINO: "customer_accounts_games_activity_livecasino",
  CUSTOMER_ACCOUNTS_GAMES_ACTIVITY_LOTTO: "customer_accounts_games_activity_lotto",
  CUSTOMER_ACCOUNTS_GAMES_ACTIVITY_VIRTUALGAMES: "customer_accounts_games_activity_virtualgames",
  CUSTOMER_ACCOUNTS_AFFILIATE_INFO: "customer_accounts_affiliate_info",
  CUSTOMER_ACCOUNTS_PLAYER_DEVICES: "customer_accounts_player_devices",
  CUSTOMER_ACCOUNTS_PLAYER_MISSIONS: "customer_accounts_player_missions",
  CUSTOMER_ACCOUNTS_LOGIN_HISTORY: "customer_accounts_login_history",
  CUSTOMER_ACCOUNTS_PLAYER_ACCOUNT_STATUS_HISTORY: "customer_accounts_player_account_status_history",

  CUSTOMER_ACCOUNTS_PRIZES: "customer_accounts_prizes",
  CUSTOMER_ACCOUNTS_DOCUMENTS: "customer_accounts_documents",
  CUSTOMER_ACCOUNTS_CARDS: "customer_accounts_cards",
  CUSTOMER_ACCOUNTS_PLAYER_COMMENTS: "customer_accounts_player_comments",
  CUSTOMER_ACCOUNTS_WALLETS: "customer_accounts_wallets",
  CUSTOMER_ACCOUNTS_VOUCHERS: "customer_accounts_vouchers",
  CUSTOMER_ACCOUNTS_LOYALITY: "customer_accounts_loyality",
  CUSTOMER_ACCOUNTS_INBOX: "customer_accounts_inbox",

  //
  CUSTOMER_ACCOUNTS_PARTIAL_ACCOUNTS: "customer_accounts_partial_accounts",
  CUSTOMER_ACCOUNTS_MANAGE_SOCIAL_NUMBER: "customer_accounts_manage_social_number",
  CUSTOMER_ACCOUNTS_TRANSACTIONS_SEARCH: "customer_accounts_transactions_search",

  FINANCIAL_IN_OUT_SEARCH: "financial_in_out_payment_search",
  FINANCIAL_IN_OUT_TAX_REPORT: "financial_in_out_tax_report",
  FINANCIAL_IN_OUT_WINNER_FUN_REPORT: "financial_in_out_winner_fun_report",
  FINANCIAL_IN_OUT_WITHDRAW_ACTIONS: "financial_in_out_withdraw_actions",

  //PAYMENTS_MANAGEMENT: "Payments Management",
  PAYMENTS_BRIDGERPAY_PAYMENT_MTHODS_ORDER_LIST: "bridgerpay_payment_methods_order",

  // RETAIL_RETAIL_TICKETS: "retail_tickets_list",
  // RETAIL_RETAIL_TICKETS_SETTINGS: "retail_tickets_settings",
  RETAIL_TICKETS_MANAGEMENT: "retail_tickets_management",

  EVOLUTION_CAMPAIGNS: "evolution_campaigns",
  // EVOLUTION_VOUCHERS: "evolution_vouchers",

  PLAYERS_PLAYER_NICKNAMES: "players_nicknames",
  PLAYERS_AVATARS: "players_avatars",
  PLAYERS_AVATARS_DEFINITIONS: "players_avatars_definitions",
  PLAYERS_SOCIAL_MANAGEMENT: "players_social_management",

  MISSIONS_MISSIONS_LEADERBOARD: "missions_rankings_leaderboard", // TODO - done
  MISSIONS_MISSIONS_RANKINGS: "missions_rankings",
  MISSIONS_OBJECTIVES_TEMPLATES: "missions_objectives_templates",
  MISSIONS_MISSIONS_TEMPLATES: "missions_templates",
  MISSIONS_OBJECTIVES: "missions_objectives",
  MISSIONS_MISSIONS: "missions_missions",
  // MISSIONS_TOURNAMENT_CASINO_GROUPS: "missions_tournaments_casino_groups", // replaced with tournaments_groups
  // MISSIONS_TOURNAMENT_SPORTS_GROUPS: "missions_tournaments_sports_groups", // replaced with tournaments_groups
  MISSIONS_TOURNAMENT_GROUPS: "missions_tournaments_groups",
  MISSIONS_TREASURE_MAPS: "missions_treasure_maps",

  LANDING_PAGES_MARKETING_OFFERS: "landing_pages_marketing_offers",
  LANDING_PAGES_PAGES: "landing_pages",

  PROMO_CAMPAIGN_SETTINGS: "promo_campaign_settings",
  PROMO_WINTER_PROMO: "promo_campaign_winter_promo",
  PROMO_WINTER_PRIZES: "promo_campaign_winter_prizes",

  LOBBY_DATA_SOURCES: "lobby_data_sources",
  LOBBY_PAGES: "lobby_pages",
  LOBBY_COMP_TEMPLATES: "lobby_component_templates",

  WHEEL_GENERAL_SETTINGS: "wheel_general_settings",
  WHEEL_CORE_ELIGIBILITY: "wheel_core_eligibility",
  WHEEL_PRODUCTS: "wheel_products",
  WHEEL_VALUES: "wheel_values",
  WHEEL_MULTIPLIERS: "wheel_multipliers",
  WHEEL_PUZZLES: "wheel_puzzles",
  WHEEL_PRIZES: "wheel_prizes",
  WHEEL_WHEEL_SETUPS: "wheel_wheel_setups",
  WHEEL_PLAYER_LEVELS: "wheel_player_levels",
  WHEEL_RNG_RANGES: "wheel_RNG_ranges",
  WHEEL_PLAYER_WHEEL_SPINS: "wheel_wheel_spins",
  WHEEL_AGG_WHEEL_SPINS: "wheel_agg_wheel_spins",
  WHEEL_PLAYER_KEYS: "wheel_player_keys",

  MAINTENANCE: "maintenance",

  BONUSES_CLASSES_TAGS: "bonuses_classes_tags",
  BONUSES_BONUS_PRESET: "bonuses_free_bets",
  BONUSES_ENROLLMENT_CAMPAIGNS: "bonuses_enrollment_campaigns",
  BONUSES_CAMPAIGNS: "bonuses_campaigns",
  BONUSES_BONUS_POPUPS: "bonuses_bonus_popups",
  BONUSES_BONUS_PRESET_OVERWRITES: "bonuses_bonus_preset_overwrites",
  BONUSES_VOUCHERS: "bonuses_vouchers",
  BONUSES_SELECTS: "bonuses_selects",

  OPTIMOVE_CAMPAIGN_TAGS: "optimove_campaign_tags",
  OPTIMOVE_SCHEDULED_RUNS: "optimove_scheduled_runs",
  OPTIMOVE_TAGGED_PLAYERS: "optimove_tagged_players",

  WINNER_FUN_CALENDAR: "winner_fun_calendar",
  WINNER_FUN_CALENDAR_RANKINGS: "winner_fun_calendar_rankings",
  WINNER_FUN_WIN_COINS: "winner_fun_win_coins",
  WINNER_FUN_PLAYERS: "winner_fun_players",
  WINNER_FUN_REFERRAL_SETTINGS: "winner_fun_referral_settings",
  WINNER_FUN_SUPER_BANNER: "winner_fun_super_banner",
  WINNER_FUN_CLAIM_REPORT: "winner_fun_claim_report",
  WINNER_FUN_RAF_REPORT: "winner_fun_RAF_report",
  WINNER_FUN_RANKING_SETTINGS: "winner_fun_ranking_settings",
  WINNER_FUN_TEAMS_UI: "winner_fun_teams_ui",
  WINNER_FUN_TEAMS_LIST: "winner_fun_teams_list",
  WINNER_FUN_TEAM_RANKINGS: "winner_fun_team_rankings",
  WINNER_FUN_TEAMS_RANKINGS: "winner_fun_teams_rankings",

  MESSAGES_NOTIFICATIONS_NEW_MESSAGE: "messages_and_notifications_new_messages",
  MESSAGES_NOTIFICATIONS_PLAYER_MESSAGES: "messages_and_notifications_player_messages",
  MESSAGES_NOTIFICATIONS_NOTIFICATIONS: "push_notifications",

  CASINO_SUPER_BANNER: "casino_super_banner",
  // CASINO_GAMES_BACKGROUND: "Casino _ Games Background",
  CASINO_HOMEPAGE: "bets_components",
  CASINO_GAMES: "casino_games",
  CASINO_GAMES_ATTRIBUTES: "casino_games_attributes",

  BETS_HOMEPAGE_HOMEPAGE_GROUPS: "bets_homepage_groups",
  BETS_HOMEPAGE_H_GROUPS_IMAGES: "bets_groups_images",
  BETS_HOMEPAGE_SPORTS_NAVBAR: "bets_sports_navbar",
  BETS_HOMEPAGE_LEAGUES: "bets_leagues",
  BETS_HOMEPAGE_NEWS: "bets_news",
  BETS_SPORTS: "bets_sports",
  BETS_HIGHLIGHTS: "bets_highlights",
  BETS_CALENDAR_HOT_DAYS: "bets_calendar_hot_days",
  BETS_LIVE_TOP_LEAGUE: "bets_live_top_league",
  BETS_SETTINGS: "bets_settings",
  BETS_CUSTOMIZE_NAVBAR: "bets_customize_navbar",
  BETS_MARKETS_SORTER: "bets_markets_sorter",
  UK_SETTINGS_MARKETS: "uk_settings_markets",

  NSOFT_CRESTS: "nSoft_crests",
  NSOFT_ENTITIES: "nSoft_entities",
  NSOFT_BETS: "nSoft_bets",
  NSOFT_OUTCOMES: "nSoft_outcomes",
  NSOFT_SPORT_RADAR: "nSoft_sport_radar",
  NSOFT_MATCHES: "nSoft_matches",
  NSOFT_TEAMS: "nSoft_teams",

  BETS_BONUS_TICKET_BUILDER: "bets_bonus_ticket_builder",
  BETS_BONUS_BONUS_TICKET: "bets_bonus_ticket",
  BETS_BONUS_BONUS_RULES: "bets_bonus_rules",
  BETS_BONUS_OPENED_TICKETS: "bets_bonus_opened_tickets",
  BETS_BONUS_SETTLED_TICKETS: "bets_bonus_settled_tickets",
  BETS_BONUS_LIST: "bets_bonus_lists",

  HAPPY_HOURS_DEFINITIONS: "happy_hours_definitions",
  HAPPY_HOURS_EVENTS: "happy_hours_events",
  HAPPY_HOURS_AWARDS: "happy_hours_awards",
  HAPPY_HOURS_PRIZES: "happy_hours_prizes",

  LOTTO_SUPER_BANNER: "lotto_super_banner",
  LOTTO_LOTTO_PROMOTED: "lotto_promoted",
  LOTTO_LOTTO_RASPBERRY_PI: "lotto_raspberry_pi",

  VIRTUAL_GAMES_SUPPER_BANNER: "virtual_games_super_banner",

  ERROR_LOG: "error_log",

  AUDIT_LOGS: "audit_logs",

  MEDIA_LIBRARY: "media_library",

  BONUS_EVAL: "bonus_eval",
  BETS_COMPONENTS: "bets_components",

  // USERS: "side_menu_users",

  REGISTRY: "registry",
  REGISTRY_SETTINGS: "registry_settings",

  VIVA_ACTIONS: "viva_actions",
  VIVA_CARD: "viva_card_token",
  VIVA_DEPOSITS: "viva_deposits",
  VIVA_HISTORY_CANCEL: "viva_history_cancel",
  VIVA_HISTORY_LOG: "viva_history_log",
  VIVA_CONSTANTS: "viva_constants",
  VIVA_WITHDRAWAL_LOG: "viva_withdrawal_log",
  VIVA_WITHDRAWAL: "viva_withdrawal",

  // CASHIER_SESSION: "cashier_session",
  // CASHIER_IDENTIFY: "cashier_identify",
  // CASHIER_DEPOSIT: "cashier_deposit",
  // CASHIER_ADMIN_DEPOSIT: "cashier_admin_deposit",
  // CASHIER_WITHDRAW: "cashier_withdraw",
  CASHIER: "cashier",

  AUTOMATION_TASKS: "automation_tasks",
  MULTI_LANGUAGES_LANGUAGES: "multi_languages_languages",
  MULTI_LANGUAGES_PROJECTS: "multi_languages_projects",
  MULTI_LANGUAGES_TEXTS: "multi_languages_texts",
  MULTI_LANGUAGES_TRANSLATIONS: "multi_languages_translations",

  ADMIN_NOTIFICATIONS: "admin_notifications",
  ADMIN_NOTIFICATIONS_SUBSCRIPTIONS: "admin_notifications_subscriptions",

  // TODO: add keys for core admin pages :
  // ex:  CORE_PLAYER_TAGS: "players_tags",
  // ex: CORE_GAMES_ACTIVITY: "games_activity" ...

  TEMPLATIZATION_PAGES: "templatization_pages",
  TEMPLATIZATION_PAGE_LAYOUTS: "templatization_page_layouts",
  TEMPLATIZATION_ELEMENTS: "templatization_elements",
  TEMPLATIZATION_COMPONENTS: "templatization_components",
  TEMPLATIZATION_CSS_VARIABLES: "templatization_css_variables",
  TEMPLATIZATION_SETS_COMPONENTS_DEFAULTS: "templatization_sets_components_defaults",
  TEMPLATIZATION_SETS_COMPONENTS_DATA_DEFAULTS: "templatization_sets_components_data_defaults",
  TEMPLATIZATION_SETS_PAGE_META_DEFAULTS: "templatization_sets_page_meta_defaults",
  TEMPLATIZATION_SETS_CSS_VARIABLES: "templatization_sets_css_variables",
  TEMPLATIZATION_SETS_FONTS: "templatization_sets_fonts",
  TEMPLATIZATION_SETS_PAGES: "templatization_sets_pages",
  TEMPLATIZATION_SITE_TEMPLATES: "templatization_site_templates",
  TEMPLATIZATION_DESIGN_SEED: "templatization_design_seed",
  TEMPLATIZATION_BRANDS_SITES: "templatization_brands_sites",
  TEMPLATIZATION_COMPONENTS_DATA: "templatization_components_data",
  TEMPLATIZATION_VIEWS: "templatization_views",
  TEMPLATIZATION_EDITOR_THEME_STYLES: "templatization_editor_styles_presets",
  TEMPLATIZATION_COMMON_CSS: "templatization_common_css",
  TEMPLATIZATION_DESIGN_CSS: "templatization_design_css",
  TEMPLATIZATION_POPUPS: "templatization_popups",
  TEMPLATIZATION_SETS_POPUPS: "templatization_sets_popups",
  TEMPLATIZATION_CSS_BREAK_POINTS: "templatization_css_break_points",
  TEMPLATIZATION_DEPLOYMENTS: "templatization_deployments",
  TEMPLATIZATION_DESIGN_VARIANTS: "templatization_design_variants",
  TEMPLATIZATION_WIZARD_SESSIONS: "templatization_design_seed", // TODO: rename this to a proper permission
  TEMPLATIZATION_CUSTOMIZATIONS: "templatization_customizations",

  WHITE_LABEL_CREATE_BRAND: "white_label_create_brand",
  WHITE_LABEL_CONTENT_DEPLOYMENT: "white_label_content_deployment",
  WHITE_LABEL_WIZARD_SESSIONS: "white_label_wizard_sessions",
  WHITE_LABEL_DOMAIN_ADMINISTRATION: "white_label_domain_administration",
  WHITE_LABEL_CONTENT_MANAGEMENT_DEFINITIONS: "white_label_content_management_definitions",
  WHITE_LABEL_CONTENT_MANAGEMENT_TEMPLATES: "white_label_content_management_templates",
  WHITE_LABEL_CONTENT_MANAGEMENT_OVERRIDES: "white_label_content_management_overrides",
  WHITE_LABEL_CONTENT_MANAGEMENT_DEPLOYMENTS: "white_label_content_management_deployments",

  DATA_SOURCES_DATA_ELEMENT_TEMPLATE: "data_sources_data_element_template",
  DATA_SOURCES_DATA_ELEMENT: "data_sources_data_element",
  DATA_SOURCES_DATA_SOURCE: "data_sources_data_source",

  JACKPOT_SOURCES: "jackpot_sources",
  JACKPOT_SOURCES_TOKENS: "jackpot_sources_tokens",
  JACKPOT_SOURCES_SECURITY_TOKENS: "jackpot_security_sources",
  JACKPOT_WIN_HISTORY: "jackpot_win_history",
  JACKPOT_DEFINITIONS: "jackpot_definitions",
  JACKPOT_MY_PROFILE: "jackpot_my_profile",

  AFFILIATES_POSTBACK_TRACKING: "affiliates_postback_tracking",
  AFFILIATES_POSTBACK_TRACKING_NETWORK: "affiliates_postback_tracking_network",

  DIGITAIN_PLAYERS: "digitain_players",
  DIGITAIN_TICKETS: "digitain_tickets",

  CALENDAR_CAMPAIGNS: "calendar_campaigns",
  CALENDAR_PRIZES: "calendar_prizes",
  CALENDAR_PLAYERS_CAMPAIGN_DATA: "calendar_players_campaign_data",
  CALENDAR_PLAYERS_PRIZE_DATA: "calendar_players_prize_data",
  CALENDAR_PLAYERS_OPERATIONS: "calendar_players_operations",

  AIRDROP_CAMPAIGNS: "airdrop_campaigns",
  AIRDROP_ITEMS: "airdrop_items",
  AIRDROP_PLAYERS_CAMPAIGN_DATA: "airdrop_players_campaign_data",
  AIRDROP_PLAYERS_ITEM_DATA: "airdrop_players_item_data",
  AIRDROP_PLAYERS_OPERATIONS: "airdrop_players_operations",

  ADMIN_TOOLS: "admin_tools",

  NOTIFICATION_TEMPLATE: "notification_template",

  MOMENTUM_BINDS: "momentum_binds",
  MOMENTUM_AWARDS: "momentum_awards",
  MOMENTUM_SETTINGS: "momentum_settings",

  TENANT_MAIN_SETTINGS: "tenant_main_settings",

  // Platform
  PLATFORM_ATTRIBUTES: "platform_attributes",
  PLATFORM_ART_BUNDLE_TEMPLATE: "platform_art_bundle_template",
  PLATFORM_ART_BUNDLE: "platform_art_bundle",
  PLATFORM_SCOPE_USAGE_LIMITS: "platform_scope_usage_limits",
  PLATFORM_SCOPE_USAGE_INSTANCES: "platform_scope_usage_instances",
  PLATFORM_SCOPE_USAGE_ASSOCIATIONS: "platform_scope_usage_associations",
  PLATFORM_CASINO_GAME_PROVIDER: "platform_casino_game_provider",
  PLATFORM_CASINO_GAME: "platform_casino_game",
  PLATFORM_BANNER: "platform_banner",
  PLATFORM_BANNER_TEMPLATE: "platform_banner_template",
  PLATFORM_PROMOTION: "platform_promotion",
  PLATFORM_PROMOTION_TEMPLATE: "platform_promotion_template",
  PLATFORM_TERMS_OF_SERVICE: "platform_terms_of_service",
  PLATFORM_CURRENCY: "platform_currency",
  PLATFORM_COUNTRY: "platform_country",
  PLATFORM_GEO_REGION: "platform_geo_region",
  PLATFORM_SHOP_ITEM: "platform_shop_item",
  PLATFORM_SHOP_ITEM_TEMPLATE: "platform_shop_item_template",
  PLATFORM_BONUS_PRESET: "platform_bonus_preset",
  PLATFORM_BONUS: "platform_bonus",
  PLATFORM_PRIZE_TEMPLATE: "platform_prize_template",
  PLATFORM_JACKPOT_GROUP: "platform_jackpot_group",
  // HH
  // Mission
  // Wheel
  PLATFORM_REWARD_BUNDLE: "platform_reward_bundles",

  INCOME_ACCESS_BACKUP_FILES: "income_access_backup_files",
  INCOME_ACCESS_UPLOAD_LOGS: "income_access_upload_logs",

  ONJN_REPORT: "onjn_report", // TODO: make custom permission for _signExportDocument and _uploadFile
  CHALLENGES_PLAYER_MISSIONS: "challenges_player_missions",
};

export const listAccess = page => {
  return true;
};
export const createAccess = page => {
  return true;
};
export const updateAccess = page => {
  return true;
};
export const deleteAccess = page => {
  return true;
};
export const exportAccess = page => {
  return true;
};

export const operationsAccess = (apiGroup, tenantId) => {
  // const state = store.getState();
  // const tenants = state.tenants.tenants; // [{id:1, label: "Casino"},...]

  /**
   * should check something like:
   *
   *   tenantId::apiGroup_list
   *   tenantId::apiGroup_create
   *   tenantId::apiGroup_update
   *   tenantId::apiGroup_delete
   *   tenantId::apiGroup_[OPERATION]
   *
   * and return the permissions from below
   * */

  const defaultPermissions = {
    canList: false,
    canAdd: false,
    canUpdate: false,
    canGet: false,
    canDelete: false,
    canExport: false,
    canImport: false,

    canExportMissionTournament: false, // special op views/missions_rankings_leaderboard/mission-rankings.js and more
    canLaunch: false, // special op views/evolution/campaigns.js
    canCancel: false, // special op views/evolution/vouchers.js & views/HappyHoursEvents.js
    canAward: false, // special op mission-tournaments/missions-ranking-view.js
    canSettle: false, // special op mission-tournaments/missions-ranking-view.js
    canDisqualify: false, // special op mission-tournaments/missions-ranking-view.js
    canReset: false, // special op wheel/PlayerWheelSpins.js
    canSeeStats: false, // special op views/HappyHoursEvents.js
    canSendNotification: false, // special op views/Notifications.js
    canLock: false, // special op views/WinnerFunCalendar.js

    // more
    // canExportPromoWinterPrizes: false, // special op \src\admin_api\api\admin\promo\winter_prizes.js
    canSpinWheelSpins: false, // special op \src\admin_api\api\admin\wheel\debug.js
    canHandleWheelSpins: false,

    // special ops in GO
    canListRelated: false, // special op for missions "tenant::missions_missions_canListRelated"  TODO: check if _canListRelated or _listRelated @ \src\admin_api\api\admin\missions-tournaments\missions.js : 265

    canSearchOptimoveAction: false, // "tenant::optimove_campaign_tags_canSearchOptimoveAction"
    canSearchTag: false, // "tenant::optimove_campaign_tags_canSearchTag"
    canListRelatedEvents: false, // "tenant::winner_fun_calendar_canListRelatedEvents"
    canFetchSeasons: false, // "tenant::winner_fun_calendar_canFetchSeasons"
    canListAllSeasonRanking: false, // "tenant::winner_fun_calendar_canListAllSeasonRanking"
    canListAllEventRanking: false, // "tenant::winner_fun_calendar_canListAllEventRanking"
    canUpdatePlayerBalance: false, // "tenant::winner_fun_calendar_canUpdatePlayerBalance"
    canSettlePlayerTicket: false, //   "tenant::bets_bonus_opened_tickets_canSettlePlayerTicket" @\src\admin_api\api\admin\player_tickets.js
    canSimpleUpdate: false, // "tenant::bonuses_free_bets_simpleUpdate": [2], // @\src\admin_api\api\admin\bonuses\free_bets.js :
    // canExportPartialAccounts: false, // \src\admin_api\api\admin-core\partial-accounts\partial-accounts.js
    // payments:
    canRejectWithdraw: false, // @ \src\admin_api\api\admin-core\payments\payment.js : 26
    canApproveWithdraw: false, // @ \src\admin_api\api\admin-core\payments\payment.js : 63
    canProcessWithdraw: false, // @ \src\admin_api\api\admin-core\payments\payment.js : 102
    addPaymentComment: false, // @ \src\admin_api\api\admin-core\payments\payment.js : 219
    // canExportPayments: false, // @ \src\admin_api\api\admin-core\payments\payments.js

    canAwardPlayerBonus: false,
    canDeductWallet: false,
    canAdjustWallet: false,
    canUpdateMainWallet: false,
    canUpdateBonusWallet: false,
    canUpdateMainRingFencedWallet: false,

    // VIVA && FINANCIAL_IN_OUT_WITHDRAW_ACTIONS
    // actions
    rejectWithdrawal: false, // @ \src\admin_api\api\admin\viva\actions.js : 63; @ \src\admin_api\api\admin-core\withdrawal\withdrawal.js : 153
    approveWithdraw: false, // @ \src\admin_api\api\admin\viva\actions.js : 111; @ \src\admin_api\api\admin-core\withdrawal\withdrawal.js : 194
    withdraw: false, // @ \src\admin_api\api\admin\viva\actions.js : 157; @ \src\admin_api\api\admin-core\withdrawal\withdrawal.js : 233

    // CASHIER
    // sessions
    cashierSessionStart: false,
    cashierSessionApprovedeny: false,
    cashierSessionTerminate: false,
    canListSessions: false,
    canGetSession: false,
    // withdraws
    cashierWithdrawCheck: false,
    cashierWithdrawExecute: false,
    // deposits
    cashierIdentify: false,
    cashierDeposit: false,
    cashierAdminDeposit: false,
    // transactions
    canListTransactions: false,
    canGetTransaction: false,

    // bets bonus rules
    evaluate: false,

    // image uploads
    canSelf: false, // direct apiGroup check permission existence

    // CA Claim Prize
    canClaimPrizeByTag: false,

    // ADMIN TOOLS
    digitain: false,

    // TEMPLATIZATION
    canClone: false,
    canOverwrite: false,
    canDeploy: false,
    canDeployAll: false,

    // PLAYER NOTIFICATIONS
    canSendEmailPreview: false,

    // PLAYER DOCUMENTS
    canRequestDocument: false,
    canActivateDocument: false,
    canDeleteDocument: false,
    canUploadFile: false,
    canDeleteFile: false,
    canApproveDocument: false,
    canRejectDocument: false,
    canScanFile: false,
    canUpdatePlayerKyc: false,
    canListDruidValues: false,
    canDownloadFile: false,
  };

  const combinedLocationString = "location::" + apiGroup;

  const combinedString = "tenant::" + apiGroup;
  // check for ACL access
  if (hasAclAccess(combinedString + "_create")) {
    defaultPermissions.canAdd = true;
  }

  if (hasAclAccess(combinedString + "_list")) {
    defaultPermissions.canList = true;
  }

  if (hasAclAccess(combinedString + "_get")) {
    defaultPermissions.canGet = true;
  }

  if (hasAclAccess(combinedString + "_update")) {
    defaultPermissions.canUpdate = true;
  }

  if (hasAclAccess(combinedString + "_delete")) {
    defaultPermissions.canDelete = true;
  }

  if (hasAclAccess(combinedString + "_export")) {
    defaultPermissions.canExport = true;
  }

  if (hasAclAccess(combinedString + "_import")) {
    defaultPermissions.canImport = true;
  }

  if (hasAclAccess(combinedString + "_clone")) {
    defaultPermissions.canClone = true;
  }

  if (hasAclAccess(combinedString + "_overwrite")) {
    defaultPermissions.canOverwrite = true;
  }

  if (hasAclAccess(combinedString)) {
    defaultPermissions.canSelf = true;
  }

  // TODO: case-uri speciale
  switch (apiGroup) {
    // special op views/Notifications.js
    case "messages_and_notifications_notifications":
      if (hasAclAccess(combinedString + "_canSendNotification")) {
        defaultPermissions.canSendNotification = true;
      }
      break;
    // special op views/WinnerFunCalendar.js
    case "winner_fun_calendar":
      if (hasAclAccess(combinedString + "_canLock")) {
        defaultPermissions.canLock = true;
      }
      // /related @\src\admin_api\api\admin\winner-fun\calendar.js : 256
      if (hasAclAccess(combinedString + "_canListRelatedEvents")) {
        defaultPermissions.canListRelatedEvents = true;
      }
      //  canFetchSeasons: false, // "tenant::winner_fun_calendar_canFetchSeasons"
      if (hasAclAccess(combinedString + "_canFetchSeasons")) {
        defaultPermissions.canFetchSeasons = true;
      }
      //  canListAllSeasonRanking: false, // "tenant::winner_fun_calendar_canListAllSeasonRanking"
      if (hasAclAccess(combinedString + "_canListAllSeasonRanking")) {
        defaultPermissions.canListAllSeasonRanking = true;
      }
      //  canListAllEventRanking: false, // "tenant::winner_fun_calendar_canListAllEventRanking"
      if (hasAclAccess(combinedString + "_canListAllEventRanking")) {
        defaultPermissions.canListAllEventRanking = true;
      }

      //  canUpdatePlayerBalance: false, // "tenant::winner_fun_calendar_canUpdatePlayerBalance";
      if (hasAclAccess(combinedString + "_canUpdatePlayerBalance")) {
        defaultPermissions.canUpdatePlayerBalance = true;
      }
      break;
    // special op views/HappyHoursEvents.js
    case "happy_hours_events":
      // if (hasAclAccess(combinedString + "_canSeeStats")) {
      //   defaultPermissions.canSeeStats = true;
      // }
      if (hasAclAccess(combinedString + "_canCancel")) {
        defaultPermissions.canCancel = true;
      }
      break;
    // special op wheel/PlayerWheelSpins.js -> src\admin_api\api\admin\wheel\debug.js
    case "wheel_wheel_spins":
      if (hasAclAccess(combinedString + "_canReset")) {
        defaultPermissions.canReset = true;
      }
      if (hasAclAccess(combinedString + "_canSpinWheelSpins")) {
        defaultPermissions.canSpinWheelSpins = true;
      }

      if (hasAclAccess(combinedString + "_canHandleWheelSpins")) {
        defaultPermissions.canHandleWheelSpins = true;
      }
      break;
    // special op views/missions_rankings_leaderboard/mission-rankings.js
    case "missions_tournaments_groups":
      if (hasAclAccess(combinedString + "_canExport")) {
        defaultPermissions.canExportMissionTournament = true;
      }
      break;
    // special op mission-tournaments/missions-ranking-view.js
    case "missions_rankings_leaderboard":
      if (hasAclAccess(combinedString + "_canDisqualify")) {
        defaultPermissions.canDisqualify = true;
      }

      if (hasAclAccess(combinedString + "_canSettle")) {
        defaultPermissions.canSettle = true;
      }

      if (hasAclAccess(combinedString + "_canAward")) {
        defaultPermissions.canAward = true;
      }

      if (hasAclAccess(combinedString + "_canExport")) {
        defaultPermissions.canExport = true;
      }
      break;

    case "missions_rankings":
      if (hasAclAccess(combinedString + "_canExport")) {
        defaultPermissions.canExport = true;
      }
      break;

    // special op Promo Winter Prizes
    case "promo_campaign_winter_prizes":
      if (hasAclAccess(combinedString + "_canExportPromoWinterPrizes")) {
        defaultPermissions.canExport = true;
      }
      break;

    // special op for missions
    case "missions_missions":
      if (hasAclAccess(combinedString + "_canListRelated")) {
        defaultPermissions.canListRelated = true;
      }
      break;

    // "tenant::optimove_campaign_tags_canSearchOptimoveAction" @\src\admin_api\api\admin\optimove\optimove-actions.js : 28
    case "optimove_campaign_tags":
      if (hasAclAccess(combinedString + "_canSearchOptimoveAction")) {
        defaultPermissions.canSearchOptimoveAction = true;
      }
      // "tenant::optimove_campaign_tags_canSearchTag" @\src\admin_api\api\admin\optimove\optimove-actions.js : 62
      if (hasAclAccess(combinedString + "_canSearchTag")) {
        defaultPermissions.canSearchTag = true;
      }
      break;

    // special op for settle opened player tickets @\src\admin_api\api\admin\player_tickets.js
    case "bets_bonus_opened_tickets":
      if (hasAclAccess(combinedString + "_canSettlePlayerTicket")) {
        defaultPermissions.canSettlePlayerTicket = true;
      }
      break;

    // special op for simple update free bets // @\src\admin_api\api\admin\bonuses\free_bets.js :
    case "bonuses_free_bets":
      if (hasAclAccess(combinedString + "_simpleUpdate")) {
        defaultPermissions.canSimpleUpdate = true;
      }
      break;
    // TODO: check
    // customer accounts >> partial accunts
    // case "customer_accounts_partial_accounts":
    //   if (hasAclAccess(combinedString + "_export")) {
    //     defaultPermissions.canExportPartialAccounts = true;
    //   }
    //   break;
    // special ops for payments reject/approve withdraw @ \src\admin_api\api\admin-core\payments\payment.js
    case "financial_in_out_payment_search":
      if (hasAclAccess(combinedString + "_rejectWithdraw")) {
        defaultPermissions.canRejectWithdraw = true;
      }
      if (hasAclAccess(combinedString + "_approveWithdraw")) {
        defaultPermissions.canApproveWithdraw = true;
      }
      if (hasAclAccess(combinedString + "_processWithdraw")) {
        defaultPermissions.canProcessWithdraw = true;
      }
      if (hasAclAccess(combinedString + "_addPaymentComment")) {
        defaultPermissions.addPaymentComment = true;
      }
      break;
    // payments search
    // case "financial_in_out_payment_search":
    //   if (hasAclAccess(combinedString + "_export")) {
    //     defaultPermissions.canExportPayments = true;
    //   }
    //   break;
    // can award player bonus // @ \src\admin_api\api\admin-core\player-bonuses\player-bonuses.js
    case "customer_accounts_bonus_search":
      if (hasAclAccess(combinedString + "_canAwardPlayerBonus")) {
        defaultPermissions.canAwardPlayerBonus = true;
      }
      break;

    case "customer_accounts_wallets":
      if (hasAclAccess(combinedString + "_walletAdjustment")) {
        defaultPermissions.canAdjustWallet = true;
      }
      if (hasAclAccess(combinedString + "_walletDeduction")) {
        defaultPermissions.canDeductWallet = true;
      }

      if (hasAclAccess(combinedString + "_type_main_update")) {
        defaultPermissions.canUpdateMainWallet = true;
      }
      if (hasAclAccess(combinedString + "_type_bonus_update")) {
        defaultPermissions.canUpdateBonusWallet = true;
      }
      if (hasAclAccess(combinedString + "_type_main_ring_fenced_update")) {
        defaultPermissions.canUpdateMainRingFencedWallet = true;
      }

      break;

    // VIVA
    case "viva_actions":
    case "financial_in_out_withdraw_actions":
      if (hasAclAccess(combinedString + "_rejectWithdrawal")) {
        defaultPermissions.rejectWithdrawal = true;
      }
      if (hasAclAccess(combinedString + "_approveWithdraw")) {
        defaultPermissions.approveWithdraw = true;
      }
      if (hasAclAccess(combinedString + "_withdraw")) {
        defaultPermissions.withdraw = true;
      }
      break;
    // Bets Bonus Rules
    case "bets_bonus_rules":
      if (hasAclAccess(combinedString + "_evaluate")) {
        defaultPermissions.evaluate = true;
        break;
      }

    // cashier requires "location::" not "tenant::"
    case "cashier":
      // session
      if (hasAclAccess(combinedLocationString + "_session_list")) {
        defaultPermissions.canListSessions = true;
      }
      if (hasAclAccess(combinedLocationString + "_session_get")) {
        defaultPermissions.canGetSession = true;
      }
      if (hasAclAccess(combinedLocationString + "_session_start")) {
        defaultPermissions.cashierSessionStart = true;
      }
      if (hasAclAccess(combinedLocationString + "_session_approvedeny")) {
        defaultPermissions.cashierSessionApprovedeny = true;
      }
      if (hasAclAccess(combinedLocationString + "_session_terminate")) {
        defaultPermissions.cashierSessionTerminate = true;
      }

      // deposits
      if (hasAclAccess(combinedLocationString + "_identify")) {
        defaultPermissions.cashierIdentify = true;
      }
      if (hasAclAccess(combinedLocationString + "_deposit")) {
        defaultPermissions.cashierDeposit = true;
      }
      // withdraw
      if (hasAclAccess(combinedLocationString + "_withdraw_check")) {
        defaultPermissions.cashierWithdrawCheck = true;
      }
      if (hasAclAccess(combinedLocationString + "_withdraw_execute")) {
        defaultPermissions.cashierWithdrawExecute = true;
      }
      // transactions
      if (hasAclAccess(combinedLocationString + "_transaction_list")) {
        defaultPermissions.canListTransactions = true;
      }
      if (hasAclAccess(combinedLocationString + "_transaction_get")) {
        defaultPermissions.canGetTransaction = true;
      }
      break;

    // CA claim prize
    case "customer_accounts_prizes":
      if (hasAclAccess(combinedString + "_claim_by_tag")) {
        defaultPermissions.canClaimPrizeByTag = true;
      }
      break;

    case "admin_tools":
      if (hasAclAccess(combinedString + "_digitain")) {
        defaultPermissions.digitain = true;
      }

      break;

    // TEMPLATIZATION
    case "templatization_deployments":
      if (hasAclAccess(combinedString + "_deploy")) {
        defaultPermissions.canDeploy = true;
      }
      if (hasAclAccess(combinedString + "_deploy_all")) {
        defaultPermissions.canDeployAll = true;
      }
      break;

    // PLAYER NOTIFICATIONS
    case "notification_template":
      if (hasAclAccess(combinedString + "_can_send_email_preview")) {
        defaultPermissions.canSendEmailPreview = true;
      }
      break;

    // PLAYER DOCUMENTS
    case "customer_accounts_documents":
      if (hasAclAccess(combinedString + "_request_document")) {
        defaultPermissions.canRequestDocument = true;
      }
      if (hasAclAccess(combinedString + "_activate_document")) {
        defaultPermissions.canActivateDocument = true;
      }
      if (hasAclAccess(combinedString + "_delete_document")) {
        defaultPermissions.canDeleteDocument = true;
      }
      if (hasAclAccess(combinedString + "_upload_file")) {
        defaultPermissions.canUploadFile = true;
      }
      if (hasAclAccess(combinedString + "_delete_file")) {
        defaultPermissions.canDeleteFile = true;
      }
      if (hasAclAccess(combinedString + "_approve_document")) {
        defaultPermissions.canApproveDocument = true;
      }
      if (hasAclAccess(combinedString + "_reject_document")) {
        defaultPermissions.canRejectDocument = true;
      }
      if (hasAclAccess(combinedString + "_scan_file")) {
        defaultPermissions.canScanFile = true;
      }
      if (hasAclAccess(combinedString + "_update_player_kyc")) {
        defaultPermissions.canUpdatePlayerKyc = true;
      }
      if (hasAclAccess(combinedString + "_list_druid_values")) {
        defaultPermissions.canListDruidValues = true;
      }
      break;
    // INCOME_ACCESS
    case "income_access_backup_files":
      if (hasAclAccess(combinedString + "_download_file")) {
        defaultPermissions.canDownloadFile = true;
      }
      break;
    // ... add more
    default:
    /*noop*/
  }
  if (DEBUG) console.log(`ACLs for ${apiGroup}: `, showOnlyTrue(defaultPermissions));

  return defaultPermissions;
};

const showOnlyTrue = hash => {
  const ret = {};

  hash &&
    Object.keys(hash).forEach(k => {
      if (hash[k]) ret[k] = true;
    });

  return ret;
};

export const utils = {
  noop: () =>
    new Promise((resolve, reject) => {
      if (typeof resolve === "function")
        resolve({
          data: [],
          page: 0,
          totalCount: 0,
        });
    }),
};
