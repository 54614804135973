import { takeEvery, put } from "redux-saga/effects";
import axios from "src/store/_shared/authAxios";

import { selectsConstants } from "../constants";
import { loadedSelects } from "../actions/selects";
import { loadedTagsTree } from "../actions/tags-tree";

import { buildApiUrl } from "../../utils/api";
import { accessPages, operationsAccess } from "../../utils/users";

const selectsApiUrl = buildApiUrl("/admin/bonuses/selects");
const tagsTreeApiUrl = buildApiUrl("/admin/audit_log/fetchTagsTree");

function* loadSelectsSaga(action) {
  const opAccessBonusesSelect = operationsAccess(accessPages.BONUSES_SELECTS);
  const opAccessAuditLog = operationsAccess(accessPages.AUDIT_LOGS);

  try {
    if (opAccessBonusesSelect.canList) {
      const { data } = yield axios.get(`${selectsApiUrl}`);
      yield put(loadedSelects(data));
    }
  } catch (e) {
    console.log("[SAGA] loadSelectsSaga selects error", e.toString());
  }

  try {
    if (opAccessAuditLog.canList) {
      const { data } = yield axios.get(`${tagsTreeApiUrl}`);

      yield put(loadedTagsTree(data));
    }
  } catch (e) {
    console.log("[SAGA] loadSelectsSaga tagsTree error", e.toString());
  }
}

export default function* watchSelectsSaga() {
  //console.log("[SAGA] watching select");
  yield takeEvery(selectsConstants.LOAD, loadSelectsSaga);
}
