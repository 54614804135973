import { produce } from "immer";
import { dataSourcesConstants } from "../constants";

const initialState = {
  dataSources: [],
  content: {}
};

const gamesReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case dataSourcesConstants.LOADED: {

        if (typeof action.data.dataSources !== "undefined") draft.dataSources = action.data.dataSources;

        break;
      }
      case dataSourcesConstants.LOADED_CONTENT: {

        if (typeof action.data.content !== "undefined" && typeof action.data.id !== "undefined")
          draft.content[action.data.id] = action.data.content;

        break;
      }
      case dataSourcesConstants.LOADED_CONTENTS: {

        if (typeof action.data.result !== "undefined") {
          Object.keys(action.data.result).forEach(key => {
            draft.content[key] = action.data.result[key];
          });
        }

        break;
      }
      case dataSourcesConstants.UPDATE_CONTENT: {

        if (typeof action.data.items !== "undefined" && typeof action.data.id !== "undefined") {
          if (typeof draft.content[action.data.id] !== "undefined") {
            draft.content[action.data.id] = action.data.items;
          }
        }

        break;
      }
      case dataSourcesConstants.CLEAR: {
        draft.dataSources = [];
        draft.content = {};
        break;
      }
      default:
        /* noop */
        break;
    }
  });

export default gamesReducer;