import {takeEvery, put} from "redux-saga/effects";
import axios from "src/store/_shared/authAxios";

import store from "..";
import {notificationTemplatesConstants as constants} from "../constants";
import {
  loadingValues,
  loadedValues,
  clear,
} from "../actions/notification-templates";
import {buildApiUrl} from "src/utils/api";
import evBus from "src/utils/evBus";
import { accessPages, operationsAccess } from "src/utils/users";

const apiUrl = buildApiUrl("/admin/player-notifications");

function* loadValuesSaga (action) {
  const opAccess = operationsAccess(accessPages.NOTIFICATION_TEMPLATE);

  try {
    const currentTenant = store.getState().tenants.currentTenant;

    if (opAccess.canList) {
      yield put(loadingValues());
      const {data} = yield axios.get(`${apiUrl}/values?clientId=${currentTenant}`);
      yield put(loadedValues(data));
    }
  } catch (e) {
    console.log("[SAGA] notification-templates loadValuesSaga error", e.toString());
  }
}

function* clearSaga (action) {
  yield put(clear());
}

export default function* watchNotificationTemplatesSaga () {
  //console.log("[SAGA] watching notification-templates");
  yield takeEvery(constants.LOAD_VALUES, loadValuesSaga);
  yield takeEvery(constants.CLEAR, clearSaga);
}