import { takeEvery, put } from "redux-saga/effects";
import axios from "src/store/_shared/authAxios";

import store from "..";
import { bulkDeductionGrant } from "../constants";
import { bulkDeductionGrantDone } from "../actions/bulk-deduction-grant";
import { buildApiUrl } from "../../utils/api";

const apiCoreUrl = buildApiUrl("/adminCore");

function* bulkDeductionGrantProgressSaga(action) {
  const { bulkDeductionGrant } = store.getState();

  const currentRequest = bulkDeductionGrant.currentRequest;

  if (currentRequest >= bulkDeductionGrant.requests.total) {
    return;
  }

  const requestData = bulkDeductionGrant.requests[currentRequest];
  const startTime = + new Date();

  try {
    const { data } = yield axios.post(`${apiCoreUrl}/player/wallets/walletDeduction`, requestData);
    const endTime = + new Date();
    yield put(bulkDeductionGrantDone({ currentRequest: currentRequest, success: true, ellapsedTimePerRequest: endTime - startTime, response: data }));
  } catch (e) {
    console.log(e.toString());

    const endTime = + new Date();

    let message = e.toString();
    if (e && e.response && e.response.data && e.response.data.error && e.response.data.error.response) {
      const errorCode = e.response.data.error.response.ResponseCode;
      const errorMessage = e.response.data.error.response.ResponseMessage;
      if (errorCode || errorMessage) {
        message = `${errorMessage}${errorCode ? ` (ErrorCode: ${errorCode})` : ''}`;
      }
    }

    yield put(bulkDeductionGrantDone({ currentRequest: currentRequest, success: false, message: message, ellapsedTimePerRequest: endTime - startTime }));
  }
}

export default function* watchBulkDeductionGrantSaga() {
  yield takeEvery(bulkDeductionGrant.PROGRESS, bulkDeductionGrantProgressSaga);
}
