import { takeEvery, put } from "redux-saga/effects";
import axios from "src/store/_shared/authAxios";
// eslint-disable-next-line
import store from "../../store";
import { casinoConstants } from "../constants";
import { doneCasinoAuthentication } from "../actions/casino";
import { buildApiUrl } from "../../utils/api";

const apiUrl = buildApiUrl("/admin");

function* authStartSaga(action) {
  try {
    const { data } = yield axios.post(`${apiUrl}/core-proxy/app_authentication`);
    //console.log("got data", data);
    yield put(doneCasinoAuthentication(data));
  } catch (e) {
    console.log(e.toString());
  }
}

export default function* watchAuthSaga() {
  //console.log("[SAGA] watching casino");
  //yield takeEvery(casinoConstants.INIT, authStartSaga);
}
