import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { DropzoneArea } from "material-ui-dropzone";
import TextField from "@mui/material/TextField";
import axios from "src/store/_shared/authAxios";

import evBus from "../utils/evBus";
import { buildApiUrl } from "../utils/api";
import { operationsAccess } from "../utils/users";
const apiUrl = buildApiUrl("/admin");

const useStyles = makeStyles({
  oh: {
    overflow: "hidden",
    outline: "none",
  },
  input: {
    width: "100%",
  },
});

const UploadTC = props => {
  const classes = useStyles();

  const { open, onUpload, value, tenantId, presetId, onClose } = props;

  const [dialogState, setDialogState] = React.useState({
    files: null,
  });

  const [fileLink, setFileLink] = React.useState("");
  const imageRef = React.useRef("");

  const handleFileChange = files => {
    setDialogState(v => ({
      ...v,
      files,
    }));
  };

  const opAccess = operationsAccess("upload_tc");

  React.useEffect(() => {
    if (fileLink && imageRef.current !== fileLink) {
      onUpload({ fileLink: fileLink });
      imageRef.current = fileLink;
    }
  }, [fileLink, onUpload]);

  const doUpload = () => {
    if (!opAccess.canSelf) return;
    if (dialogState.files && dialogState.files.length) {
      const formData = new FormData();
      formData.append("myFile", dialogState.files[0]);

      axios
        .post(`${apiUrl}/upload-tc/tc${tenantId ? `?tenant_id=${tenantId}&presetId=${presetId}` : ""}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          if (response && response.data) {
            setFileLink(response.data.fileLink);
            onClose();

            evBus.emit("GENERIC_ALERT", {
              msg: "File uploaded",
            });
          }
        })
        .finally(() => {
          setDialogState({
            files: null,
          });
        });
    }
  };

  const handleChange = event => {
    setFileLink(event.target.value);
  };

  if (!open) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="md"
      scroll="body"
      aria-labelledby="upload-dialog"
      aria-describedby="upload-dialog-body"
    >
      <DialogTitle id="scroll-dialog-title">Upload pdf</DialogTitle>
      <DialogContent dividers={true} id="upload-dialog-body" className={classes.oh}>
        <DropzoneArea
          acceptedFiles={["application/pdf"]}
          dropzoneText={"Drag and drop a pdf file here or click"}
          onChange={handleFileChange}
          maxFileSize={100000000}
        />
        <TextField
          label="Pdf Name"
          defaultValue={value}
          type="text"
          onChange={handleChange}
          className={classes.input}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>

        {opAccess.canSelf && (
          <Button onClick={doUpload} color="secondary" disabled={!dialogState.files || !dialogState.files.length}>
            Upload
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default UploadTC;
