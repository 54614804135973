// !Do not remove the Layout import
import Layout from '../@core-overwrite/layouts/VerticalLayout';

// ** Components
import CustomMenu from './components/menu/customer';
import CustomNavbar from './components/navbar';
import CustomFooter from './components/footer';

const CustomerLayout = props => {
  return <Layout
    menu={props => <CustomMenu {...props} />}
    navbar={props => <CustomNavbar {...props} />}
    footer={props => <CustomFooter {...props} />}
    {...props}
  >
    {props.children}
  </Layout>;
};

export default CustomerLayout
