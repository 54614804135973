import {playerHistoryConstants} from '../constants';

export const loadPlayerHistory = (data) => ({
  type: playerHistoryConstants.LOAD,
  data
});

export const loadedPlayerHistory = (data, error) => ({
  type: playerHistoryConstants.LOADED,
  data: data,
  error: error
});

export const selectTransactionTypes = (data) => ({
  type: playerHistoryConstants.SELECT_TRANSACTION_TYPES,
  data
});

export const toggleLoadPlayerHistory = (data) => ({
  type: playerHistoryConstants.TOGGLE_LOAD,
  data: data
});

export const changedStartDate = (data) => ({
  type: playerHistoryConstants.CHANGED_START_DATE,
  data: data
});

export const updateStartDate = (data) => ({
  type: playerHistoryConstants.UPDATE_START_DATE,
  data: data
});

export const changedEndDate = (data) => ({
  type: playerHistoryConstants.CHANGED_END_DATE,
  data: data
});

export const updateEndDate = (data) => ({
  type: playerHistoryConstants.UPDATE_END_DATE,
  data: data
});

export const loadPlayerHistoryGameSessionRounds = (data) => ({
  type: playerHistoryConstants.LOAD_GAME_SESSION_ROUNDS,
  data
});

export const loadedPlayerHistoryGameSessionRounds = (data, error) => ({
  type: playerHistoryConstants.LOADED_GAME_SESSION_ROUNDS,
  data: data,
  error: error
});

export const toggleLoadGameSessionRoundsPlayerHistory = (data) => ({
  type: playerHistoryConstants.TOGGLE_LOAD_GAME_SESSION_ROUNDS,
  data: data
});

export const loadProviderWithdrawStatuses = (data) => ({
  type: playerHistoryConstants.LOAD_PROVIDER_WITHDRAW_STATUSES,
  data
});

export const loadedProviderWithdrawStatuses = (data, error) => ({
  type: playerHistoryConstants.LOADED_PROVIDER_WITHDRAW_STATUSES,
  data: data,
  error: error,
});

export const loadOktoTransactionStatuses = (data) => ({
  type: playerHistoryConstants.LOAD_OKTO_TRANSACTION_STATUSES,
  data,
});

export const loadedOktoTransactionStatuses = (data, error) => ({
  type: playerHistoryConstants.LOADED_OKTO_TRANSACTION_STATUSES,
  data: data,
  error: error,
});

export const loadSkrillTransactionStatuses = (data) => ({
  type: playerHistoryConstants.LOAD_SKRILL_TRANSACTION_STATUSES,
  data,
});

export const loadedSkrillTransactionStatuses = (data, error) => ({
  type: playerHistoryConstants.LOADED_SKRILL_TRANSACTION_STATUSES,
  data: data,
  error: error,
});

export const loadAircashTransactionStatuses = (data) => ({
  type: playerHistoryConstants.LOAD_AIRCASH_TRANSACTION_STATUSES,
  data,
});

export const loadedAircashTransactionStatuses = (data, error) => ({
  type: playerHistoryConstants.LOADED_AIRCASH_TRANSACTION_STATUSES,
  data: data,
  error: error,
});
