import { produce } from "immer";
import { themeConstants } from "../constants";

const initialState = {
  darkMode: localStorage.getItem("skin") ? JSON.parse(localStorage.getItem("skin")) : "",
};

const themeReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case themeConstants.SET_DARK_MODE: {
        draft.darkMode = action.value;
        break;
      }
      default:
        /* noop */
        break;
    }
  });

export default themeReducer;
