import { produce } from "immer";
import { selectsMarketingEventsConstants } from "../constants";

const initialState = {
  events: [],
  eventsDefaults: [],
  postbackMethods: [],
  networks: [],
};

const selectsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case selectsMarketingEventsConstants.LOADED: {

        if (typeof action.data.events !== "undefined") draft.events = action.data.events;
        if (typeof action.data.eventsDefaults !== "undefined") draft.eventsDefaults = action.data.eventsDefaults;
        if (typeof action.data.postbackMethods !== "undefined") draft.postbackMethods = action.data.postbackMethods;
        if (typeof action.data.networks !== "undefined") draft.networks = action.data.networks;

        break;
      }
      default:
        /* noop */
        break;
    }
  });

export default selectsReducer;