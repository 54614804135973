// !Do not remove the Layout import
import Layout from '../@core-overwrite/layouts/VerticalLayout';

// ** Components
import CustomMenu from './components/menu';
import CustomNavbar from './components/navbar';
import CustomFooter from './components/footer';

const VerticalLayout = props => {

  return <Layout
    menu={props => <CustomMenu {...props} />}
    navbar={navbarProps => <CustomNavbar {...navbarProps} routerProps={props.routerProps} />}
    footer={props => <CustomFooter {...props} />}
    {...props}
  >
    {props.children}
  </Layout>;
};

export default VerticalLayout
