import React from "react";
import VirtualizedSelect, { components } from "react-select";
import AsyncVirtualizedSelect from "react-select/async";

import AlertCircleFullIcon from "../assets/alert-circle-full";

const DropdownIndicator = () => {
  return (
    <svg className="virtual-select-indicator" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.025 7.275L9.525 11.775C9.375 11.925 9.225 12 9 12C8.775 12 8.625 11.925 8.475 11.775L3.975 7.275C3.675 6.975 3.675 6.525 3.975 6.225C4.275 5.925 4.725 5.925 5.025 6.225L9 10.2L12.975 6.225C13.275 5.925 13.725 5.925 14.025 6.225C14.325 6.525 14.325 6.975 14.025 7.275Z"
        fill="var(--v-body-light-forms)"
      />
      <mask
        id="mask0_1190_707"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="6"
        width="12"
        height="6"
      >
        <path
          d="M14.025 7.275L9.525 11.775C9.375 11.925 9.225 12 9 12C8.775 12 8.625 11.925 8.475 11.775L3.975 7.275C3.675 6.975 3.675 6.525 3.975 6.225C4.275 5.925 4.725 5.925 5.025 6.225L9 10.2L12.975 6.225C13.275 5.925 13.725 5.925 14.025 6.225C14.325 6.525 14.325 6.975 14.025 7.275Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1190_707)"></g>
    </svg>
  );
};

const ClearIndicator = props => {
  return (
    <div className="virtual-clear-indicator" onClick={props.clearValue}>
      <svg width="8" height="8" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3 3.63878L0.764258 5.87452C0.680608 5.95817 0.574144 6 0.444867 6C0.315589 6 0.209125 5.95817 0.125475 5.87452C0.0418249 5.79087 0 5.68441 0 5.55513C0 5.42586 0.0418249 5.31939 0.125475 5.23574L2.36122 3L0.125475 0.764258C0.0418249 0.680608 0 0.574144 0 0.444867C0 0.315589 0.0418249 0.209125 0.125475 0.125475C0.209125 0.0418249 0.315589 0 0.444867 0C0.574144 0 0.680608 0.0418249 0.764258 0.125475L3 2.36122L5.23574 0.125475C5.31939 0.0418249 5.42586 0 5.55513 0C5.68441 0 5.79087 0.0418249 5.87452 0.125475C5.95817 0.209125 6 0.315589 6 0.444867C6 0.574144 5.95817 0.680608 5.87452 0.764258L3.63878 3L5.87452 5.23574C5.95817 5.31939 6 5.42586 6 5.55513C6 5.68441 5.95817 5.79087 5.87452 5.87452C5.79087 5.95817 5.68441 6 5.55513 6C5.42586 6 5.31939 5.95817 5.23574 5.87452L3 3.63878Z"
          fill="var(--v-text-color-body)"
        />
      </svg>
    </div>
  );
};

const SuccessIndicator = () => {
  return (
    <svg className="virtual-select-indicator" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.8 5.78177L6.46667 13.1151C6.33333 13.2484 6.2 13.3151 6 13.3151C5.8 13.3151 5.66667 13.2484 5.53333 13.1151L2.2 9.78177C1.93333 9.5151 1.93333 9.1151 2.2 8.84844C2.46667 8.58177 2.86667 8.58177 3.13333 8.84844L6 11.7151L12.8667 4.84844C13.1333 4.58177 13.5333 4.58177 13.8 4.84844C14.0667 5.1151 14.0667 5.5151 13.8 5.78177Z"
        fill="black"
      />
      <mask
        id="mask0_2692_66102"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="4"
        width="12"
        height="10"
      >
        <path
          d="M13.8 5.78177L6.46667 13.1151C6.33333 13.2484 6.2 13.3151 6 13.3151C5.8 13.3151 5.66667 13.2484 5.53333 13.1151L2.2 9.78177C1.93333 9.5151 1.93333 9.1151 2.2 8.84844C2.46667 8.58177 2.86667 8.58177 3.13333 8.84844L6 11.7151L12.8667 4.84844C13.1333 4.58177 13.5333 4.58177 13.8 4.84844C14.0667 5.1151 14.0667 5.5151 13.8 5.78177Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2692_66102)">
        <rect y="0.648438" width="16" height="16" fill="#27A760" />
      </g>
    </svg>
  );
};

const ErrorIndicator = () => {
  return (
    <div className="virtual-error-indicator">
      <AlertCircleFullIcon size={16} />
    </div>
  );
};

const classNames = {
  clearIndicator: state => "react-select-clearIndicator",
  container: state => {
    //console.log("container-state", state);
    return `react-select-container ${state.isFocused ? "is--focused" : ""} ${state.hasValue ? "has--value" : ""} ${state.isMulti ? "is--multi" : ""
      } ${state.isDisabled ? "is--disabled" : ""} `;
  },
  control: state => {
    //console.log("control-state", state, state.getClassNames("menu", state));
    let bottomPlacement = true;
    if (state.selectProps.menuPlacement === "top") bottomPlacement = false;
    return `react-select-control ${state.isFocused ? "is--focused" : ""} ${state.menuIsOpen ? "is--menu-opened" : ""} ${state.menuIsOpen ? (!bottomPlacement ? "menu-placement-top" : "menu-placement-bottom") : ""
      }`;
  },
  dropdownIndicator: state => "react-select-dropdownIndicator",
  group: state => "react-select-group",
  groupHeading: state => "react-select-groupHeading",
  indicatorsContainer: state => "react-select-indicatorsContainer",
  indicatorSeparator: state => "react-select-indicatorSeparator",
  input: state => "react-select-input",
  loadingIndicator: state => "react-select-loadingIndicator",
  loadingMessage: state => "react-select-loadingMessage",
  menu: state => {
    //console.log("menu-state", state);
    return `react-select-menu ${state.menuPlacement === "top" ? "menu-placement-top" : "menu-placement-bottom"}`;
  },
  menuList: state => "react-select-menuList",
  menuPortal: state => "react-select-menuPortal",
  multiValue: state => "react-select-multiValue",
  multiValueLabel: state => "react-select-multiValueLabel",
  multiValueRemove: state => "react-select-multiValueRemove",
  noOptionsMessage: state => "react-select-noOptionsMessage",
  option: state =>
    `react-select-option ${state.isFocused ? "is--focused" : ""} ${state.isSelected ? "is--selected" : ""}`,
  placeholder: state => "react-select-placeholder",
  singleValue: state => "react-select-singleValue",
  valueContainer: state => "react-select-valueContainer",
};

/***
 * Pass this component to a VirtualizedSelectOption component to enable the use of custom components
 * as option label.
 * example:
 * <VirtualizedSelectOption
 *    useCustomOptionComponent={true}
 *    options={optionList.map((item) => {
 *      item.label = (
 *        <div>
 *          <span>{item.labelValue}</span>
 *          <SaveIcon size={14}>
 *        </div>
 *      );
 *      return item;
 *    })}
 * />
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Option = props => {
  return (
    <React.Fragment>
      <components.Option {...props}>{props.children}</components.Option>
    </React.Fragment>
  );
};

const VirtualizedSelectOption = props => {
  const [opened, setOpened] = React.useState(false);

  const handleClick = () => {
    setOpened(v => !v);
  };

  const handleChange = values => {
    if (typeof props.onChange !== "function") return;

    let returnKey = props.returnKey ? props.returnKey : props.valueKey ? props.valueKey : "id";

    let tmp = values;
    if (props.valueType === "array") {
      tmp = [];
      if (Array.isArray(values)) {
        values.forEach(v => {
          if (returnKey === "_asis") {
            tmp.push(v);
          } else {
            tmp.push(v[returnKey]);
          }
        });
      } else {
        if (returnKey === "_asis") {
          tmp.push(values);
        } else {
          tmp.push(values[returnKey]);
        }
      }
    } else {
      if (Array.isArray(values)) {
        tmp = [];
        values.forEach(v => {
          if (returnKey === "_asis") {
            tmp.push(v);
          } else {
            tmp.push(v[returnKey]);
          }
        });
        tmp = tmp.join(",");
      } else {
        if (returnKey === "_asis") {
          tmp = values;
        } else {
          tmp = values && values[returnKey] ? values[returnKey] : values;
        }
      }
    }

    const val = {
      target: {
        name: props.name,
        value: tmp,
      },
    };

    props.onChange(val);
  };

  const style = {
    display: "inline-block",
  };

  if (!props.children) {
    style.display = "none";
  }

  let value = props.value ? props.value : props.valueType === "array" ? [] : "";

  if (value) {
    if (Array.isArray(value)) {
      let tmp = [];
      value.forEach(v => {
        const opt = props.options.find(o => {
          if (o[props.valueKey ? props.valueKey : "id"]?.toString?.() === v?.toString?.()) return true;
          return false;
        });

        if (opt) tmp.push(opt);
      });
      value = tmp;
    } else {
      let tmp = [];
      if (props.multi) {
        value.split(",").forEach(v => {
          const opt = props.options.find(o => {
            if (o[props.valueKey ? props.valueKey : "id"]?.toString?.() === v?.toString?.()) return true;
            return false;
          });

          if (opt) tmp.push(opt);
        });
        value = tmp;
      } else {
        value = props.options.find(o => {
          if (o[props.valueKey ? props.valueKey : "id"]?.toString?.() === value?.toString?.()) return true;
          return false;
        });
      }
    }
  }

  const filterOption = (option, inputValue) => {
    if (typeof props.useCustomOptionComponent !== "undefined" && props.useCustomOptionComponent) {
      if (
        option &&
        option.data &&
        option.data[props.labelKeyForFilter ? props.labelKeyForFilter : "labelForFilter"] &&
        inputValue
      ) {
        let matches = option.data[props.labelKeyForFilter ? props.labelKeyForFilter : "labelForFilter"]
          .toLowerCase()
          .match(inputValue.replace(/[\(\)\[\]\\]/g, c => `\\${c}`).toLowerCase());
        if (matches !== null) return true;
        return false;
      }
      return true;
    } else {
      if (option && option.data && option.data[props.labelKey ? props.labelKey : "label"] && inputValue) {
        let matches = option.data[props.labelKey ? props.labelKey : "label"]
          .toLowerCase()
          .match(inputValue.replace(/[\(\)\[\]\\]/g, c => `\\${c}`).toLowerCase());
        if (matches !== null) return true;
        return false;
      }
      return true;
    }
  };

  const components = {
    ...props.components,
    DropdownIndicator,
    ClearIndicator,
  };

  if (typeof props.isSuccess === "boolean" && props.isSuccess) {
    components.DropdownIndicator = SuccessIndicator;
  }

  if (typeof props.useCustomOptionComponent !== "undefined" && props.useCustomOptionComponent) {
    components.Option = Option;
  }

  const selectProps = {
    components: components,
    className: "virtualized-select-option",
    value,
    onChange: handleChange,
    formatOptionLabel: typeof props.formatOptionLabel === "function" ? props.formatOptionLabel : option => option[props.labelKey ? props.labelKey : "label"],
    getOptionValue: option => option[props.valueKey ? props.valueKey : "id"],
    placeholder: props.placeholder ? props.placeholder : "Select...",
    simpleValue: typeof props.simpleValue !== "undefined" ? props.simpleValue : true,
    isSearchable: typeof props.searchable !== "undefined" ? props.searchable : false,
    isClearable: typeof props.clearable !== "undefined" ? props.clearable : false,
    isMulti: typeof props.multi !== "undefined" ? props.multi : false,
    isDisabled: typeof props.disabled !== "undefined" ? props.disabled : false,
    closeMenuOnSelect: typeof props.closeOnSelect !== "undefined" ? props.closeOnSelect : true,
    menuPlacement: typeof props.menuPlacement !== "undefined" ? props.menuPlacement : "auto",
    classNames: typeof props.menuClassNames !== "undefined" ? { ...classNames, ...props.menuClassNames } : classNames,
    filterOption,
    styles: props.styles ? props.styles : {},
    menuPortalTarget: props.menuPortalTarget ?? null,
    "aria-invalid": props.invalid,
    onInputChange: e => {
      if (typeof props.onInputChange === "function") {
        props.onInputChange(e);
      }
    },
    onMenuClose: () => {
      if (typeof props.onMenuClose === "function") {
        props.onMenuClose();
      }
    },
    onMenuOpen: () => {
      if (typeof props.onMenuOpen === "function") {
        props.onMenuOpen();
      }
    },
    // menuIsOpen: true,
  };

  return (
    <div
      className={`option textboxoption has-addon ${!opened ? "collapsed" : ""} ${props.className ? props.className : ""
        } ${props.invalid ? "has-error" : ""}`}
    >
      <label className={"option-content" + (props.inline === true ? " inline" : "")}>
        {!!props.label && (
          <span
            onClick={handleClick}
            className={`${props.value && props.value.length && props.def ? "has-changes" : ""}`}
          >
            {props.label}
            <i className="caret" style={style}></i>
          </span>
        )}
        <div className="control w100">
          {!props.async ? (
            <VirtualizedSelect {...selectProps} options={props.options} />
          ) : (
            <AsyncVirtualizedSelect {...selectProps} defaultOptions loadOptions={props.async} cacheOptions />
          )}
          {props.invalid && <ErrorIndicator />}
        </div>
      </label>
      <div className="children">
        {props.children}
        {props.invalid && typeof props.errorMessages !== "undefined" && props.errorMessages.length > 0 && (
          <div className="error">
            {props.errorMessages.map((e, idx) => {
              return (
                <React.Fragment key={`virtual-name-error-${idx}`}>
                  <span>{e}.</span>
                  <br />
                </React.Fragment>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default VirtualizedSelectOption;
