// ** React Imports
import { Fragment, useState, useRef } from 'react'

// ** Vertical Menu Items Array
import navigation from '@src/navigation/vertical'

// ** Third Party Components
import classnames from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar'

// ** Vertical Menu Components
import VerticalMenuHeader from '@layouts/components/menu/vertical-menu/VerticalMenuHeader'
import VerticalNavMenuItems from '@layouts/components/menu/vertical-menu/VerticalNavMenuItems'

import ClickAwayListener from '../../../../../../utils/click-away-hook';

const Sidebar = props => {
  // ** Props
  const { menuCollapsed, routerProps, menu, currentActiveItem, skin, menuHover, setMenuHover } = props

  // ** States
  const [groupOpen, setGroupOpen] = useState([])
  const [groupActive, setGroupActive] = useState([])
  const [activeItem, setActiveItem] = useState(null)

  // ** Ref
  const shadowRef = useRef(null)

  // ** Scroll Menu
  const scrollMenu = container => {
    if (shadowRef && container.scrollTop > 0) {
      if (!shadowRef.current.classList.contains('d-block')) {
        shadowRef.current.classList.add('d-block')
      }
    } else {
      if (shadowRef.current.classList.contains('d-block')) {
        shadowRef.current.classList.remove('d-block')
      }
    }
  }

  const handleClickAway = () => {
    if (menuCollapsed) props.setIsHidden(true);
  }

  return (
    <Fragment>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div
          className={classnames('main-menu menu-fixed menu-accordion menu-shadow', {
            expanded: menuHover || menuCollapsed === false,
            'menu-light': skin !== 'semi-dark' && skin !== 'dark',
            'menu-dark': skin === 'semi-dark' || skin === 'dark'
          })}
        //onMouseEnter={onMouseEnter}
        // onMouseLeave={() => {
        //   if (menuCollapsed) props.setIsHidden(true);
        // }}
        >
          {menu ? (
            menu(props)
          ) : (
            <Fragment>
              {/* Vertical Menu Header */}
              <VerticalMenuHeader setGroupOpen={setGroupOpen} menuHover={menuHover} {...props} />
              {/* Vertical Menu Header Shadow */}
              <div className='shadow-bottom' ref={shadowRef}></div>
              {/* Perfect Scrollbar */}
              <PerfectScrollbar
                className='main-menu-content'
                options={{ wheelPropagation: false }}
                onScrollY={container => scrollMenu(container)}
              >
                <ul className='navigation navigation-main'>
                  <VerticalNavMenuItems
                    items={navigation}
                    groupActive={groupActive}
                    setGroupActive={setGroupActive}
                    activeItem={activeItem}
                    setActiveItem={setActiveItem}
                    groupOpen={groupOpen}
                    setGroupOpen={setGroupOpen}
                    routerProps={routerProps}
                    menuCollapsed={menuCollapsed}
                    menuHover={menuHover}
                    currentActiveItem={currentActiveItem}
                  />
                </ul>
              </PerfectScrollbar>
            </Fragment>
          )}
        </div>
      </ClickAwayListener>
    </Fragment>
  )
}

export default Sidebar;
