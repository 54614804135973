// Libraries
import React, { PureComponent, memo } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import Tooltip from '@mui/material/Tooltip';
// import DateRangeIcon from '@mui/icons-material/DateRange';
import {ReactComponent as DateRangeIcon} from 'src/components/table-customization/MaterialTable/_icons/calendar.svg';
import Checkbox from '@mui/material/Checkbox';

import "./index.scss";

import { TimePickerContent } from './TimeRangePicker/TimePickerContent';
import { ClickOutsideWrapper } from './TimeRangePicker/ClickOutsideWrapper';

import { isDateTime, rangeUtil, dateTimeFormat, timeZoneFormatUserFriendly, dateMath, dateTimeForTimeZone, dateTimeParse } from './data';
import { otherOptions, quickOptions } from './rangeOptions';
import { getShiftedTimeRange, getZoomedTimeRange } from "./data/timePickers";

export class UnthemedTimeRangePicker extends PureComponent {
  constructor() {
    super(...arguments);
    this.state = {
      isOpen: false,
      timeZone: this.props.timeZone ? this.props.timeZone : "browser",
      initialValue: this.props.value,
    };
    this.onChange = (timeRange) => {
      const newTimeRange = { ...timeRange };
      if (typeof this.props.value.checked !== "undefined") {
        newTimeRange.checked = this.props.value.checked;
      } else {
        newTimeRange.checked = true;
      }
      this.props.onChange(newTimeRange);
      this.setState({ isOpen: false });
    };
    this.onOpen = (event) => {
      if (event.target && event.target.tagName === 'INPUT' && event.target.getAttribute('type') === 'checkbox') return;

      const { isOpen } = this.state;
      event.stopPropagation();
      event.preventDefault();
      this.setState({ isOpen: !isOpen });
    };
    this.handleDisable = (event) => {
      event.stopPropagation();
      const { value, onChange } = this.props;
      const newVal = { ...value };
      newVal.checked = event.target.checked;
      onChange(newVal);
    };
    this.onClose = () => {
      this.setState({ isOpen: false, initialValue: null });
    };
  }

  onMoveTimePicker = (direction) => {
    const { value, onChange, timeZone } = this.props;
    const { from, to } = getShiftedTimeRange(direction, value);
    const nextTimeRange = {
      from: dateTimeForTimeZone(timeZone, from),
      to: dateTimeForTimeZone(timeZone, to),
      raw: { from: dateTimeForTimeZone(timeZone, from), to: dateTimeForTimeZone(timeZone, to) },
    };

    onChange(nextTimeRange);
  };

  onMoveForward = () => this.onMoveTimePicker(1);
  onMoveBack = () => this.onMoveTimePicker(-1);

  onZoom = () => {
    const { value, onChange, timeZone } = this.props;
    const { from, to } = getZoomedTimeRange(value, 2);
    const nextTimeRange = {
      from: dateTimeForTimeZone(timeZone, from),
      to: dateTimeForTimeZone(timeZone, to),
      raw: { from: dateTimeForTimeZone(timeZone, from), to: dateTimeForTimeZone(timeZone, to) },
    };

    onChange(nextTimeRange);
  };

  onChangeTimeZone = (value) => {
    this.setState({ timeZone: value })
  }

  render() {
    const { value, timeSyncButton, isSynced, history, hideZoom, className, label, checkbox, id, placeholder } = this.props;
    const { isOpen, timeZone } = this.state;

    const hasAbsolute = value.raw && (isDateTime(value.raw.from) || isDateTime(value.raw.to));
    const syncedTimePicker = timeSyncButton && isSynced;
    const timePickerIconClass = syncedTimePicker ? 'icon-brand-gradient' : '';
    const timePickerButtonClass = `trp-btn navbar-button--tight center ${timeSyncButton ? `btn--radius-right-0 gf-utrp-noRightBorderStyle` : ''} ${syncedTimePicker ? `explore-active-button` : ''} ${checkbox ? 'hasCheckbox' : ''}`;
    const checked = typeof value.checked !== "undefined" ? value.checked : true;

    const classes = {
      popper: "MuiTooltip-popper gf-tooltip"
    }

    return (<div className={`gf-utrp-container ${className ? className : ''}`}>
      {!!label && <div className="gf-label">{label}</div>}
      <div className={`gf-utrp-buttons`}>
        {hasAbsolute && (
          <Tooltip classes={classes} arrow title="Time range shift backward">
            <button className="trp-btn navbar-button--tight" onClick={this.onMoveBack}>
              <ChevronLeftIcon />
            </button>
          </Tooltip>
        )}
        <div>
          {value.from != null && value.to != null && (
            <Tooltip classes={classes} arrow title={<TimePickerTooltip timeRange={value} timeZone={timeZone} />}>
              <button id={id} aria-label="TimePicker Open Button" className={timePickerButtonClass} onClick={this.onOpen}>
                {checkbox && <Checkbox name="bogus" checked={checked} onChange={this.handleDisable} size="small" />}
                <DateRangeIcon className={`gf-utrp-clockIcon ${timePickerIconClass}`} />
                <TimePickerButtonLabel {...this.props} />
                <span className={`gf-utrp-caretIcon`}>{isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>
              </button>
            </Tooltip>
          )}
          {(value.from == null || value.to == null) && (
            <button id={id} aria-label="TimePicker Open Button" className={timePickerButtonClass} onClick={this.onOpen}>
              {checkbox && <Checkbox name="bogus" checked={checked} onChange={this.handleDisable} size="small" />}
              <DateRangeIcon className={`gf-utrp-clockIcon ${timePickerIconClass}`} />
              <TimePickerButtonLabel {...this.props} />
              <span className={`gf-utrp-caretIcon`}>{isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>
            </button>
          )}

          {isOpen && (<ClickOutsideWrapper includeButtonPress={false} onClick={this.onClose}>
            <TimePickerContent isReversed={this.props.isReversed} timeZone={timeZone} value={value} onChange={this.onChange} otherOptions={otherOptions} quickOptions={quickOptions} history={history} showHistory onChangeTimeZone={this.onChangeTimeZone} initialValue={this.state.initialValue}/>
          </ClickOutsideWrapper>)}
        </div>

        {timeSyncButton}

        {hasAbsolute && (
          <Tooltip classes={classes} arrow title="Time range shift forward">
            <button className="trp-btn navbar-button--tight" onClick={this.onMoveForward}>
              <ChevronRightIcon style={{ paddingRight: "0" }} />
            </button>
          </Tooltip>
        )}

        {!hideZoom && <Tooltip classes={classes} arrow title="Time range zoom out">
          <button className="trp-btn navbar-button--zoom" onClick={this.onZoom}>
            <ZoomOutIcon />
          </button>
        </Tooltip>}
      </div>
    </div>);
  }
}

const TimePickerTooltip = ({ timeRange, timeZone }) => {

  const [state, setState] = React.useState({
    timeRange: timeRange,
    timeZone: timeZone,
  });

  const [dates, setDates] = React.useState({
    from: "",
    to: ""
  });

  React.useEffect(() => {
    setState(s => {
      return {
        timeRange: timeRange,
        timeZone: timeZone,
      }
    });
  }, [timeRange, timeZone]);

  const timerId = React.useRef(0);
  const unmounted = React.useRef(false);

  React.useEffect(() => {
    window.clearInterval(timerId.current);

    const calculateDates = () => {
      if (unmounted.current) return;

      let from = "";
      let to = "";

      from = dateTimeFormat(state.timeRange.from, { timeZone: state.timeZone });
      to = dateTimeFormat(state.timeRange.to, { timeZone: state.timeZone });

      if (state.timeRange.raw) {
        if (state.timeRange.raw.from && state.timeRange.raw.to && state.timeRange.raw.from !== state.timeRange.raw.to) {
          if (dateMath.isMathString(state.timeRange.raw.from) && dateMath.isMathString(state.timeRange.raw.to)) {
            from = dateTimeParse(state.timeRange.raw.from, { roundUp: false, timeZone: state.timeZone });
            from = dateTimeFormat(from, { timeZone: state.timeZone });
            to = dateTimeParse(state.timeRange.raw.to, { roundUp: false, timeZone: state.timeZone });
            to = dateTimeFormat(to, { timeZone: state.timeZone });
          }
        }
      }

      setDates({
        from,
        to,
      });
    }

    timerId.current = setInterval(calculateDates, 500);
    calculateDates();
  }, [state]);

  React.useEffect(() => {
    return () => {
      window.clearInterval(timerId.current);
      unmounted.current = true;
    };
  }, []);

  return (
    <div>
      <div className="text-center txt-monospace">{dates.from}</div>
      <div className="text-center">to</div>
      <div className="text-center txt-monospace">{dates.to}</div>
      <div className="text-center pt-3">
        <span className={"gf-tpt-utc"}>{timeZoneFormatUserFriendly(timeZone)}</span>
      </div>
    </div>
  );
};

export const TimePickerButtonLabel = memo(({ hideText, value, timeZone, placeholder }) => {
  if (hideText) {
    return null;
  }

  let text = formattedRange(value, timeZone);

  if (text.indexOf("N/A") > -1) {
    return (<span className={`gf-tpbl-container`}>
      <span className="input-placeholder">{placeholder}</span>
    </span>);
  }

  if (text.indexOf(" to ") > -1) {
    const parts = text.split(" to ", 2);
    text = <React.Fragment>
      <span className="semi-bold">{parts[0]}</span> <ArrowRightAltIcon className="gf-arrow" /> <span className="semi-bold">{parts[1]}</span>
    </React.Fragment>
  } else {
    text = <span className="semi-bold">{text}</span>;
  }


  return (<span className={`gf-tpbl-container`}>
    <span>{text}</span>
    <span className={`gf-tpbl-utc`}>{rangeUtil.describeTimeRangeAbbreviation(value, timeZone)}</span>
  </span>);
});

TimePickerButtonLabel.displayName = 'TimePickerButtonLabel';

const formattedRange = (value, timeZone) => {
  const adjustedTimeRange = {
    to: value.raw && value.raw.to && dateMath.isMathString(value.raw.to) ? value.raw.to : value.to ? value.to : "N/A",
    from: value.raw && value.raw.from && dateMath.isMathString(value.raw.from) ? value.raw.from : value.from ? value.from : "N/A",
  };
  return rangeUtil.describeTimeRange(adjustedTimeRange, timeZone);
};

export const TimeRangePicker = UnthemedTimeRangePicker;