import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { X, Search, RotateCw } from "react-feather";
import { connect } from "react-redux";
import axios from "src/store/_shared/authAxios";
import { endpoints } from "src/api/endpoints";

import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

import { Modal, ModalBody, Input, InputGroup, InputGroupText } from "reactstrap";

import DefaultTenantLogo from "./assets/default-tenant-logo.svg";

import "./TenantSelector.scss";
import { setcurrentSite, sitesLoadAll } from "src/store/actions/sites";

const toLocalStorage = val => {
  let data = localStorage.getItem("recentSitesSelected");
  if (!data) {
    data = [];
  } else {
    data = JSON.parse(data);
  }

  let filtered = data.filter(s => s.id.toString() !== val.id.toString());
  filtered.unshift(val);

  filtered = filtered.slice(0, 3);
  localStorage.setItem("recentSitesSelected", JSON.stringify(filtered));
  return filtered;
};
const fromLocalStorage = () => {
  let data = localStorage.getItem("recentSitesSelected");
  if (!data) {
    data = [];
  } else {
    data = JSON.parse(data);
  }
  return data;
};

const SitesSelector = props => {
  const { routerProps, currentTenant } = props;
  const state = useSelector(state => ({
    sites: state.sites.sites,
    currentSite: state.sites.currentSite,
  }));
  const dispatch = useDispatch();

  const [query, setQuery] = React.useState("");
  const [showSelector, setShowSelector] = React.useState(false);
  const handleShowSelector = () => {
    setShowSelector(true);
  };
  const closeSelector = () => {
    setShowSelector(false);
  };
  const handleQuery = e => {
    setQuery(e.target.value);
  };

  // const filteredSites = state.sites;
  // const queryFilteredSites = filteredSites.filter(s => {
  //   if (query) {
  //     if (s.label.toLowerCase().indexOf(query.toLowerCase()) > -1) return true;
  //     return false;
  //   }
  //   return true;
  // });

  const defOption = [{ id: "0", name: "None", label: "None", tenant_id: "0" }];
  const [filteredSites, setFilteredSites] = React.useState(defOption);
  const [queryFilteredSites, setQueryFilteredSites] = React.useState([]);

  const queryFilter = () => {
    const filtered = filteredSites.filter(s => {
      if (query) {
        if (s.label.toLowerCase().indexOf(query.toLowerCase()) > -1) return true;
        return false;
      }
      return true;
    });

    setQueryFilteredSites(filtered);
  };

  const setSelectedSite = value => {
    dispatch(setcurrentSite(value.id.toString()));
  };

  const selectSite = e => {
    if (e.currentTarget && e.currentTarget.dataset && e.currentTarget.dataset.site) {
      const data = JSON.parse(e.currentTarget.dataset.site);

      toLocalStorage(data);
      setSelectedSite(data);
      closeSelector();
    }
  };

  const loadCurrentTenantSites = () => {
    // fetch sites for current tenant
    if (currentTenant && Array.isArray(props.tenants) && props.tenants.length > 0) {
      const postData = {
        exactFields: ["tenant_id"],
        exactValues: [[`${currentTenant}`]],
      };

      try {
        console.log(`runing axios for: `, { url: endpoints.site.list, postData });

        const data = [axios.post(endpoints.site.list, postData)];

        Promise.all(data).then(values => {
          const data = values[0] && values[0].data && values[0].data.data.rows ? values[0].data.data.rows : [];

          if (data) {
            data.unshift({ id: "0", name: "None" });
            dispatch(sitesLoadAll(data));
          } else {
            dispatch(sitesLoadAll([{ name: "None", id: "0" }]));
          }
        });
      } catch (e) {
        console.error("failed to load effect sites", e);
      }
    }
  };

  const selectCurrentTenantSites = () => {
    // filter sites for current tenant

    if (state.sites.length > 0) {
      const filteredStateSites = state.sites.filter(s => parseInt(s.tenant_id, 10) === parseInt(currentTenant, 10));

      setFilteredSites([...defOption, ...filteredStateSites]);
    }
  };

  React.useEffect(() => {
    // loadCurrentTenantSites();
    selectCurrentTenantSites();
  }, [routerProps, currentTenant, props.tenants, state.sites, query]);

  React.useEffect(() => {
    queryFilter();
  }, [filteredSites]);

  const actualValue = filteredSites.find(
    s => s.id && state.currentSite && s.id.toString() === state.currentSite.toString()
  );
  if (!actualValue) {
    if (filteredSites.length === 0) return null;
  }

  const letters = {};
  const getLetter = letter => {
    if (typeof letters[letter] === "undefined") {
      letters[letter] = true;
      return letter;
    }
    return <span>&nbsp;</span>;
  };

  const recentSelection = fromLocalStorage();

  false && console.log(`DEBUG SITE SELECTOR: `, { state, queryFilteredSites, filteredSites });

  return (
    <div className="nav-bar-tenant-selector">
      <div className="nav-bar-tenant-selector-wrapper" onClick={handleShowSelector}>
        <div className="tenant-title">{actualValue ? actualValue.label : "None"}</div>
        <div className="tenant-selector">Change site</div>
      </div>
      <div className="fill">&nbsp;</div>
      <Modal
        isOpen={showSelector}
        toggle={closeSelector}
        modalClassName="tenant-selector-modal-parent"
        className="tenant-selector-modal"
      >
        <ModalBody className="p-0">
          <div className="nav-bar">
            <div>
              <div className="tenant">{actualValue ? actualValue.label : "None"}</div>
              <div className="sep"></div>
              <div className="label">Switch site</div>
              <div className="full"></div>
              <div className="close" onClick={closeSelector}>
                <X className="ficon" />
              </div>
            </div>
          </div>
          <div className="content-container">
            <div className="tenants-list">
              {recentSelection.length != 0 && (
                <div className="recent-tenant-selection">
                  <div className="recent-title">
                    <RotateCw size={10} /> Recent sites
                  </div>
                  <div className="recent-list">
                    {recentSelection.map((s, i) => {
                      return (
                        <div className="tenant" key={i} data-site={JSON.stringify(s)} onClick={selectSite}>
                          <div className="logo">
                            <img src={DefaultTenantLogo} alt="" />
                          </div>
                          <div className="fill">
                            <div className="label">{s.label}</div>
                            <div className="sublabel">www</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              <div className="query">
                <InputGroup className="input-group-merge">
                  <InputGroupText>
                    <Search size={16} />
                  </InputGroupText>
                  <Input type="text" value={query} onChange={handleQuery} placeholder="Search site" />
                </InputGroup>
              </div>
              <div className="list">
                {queryFilteredSites.map((s, i) => {
                  return (
                    <div
                      key={i}
                      className={`d-flex ${
                        actualValue && s.id.toString() === actualValue.id.toString() ? "selected" : ""
                      }`}
                    >
                      <div className="letter">{getLetter(s.name[0].toUpperCase())}</div>
                      <div className="tenant" data-site={JSON.stringify(s)} onClick={selectSite}>
                        <div className="logo">
                          <img src={DefaultTenantLogo} alt="" />
                        </div>
                        <div className="fill">
                          <div className="label">{s.label}</div>
                          <div className="sublabel">www</div>
                        </div>
                        <div className="selected">
                          {(!actualValue || s.id.toString() !== actualValue.id.toString()) && (
                            // <Circle className="ficon" size={16} />
                            <RadioButtonUncheckedIcon className="ficon" size={16} />
                          )}
                          {actualValue && s.id.toString() === actualValue.id.toString() && (
                            // <CheckCircle className="ficon checked" size={16} />
                            <RadioButtonCheckedIcon className="ficon checked" size={16} />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    tenants: state.tenants.tenants,
    currentTenant: state.tenants.currentTenant,
  };
};

const mapActions = {};

export default connect(mapStateToProps, mapActions)(SitesSelector);

// export default SitesSelector;
