import { takeEvery, put } from "redux-saga/effects";
import axios from "src/store/_shared/authAxios";

import store from "..";
import { druidConstants } from "../constants";
import { loadedDruid } from "../actions/druid";

import { buildApiUrl } from "../../utils/api";
import { accessPages, operationsAccess } from "../../utils/users";

const selectsApiUrl = buildApiUrl("/adminCore/player/documents/druid/values");

function* loadDruidSaga(action) {
  // const opAccessDruid = operationsAccess();
  const opAccessDruid = {canList: true};

  const {tenants} = store.getState();
  const currentTenant = tenants.currentTenant || 0;

  try {
    if (opAccessDruid.canList) {
      const { data } = yield axios.get(`${selectsApiUrl}?tenant_id=${currentTenant}`);
      yield put(loadedDruid(data));
    }
  } catch (e) {
    console.log("[SAGA] loadDruidSaga selects error", e.toString());
  }
}

export default function* watchDruidSaga() {
  //console.log("[SAGA] watching select");
  yield takeEvery(druidConstants.LOAD, loadDruidSaga);
}
