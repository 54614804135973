import React from 'react';
import { withRouter } from 'react-router';

import TenantTabs from '../../../../components/TenantTabs';

const TenantConfigSelector = (props) => {
  const { match } = props;

  const [hasTenantId, setHasTenantId] = React.useState(null);

  React.useEffect(() => {
    if (match.params && typeof match.params.tenantId !== "undefined") {
      setHasTenantId(match.params.tenantId)
    } else {
      setHasTenantId(null);
    }
  }, [match.params]);

  if (!hasTenantId) return null;

  return <TenantTabs tenantId={hasTenantId} />;
}

export default withRouter(TenantConfigSelector);