import React from "react";

import CustomMultiSelect from "../../../views/bonuses/components/rules/MultiSelect";

const PlayerTagField = ({forwardedRef, ...props}) => {
  // console.log("PlayerTagField props", props);

  return (
    <div className="flx full">
      <CustomMultiSelect
        value={props.value
          ? (props.columnDef.single ? [props.value] : props.value)
          : []}
        setValue={val => {
          if (props.columnDef.single) {
            val = val[val.length - 1];
          }
          props.onChange(val);
        }}
        menuPlacement="bottom"
      />
    </div>
  );
}

const BooleanFieldRef = React.forwardRef((props, ref) => {
  return <PlayerTagField {...props} forwardedRef={ref}/>;
});

export default BooleanFieldRef;