import React from "react";
import ReactDOM from "react-dom";
import { MTableToolbar } from "@material-table/core";
import { Layers, Filter, Plus, X, Save, Trash2, ArrowUp, ArrowDown, RotateCw, Edit3 } from "react-feather";

import { ReactComponent as RefreshIcon } from "src/components/table-customization/MaterialTable/_icons/refresh.svg";

import {
  DropdownMenu,
  DropdownToggle,
  ButtonDropdown,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Input,
  Button,
  UncontrolledTooltip,
  FormGroup,
  Label,
} from "reactstrap";
import { cloneDeep } from "lodash/fp";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { connect } from "react-redux";
import moment from "moment";

import { TimePickerWithHistory } from "../grafana-datetime/TimePickerWithHistory";
import { selectThemeColors } from "@utils";

import { tableFilterSortingSetData } from "../../store/actions/table-filters-sorting";
import {tableSearchSetLastRequestSent} from "../../store/actions/table-search-filter";

import "./table-filters.scss";
import { TimePickerButtonLabel } from "../grafana-datetime/TimeRangePicker";
import Utils from "../../components/react-tageditor/utils";
import { dateMath } from "../../components/grafana-datetime/data";

const loadViewsFromStorage = key => {
  const views = localStorage.getItem(`filter-views-${key}`);
  if (views) {
    return JSON.parse(views);
  }
  return [];
};
const saveViewInStorage = (key, data) => {
  let views = localStorage.getItem(`filter-views-${key}`);
  if (views) {
    views = JSON.parse(views);
  } else {
    views = [];
  }

  if (Array.isArray(views)) {
    views.push(data);
    localStorage.setItem(`filter-views-${key}`, JSON.stringify(views));
  }

  return views;
};
const removeViewInStorage = (key, id) => {
  let views = localStorage.getItem(`filter-views-${key}`);
  if (views) {
    views = JSON.parse(views);
  } else {
    views = [];
  }

  if (Array.isArray(views)) {
    const index = views.findIndex(v => v.id === id);
    if (index > -1) {
      views.splice(index, 1);
      localStorage.setItem(`filter-views-${key}`, JSON.stringify(views));
    }
  }

  return views;
};
const getViewFromStorage = (key, id) => {
  let views = localStorage.getItem(`filter-views-${key}`);
  if (views) {
    views = JSON.parse(views);
  } else {
    views = [];
  }

  if (Array.isArray(views)) {
    const index = views.findIndex(v => v.id === id);
    if (index > -1) {
      return views[index];
    }
  }

  return null;
};
const updateViewInStorage = (key, id, data) => {
  let views = localStorage.getItem(`filter-views-${key}`);
  if (views) {
    views = JSON.parse(views);
  } else {
    views = [];
  }

  if (Array.isArray(views)) {
    const index = views.findIndex(v => v.id === id);
    if (index > -1) {
      views[index] = data;
      localStorage.setItem(`filter-views-${key}`, JSON.stringify(views));
    }
  }
  return views;
};

const TableFilters = props => {
  const {
    portalId,
    filters,
    onFilterChange,
    filtersSelected,
    filtersDeleted,
    changed,
    sortBy,
    sortDirection,
    tableFilterSortingSetData,
    tableSearchSetLastRequestSent,
  } = props;

  const [saveViewMode, setViewMode] = React.useState({
    active: false,
    id: "",
    name: "",
    category: [],
  });
  const [rerender, setRerender] = React.useState(0);

  const [filterDopdown, setFilterDropdown] = React.useState(false);
  const [selectedView, setSelectedView] = React.useState("");

  const [active, setActive] = React.useState("1");

  const toggleFilterDropdown = e => {
    if (filterDopdown) {
      closeAndClearViewMode();
    }
    setActive("1");
    setSelectedView("");
    setFilterDropdown(!filterDopdown);

    if (e && e.currentTarget && e.currentTarget.dataset && e.currentTarget.dataset.index !== undefined) {
      let type = null;
      let index = null;
      try {
        index = parseInt(e.currentTarget.dataset.index);
        const filterIndex = parseInt(filtersSelected[index].filterIndex);
        type = filters[filterIndex].type;
      } catch (err) {}
      setTimeout(() => {
        if (type && index !== null) {
          try {
            if (type === "datetime") {
              document.getElementById(`input-${index}`).click();
            } else if (type === "select") {
              document.getElementById(`input-${index}`).querySelector(".select__input").focus();
            } else {
              document.getElementById(`input-${index}`).select();
            }
          } catch (err) {}
        }
      }, 10);
    }
  };

  const [sortDopdown, setSortDropdown] = React.useState(false);
  const toggleSortDropdown = () => setSortDropdown(!sortDopdown);

  const addFilterOption = () => {
    if (filtersSelected.length < filters.length) {
      const copyFilters = cloneDeep(filtersSelected);
      copyFilters.push({
        filterIndex: "-1",
        required: false,
        value: null,
        applied: false,
      });
      tableFilterSortingSetData({
        filtersSelected: copyFilters,
      });
    }
  };
  const handleFilterSelect = index => e => {
    const copyFilters = cloneDeep(filtersSelected);
    copyFilters[index].filterIndex = e.target.value;

    const filter = filters[parseInt(copyFilters[index].filterIndex)];
    copyFilters[index].value = cloneDeep(filter.value);

    if (typeof copyFilters[index].value !== "string" && filter.type === "select") {
      copyFilters[index].value = copyFilters[index].value.map(v => {
        return filter.options.find(o => o.value.toString() === v.toString());
      });

      copyFilters[index].required = filter.required ? true : false;
    }

    if (filter.type === "datetime" && filter.checked) {
      copyFilters[index].value.checked = true;
    }

    const copyFiltersDeleted = cloneDeep(filtersDeleted);
    const exists = copyFiltersDeleted.findIndex(
      v => typeof v !== "undefined" && v.toString() === filter.key.toString()
    );
    if (exists > -1) {
      copyFiltersDeleted.splice(exists, 1);
    }

    tableFilterSortingSetData({ filtersSelected: copyFilters, filtersDeleted: copyFiltersDeleted, changed: true });
  };
  const removeFilter = index => e => {
    const copyFilters = cloneDeep(filtersSelected);
    const copyFiltersDeleted = cloneDeep(filtersDeleted);

    const filterIndex = copyFilters[index].filterIndex;
    copyFilters.splice(index, 1);

    if (filterIndex.toString() !== "-1") {
      const filter = filters[parseInt(filterIndex)];

      const exists = copyFiltersDeleted.find(v => v.toString() === filter.key.toString());
      if (!exists) {
        copyFiltersDeleted.push(filter.key);
      }
    }

    tableFilterSortingSetData({ filtersSelected: copyFilters, filtersDeleted: copyFiltersDeleted, changed: true });

    return { filtersSelected: copyFilters, filtersDeleted: copyFiltersDeleted };
  };
  const removeAllFilters = () => {
    const copyFilters = cloneDeep(filtersSelected);
    const copyFiltersDeleted = cloneDeep(filtersDeleted);
    const newFilters = [];

    copyFilters.forEach((f, i) => {
      const filter = filters[parseInt(copyFilters[i].filterIndex)];

      if (!filter) return;

      if (filter.required) {
        newFilters.push(f);
        return;
      }

      const exists = copyFiltersDeleted.find(v => v.toString() === filter.key.toString());
      if (!exists) {
        copyFiltersDeleted.push(filter.key);
      }
    });

    tableFilterSortingSetData({ filtersSelected: newFilters, filtersDeleted: copyFiltersDeleted, changed: true });

    return { filtersSelected: newFilters, filtersDeleted: copyFiltersDeleted };
  };
  const removeFilterAndApply = index => () => {
    const data = removeFilter(index)();
    const updates = calculateUpdates(data.filtersSelected, data.filtersDeleted);
    onFilterChange(updates);
  };
  const clearAllFilters = pushChanges => () => {
    const data = removeAllFilters();
    if (pushChanges) {
      const updates = calculateUpdates(data.filtersSelected, data.filtersDeleted);
      onFilterChange(updates);
    }
  };
  const onDateValueChange = index => value => {
    const copyFilters = cloneDeep(filtersSelected);
    copyFilters[index].value = value;
    tableFilterSortingSetData({ filtersSelected: copyFilters, changed: true });
  };
  const onTextValueChange = index => e => {
    const copyFilters = cloneDeep(filtersSelected);
    copyFilters[index].value = e.target.value;
    tableFilterSortingSetData({ filtersSelected: copyFilters, changed: true });
  };
  const onSelectValueChange = index => e => {
    const copyFilters = cloneDeep(filtersSelected);
    copyFilters[index].value = e;
    tableFilterSortingSetData({ filtersSelected: copyFilters, changed: true });
  };
  const calculateUpdates = (filtersSelected, filtersDeleted) => {
    const updates = [];
    filtersSelected.forEach(fs => {
      let filter = null;
      if (fs.filterIndex !== "-1") {
        filter = filters[parseInt(fs.filterIndex)];
      }
      if (filter) {
        const toUpdate = {
          type: filter.type,
          value: fs.value,
          key: filter.key,
        };

        if (toUpdate.type === "datetime") {
          toUpdate.fromKey = filter.fromKey;
          toUpdate.toKey = filter.toKey;
          toUpdate.rawKey = filter.rawKey;
          toUpdate.checkedKey = filter.checkedKey;
        } else if (toUpdate.type === "select" && filter.multiple) {
          if (typeof toUpdate.value !== "string") {
            toUpdate.value = toUpdate.value.map(v => {
              return v.value;
            });
          }
        } else if (toUpdate.type === "select") {
          if (typeof toUpdate.value !== "string") {
            toUpdate.value = toUpdate.value.value;
          }
        }

        updates.push(toUpdate);
      }
    });
    filtersDeleted.forEach(key => {
      const filter = filters.find(f => f.key.toString() === key.toString());
      if (filter) {
        const emptyData = {
          type: filter.type,
          key: filter.key,
          value: "",
        };

        if (filter.type === "select" && filter.multiple) {
          emptyData.value = "";
        } else if (filter.type === "datetime") {
          emptyData.value = cloneDeep(filter.value);
          emptyData.value.checked = false;
          emptyData.fromKey = filter.fromKey;
          emptyData.toKey = filter.toKey;
          emptyData.rawKey = filter.rawKey;
          emptyData.checkedKey = filter.checkedKey;
        }

        updates.push(emptyData);
      }
    });
    return updates;
  };
  const applyAll = () => {
    const copyFilters = cloneDeep(filtersSelected);
    copyFilters.forEach(f => {
      f.applied = true;
    });
    tableFilterSortingSetData({ filtersSelected: copyFilters, changed: true });
  };
  const handleApplyFilters = () => {
    const updates = calculateUpdates(filtersSelected, filtersDeleted);
    onFilterChange(updates);
    applyAll();
    setFilterDropdown(false);
  };
  const reloadData = () => {
    onFilterChange([]);
    tableSearchSetLastRequestSent();
  };

  const deleteSort = () => {
    tableFilterSortingSetData({ sortDirection: { value: "", label: "None" } });
    const index = props.dataManager.columns.findIndex(c => {
      if (c && sortBy && c.field === sortBy.value) return true;
      return false;
    });
    if (index > -1) props.tableRef.current.onChangeOrder(index, "", 1);
    toggleSortDropdown();
  };
  const toggleSort = () => {
    if (props.tableRef && props.tableRef.current) {
      const newSortDirection = sortDirection.value === "desc" ? "asc" : "desc";
      const index = props.dataManager.columns.findIndex(c => {
        if (c && sortBy && c.field === sortBy.value) return true;
        return false;
      });
      props.tableRef.current.onChangeOrder(index, newSortDirection, 1);
      tableFilterSortingSetData({ sortDirection: newSortDirection });
    }
  };
  const deleteSortNoToggle = e => {
    e.stopPropagation();
    e.preventDefault();
    tableFilterSortingSetData({ sortDirection: { value: "", label: "None" } });
    const index = props.dataManager.columns.findIndex(c => {
      if (c && sortBy && c.field === sortBy.value) return true;
      return false;
    });
    if (index > -1) props.tableRef.current.onChangeOrder(index, "", 1);
  };
  const handleApplySort = () => {
    if (props.tableRef && props.tableRef.current) {
      // orderBy, orderDirection, sortOrder
      //orderBy: 1, sortOrder: 1, orderDirection: 'desc'
      const index = props.dataManager.columns.findIndex(c => {
        if (c && sortBy && c.field === sortBy.value) return true;
        return false;
      });
      props.tableRef.current.onChangeOrder(index, sortDirection.value, 1);
      toggleSortDropdown();
    }
  };

  const activateSaveViewMode = () => {
    setViewMode(v => ({ ...v, active: true }));
  };
  const closeAndClearViewMode = () => {
    setViewMode(v => ({
      active: false,
      id: "",
      name: "",
      category: [],
    }));
  };
  const saveAndCloseViewMode = () => {
    const view = {
      name: saveViewMode.name,
      category: saveViewMode.category,
      filters: filtersSelected,
      id: saveViewMode.id ? saveViewMode.id : Utils.uuid(),
    };

    if (saveViewMode.id) {
      updateViewInStorage(props.storageKey, saveViewMode.id, view);
    } else {
      saveViewInStorage(props.storageKey, view);
    }

    setViewMode(v => ({
      active: false,
      id: "",
      name: "",
      category: null,
    }));
  };

  const toggle = tab => {
    if (active !== tab) {
      setActive(tab);
    }
  };
  const handleSaveViewChange = e => {
    setViewMode(v => ({
      ...v,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSaveViewCategoryChange = val => {
    setViewMode(v => ({
      ...v,
      category: val,
    }));
  };
  const editView = e => {
    e.stopPropagation();

    const target = e.currentTarget;

    if (target) {
      const viewId = target.dataset.id;

      const view = getViewFromStorage(props.storageKey, viewId);

      if (view) {
        const newFilters = view.filters;
        const oldFilters = cloneDeep(filtersSelected);
        const deleteFilters = [];

        oldFilters.forEach((f, i) => {
          if (f.filter != "-1") {
            const filter = filters[parseInt(f.filterIndex)];

            if (!filter) return;

            const exists = deleteFilters.find(v => v.toString() === filter.key.toString());
            if (!exists) {
              deleteFilters.push(filter.key);
            }
          }
        });

        newFilters.forEach(fs => {
          fs.applied = true;

          let filter = null;
          if (fs.filterIndex !== "-1") {
            filter = filters[parseInt(fs.filterIndex)];
          }

          if (filter) {
            const exists = deleteFilters.findIndex(v => v.toString() === filter.key.toString());
            if (exists > -1) {
              deleteFilters.splice(exists, 1);
            }
          }

          if (typeof fs.value === "object" && typeof fs.value.from !== "undefined") {
            if (typeof fs.value.from === "string" && !dateMath.isMathString(fs.value.from)) {
              fs.value.from = moment(fs.value.from);
            }
            if (typeof fs.value.to === "string" && !dateMath.isMathString(fs.value.to)) {
              fs.value.to = moment(fs.value.to);
            }
            if (
              typeof fs.value.raw === "object" &&
              typeof fs.value.raw.from === "string" &&
              !dateMath.isMathString(fs.value.raw.from)
            ) {
              fs.value.raw.from = moment(fs.value.raw.from);
            }
            if (
              typeof fs.value.raw === "object" &&
              typeof fs.value.raw.to === "string" &&
              !dateMath.isMathString(fs.value.raw.to)
            ) {
              fs.value.raw.to = moment(fs.value.raw.to);
            }
          }
        });

        tableFilterSortingSetData({ filtersSelected: newFilters, filtersDeleted: deleteFilters, changed: true });
        setActive("1");
        setViewMode({
          active: true,
          id: view.id,
          name: view.name,
          category: view.category,
        });
      }
    }
  };
  const deleteView = e => {
    e.stopPropagation();

    const target = e.currentTarget;

    if (target) {
      const viewId = target.dataset.id;

      removeViewInStorage(props.storageKey, viewId);
      setRerender(v => v + 1);
    }
  };
  const handleApplyView = () => {
    const savedViews = loadViewsFromStorage(props.storageKey);
    const view = savedViews.find(v => v.id === selectedView);

    if (view) {
      const newFilters = view.filters;
      const oldFilters = cloneDeep(filtersSelected);
      const deleteFilters = [];

      oldFilters.forEach((f, i) => {
        if (f.filter != "-1") {
          const filter = filters[parseInt(f.filterIndex)];

          if (!filter) return;

          const exists = deleteFilters.find(v => v.toString() === filter.key.toString());
          if (!exists) {
            deleteFilters.push(filter.key);
          }
        }
      });

      newFilters.forEach(fs => {
        fs.applied = true;

        let filter = null;
        if (fs.filterIndex !== "-1") {
          filter = filters[parseInt(fs.filterIndex)];
        }

        if (filter) {
          const exists = deleteFilters.findIndex(v => v.toString() === filter.key.toString());
          if (exists > -1) {
            deleteFilters.splice(exists, 1);
          }
        }

        if (typeof fs.value === "object" && typeof fs.value.from !== "undefined") {
          if (typeof fs.value.from === "string" && !dateMath.isMathString(fs.value.from)) {
            fs.value.from = moment(fs.value.from);
          }
          if (typeof fs.value.to === "string" && !dateMath.isMathString(fs.value.to)) {
            fs.value.to = moment(fs.value.to);
          }
          if (
            typeof fs.value.raw === "object" &&
            typeof fs.value.raw.from === "string" &&
            !dateMath.isMathString(fs.value.raw.from)
          ) {
            fs.value.raw.from = moment(fs.value.raw.from);
          }
          if (
            typeof fs.value.raw === "object" &&
            typeof fs.value.raw.to === "string" &&
            !dateMath.isMathString(fs.value.raw.to)
          ) {
            fs.value.raw.to = moment(fs.value.raw.to);
          }
        }
      });

      const updates = calculateUpdates(newFilters, deleteFilters);
      onFilterChange(updates);
      tableFilterSortingSetData({ filtersSelected: newFilters, filtersDeleted: deleteFilters, changed: true });
      setActive("1");
      setFilterDropdown(false);
    }
  };
  const handleSelectedView = e => {
    setSelectedView(e.target.value);
  };

  const domNode = document.getElementById(portalId);
  if (!domNode) return null;

  const sortColumns = props.dataManager.columns.map(c => {
    if (c.sorting === false) return null;
    return {
      label: c.title,
      value: c.field,
    };
  });

  let hasFilters = 0;
  filtersSelected.forEach(f => {
    if (f.filterIndex !== "-1" && f.value) hasFilters += 1;
  });

  let hasSort = false;
  if (sortBy && sortDirection && sortDirection.value !== "") hasSort = true;

  let canClear = false;
  filtersSelected.find(f => {
    if (f.filterIndex !== "-1" && f.value) {
      const filter = filters[parseInt(f.filterIndex)];
      if (filter && !filter.required) {
        canClear = true;
        return true;
      }
    }

    return false;
  });

  const savedViews = loadViewsFromStorage(props.storageKey);
  const categoryValues = {};
  let categories = savedViews.map(sv => {
    if (typeof categoryValues[sv.category.value] !== "undefined") return null;
    categoryValues[sv.category.value] = true;
    delete sv.category.__isNew__;
    return sv.category;
  });
  categories = categories.filter(g => g !== null);
  let groups = savedViews.group(sv => {
    return sv.category.value;
  });

  return ReactDOM.createPortal(
    <div className="table-filters-wrapper">
      <div className="table-filters-holder">
        <div className="table-filters">
          <div>
            <ButtonDropdown isOpen={sortDopdown} toggle={toggleSortDropdown} direction="down">
              <DropdownToggle
                className={`filter-button btn-outline-primary btn-sm ${hasSort ? "has-values" : ""} ${
                  sortDopdown ? "btn-primary" : ""
                }`}
                color={"none"}
                caret
              >
                <Layers className="ficon filter-icon" /> Sort {hasSort && <div className="feedback">1</div>}
              </DropdownToggle>
              <DropdownMenu>
                <div className="filters-list">
                  <div className="filter-row">
                    <div className="selector">
                      <Select
                        searchable={false}
                        theme={selectThemeColors}
                        className="react-select"
                        classNamePrefix="select"
                        value={sortBy}
                        onChange={val => {
                          const index = props.dataManager.columns.findIndex(c => {
                            if (c && sortBy && c.field === sortBy.value) return true;
                            return false;
                          });

                          if (index > -1) props.dataManager.changeColumnOrder(index, "", 1);
                          tableFilterSortingSetData({ sortBy: val });
                        }}
                        isMulti={false}
                        options={sortColumns.filter(c => c !== null)}
                        hasValue={true}
                      />
                    </div>
                    <div className="full">
                      <Select
                        searchable={false}
                        theme={selectThemeColors}
                        className="react-select"
                        classNamePrefix="select"
                        value={sortDirection}
                        onChange={val => {
                          tableFilterSortingSetData({ sortDirection: val });
                        }}
                        isMulti={false}
                        options={[
                          { value: "", label: "None" },
                          { value: "asc", label: "Ascendent" },
                          { value: "desc", label: "Descendent" },
                        ]}
                        getOptionLabel={option => {
                          let arrow = null;
                          if (option.value === "asc") {
                            arrow = <ArrowUp className="ficon filter-icon" />;
                          } else if (option.value === "desc") {
                            arrow = <ArrowDown className="ficon filter-icon" />;
                          }
                          return (
                            <span>
                              {arrow} {option.label}
                            </span>
                          );
                        }}
                        hasValue={true}
                      />
                    </div>
                  </div>
                  <div className="filter-buttons mt-24">
                    <div className="action-button mr-24" onClick={deleteSort}>
                      <Trash2 className="ficon filter-icon" /> Delete sort
                    </div>
                    <div className="full">&nbsp;</div>
                    <div className="action-button primary mr-24" onClick={toggleSortDropdown}>
                      Cancel
                    </div>
                    <Button color="primary" disabled={!hasSort} onClick={handleApplySort}>
                      Sort
                    </Button>
                  </div>
                </div>
              </DropdownMenu>
            </ButtonDropdown>
            <ButtonDropdown isOpen={filterDopdown} toggle={toggleFilterDropdown} direction="down">
              <DropdownToggle
                className={`filter-button btn-outline-primary btn-sm ${hasFilters > 0 ? "has-values" : ""} ${
                  filterDopdown ? "btn-primary" : ""
                }`}
                color={"none"}
                caret
              >
                <Filter className="ficon filter-icon" /> Filter{" "}
                {hasFilters > 0 && <div className="feedback">{hasFilters}</div>}
              </DropdownToggle>
              <DropdownMenu>
                {!saveViewMode.active && (
                  <div className="d-flex align-items-center justify-content-between">
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          active={active === "1"}
                          onClick={() => {
                            toggle("1");
                          }}
                        >
                          Filter by
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          active={active === "2"}
                          onClick={() => {
                            toggle("2");
                          }}
                        >
                          Saved views
                        </NavLink>
                      </NavItem>
                    </Nav>
                    {canClear && active === "1" && (
                      <div className="action-button primary ml-24" onClick={clearAllFilters(false)}>
                        Clear all
                      </div>
                    )}
                  </div>
                )}
                {saveViewMode.active && (
                  <div className="d-flex align-items-center justify-content-between save-mode-header">
                    <div className="title">Edit view</div>
                    <div className="close">
                      <X className="ficon filter-icon" onClick={closeAndClearViewMode} />
                    </div>
                  </div>
                )}

                <TabContent className="p-50" activeTab={active}>
                  <TabPane tabId="1">
                    {saveViewMode.active && (
                      <div className="view-web-form">
                        <FormGroup>
                          <Label for="view-name">Name</Label>
                          <Input
                            type="text"
                            id="view-name"
                            placeholder={"Enter filter name"}
                            name="name"
                            value={saveViewMode.name}
                            onChange={handleSaveViewChange}
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label for="view-name">Category</Label>
                          <CreatableSelect
                            theme={selectThemeColors}
                            className="react-select"
                            classNamePrefix="select"
                            value={saveViewMode.category}
                            onChange={handleSaveViewCategoryChange}
                            isMulti={false}
                            isClearable
                            options={categories}
                            hasValue={true}
                          />
                        </FormGroup>
                      </div>
                    )}
                    {saveViewMode.active && <div className="view-filters-title">Filters applied</div>}
                    {filtersSelected.length === 0 && <div>No filter is applied to this view</div>}
                    {filtersSelected.length !== 0 && (
                      <form onSubmit={handleApplyFilters}>
                        <div className="filters-list">
                          {filtersSelected.map((o, i) => {
                            const filterIndex = o.filterIndex;
                            let filter = null;
                            if (filterIndex !== "-1") {
                              filter = filters[parseInt(filterIndex)];
                            }

                            return (
                              <div key={i} className="filter-row">
                                <div className="selector">
                                  {filterIndex.toString() === "-1" && (
                                    <Input
                                      type="select"
                                      name="selectFilter"
                                      value={filterIndex.toString()}
                                      onChange={handleFilterSelect(i)}
                                    >
                                      <option val={-1}>Select Filter</option>
                                      {filters.map((f, i) => {
                                        if (!f) return null;
                                        const exists = filtersSelected.find(t => t.filterIndex === i.toString());
                                        if (exists) return null;
                                        return (
                                          <option key={i} value={i}>
                                            {f.label}
                                          </option>
                                        );
                                      })}
                                    </Input>
                                  )}
                                  {filterIndex.toString() !== "-1" && (
                                    <Input type="text" value={filters[parseInt(filterIndex)].label} readOnly />
                                  )}
                                </div>
                                <div className="full">
                                  {filter && filter.type === "datetime" && (
                                    <TimePickerWithHistory
                                      id={`input-${i}`}
                                      value={o.value}
                                      onChange={onDateValueChange(i)}
                                      isReversed={true}
                                    />
                                  )}
                                  {filter && filter.type === "input" && (
                                    <Input
                                      id={`input-${i}`}
                                      type="text"
                                      value={o.value}
                                      onChange={onTextValueChange(i)}
                                    />
                                  )}
                                  {filter && filter.type === "select" && (
                                    <Select
                                      id={`input-${i}`}
                                      theme={selectThemeColors}
                                      className="react-select"
                                      classNamePrefix="select"
                                      value={o.value}
                                      onChange={onSelectValueChange(i)}
                                      isMulti={!!filter.multiple}
                                      isClearable={!filter.required && filter.multiple}
                                      options={filter.options}
                                      hasValue={true}
                                    />
                                  )}
                                </div>
                                <div className="close-icon">
                                  {(!filter || (filter && !filter.required)) && (
                                    <X className="ficon filter-icon" onClick={removeFilter(i)} />
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <input type="submit" className="d-none" value="fake submit button" />
                      </form>
                    )}
                    <div className="filter-buttons mt-24">
                      <div className="action-button mr-24 wbg" onClick={addFilterOption}>
                        <Plus className="ficon filter-icon" /> Add filter
                      </div>
                      {changed && !saveViewMode.active && (
                        <div className="action-button" onClick={activateSaveViewMode}>
                          <Save className="ficon filter-icon" /> Save view
                        </div>
                      )}
                      <div className="full">&nbsp;</div>
                      {!saveViewMode.active && (
                        <React.Fragment>
                          <div className="action-button primary mr-24" onClick={toggleFilterDropdown}>
                            Cancel
                          </div>

                          <Button color="primary" disabled={!changed} onClick={handleApplyFilters}>
                            Apply Filters
                          </Button>
                        </React.Fragment>
                      )}
                      {saveViewMode.active && (
                        <React.Fragment>
                          <div className="action-button primary mr-24" onClick={closeAndClearViewMode}>
                            Cancel
                          </div>

                          <Button
                            color="primary"
                            disabled={!(saveViewMode.name && saveViewMode.category && hasFilters)}
                            onClick={saveAndCloseViewMode}
                          >
                            <Save className="ficon filter-icon" /> Save View
                          </Button>
                        </React.Fragment>
                      )}
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <div className="groups">
                      {Object.keys(groups).length === 0 && <div>There are no views saved</div>}
                      {Object.keys(groups)
                        .sort((a, b) => a.localeCompare(b))
                        .map((groupName, gi) => {
                          return (
                            <div className="view-group" key={gi}>
                              <div className="group-header">{groupName}</div>
                              <div className="list">
                                {groups[groupName].map((view, i) => {
                                  return (
                                    <div className="view" key={i}>
                                      <div className="view-radio form-check">
                                        <Input
                                          value={view.id}
                                          name="selectedView"
                                          type="radio"
                                          checked={selectedView === view.id}
                                          onChange={handleSelectedView}
                                          id={`radio-${gi}-${i}`}
                                        />
                                        <Label className="form-check-label view-name" for={`radio-${gi}-${i}`}>
                                          {view.name}
                                        </Label>
                                      </div>
                                      <div className="full">&nbsp;</div>
                                      <div className="load-view" data-id={view.id} onClick={editView}>
                                        <Edit3 className="ficon" />
                                      </div>
                                      <div className="delete-view" data-id={view.id} onClick={deleteView}>
                                        <Trash2 className="ficon" />
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    <div className="filter-buttons mt-24">
                      <div className="full">&nbsp;</div>
                      <div className="action-button primary mr-24" onClick={toggleFilterDropdown}>
                        Cancel
                      </div>

                      <Button color="primary" disabled={!selectedView} onClick={handleApplyView}>
                        Apply View
                      </Button>
                    </div>
                  </TabPane>
                </TabContent>
              </DropdownMenu>
            </ButtonDropdown>
            <div className="reload-data-button" onClick={reloadData} id="reload-data-button">
              {/* <RotateCw className="ficon filter-icon" /> */}
              <RefreshIcon />
            </div>
            <UncontrolledTooltip target="reload-data-button">Reload Data</UncontrolledTooltip>
          </div>
        </div>
        <MTableToolbar {...props} />
      </div>
      {(hasSort || hasFilters > 0) && (
        <div className="value-pills">
          {hasSort && (
            <div className="pill" onClick={toggleSort}>
              <div className="label">
                {sortDirection.value === "asc" ? (
                  <ArrowUp className="ficon filter-icon" />
                ) : sortDirection.value === "desc" ? (
                  <ArrowDown className="ficon filter-icon" />
                ) : (
                  ""
                )}{" "}
                {sortBy.label}
              </div>
              <div className="delete" onClick={deleteSortNoToggle}>
                <X className="ficon filter-icon" />
              </div>
            </div>
          )}
          {hasSort && hasFilters > 0 && <div className="sep"></div>}
          {hasFilters > 0 &&
            filtersSelected.map((o, i) => {
              const filterIndex = o.filterIndex;
              let filter = null;
              if (filterIndex !== "-1") {
                filter = filters[parseInt(filterIndex)];
              } else {
                return null;
              }

              let val = null;
              if (filter.type === "datetime") {
                val = <TimePickerButtonLabel value={o.value} />;
              } else if (filter.type === "select" && filter.multiple) {
                val = o.value.map(v => v.label).join(", ");
              } else if (filter.type === "select" && o.value !== null) {
                val = o.value.label;
              } else {
                val = o.value;
              }

              return (
                <div
                  className={`pill big ${!filter || (filter && !filter.required) ? "" : "no-click"} ${
                    !o.applied ? "not-applied" : ""
                  }`}
                  key={i}
                  title={filter.type === "datetime" ? "" : val}
                  data-index={i}
                  onClick={toggleFilterDropdown}
                >
                  <div className="label">{filter.label}:&nbsp;</div>
                  <div className={`${filter.type === "datetime" ? "valueDate" : "value"}`}>{val}</div>
                  {(!filter || (filter && !filter.required)) && (
                    <div className="delete" onClick={removeFilterAndApply(i)} title="Remove Filter">
                      <X className="ficon filter-icon" />
                    </div>
                  )}
                </div>
              );
            })}
          {hasFilters > 0 && canClear && (
            <div className="action-button primary ml-24" onClick={clearAllFilters(true)}>
              Clear all
            </div>
          )}
        </div>
      )}
    </div>,
    domNode
  );
};

const mapStateProps = state => ({
  filtersSelected: state.tableFiltersSorting.filtersSelected,
  filtersDeleted: state.tableFiltersSorting.filtersDeleted,
  sortBy: state.tableFiltersSorting.sortBy,
  sortDirection: state.tableFiltersSorting.sortDirection,
  changed: state.tableFiltersSorting.changed,
});
const mapActions = {
  tableFilterSortingSetData,
  tableSearchSetLastRequestSent,
};

export default connect(mapStateProps, mapActions)(TableFilters);
