import React, { useState } from "react";
import { useSkin } from '@hooks/useSkin'
import { Link } from 'react-router-dom'
import InputPasswordToggle from '@components/input-password-toggle'
import { connect } from "react-redux";
import { Row, Col, CardTitle, CardText, Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap'

import '@styles/base/pages/page-auth.scss';
import LogoDark from "@src/assets/images/pages/login-v2-dark.svg";
import LogoLight from "@src/assets/images/pages/login-v2-dark.svg";
import Spinner from '../@core/components/spinner/Fallback-spinner'

import { userActions } from "../../store/_shared/_redux/authActions";

const rootPath = window.ini.REACT_APP_ROOT_PATH;

const Login = (props) => {
  const { requestActive, isLoginType, error } = props;
  const [skin, setSkin] = useSkin()

  const source = skin === 'dark' ? LogoDark : LogoLight;

  const [authChecking, setAuthChecking] = useState(requestActive);
  React.useEffect(() => {
    if (requestActive && !isLoginType) {
      setAuthChecking(true);
    } else {
      setAuthChecking(false);
    }
  }, [requestActive, isLoginType]);

  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });

  const usernameChange = ev => {
    if (ev && typeof ev.target !== "undefined" && typeof ev.target.value !== "undefined") {
      const value = ev.target.value;
      setCredentials(c => ({ ...c, username: value }));
    }
  };
  const passwordChange = ev => {
    if (ev && typeof ev.target !== "undefined" && typeof ev.target.value !== "undefined") {
      const value = ev.target.value;
      setCredentials(c => ({ ...c, password: value }));
    }
  };

  const doLogin = e => {
    e.preventDefault();
    props.login(credentials.username, credentials.password);
  };

  if (authChecking) {
    return <Spinner />;
  }

  return (
    <div className='auth-wrapper auth-v2'>
      <Row className='auth-inner m-0'>
        <Link className='brand-logo' to='/'>
          <h2 className='brand-text text-primary ml-1'>Celestial</h2>
        </Link>
        <Col className='d-none d-lg-flex align-items-center p-5' lg='8' sm='12'>
          <div className='w-100 d-lg-flex align-items-center justify-content-center px-5'>
            <img className='img-fluid' src={source} alt='Login V2' />
          </div>
        </Col>
        <Col className='d-flex align-items-center auth-bg px-2 p-lg-5' lg='4' sm='12'>
          <Col className='px-xl-2 mx-auto' sm='8' md='6' lg='12'>
            <CardTitle tag='h2' className='font-weight-bold mb-1'>
              Welcome to Celestial! 👋
            </CardTitle>
            <CardText className='mb-2'>Please sign-in to your account and start the adventure</CardText>
            {!!error && <Alert color="danger" className="p-1">{error}</Alert>}
            <Form className='auth-login-form mt-2' onSubmit={doLogin}>
              <FormGroup>
                <Label className='form-label' for='login-email'>
                  Username
                </Label>
                <Input
                  type='text'
                  id='login-username'
                  placeholder='john@example.com'
                  name="username"
                  autoFocus
                  autoComplete="username"
                  onChange={usernameChange}
                />
              </FormGroup>
              <FormGroup>
                <div className='d-flex justify-content-between'>
                  <Label className='form-label' for='login-password'>
                    Password
                  </Label>
                </div>
                <InputPasswordToggle
                  className='input-group-merge'
                  id='login-password'
                  name="password"
                  autoComplete="current-password"
                  onChange={passwordChange}
                />
              </FormGroup>

              <Button.Ripple type="submit" color='primary' onClick={doLogin}>
                Sign in
              </Button.Ripple>

              <p className='text-center mt-4'>
                <span className='mr-25'>
                  {"Copyright © "}
                  <Link color="inherit" to={`${rootPath}/home`}>
                    Newton
                  </Link>{" "}
                  2019 - {new Date().getFullYear()}

                </span>
              </p>
            </Form>
          </Col>
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = state => {
  const { requestActive, error, user } = state.auth;
  return { requestActive, error, isLoginType: !!user && Object.keys(user).length > 0 };
};

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

export default connect(mapStateToProps, actionCreators)(Login);
