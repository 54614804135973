import React from 'react';
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import DatePicker from "src/components/x-date-pickers/DatePicker";
import DateTimePicker from "src/components/x-date-pickers/DateTimePicker";
import TimePicker from "src/components/x-date-pickers/TimePicker";
import AlertCircleFullIcon from "src/components/ui/assets/alert-circle-full";

const DateBoxOption = (props) => {
  const { onChange } = props;
  const [opened, setOpened] = React.useState(false);

  const handleClick = () => {
    setOpened(v => !v);
  }

  const style = {
    display: "inline-block"
  }

  let value = typeof props.value !== "undefined" && props.value !== null ? props.value : null;
  let resetValue = typeof props.resetValue !== "undefined" && props.resetValue !== null ? props.resetValue : null;
  if (typeof props.resetType !== "undefined" && props.resetType === "seconds" && resetValue) resetValue *= 1000; //transform to milliseconds if needed
  let format = props.format ? props.format : 'YYYY-MM-DD';

  const handleTimeChange = date => {
    if (date === null) {
      return onChange({ target: { name: props.name, value: null } });
    }
    onChange({ target: { name: props.name, value: props.valueType === "moment" ? date : date.format(format) } })
  };

  const handleReset = (ev) => {
    ev.stopPropagation();
    if (typeof props.resetValue !== "undefined") {
      if (props.resetValue === null) {
        return onChange({ target: { name: props.name, value: null } });
      }
      let v = moment(`${resetValue}`, 'x');
      onChange({ target: { name: props.name, value: props.valueType === "moment" ? v : v.format(format) } });
    }
  };

  if (!props.children) {
    style.display = "none";
  }

  const type = props.type ? props.type : "date";

  return (
    <div className={`option textboxoption dateboxoption has-addon ${!opened ? 'collapsed' : ''} ${props.className ? props.className : ""} ${props.invalid ? "has-error" : ""}`}>
      <label className={"option-content" + (props.inline === true ? " inline" : "")}>
        <span onClick={handleClick} className={(props.value && props.def) ? 'has-changes' : ''}>{props.label}<i className="caret" style={style}></i></span>
        <div className="control">
          {type === "date" &&
            <DatePicker
              className="date-time-control"
              ampm={!!props.ampm}
              name={props.name}
              toolbarLabel={props.label}
              format={format}
              disableFuture={!!props.disableFuture}
              disablePast={!!props.disablePast}
              value={value}
              onChange={handleTimeChange}
              disabled={props.disabled}
              readOnly={props.readOnly}
              useUtcAdapter={props.isUTC}
            />}
          {type === "datetime" &&
            <DateTimePicker
              className="date-time-control"
              ampm={!!props.ampm}
              name={props.name}
              toolbarLabel={props.label}
              format={format}
              disableFuture={!!props.disableFuture}
              disablePast={!!props.disablePast}
              value={value}
              onChange={handleTimeChange}
              disabled={props.disabled}
              readOnly={props.readOnly}
              useUtcAdapter={props.isUTC}
            />}
          {type === "time" &&
            <TimePicker
              className="date-time-control"
              ampm={!!props.ampm}
              name={props.name}
              toolbarLabel={props.label}
              format={format}
              disableFuture={!!props.disableFuture}
              disablePast={!!props.disablePast}
              value={value}
              onChange={handleTimeChange}
              disabled={props.disabled}
              readOnly={props.readOnly}
              useUtcAdapter={props.isUTC}
            />}
          {typeof props.resetValue !== "undefined" && value !== resetValue &&
            <div className="reset-date" onClick={handleReset} title={props.resetTitle ? props.resetTitle : ''}><CloseIcon /></div>
          }
          {props.invalid && (<div className="error-indicator">
            <AlertCircleFullIcon size={16}/>
          </div>)}
        </div>
      </label>
      <div className="children">
        {props.children}
        {props.invalid && typeof props.errorMessages !== "undefined" && props.errorMessages.length > 0 && (
          <div className="error">
            {props.errorMessages.map((e, idx) => {
              return <React.Fragment key={`tenant-name-error-${idx}`}>
                <span>{e}.</span><br/>
              </React.Fragment>;
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default DateBoxOption;