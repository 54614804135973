import { produce } from "immer";
import { selectorsConstants } from "../constants";

const initialState = {
  allTournaments: [],
  allMatches: [],
  sports: [],
};

const selectorsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case selectorsConstants.SET_DATA: {
        if (typeof action.data.allTournaments !== "undefined") draft.allTournaments = action.data.allTournaments;
        if (typeof action.data.allMatches !== "undefined") draft.allMatches = action.data.allMatches;
        if (typeof action.data.sports !== "undefined") draft.sports = action.data.sports;
        break;
      }
      case selectorsConstants.CLEAR_ALL: {
        draft.allTournaments = [];
        draft.allMatches = [];
        draft.sports = [];
        break;
      }
      default:
        /* noop */
        break;
    }
  });

export default selectorsReducer;