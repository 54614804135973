let hostName = "";

if (typeof hostName === "undefined") {
  hostName = "";
}

let wsHost;
wsHost = "https://test1-micros-1.play-online.com";

if (!wsHost) {
  wsHost = window.location.protocol + "//" + window.location.hostname;
  if (window.location.port !== 80 && window.location.port !== 443) {
    wsHost += ":" + window.location.port;
  }
}

const aclHostName = window.ini.REACT_APP_ACL_API_URL + "/acl";
const aclMicrosHostName = window.ini.REACT_APP_ACL_API_URL + "/acl";
const digitainHostName = window.ini.REACT_APP_ACL_API_URL + "/acl";

export const endpoints = {
  manual_transaction: {
    list: digitainHostName + "/digitain-b2b/manual-transcations/list",
    deny: digitainHostName + "/digitain-b2b/manual-transcation/deny",
    approve: digitainHostName + "/digitain-b2b/manual-transcation/approve",
  },

  // clickhouse service
  counter: {
    list: hostName + "/api/rawdata/v1/counters",
    save: hostName + "/api/rawdata/v1/edit",
  },

  event: {
    list: hostName + "/api/rawdata/v1/events",
    save: hostName + "/api/rawdata/v1/edit",
  },

  messages: {
    list: hostName + "/api/rawdata/v1/events",
    save: hostName + "/api/rawdata/v1/edit",
  },

  events: {
    list: hostName + "/api/rawdata/v1/events-custom",
  },

  slotdata: {
    list: "http://localhost:8666/api",
    save: "http://localhost:8666/api",
  },

  // configuration service
  slot_config: {
    load: hostName + "/api/configuration/v1/slot-config",
    save: hostName + "/api/configuration/v1/slot-config",
  },

  // slot sas configuration service
  slot_sas_config: {
    load: hostName + "/api/configuration/v1/slot-sas-config/:id/type/:type",
    save: hostName + "/api/configuration/v1/slot-sas-config",
  },

  location_config: {
    save: hostName + "/api/configuration/v1/location-config",
  },

  smib_config: {
    save: hostName + "/api/configuration/v1/smib-config",
  },

  tenant_config: {
    save: hostName + "/api/configuration/v1/tenant-config",
  },

  location: {
    list: aclMicrosHostName + "/configuration/v1/locations",
    save: aclMicrosHostName + "/configuration/v1/location",
    realtime: aclMicrosHostName + "/smib-realtime/v1/location/status/:idsList",
    archive: aclMicrosHostName + "/configuration/v1/archive/location",
    load: aclMicrosHostName + "/configuration/v1/location",
  },

  domains: {
    list: aclHostName + "/configuration/v1/domains",
    load: aclHostName + "/configuration/v1/domain",
  },

  site: {
    list: aclMicrosHostName + "/configuration/v1/sites",
    save: aclMicrosHostName + "/configuration/v1/site",
    load: aclMicrosHostName + "/configuration/v1/site",
  },

  slot: {
    list: hostName + "/api/configuration/v1/slots",
    save: hostName + "/api/configuration/v1/slot",
    realtime: hostName + "/api/smib-realtime/v1/slot/status/:idsList",
    list_machine_meters: hostName + "/api/configuration/v1/slot-machine-meters/:slotId",
    load_machine_meter: hostName + "/api/configuration/v1/slot-machine-meter/:id",
    save_machine_meter: hostName + "/api/configuration/v1/slot-machine-meter",
    load_machine_meter_defs: hostName + "/api/settings/v1/mechanical-meters",
    archive: hostName + "/api/configuration/v1/archive/slot",
    set_machine_meters: hostName + "/api/configuration/v1/slot-machine-meters/:slotId",
  },

  smib: {
    list: hostName + "/api/configuration/v1/smibs",
    save: hostName + "/api/configuration/v1/smib",
    replace: hostName + "/api/configuration/v1/smib-to-smib",
    test_mode: hostName + "/api/configuration/v1/smib/:id/toggle-test",
    log_mode: hostName + "/api/configuration/v1/smib/:id/toggle-log",
    realtime: hostName + "/api/smib-realtime/v1/smib/status/:serialsList",
    refreshPass: hostName + "/api/configuration/v1/smib-refresh-password/:id",
    runSmibCmd: hostName + "/api/configuration/v1/smib-send-cmd/:id/type/:type",
    archive: hostName + "/api/configuration/v1/archive/smib",
  },

  tenant: {
    list: aclMicrosHostName + "/configuration/v1/tenants",
    load: aclMicrosHostName + "/configuration/v1/tenant",
    save: aclMicrosHostName + "/configuration/v1/tenant",
    realtime: aclMicrosHostName + "/api/smib-realtime/v1/tenant/status/:idsList",
    archive: aclMicrosHostName + "/api/configuration/v1/archive/tenant",
    fraud_detector: aclMicrosHostName + "/api/fraud-detector-db/v1/tenant-settings/:id",
  },

  paymentTransaction: {
    list: hostName + "/api/payment-transactions-processor/v1/get-payment-transactions",
    load: hostName + "/api/payment-transactions-processor/v1/get-payment-transaction-log",
  },

  // auth service
  user: {
    login: aclHostName + "/authorization/v1/login",
    logout: aclHostName + "/authorization/v1/logout",
    check: aclHostName + "/authorization/v1/check",
    refresh: aclHostName + "/authorization/v1/refresh",
  },

  timelog: {
    list: hostName + "/api/timelog/v1",
  },

  aggregation: {
    list: hostName + "/api/aggregate/v1",
    list_with_granularity: hostName + "/api/aggregate/v1/points",
  },

  checkpoint: {
    list: hostName + "/api/checkpoints/v1",
    save: hostName + "/api/checkpoints/v1",
  },

  task: {
    list: hostName + "/api/tasks/v1/completed",
    listcurrent: hostName + "/api/tasks/v1/current",
  },

  dataexport: {
    list: hostName + "/api/dataexport/v1/list",
    save: hostName + "/api/rawdata/v1/export",
  },

  jackpot: {
    list: hostName + "/api/jackpot-manager/v1/jackpots",
    save: hostName + "/api/jackpot-manager/v1/jackpot",
    stop: hostName + "/api/jackpot-manager/v1/jackpot/cancel-win-display",
    reset: hostName + "/api/jackpot-manager/v1/jackpot/reset",
    regen: hostName + "/api/jackpot-manager/v1/jackpot/regen",
    delete: hostName + "/api/jackpot-manager/v1/jackpot/delete",
    swagger_specs: hostName + "/api/jackpot-manager/v1/swagger.json",
  },

  jackpot_history: {
    list: hostName + "/api/jackpot-manager/v1/jackpot-wins",
    load: hostName + "/api/jackpot-manager/v1/jackpot-win",
  },

  jackpot_source: {
    list: hostName + "/api/jackpot-manager/v1/jackpot-sources",
    save: hostName + "/api/jackpot-manager/v1/jackpot-source",
    swagger_specs: hostName + "/api/jackpot-manager/v1/swagger.json",
  },

  display_channel: {
    list: hostName + "/api/display-channels/v1/displays",
    save: hostName + "/api/display-channels/v1/display",
    refreshBt: hostName + "/api/display-channels/v1/displays/update",
  },

  dashboard_settings: {
    list: hostName + "/api/settings/v1/get-dashboard-settings",
    save: hostName + "/api/settings/v1/add-dashboard-settings",
    update: hostName + "/api/settings/v1/update-dashboard-settings",
  },

  rawaggregation: {
    list: hostName + "/api/aggregations/v1/query",
    export: hostName + "/api/aggregations/v1/export",
  },

  log: {
    list: hostName + "/api/rawdata/v1/logs",
  },

  logError: {
    list: hostName + "/api/rawdata/v1/log-errors",
  },

  acl: {
    user: {
      // list: aclHostName + "/users/v1/users",
      list: aclMicrosHostName + "/users/v1/users",
      load: aclMicrosHostName + "/users/v1/user",
      save: aclMicrosHostName + "/users/v1/user",
      delete: aclMicrosHostName + "/users/v1/user/delete",
    },
    entity_group: {
      list: aclMicrosHostName + "/users/v1/entity-groups",
      save: aclMicrosHostName + "/users/v1/entity-group",
      delete: aclMicrosHostName + "/users/v1/entity-group/delete",
    },
    permission: {
      list: aclMicrosHostName + "/users/v1/permissions",
      save: aclMicrosHostName + "/users/v1/permission",
      delete: aclMicrosHostName + "/users/v1/permission/delete",
    },
    user_group: {
      // list: aclHostName + "/users/v1/user-groups",
      list: aclMicrosHostName + "/users/v1/user-groups",
      load: aclMicrosHostName + "/users/v1/user-group",
      save: aclMicrosHostName + "/users/v1/user-group",
      delete: aclMicrosHostName + "/users/v1/user-group/delete",
    },
    role: {
      // list: aclHostName + "/users/v1/roles",
      list: aclMicrosHostName + "/users/v1/roles",
      // save: aclHostName + "/users/v1/role",
      load: aclMicrosHostName + "/users/v1/role",
      save: aclMicrosHostName + "/users/v1/role",
      // delete: aclHostName + "/users/v1/role/delete",
      delete: aclMicrosHostName + "/users/v1/role/delete",
    },
    rule: {
      // list: aclHostName + "/users/v1/acl-rules",
      list: aclMicrosHostName + "/users/v1/acl-rules",

      load: aclMicrosHostName + "/users/v1/acl-rule",
      save: aclMicrosHostName + "/authorization/v1/acl-rule",
      delete: aclMicrosHostName + "/authorization/v1/acl-rule/delete",
    },
    api_token: {
      list: aclMicrosHostName + "/users/v1/api-tokens",
      save: aclMicrosHostName + "/authorization/v1/api-token",
      load: aclMicrosHostName + "/users/v1/api-token",
      delete: aclMicrosHostName + "/users/v1/api-token/delete",
    },
    models: {
      load: aclHostName + "/authorization/v1/acl-model-fields",
    },
  },

  admin: {
    aggregation_rule: {
      list: hostName + "/api/configuration/v1/aggregation-rules",
      save: hostName + "/api/configuration/v1/aggregation-rule",
    },
    columns_configuration: {
      list: hostName + "/api/settings/v1/get-system-all-columns",
      get: hostName + "/api/settings/v1/get-page-columns",
      get_by_page_route: hostName + "/api/settings/v1/get-system-page-columns-by-page-route",
      save: hostName + "/api/settings/v1/add-page-columns",
      update: hostName + "/api/settings/v1/update-page-columns",
      delete: hostName + "/api/settings/v1/delete-page-columns",
      dataTypes: hostName + "/api/settings/v1/data-types",
    },

    general_settings: {
      list: hostName + "/api/settings/v1/get-general-settings/type/:type/:idsList",
      save: hostName + "/api/settings/v1/add-general-settings",
      update: hostName + "/api/settings/v1/update-general-settings",
    },
  },

  daily_monthly_legal: {
    list: hostName + "/api/fiscal-reports/v1/list/:location_id",
    get_validated_report: hostName + "/api/fiscal-reports/v1/validated-report",
    validate_report: hostName + "/api/fiscal-reports/v1/validate",
    // TODO - switch back after work is done on Monthly Jackpot Reports
    jackpot_checkpoints: hostName + "/api/jackpot-snapshot/v1/jackpot-checkpoints",
    //jackpot_checkpoints: 'https://zeus-test2.slotmonitor.ro/api/jackpot-snapshot/v1/jackpot-checkpoints',
    list_monthly_reports: hostName + "/api/fiscal-reports/v1/list-monthly-reports/:location_id",
    get_monthly_report_with_validated_daily_reports:
      hostName + "/api/fiscal-reports/v1/monthly-report-with-validated-daily-reports",
    get_monthly_report_all_daily_reports: hostName + "/api/fiscal-reports/v1/monthly-report-all-daily-reports",
    get_monthly_report_slot_ranges_intervals: hostName + "/api/fiscal-reports/v1/monthly-report-slot-ranges-intervals",
  },

  okto_processor: {
    get_merchant_data: hostName + "/api/okto-processor/v1/get-merchant-data",
    tenant_settings: hostName + "/api/okto-processor/v1/tenant-settings",
    swagger_specs: hostName + "/api/okto-processor/v1/swagger.json",
  },

  slotcontrol: {
    slot_status: hostName + "/api/slotcontrol/v1/slot-status",
    refresh_registration: hostName + "/api/slotcontrol/v1/aft/refresh-registration",
    set_asset_number: hostName + "/api/slotcontrol/v1/aft/set-asset-number",
    lock: hostName + "/api/slotcontrol/v1/lock",
    unlock: hostName + "/api/slotcontrol/v1/unlock",
  },

  remote_cashout: {
    list: hostName + "/api/remote-cashout/v1/list",
    reset: hostName + "/api/remote-cashout/v1/reset",
  },

  websocket: {
    host: window.ini.REACT_APP_NOTIFICATION_HOST,
    path: "/api/notifications-ws/v1/connect",
  },

  notifications: {
    list: hostName + "/api/notifications/v1/notifications",
    seen: hostName + "/api/notifications/v1/notifications/seen",
    confirm: hostName + "/api/notifications/v1/notifications/confirm",
    templates: {
      load: hostName + "/api/notifications/v1/notification-templates",
    },
    subscriptions: {
      load: hostName + "/api/notifications/v1/user-subscriptions",
      save: hostName + "/api/notifications/v1/user-subscriptions/set",
    },
  },
};
