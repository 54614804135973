import React from 'react';
import { withRouter } from 'react-router';

let globalHistory = null;

const Spy = (props) => {
  globalHistory = props.history;

  const [state, setState] = React.useState({
    first: true,
    path: "",
    navigated: false,
  });

  React.useEffect(() => {
    if (!state.navigated) {
      if (state.first) {
        setState(v => ({ ...v, first: false, path: props.location.pathname }));
      } else {
        if (state.path !== props.location.pathname) {
          window._navigated = true;
          setState(v => ({ ...v, navigated: true }));
        }
      }
    }
  }, [props.location, state]);

  return null;
};

export const ReactRouterGlobalHistory = withRouter(Spy);

export default function getHistory() {
  if (!globalHistory) {
    throw new Error('No history Object. You probably forgot to mount ReactRouterGlobalHistory from this package.');
  }

  return globalHistory;
}