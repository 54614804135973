import axios from "@store/_shared/authAxios";

const valuesSrc = {
  currencyManager: {
    apiRoutes: {
      parent: (apiUrl) => `${apiUrl}/loyalty/currency-manager`,
      groups: {
        listAll: {
          url: (apiUrl, tenantId) => `${
            valuesSrc.currencyManager.apiRoutes.parent(apiUrl)
          }/groups/all?tenant_id=${tenantId}`,
          method: axios.post,
        },
        fetch: {
          url: (apiUrl, tenantId, groupId) => `${
            valuesSrc.currencyManager.apiRoutes.parent(apiUrl)
          }/groups/fetch/${groupId}?tenant_id=${tenantId}`,
          method: axios.get,
        },
        create: {
          url: (apiUrl) => `${
            valuesSrc.currencyManager.apiRoutes.parent(apiUrl)
          }/groups/create`,
          method: axios.post,
        },
        edit: {
          url: (apiUrl) => `${
            valuesSrc.currencyManager.apiRoutes.parent(apiUrl)
          }/groups/edit`,
          method: axios.post,
        },
        clone: {
          url: (apiUrl) => `${
            valuesSrc.currencyManager.apiRoutes.parent(apiUrl)
          }/groups/clone`,
          method: axios.post,
        },
        clearCache: {
          url: (apiUrl, tenantId) => `${
            valuesSrc.currencyManager.apiRoutes.parent(apiUrl)
          }/groups/clearCache?tenant_id=${tenantId}`,
          method: axios.post,
        },
      },
      currencies: {
        listAll: {
          url: (apiUrl, tenantId) => `${
            valuesSrc.currencyManager.apiRoutes.parent(apiUrl)
          }/currencies/all?tenant_id=${tenantId}`,
          method: axios.post,
        },
        fetch: {
          url: (apiUrl, tenantId, id) => `${
            valuesSrc.currencyManager.apiRoutes.parent(apiUrl)
          }/currencies/fetch/${id}?tenant_id=${tenantId}`,
          method: axios.get,
        },
        create: {
          url: (apiUrl) => `${
            valuesSrc.currencyManager.apiRoutes.parent(apiUrl)
          }/currencies/create`,
          method: axios.post,
        },
        edit: {
          url: (apiUrl) => `${
            valuesSrc.currencyManager.apiRoutes.parent(apiUrl)
          }/currencies/edit`,
          method: axios.post,
        },
        clone: {
          url: (apiUrl) => `${
            valuesSrc.currencyManager.apiRoutes.parent(apiUrl)
          }/currencies/clone`,
          method: axios.post,
        }
      },
    },

    frontendVisibility: [
      {id: "1", name: "Hidden", color: "dark", default: true},
      {id: "2", name: "Visible non-zero", color: "success secondary"},
      {id: "3", name: "Visible always", color: "success"},
    ],

    adminStatuses: [
      {id: "1", label: "ACTIVE", color: "success"},
      {id: "2", label: "NOT ACTIVE", color: "dark", default: true},
    ],

    types: [
      {id: "1", name: "Shop points", color: "success", default: true, cssClass: "loyalty-type-react-select-container shop-type"},
      {id: "2", name: "Rank points", color: "success secondary", cssClass: "loyalty-type-react-select-container rank-type"},
    ],

    adminStatusesIds: {
      active: 1,
      archived: 2,
    },

    typesIds: {
      shopLoyaltyPoints: 1,
      rankPoints: 2,
    },

    actions: {
      groups: [
        {labelValue: "Clone", icon: "copy", action: "clone"},
        {labelValue: "Edit", icon: "edit", action: "edit"},
        // {labelValue: "Delete", icon: "delete", action: "delete"},
      ],
      currencies: [
        // {labelValue: "Group transfer", icon: "transfer", action: "transfer"},
        {labelValue: "Clone", icon: "copy", action: "clone"},
        {labelValue: "Edit", icon: "edit", action: "edit"},
        // {labelValue: "Delete", icon: "delete", action: "delete"},
      ],
    }
  },

  campaignManager: {
    apiRoutes: {
      parent: (apiUrl) => `${apiUrl}/loyalty/campaign-manager`,
      groups: {
        listAll: {
          url: (apiUrl, tenantId) => `${
            valuesSrc.campaignManager.apiRoutes.parent(apiUrl)
          }/groups/all?tenant_id=${tenantId}`,
          method: axios.post,
        },
        fetch: {
          url: (apiUrl, tenantId, groupId) => `${
            valuesSrc.campaignManager.apiRoutes.parent(apiUrl)
          }/groups/fetch/${groupId}?tenant_id=${tenantId}`,
          method: axios.get,
        },
        create: {
          url: (apiUrl) => `${
            valuesSrc.campaignManager.apiRoutes.parent(apiUrl)
          }/groups/create`,
          method: axios.post,
        },
        edit: {
          url: (apiUrl) => `${
            valuesSrc.campaignManager.apiRoutes.parent(apiUrl)
          }/groups/edit`,
          method: axios.post,
        },
        clone: {
          url: (apiUrl) => `${
            valuesSrc.campaignManager.apiRoutes.parent(apiUrl)
          }/groups/clone`,
          method: axios.post,
        },
        clearCache: {
          url: (apiUrl, tenantId) => `${
            valuesSrc.campaignManager.apiRoutes.parent(apiUrl)
          }/groups/clearCache?tenant_id=${tenantId}`,
          method: axios.post,
        },
      },
      campaigns: {
        listAll: {
          url: (apiUrl, tenantId) => `${
            valuesSrc.campaignManager.apiRoutes.parent(apiUrl)
          }/campaigns/all?tenant_id=${tenantId}`,
          method: axios.post,
        },
        fetch: {
          url: (apiUrl, tenantId, id) => `${
            valuesSrc.campaignManager.apiRoutes.parent(apiUrl)
          }/campaigns/fetch/${id}?tenant_id=${tenantId}`,
          method: axios.get,
        },
        create: {
          url: (apiUrl) => `${
            valuesSrc.campaignManager.apiRoutes.parent(apiUrl)
          }/campaigns/create`,
          method: axios.post,
        },
        edit: {
          url: (apiUrl, tenantId) => `${
            valuesSrc.campaignManager.apiRoutes.parent(apiUrl)
          }/campaigns/edit?tenant_id=${tenantId}`,
          method: axios.post,
        },
        clone: {
          url: (apiUrl) => `${
            valuesSrc.campaignManager.apiRoutes.parent(apiUrl)
          }/campaigns/clone`,
          method: axios.post,
        },
      },
    },

    adminStatuses: [
      {id: "1", label: "ACTIVE", color: "success"},
      {id: "2", label: "NOT ACTIVE", color: "dark", default: true},
    ],

    adminStatusesIds: {
      active: 1,
      archived: 2,
    },

    actions: {
      groups: [
        {labelValue: "Clone", icon: "copy", action: "clone"},
        {labelValue: "Edit", icon: "edit", action: "edit"},
        // {labelValue: "Delete", icon: "delete", action: "delete"},
      ],
    }
  },

  rankSystems: {
    apiRoutes: {
      parent: (apiUrl) => `${apiUrl}/loyalty/rank-systems`,
      titles: {
        listAll: {
          url: (apiUrl, tenantId) => `${
            valuesSrc.rankSystems.apiRoutes.parent(apiUrl)
          }/titles/all?tenant_id=${tenantId}`,
          method: axios.post,
        },
        fetch: {
          url: (apiUrl, tenantId, titleId) => `${
            valuesSrc.rankSystems.apiRoutes.parent(apiUrl)
          }/titles/fetch/${titleId}?tenant_id=${tenantId}`,
          method: axios.get,
        },
        save: {
          url: (apiUrl, tenantId) => `${
            valuesSrc.rankSystems.apiRoutes.parent(apiUrl)
          }/titles/save?tenant_id=${tenantId}`,
          method: axios.post,
        }
      },
      groups: {
        listAll: {
          url: (apiUrl, tenantId) => `${
            valuesSrc.rankSystems.apiRoutes.parent(apiUrl)
          }/groups/all?tenant_id=${tenantId}`,
          method: axios.post,
        },
        fetch: {
          url: (apiUrl, tenantId, groupId) => `${
            valuesSrc.rankSystems.apiRoutes.parent(apiUrl)
          }/groups/fetch/${groupId}?tenant_id=${tenantId}`,
          method: axios.get,
        },
        create: {
          url: (apiUrl) => `${
            valuesSrc.rankSystems.apiRoutes.parent(apiUrl)
          }/groups/create`,
          method: axios.post,
        },
        edit: {
          url: (apiUrl) => `${
            valuesSrc.rankSystems.apiRoutes.parent(apiUrl)
          }/groups/edit`,
          method: axios.post,
        },
        clone: {
          url: (apiUrl) => `${
            valuesSrc.rankSystems.apiRoutes.parent(apiUrl)
          }/groups/clone`,
          method: axios.post,
        },
        clearCache: {
          url: (apiUrl, tenantId) => `${
            valuesSrc.rankSystems.apiRoutes.parent(apiUrl)
          }/groups/clearCache?tenant_id=${tenantId}`,
          method: axios.post,
        },
      },
      campaigns: {
        listAll: {
          url: (apiUrl, tenantId) => `${
            valuesSrc.rankSystems.apiRoutes.parent(apiUrl)
          }/campaigns/all?tenant_id=${tenantId}`,
          method: axios.post,
        },
        fetch: {
          url: (apiUrl, tenantId, id) => `${
            valuesSrc.rankSystems.apiRoutes.parent(apiUrl)
          }/campaigns/fetch/${id}?tenant_id=${tenantId}`,
          method: axios.get,
        },
        create: {
          url: (apiUrl) => `${
            valuesSrc.rankSystems.apiRoutes.parent(apiUrl)
          }/campaigns/create`,
          method: axios.post,
        },
        edit: {
          url: (apiUrl, tenantId) => `${
            valuesSrc.rankSystems.apiRoutes.parent(apiUrl)
          }/campaigns/edit?tenant_id=${tenantId}`,
          method: axios.post,
        },
        clone: {
          url: (apiUrl) => `${
            valuesSrc.rankSystems.apiRoutes.parent(apiUrl)
          }/campaigns/clone`,
          method: axios.post,
        },
      },
    },

    adminStatuses: [
      {id: "1", label: "Active", color: "success"},
      {id: "2", label: "Archived", color: "dark"},
    ],

    adminStatusesIds: {
      active: 1,
      archived: 2,
    },

    actions: {
      titles: [
        {labelValue: "Clone", icon: "copy", action: "clone"},
        {labelValue: "Edit", icon: "edit", action: "edit"},
        // {labelValue: "Delete", icon: "delete", action: "delete"},
      ],
    }
  },

  missions: {
    apiRoutes: {
      parent: (apiUrl) => `${apiUrl}/loyalty/missions-manager`,
      missions: {
        listAll: {
          url: (apiUrl, tenantId) => `${
            valuesSrc.missions.apiRoutes.parent(apiUrl)
          }/missions/all?tenant_id=${tenantId}`,
          method: axios.post,
        },
        fetch: {
          url: (apiUrl, tenantId, id) => `${
            valuesSrc.missions.apiRoutes.parent(apiUrl)
          }/missions/fetch/${id}?tenant_id=${tenantId}`,
          method: axios.get,
        },
        create: {
          url: (apiUrl) => `${
            valuesSrc.missions.apiRoutes.parent(apiUrl)
          }/missions/create`,
          method: axios.post,
        },
        edit: {
          url: (apiUrl, tenantId) => `${
            valuesSrc.missions.apiRoutes.parent(apiUrl)
          }/missions/edit?tenant_id=${tenantId}`,
          method: axios.post,
        },
        clone: {
          url: (apiUrl) => `${
            valuesSrc.missions.apiRoutes.parent(apiUrl)
          }/missions/clone`,
          method: axios.post,
        }
      },
      objectives: {
        all: {
          url: (apiUrl, tenantId) => `${
            valuesSrc.missions.apiRoutes.parent(apiUrl)
          }/objectives/all?tenant_id=${tenantId}`,
          method: axios.post,
        },
        create: {
          url: (apiUrl, tenantId) => `${
            valuesSrc.missions.apiRoutes.parent(apiUrl)
          }/objectives/create?tenant_id=${tenantId}`,
          method: axios.post,
        },
        edit: {
          url: (apiUrl, tenantId) => `${
            valuesSrc.missions.apiRoutes.parent(apiUrl)
          }/objectives/edit?tenant_id=${tenantId}`,
          method: axios.post,
        },
        clone: {
          url: (apiUrl) => `${
            valuesSrc.missions.apiRoutes.parent(apiUrl)
          }/objectives/clone`,
          method: axios.post,
        }
      },
    },

    adminStatuses: [
      {id: "1", label: "Active", color: "success"},
      {id: "2", label: "Archived", color: "dark"},
    ],

    adminStatusesIds: {
      active: 1,
      archived: 2,
    },

    actions: {
      titles: [
        {labelValue: "Clone", icon: "copy", action: "clone"},
        {labelValue: "Edit", icon: "edit", action: "edit"},
        // {labelValue: "Delete", icon: "delete", action: "delete"},
      ],
    },

    typeIds: {
      mission: 1,
      tournamentCasino: 2,
      tournamentSport: 3,
      loyaltyMission: 4,
      rankMission: 5,
    },

    types: {
      1: "MISSION",
      2: "TOURNAMENT CASINO",
      3: "TOURNAMENT SPORT",
      4: "SHOP",
      5: "RANK",
    },

    typeColors: {
      1: "success",
      2: "primary",
      3: "secondary",
      4: "success",
      5: "success secondary",
    },

    typeSelectClasses: {
      1: "",
      2: "",
      3: "",
      4: "loyalty-type-react-select-container shop-type",
      5: "loyalty-type-react-select-container rank-type",
    },

    status: {
      0: "INACTIVE",
      1: "ACTIVE",
      2: "COMPLETED",
      3: "DELETED",
      4: "FORFEITED",
      5: "EXPIRED",
      6: "FAILED",
      100: "LOCKED",
      101: "AWARDED",
    },

    statusColors: {
      0: "danger",
      1: "success",
      2: "success",
      3: "danger",
      4: "primary",
      5: "secondary",
      6: "danger",
      100: "dark",
      101: "success",
    },
  },


  objectivesTemplates: {
    typeIds: {
      missionSuccess: 1,
      missionFail: 2,
      missionRegression: 3,
    },
    types: {
      1: "MISSION SUCCESS",
      2: "MISSION FAIL",
      3: "MISSION REGRESSION",
    },
    statusIds: {
      inactive: 0,
      active: 1,
      deleted: 2,
    },
    status: {
      0: "INACTIVE",
      1: "ACTIVE",
      2: "DELETED",
    },
  },
  missionsTemplates: {
    status: {
      0: "INACTIVE",
      1: "ACTIVE",
      2: "COMPLETED",
      3: "DELETED",
    },
  },
  campaignGroups: {
    status: [
      {value: "0", label: "Inactive", color: "danger"},
      {value: "1", label: "Active", color: "success"},
      {value: "2", label: "Deleted", color: "danger"},
      {value: "3", label: "Archived", color: "dark"},
    ],
    types: [
      {value: "1", label: "Mission"},
      {value: "2", label: "Tournament Casino"},
      {value: "3", label: "Tournament Sport"},
    ]
  },
};

export default valuesSrc;