import { lotteriesConstants } from "../constants";

export const loadLotteries = (data) => ({
  type: lotteriesConstants.LOAD,
  data
});

export const loadedLotteries = (data) => ({
  type: lotteriesConstants.LOADED,
  data: data
});