import { produce } from "immer";
import { bulkDeductionGrant } from "../constants";

const IDLE_STATUS = 0;
const IN_PROGRESS_STATUS = 1;
const DONE_STATUS = 2;

const initialState = {
  status: IDLE_STATUS,
  failed: 0,
  succeded: 0,
  players: {},
  total: 0,
  elapsedTime: 0,
  requests: [],
  currentRequest: 0,
};

const bulkDeductionGrantReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case bulkDeductionGrant.START: {
        draft.status = IN_PROGRESS_STATUS;
        draft.requests = action.data.requests;
        draft.total = action.data.requests.length;
        draft.elapsedTime = action.data.requests.length * 500; // estimated milliseconds until we're done
        break;
      }
      case bulkDeductionGrant.DONE: {
        if (action.data) {
          const request = { ...draft.requests[draft.currentRequest] };

          draft.elapsedTime = (draft.requests.length - (action.data.currentRequest + 1)) * action.data.ellapsedTimePerRequest;
          draft.currentRequest = action.data.currentRequest + 1;

          if (action.data.success) {
            draft.players[request.playerId] = "Success";
            draft.succeded = draft.succeded + 1;

            request.response = action.data.response;
            draft.requests[draft.currentRequest - 1] = request;
          } else {
            draft.players[request.playerId] = action.data.message;
            draft.failed = draft.failed + 1;
          }

          if (draft.currentRequest === draft.requests.length) {
            draft.status = DONE_STATUS;
          }
        }
        break;
      }
      case bulkDeductionGrant.CLEAR: {
        draft.status = IDLE_STATUS;
        draft.failed = 0;
        draft.succeded = 0;
        draft.players = {};
        draft.total = 0;
        draft.elapsedTime = 0;
        draft.requests = [];
        draft.currentRequest = 0;
        break;
      }
      default:
        /* noop */
        break;
    }
  });

export default bulkDeductionGrantReducer;