import React from "react";

import UploadOption from "../../ui/ui-components/upload-option-micros-cdn";

const MFileField = ({ forwardedRef, ...props }) => {
  return (
    <UploadOption
      {...props}
      ref={forwardedRef}
      fullWidth
      name={props.columnDef.field}
      pdf={props.columnDef.pdf ? true : false}
      onChange={event => {
        props.onChange(event.target.value);
      }}
    />
  );
};

const MFileFieldRef = React.forwardRef((props, ref) => {
  return <MFileField {...props} forwardedRef={ref} />;
});

export default MFileFieldRef;
