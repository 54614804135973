import moment from "moment";

export const monthList = (startTimestamp, endTimestamp, reversed = true) => {
  const startMonth = moment.unix(startTimestamp).startOf("month");
  const endMonth = moment.unix(endTimestamp).startOf("month");
  const months = [];
  let currentMonth = reversed ? endMonth.clone() : startMonth.clone();
  if (reversed) {
    while (currentMonth.isSameOrAfter(startMonth)) {
      months.push(currentMonth.format("MMMM YYYY"));
      currentMonth.subtract(1, "month");
    }
  } else {
    while (currentMonth.isSameOrBefore(endMonth)) {
      months.push(currentMonth.format("MMMM YYYY"));
      currentMonth.add(1, "month");
    }
  }
  return months;
};