import { sitesConstants } from "../constants";

export const sitesFetch = data => ({
  type: sitesConstants.FETCH,
  data,
});

export const sitesFetchAll = data => ({
  type: sitesConstants.FETCH_ALL,
  data,
});

export const setcurrentSite = siteId => ({
  type: sitesConstants.SET_CURRENT_SITE,
  siteId,
});

export const sitesLoad = data => ({
  type: sitesConstants.LOADED,
  data,
});

export const sitesLoadAll = data => ({
  type: sitesConstants.LOADED_ALL,
  data,
});

export const sitesIsLoading = data => ({
  type: sitesConstants.IS_LOADING,
  data,
});

export const sitesAddSite = data => ({
  type: sitesConstants.ADD_SITE,
  data,
});

export const sitesCopyTenantConfig = data => ({
  type: sitesConstants.COPY_SITE_CONFIG,
  data,
});

export const siteRemoveRemote = data => ({
  type: sitesConstants.DELETE_SITE_REMOTE,
  data,
});
export const siteRemoveLocal = data => ({
  type: sitesConstants.DELETE_SITE_LOCAL,
  data,
});
