import { produce } from "immer";
import { druidConstants } from "../constants";

const initialState = {
  minConfidenceLevel: 0,
  minAge: 18,
  maxAge: 100,
};

const druidReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case druidConstants.LOADED: {

        // console.log("druidConstants.LOADED", action.data);
        if (action.data.success && typeof action.data.druid !== "undefined") {
          Object.keys(action.data.druid).forEach((key) => {
            let value = action.data.druid[key];
            if (key === "minConfidenceLevel") {
              value = parseFloat(value);
            } else {
              value = parseInt(value, 10);
            }
            draft[key] = value;
          });
        }

        break;
      }
      default:
        /* noop */
        break;
    }
  });

export default druidReducer;