import React, { PureComponent } from 'react';
import store from './store';

export class LocalStorageValueProvider extends PureComponent {
  constructor(props) {
    super(props);

    this.onSaveToStore = (value) => {
      const { storageKey } = this.props;
      try {
        store.setObject(storageKey, value);
      }
      catch (error) {
        console.error(error);
      }
      this.setState({ value });
    };
    const { storageKey, defaultValue } = props;
    this.state = {
      value: store.getObject(storageKey, defaultValue),
    };
  }

  render() {
    const { children } = this.props;
    const { value } = this.state;
    return <>{children(value, this.onSaveToStore)}</>;
  }
}