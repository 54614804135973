import ENTranslations from "./langs/en";
import ROTranslations from "./langs/ro";

const translations = {
  "en": {
    "translation": ENTranslations
  },
  "ro": {
    "translation": ROTranslations
  }
};

export default translations;
