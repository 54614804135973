const DefaultTimeZone = "browser";
let defaultTimeZoneResolver = () => DefaultTimeZone;

export const setTimeZoneResolver = (resolver) => {
  defaultTimeZoneResolver = resolver !== null && resolver !== void 0 ? resolver : defaultTimeZoneResolver;
};

export const getTimeZone = (options) => {
  var _a, _b;
  return (_b = (_a = options === null || options === void 0 ? void 0 : options.timeZone) !== null && _a !== void 0 ? _a : defaultTimeZoneResolver()) !== null && _b !== void 0 ? _b : DefaultTimeZone;
};