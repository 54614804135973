import { userConstants } from "../constants";
//import { authConstants } from "../constants";



export const authStart = credentials => ({
	type: userConstants.LOGIN_REQUEST,
	// type: authConstants.START,
	credentials,
});

export const authSuccess = data => ({
	type: userConstants.AUTH_CHECK_SUCCESS,
	// type: authConstants.SUCCESS,
	data,
});

export const authError = error => ({
	type: userConstants.AUTH_CHECK_FAILURE,
	//type: authConstants.ERROR,
	error,
});

// used in \admin_ui\src\index.jsx:
export const authCheck = () => ({
	type: userConstants.AUTH_CHECK_REQUEST,
	// type: authConstants.CHECK
});

export const authLogout = () => ({
	//type: userConstants.LOGIN_FAILURE,
	type: userConstants.LOGOUT,
});
export const authLogoutSuccess = () => ({
	type: userConstants.LOGOUT,
	// type: authConstants.LOGOUT_SUCCESS,
});

export const authInProgress = active => ({
	type: userConstants.AUTH_IN_PROGRESS,
	active,
});


export const handleLogin = () => ({
	type: "xxxxxx",
});
export const handleLogout = () => ({
	type: "xxxxxx",
});