import { contentManagementConstants } from "../constants";

export const contentManagementTypesDefRequest = () => ({
  type: contentManagementConstants.DEFINITION_TYPES_REQUEST,
});

export const contentManagementTypesDefReceived = typesDef => ({
  type: contentManagementConstants.DEFINITION_TYPES_RECEIVED,
  definitionTypes: typesDef,
});

export const contentManagementAttributesListRequest = () => ({
  type: contentManagementConstants.ATTRIBUTES_LIST_REQUEST,
});

export const contentManagementAttributesListReceived = attributes => ({
  type: contentManagementConstants.ATTRIBUTES_LIST_RECEIVED,
  attributesList: attributes,
});
