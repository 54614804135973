// ** React Imports
import React from 'react';

// ** Custom Components
import NavbarUser from './NavbarUser';

const ThemeNavbar = props => {
  return <NavbarUser {...props} />;
}

export default ThemeNavbar
