import { produce } from "immer";
import { tagsThreeConstants } from "../constants";

const initialState = {
  tree: {}
};

const tagsTreeReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case tagsThreeConstants.TAGS_TREE_LOADED: {
        draft.tree = action.data;
        break;
      }
      default:
        /* noop */
        break;
    }
  });

export default tagsTreeReducer;
