import React, { useContext } from 'react';
import { Row, Col } from 'reactstrap';

import SubscribersGained from "../components/micros-home-components/SubscribersGained";
import DepositsReceived from "../components/micros-home-components/DepositsReceived";
import WithdrawalsSent from "../components/micros-home-components/WithdrawalsSent";
import OrdersBarChart from "../components/micros-home-components/OrdersBarChart";
import ProfitLineChart from '../components/micros-home-components/ProfitLineChart';
import Earnings from '../components/micros-home-components/Earnings';
import RevenueReport from '../components/micros-home-components/RevenueReport';
import Profit from "../components/micros-home-components/Profit";
import { kFormatter } from '@utils';
import { ThemeColors } from '@src/utility/context/ThemeColors'

import "../style/Home.scss";

const Home = (props) => {
  // ** Context
  const { colors } = useContext(ThemeColors);

  return (<div className="micros-home">
    <Row className='match-height'>
      <Col lg='3' sm='6'>
        <SubscribersGained kFormatter={kFormatter} />
      </Col>
      <Col lg='3' sm='6'>
        <DepositsReceived kFormatter={kFormatter} color={colors.success.main} colorName={'success'} />
      </Col>
      <Col lg='3' sm='6'>
        <WithdrawalsSent kFormatter={kFormatter} color={colors.danger.main} colorName={'danger'} />
      </Col>
      <Col lg='3' sm='6'>
        <Profit kFormatter={kFormatter} color={colors.info.main} colorName={'info'} />
      </Col>
    </Row>
    <Row className='match-height'>
      <Col lg='4' md='12'>
        <Row className='match-height'>
          <Col lg='6' md='3' xs='6'>
            <OrdersBarChart warning={colors.warning.main} />
          </Col>
          <Col lg='6' md='3' xs='6'>
            <ProfitLineChart info={colors.info.main} />
          </Col>
          <Col lg='12' md='6' xs='12'>
            <Earnings success={colors.success.main} />
          </Col>
        </Row>
      </Col>
      <Col lg='8' md='12'>
        <RevenueReport primary={colors.primary.main} warning={colors.warning.main} />
      </Col>
    </Row>

  </div>);
}

export default Home;
